import { useMyId } from "@pages/u/u.webstatemgr";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { useQuery } from "convex/react";
import { Option } from "effect";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "shared/convex/_generated/api";
import type { OnboardImportedProfileST } from "shared/convex/Onboard/Onboard.Types";
import { UROUTES } from "shared/routes/u.routes";
import { MyProfileForm } from "src/domains/user/my-profile-form";

export const HpOnboardSetProfilePage: React.FC = () => {
  const myId = useMyId();

  const mbMyImportedProfile = useQuery(
    api.Onboard.OnboardFns.getMyMbImportedProfile,
    {
      userId: myId,
    }
  );

  if (mbMyImportedProfile === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return <LoadedImportView importedProfile={mbMyImportedProfile} />;
};

export const LoadedImportView: React.FC<{
  importedProfile: {
    profileData: typeof OnboardImportedProfileST.Encoded;
    profilePhotoInfo: {
      downloadUrl: string;
    } | null;
    matchedModalitySlugs: string[] | undefined;
  } | null;
}> = ({ importedProfile }) => {
  const nav = useNavigate();
  const mbBaseProfileData = useMemo(
    () =>
      Option.fromNullable(importedProfile?.profileData).pipe(
        Option.map((mpd) => {
          return {
            firstName: mpd.firstName,
            lastName: mpd.lastName,
            selectedModalitySlugs: importedProfile?.matchedModalitySlugs ?? [],
            profilePhoto: importedProfile?.profilePhotoInfo ?? undefined,
            bio: mpd.biography,
          };
        }),
        Option.getOrUndefined
      ),
    [importedProfile]
  );

  return (
    <div className="flex-1 flex flex-col mt-20 items-center">
      <MyProfileForm
        initialData={mbBaseProfileData}
        onSuccessSubmit={() => {
          nav(UROUTES.HP.ONBOARD_COMPLETE.path);
        }}
        button={{ title: "Next" }}
      />
      {/* <HpOnboardSetProfileForm
        mbInitialData={{
          baseProfileData: mbBaseProfileData,
          initialKnownModalitySlugs:
            importedProfile?.matchedModalitySlugs ?? [],
        }}
      /> */}
    </div>
  );
};
