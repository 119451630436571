import { Listbox, Transition } from "@headlessui/react";
import { createEvent, createStore } from "effector";
import { useUnit } from "effector-react";
import { FormFocusVM } from "frontend-shared/src/form-focus.vm";
import { IoCheckmark, IoChevronDown } from "react-icons/io5";
import { Fragment } from "react/jsx-runtime";

export class DurationPickerVM {
  $selectedDurationMins = createStore<number>(60);
  setDurationMins = createEvent<number>();

  constructor() {
    this.$selectedDurationMins.on(
      this.setDurationMins,
      (_, newValue) => newValue
    );
  }
}

export const DurationSelector: React.FC<{
  vm: DurationPickerVM;
}> = ({ vm }) => {
  const selectedDurationMins = useUnit(vm.$selectedDurationMins);
  const selectedDurationText = `${selectedDurationMins} minutes`;
  const focusedField = useUnit(FormFocusVM.getInstance().$focusedField);

  return (
    <div className="w-full">
      <label className="block text-sm font-medium text-gray-700 mb-1">
        Duration
      </label>
      <Listbox
        value={selectedDurationMins}
        onChange={(value) => {
          vm.setDurationMins(value);
        }}
      >
        <div className="relative">
          <Listbox.Button
            onClick={() =>
              FormFocusVM.getInstance().setFocusedField("duration")
            }
            className="relative w-full cursor-default rounded-lg bg-white py-2.5 pl-3 pr-10 text-left border border-gray-300 hover:border-gray-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <span className="block truncate text-gray-900">
              {selectedDurationText}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <IoChevronDown
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          {focusedField === "duration" && (
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                {[60, 90, 120].map((duration) => (
                  <Listbox.Option
                    key={duration}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                      }`
                    }
                    value={duration}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {duration} minutes
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                            <IoCheckmark
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          )}
        </div>
      </Listbox>
    </div>
  );
};
