import { EyeIcon } from "@webapp/componentsicons/eyeicon";
import { PrimaryButton } from "@webapp/componentsprimitives/button";
import { useConvexAuth } from "convex/react";
import { Effect } from "effect";
import { useUnit } from "effector-react";
import { LoginFormVM } from "frontend-shared/src/auth/login.vm";
import { useFirebaseJs } from "frontend-shared/src/firebase";
import { useOnce } from "frontend-shared/src/util";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const LoginForm: React.FC<{
  prefilledEmail?: string;
}> = ({ prefilledEmail }) => {
  const nav = useNavigate();
  const firebaseJs = useFirebaseJs();
  const vm = useOnce(
    () =>
      new LoginFormVM({
        prefilledEmail,
        submitForm: async (p) => {
          console.log("SUBMITTED YO!!!! ", p);
          const er = await Effect.runPromise(
            firebaseJs.signinWithEmailPasswordEff(p).pipe(Effect.either)
          );
          return er;
        },
        forgotPassword: async (email: string) => {
          await Effect.runPromise(firebaseJs.sendPasswordResetEmailEff(email));
        },
      })
  );

  const [submitErrorMsg, forgotPwdError] = useUnit([
    vm.$submitErrorMsg,
    vm.$forgotPwdError,
  ]);

  const { isAuthenticated } = useConvexAuth();

  useEffect(() => {
    if (isAuthenticated) {
      console.log("FIRING AUTH LOCAL VERIFIED EVT");
      vm.authLocalVerifiedEvt();
      nav("/u");
      //   nav(UROUTES.HP.ONBOARD_SET_PROFILE.path);
    }
  }, [isAuthenticated]);

  return (
    <form
      className="flex flex-col gap-5"
      onSubmit={(e) => {
        e.preventDefault();
        vm.submitEvt();
      }}
    >
      <EmailInput vm={vm} />
      <PasswordInput vm={vm} />
      <PrimaryButton
        title="Login"
        onClick={() => {
          vm.submitEvt();
        }}
      />

      <div
        className="text-vid-black-400 text-[18px] font-roboto-flex self-center cursor-pointer hover:text-vid-black-600"
        onClick={() => vm.forgotPwdClickEvt()}
      >
        Forgot password?
      </div>

      <div className="text-vid-black-400 text-[18px] font-roboto-flex self-center">
        {submitErrorMsg && (
          <div className="text-red-500 mt-2 text-lg font-semibold self-center">
            {submitErrorMsg}
          </div>
        )}

        {forgotPwdError && (
          <div className="text-red-500 mt-2 text-lg font-semibold self-center">
            {forgotPwdError}
          </div>
        )}
      </div>
    </form>
  );
};

const PasswordInput: React.FC<{
  vm: LoginFormVM;
}> = ({ vm }) => {
  const [isShowingPassword, password, isPending] = useUnit([
    vm.$isShowingPassword,
    vm.$password,
    vm.submitEffect.pending,
  ]);
  return (
    <div className="relative">
      <input
        type={isShowingPassword ? "text" : "password"}
        placeholder="Password"
        className="text-input pr-10 w-full"
        id="password"
        value={password}
        onChange={(e) => vm.passwordTextEvt(e.target.value)}
      />
      <button
        type="button"
        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none"
        onClick={() => {
          vm.toggleShowPasswordEvt(!isShowingPassword);
        }}
      >
        <EyeIcon showPassword={isShowingPassword} />
      </button>
    </div>
  );
};

const EmailInput: React.FC<{
  vm: LoginFormVM;
}> = ({ vm }) => {
  const [email] = useUnit([vm.$email]);
  return (
    <input
      type="email"
      placeholder="Email"
      className="text-input"
      id="email"
      value={email}
      onChange={(e) => vm.emailTextEvt(e.target.value)}
    />
  );
};
