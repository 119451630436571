import { Effect, Option } from "effect";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { E, epipe, RD, Rx, TE } from "../prelude";
import { useAuthedFetchTE, useTaskEither } from "frontend-shared/src/api.mgr";
import { pipe } from "fp-ts/lib/function";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function useDebouncedOnClick(onClick: () => void, delay: number = 300) {
  return useDebouncedCallback(onClick, delay);
}

export function useBehaviorSubject<T>(subject: Rx.BehaviorSubject<T>) {
  const [value, setValue] = useState(subject.getValue());
  useEffect(() => {
    const sub = subject.subscribe((s) => {
      console.log("SUBJECT! ", s);
      setValue(s);
    });
    return () => {
      console.log("UNUSBCRIBING!");
      sub.unsubscribe();
    };
  }, [subject]);
  return value;
}

export function convertObservableToBehaviorSubject<T>(
  observable: Rx.Observable<T>,
  initValue: T
): Rx.BehaviorSubject<T> {
  const subject = new Rx.BehaviorSubject(initValue);

  observable.subscribe(subject);

  return subject;
}

export function convertObservableToSubject<T>(
  observable: Rx.Observable<T>
): Rx.Subject<T> {
  const subject = new Rx.Subject<T>();

  observable.subscribe(subject);

  return subject;
}

export function currentObservableValue<T>(
  observable: Rx.Observable<T>
): Promise<T> {
  return new Promise((resolve) => {
    const sub = observable.subscribe((v) => {
      resolve(v);
      sub.unsubscribe();
    });
  });
}

export function safeParseInt(s: string): number | undefined {
  const n = parseInt(s);
  return isNaN(n) ? undefined : n;
}

export function useScrollToAnchor() {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
}

export function isNonNull<T>(value: T): value is NonNullable<T> {
  return value != null;
}

export function delayP(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function taskEitherToEff<E, V>(
  te: TE.TaskEither<E, V>
): Effect.Effect<V, E, never> {
  return epipe(
    Effect.promise(te),
    Effect.flatMap((eres) =>
      E.isLeft(eres) ? Effect.fail(eres.left) : Effect.succeed(eres.right)
    )
  );
}

export const FetchRemoteDataView = <E, V>(p: {
  fetchTE: TE.TaskEither<E, V>;
  loadedView: (v: V) => JSX.Element;
  deps?: any[];
}) => {
  const rdV = useTaskEither(p.fetchTE, p.deps ?? []);

  return pipe(
    rdV,
    RD.fold3(
      () => <FullContainerLoadingSpinner />,
      (error) => <div>{JSON.stringify(error)}</div>,
      (v) => p.loadedView(v)
    )
  );
};

export const RemoteDataView = <E, V>(p: {
  rd: RD.RemoteData<E, V>;
  loadedView: (v: V) => JSX.Element;
}) => {
  return pipe(
    p.rd,
    RD.fold3(
      () => <FullContainerLoadingSpinner />,
      (error) => <div>{JSON.stringify(error)}</div>,
      (v) => p.loadedView(v)
    )
  );
};

export const SuccessOnlyViewO = <V,>(p: {
  mbV: Option.Option<V>;
  loadedView: (v: V) => JSX.Element;
}) => {
  return Option.match(p.mbV, {
    onNone: () => <FullContainerLoadingSpinner />,
    onSome: (v) => p.loadedView(v),
  });
};
