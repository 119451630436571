import { TabView } from "@/src/components/tabs";
import { MemberCard } from "@/src/domains/communities/community/members/member-card";
import { InviteToCommunityRightDrawer } from "@/src/domains/communities/invite-to-community.form";
import {
  CommunityDashLayout,
  CommunityDashTabs,
} from "@/src/domains/communities/shared/dash.components";
import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { useMyId } from "@pages/u/u.webstatemgr";
import { apiMgr } from "@webapp/backend";
import { AvatarCirclesWithCountInfo } from "@webapp/components/avatar.tc";
import {
  InversePrimaryButton,
  PrimaryButton,
} from "@webapp/components/primitives/button";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { useMutation, useQuery } from "convex/react";
import { Match } from "effect";
import {
  CommunitySamContext,
  CommunityStateMgr,
  useCommunitySam,
  type CommunityPageTab,
} from "frontend-shared/src/mgrs/state-mgrs/community.statemgr";
import { useNow, useOnce } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useTypedSearchParams } from "react-router-typesafe-routes/dom";
import { api } from "shared/convex/_generated/api";
import { UROUTES } from "shared/routes/u.routes";
import { type CommunityInfo } from "shared/schemas/community/community.schemas";
import { ScreenActions } from "shared/types/screen-action.types";
import { SimpleUserWithProfilePhoto } from "shared/types/user.types";
import { isNotNullOrUndefined } from "shared/util";
import { useConvexCli } from "src/convex-cli";
import {
  CommunityPostCvx,
  WriteAPostSection,
} from "../../../../../../domains/communities/community/community-post/community-post.fc";
import { MyCommunitiesProfileCard } from "../components/my-profile-card";

export const HpDashboardCommunityPageLayout: React.FC = () => {
  const communitySlug = useParams()["community"]!;

  const communityInfo = useQuery(
    api.Community.CommunityScreenFns.getCommunityInfo,
    {
      communitySlug,
    }
  );

  if (communityInfo === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return <LoadedView community={communityInfo} />;
};

const LoadedView: React.FC<{ community: CommunityInfo }> = ({ community }) => {
  const myId = useMyId();
  const convex = useConvexCli();
  const sam = useOnce(
    () => new CommunityStateMgr(community, apiMgr, convex, myId)
  );
  return (
    <CommunitySamContext.Provider value={sam}>
      <HpDashboardCommunityTabLayout />
    </CommunitySamContext.Provider>
  );
};

const HpDashboardCommunityTabLayout: React.FC = () => {
  const location = useLocation();
  const isEventsTab = location.pathname.toLowerCase().includes("events");
  const [maxHeight, setMaxHeight] = useState("500px");
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    if (isEventsTab) {
      setOpacity(0);
      setMaxHeight("0px");
    } else {
      setOpacity(1);
      setMaxHeight("500px");
    }
  }, [isEventsTab]);

  const sam = useCommunitySam();
  const hpState = useHpState();
  const nav = useNavigate();
  const [{ action }] = useTypedSearchParams(
    UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY
  );
  const isJoiningOrUnjoining = useObservableEagerState(
    sam.isJoiningOrUnjoining$
  );
  const community = useObservableEagerState(sam.community$);

  const onJoinCommunityButtonClick = useMutation(
    api.Community.CommunityScreenFns.onJoinButtonClick
  );

  const now = useNow();
  const topLiveEvent = useQuery(
    api.Screens.Community.CommunityEventsScreenFns.getTopLiveEvent,
    {
      communitySlug: sam.communitySlug,
      now,
    }
  );

  React.useEffect(() => {
    if (action) {
      const asActionObj =
        ScreenActions.CommunityScreen.decodeActionFromString(action);

      if (asActionObj.autoJoin === "true" && !community.amIJoined) {
        onJoinCommunityButtonClick({
          communitySlug: community.slug,
          joinAction: "join",
        }).catch();
      }
    }
  }, [action, community.amIJoined]);

  return (
    <CommunityDashLayout
      topBanner={{
        maxHeight,
        opacity,
        props: {
          isPrivate: community.isPrivate,
          communityColorName: community.color,
          circleImageUrl: null,
          title: { _tag: "TEXT", text: community.name },
          nextToTitleFc: <TopBannerNextToTitleView />,
          rightView: (
            <div className="flex items-center gap-2">
              <PrimaryButton
                title="Invite"
                dims={{ width: 171 }}
                onClick={() => {
                  hpState.uMgr.rightDrawerVM.openEvt(
                    <InviteToCommunityRightDrawer
                      communitySlug={community.slug}
                    />
                  );
                }}
              />

              <InversePrimaryButton
                title={community.amIJoined ? "Joined" : "Join"}
                onClick={() => {
                  const joinAction = community.amIJoined ? "unjoin" : "join";
                  const action = onJoinCommunityButtonClick({
                    communitySlug: community.slug,
                    joinAction,
                  });

                  action.then((_) => {
                    hpState.dashboardState.showBottomToast({
                      msg: joinAction === "join" ? "Joined" : "Unjoined",
                      duration: { _tag: "SECONDS", seconds: 1 },
                      reload: true,
                    });
                  });
                }}
              />
            </div>
          ),
        },
      }}
      extraTopView={
        isNotNullOrUndefined(topLiveEvent) && isEventsTab ? (
          <TopLiveBanner
            topLiveEvent={topLiveEvent}
            onJoin={() => {
              if (topLiveEvent.mbSessionId === null) {
                return; // TODO: Report error to sentry maybe?
              }
              nav(
                UROUTES.SESSIONS.SESSION_ID.RTC.buildPath({
                  sessionId: topLiveEvent.mbSessionId!,
                })
              );
            }}
          />
        ) : (
          <></>
        )
      }
      isFullWidth={isEventsTab}
    >
      {isJoiningOrUnjoining ? (
        <FullContainerLoadingSpinner />
      ) : (
        <div className="flex flex-col gap-8">
          <div className="flex-1 flex gap-8 self-stretch">
            <div className="flex-1 flex flex-col min-w-0 gap-6">
              <CommunityDashTabs communitySlug={community.slug} />
              <div className="flex-1 flex flex-col">
                {community.amIJoined ? <Outlet /> : <UnjoinedContentView />}
              </div>
            </div>
            {!location.pathname.includes("events") && <MyProfile />}
          </div>
        </div>
      )}
    </CommunityDashLayout>
  );
};

const UnjoinedContentView: React.FC = () => {
  const hpState = useHpState();
  const sam = useCommunitySam();
  const onJoinCommunityButtonClick = useMutation(
    api.Community.CommunityScreenFns.onJoinButtonClick
  );
  return (
    <div
      className="basis-[300px] grow-0 shrink-0 flex flex-col py-8 items-center underline cursor-pointer text-vid-purple text-xl"
      onClick={() => {
        onJoinCommunityButtonClick({
          communitySlug: sam.communitySlug,
          joinAction: "join",
        }).then((_) => {
          hpState.dashboardState.showBottomToast({
            msg: "Joined",
            duration: { _tag: "SECONDS", seconds: 1 },
            reload: true,
          });
        });
      }}
    >
      Join to view the discussion
    </div>
  );
};

const TopLiveBanner: React.FC<{
  topLiveEvent: { title: string };
  onJoin: () => void;
}> = ({ topLiveEvent, onJoin }) => {
  return (
    <div className="bg-vid-purple py-4 px-6 rounded-[10px] mb-10 flex items-center justify-between">
      <div className="text-white font-medium font-roboto-flex">
        {`${topLiveEvent.title} is live`}
      </div>
      <button
        className="bg-white text-vid-black-900 px-6 py-3 rounded-[35px] text-sm"
        onClick={onJoin}
      >
        Join
      </button>
    </div>
  );
};

const TopBannerNextToTitleView: React.FC = () => {
  const sam = useCommunitySam();
  const communityMembers = useQuery(
    api.Community.CommunityScreenFns.listAllJoinedMembers,
    {
      communitySlug: sam.communitySlug,
    }
  );

  if (communityMembers === undefined) {
    return null;
  }

  return (
    <Link
      to={UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.MEMBERS.buildPath(
        { community: sam.communitySlug }
      )}
    >
      <AvatarCirclesWithCountInfo
        sources={communityMembers.slice(0, 3).map((m) => m.profilePhoto)}
        countText={`${communityMembers.length} ${communityMembers.length === 1 ? "Member" : "Members"}`}
        size={40}
      />
    </Link>
  );
};

const TAB_NAMES = ["Discussion", "Events", "Members", "Learning"] as const;

function useGetCurrentTab() {
  const { pathname } = useLocation();

  return TAB_NAMES.find((tab) =>
    pathname.toLowerCase().includes(tab.toLowerCase())
  );
}

const Tab: React.FC<{ tab: CommunityPageTab; isCurrent?: boolean }> = ({
  tab,
}) => {
  const sam = useCommunitySam();
  const community = useObservableEagerState(sam.community$);

  return (
    <TabView
      title={tab}
      linkTo={Match.value(tab).pipe(
        Match.when("Discussion", () =>
          UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.DISCUSSION.buildPath(
            { community: community.slug }
          )
        ),
        Match.when("Learning", () =>
          UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.LEARNING.buildPath(
            { community: community.slug }
          )
        ),
        Match.when("Members", () =>
          UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.MEMBERS.buildPath(
            { community: community.slug }
          )
        ),
        Match.when("Events", () =>
          UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.EVENTS.ALL.buildPath(
            { community: community.slug }
          )
        ),
        Match.exhaustive
      )}
      onClick={() => {}}
    />
  );
};

export const CommunityDiscussionFeed: React.FC = () => {
  return (
    <div className="flex-1 flex flex-col gap-8 overflow-y-auto overscroll-x-none">
      <WriteAPostSection />
      <PostsSection />
    </div>
  );
};

export const CommunityMembersTab: React.FC = () => {
  const sam = useCommunitySam();

  const allMembers = useQuery(
    api.Community.CommunityScreenFns.listAllJoinedMembers,
    {
      communitySlug: sam.community$.value.slug,
    }
  );

  if (allMembers === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return <AllMembersTabContent members={allMembers} />;
};

const AllMembersTabContent: React.FC<{
  members: SimpleUserWithProfilePhoto[];
}> = ({ members }) => {
  return (
    <div className="flex flex-col gap-8">
      <div className="flex gap-4 flex-wrap">
        {members.map((m, index) => {
          return (
            <MemberCard
              key={m.id}
              index={index}
              userId={m.id}
              name={m.name!}
              profilePhoto={m.profilePhoto}
              isAvailable={false}
              modalities={[]}
            />
          );
        })}
      </div>
    </div>
  );
};

const MyProfile: React.FC = () => {
  const globalProfile = useQuery(api.User.Hp.HpFns.getMyGlobalProfile);

  return (
    <div>
      <MyCommunitiesProfileCard globalProfile={globalProfile} />
    </div>
  );
};

const PostsSection: React.FC = () => {
  const sam = useCommunitySam();
  const community = useObservableEagerState(sam.community$);

  const postsRes = useQuery(api.Community.CommunityScreenFns.getCommunityFeed, {
    communitySlug: community.slug,
  });

  if (postsRes === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-8">
        {postsRes.posts.map((post) => (
          <CommunityPostCvx key={post._id} enrichedPost={post} />
        ))}
      </div>
    </div>
  );
};
