import type { Rx } from "@/src/prelude";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { useObservableEagerState } from "observable-hooks";
import * as React from "react";
import type { ChatChannelUserSetup } from "shared/types/chat.types";
import { isNotNullOrUndefined, isNullOrUndefined } from "shared/util";
import * as stream from "stream-chat";
import { StreamChat } from "stream-chat";
import {
  Channel,
  ChannelHeader,
  Chat,
  MessageInput,
  MessageList,
  Thread,
  Window,
  type DefaultStreamChatGenerics,
} from "stream-chat-react";
import "stream-chat-react/dist/css/v2/index.css";

interface ChatChannelConfig {
  type: string;
  id: string;
}

export const ChatFC: React.FC<{
  chatCli$: Rx.BehaviorSubject<
    StreamChat<DefaultStreamChatGenerics> | undefined
  >;
  userChatSetup: typeof ChatChannelUserSetup.Encoded;
}> = ({ chatCli$, userChatSetup }) => {
  const chatCli = useObservableEagerState(chatCli$);

  if (chatCli === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return <LoadedChatFC chatCli={chatCli} userChatSetup={userChatSetup} />;
};

const LoadedChatFC: React.FC<{
  chatCli: StreamChat<stream.DefaultGenerics>;
  userChatSetup: typeof ChatChannelUserSetup.Encoded;
}> = ({ chatCli, userChatSetup }) => {
  const [mbChannel, setMbChannel] =
    React.useState<stream.Channel<DefaultStreamChatGenerics> | null>(null);

  React.useEffect(() => {
    async function setupChannelWatch(cus: typeof ChatChannelUserSetup.Encoded) {
      if (isNotNullOrUndefined(cus)) {
        const { channelConfig, memberIds } = cus.setup!;
        const cli = chatCli;
        const channel = cli.channel(
          channelConfig.channelType,
          channelConfig.channelId,
          {
            members: [...memberIds],
          }
        );
        await channel.watch();

        return channel;
      }

      return null;
    }

    if (userChatSetup.setup) {
      setupChannelWatch(userChatSetup).then(setMbChannel);
    }
  }, [userChatSetup]);

  if (isNullOrUndefined(mbChannel)) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <Chat client={chatCli}>
      {/* <ChannelList /> */}
      <Channel channel={mbChannel}>
        <Window>
          <ChannelHeader />
          <MessageList />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </Chat>
  );
};
