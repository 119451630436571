import { Effect } from "effect";
import {
  FileSchemas,
  type FileLike,
  type InMemoryFile,
  type UploadFileToCloudinaryResponse,
} from "shared/schemas/file.schemas";
import { ImageSrc } from "shared/types/miscellaneous.types";

export class FileUtils {
  static fileAsInMemoryFile = (file: File): Effect.Effect<InMemoryFile> =>
    Effect.gen(this, function* () {
      const fileMetadata = FileSchemas.FileMetadata.unsafeDecodeFromFile(file);
      const b64File = yield* FileUtils.fileAsBase64(file);
      return { base64String: b64File, fileMetadata };
    });

  static fileAsImgSrc = (file: File): Effect.Effect<ImageSrc> =>
    Effect.gen(this, function* () {
      const inMemoryFile = yield* FileUtils.fileAsInMemoryFile(file);
      return ImageSrc.fromInMemoryFile(inMemoryFile);
    });

  static fileAsBase64 = (file: File): Effect.Effect<string> =>
    Effect.async<string>((resume) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === "string") {
          resume(Effect.succeed(reader.result));
        } else {
          resume(Effect.die(new Error("FileReader result is not a string")));
        }
      };
      reader.onerror = () => {
        resume(Effect.die(new Error("Failed to read file")));
      };
      reader.readAsDataURL(file);
    });

  static blobAsBase64 = (blob: Blob): Effect.Effect<string> =>
    Effect.async<string>((resume) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === "string") {
          resume(Effect.succeed(reader.result));
        } else {
          resume(Effect.die(new Error("FileReader result is not a string")));
        }
      };
      reader.onerror = () => {
        resume(Effect.die(new Error("Failed to read blob")));
      };
      reader.readAsDataURL(blob);
    });

  static downloadFile = (p: {
    presignedDownloadUrl: string;
  }): Effect.Effect<{ blob: Blob; metadata: FileSchemas.FileMetadata }> =>
    Effect.gen(function* (_) {
      const response = yield* Effect.promise(() =>
        fetch(p.presignedDownloadUrl)
      );
      const blob = yield* Effect.promise(() => response.blob());

      const fileMetadata = FileSchemas.FileMetadata.unsafeDecodeFromFile(blob);

      return { blob, metadata: fileMetadata };
    });
}

export class FileUploadUtils {
  static uploadImageSrcToCloudinary = (p: {
    secureUploadUrl: string;
    imgSrc: ImageSrc;
  }): Effect.Effect<UploadFileToCloudinaryResponse> =>
    Effect.gen(this, function* () {
      const formData = new FormData();
      formData.append("file", p.imgSrc.src);

      const uploadResponse = yield* Effect.promise(() =>
        fetch(p.secureUploadUrl, {
          method: "POST",
          body: formData,
        })
      );

      if (!uploadResponse.ok) {
        const errorText = yield* Effect.promise(() => uploadResponse.text());
        throw new Error(`Cloudinary upload failed: ${errorText}`);
      }

      const result: UploadFileToCloudinaryResponse = yield* Effect.promise(() =>
        uploadResponse.json()
      );
      console.log("Cloudinary upload result:", result);

      return result;
    });

  static uploadInMemoryFileToCloudinary = (p: {
    secureUploadUrl: string;
    file: InMemoryFile;
  }): Effect.Effect<UploadFileToCloudinaryResponse> =>
    Effect.gen(this, function* () {
      const asImgSrc = ImageSrc.fromInMemoryFile(p.file);
      return yield* FileUploadUtils.uploadImageSrcToCloudinary({
        secureUploadUrl: p.secureUploadUrl,
        imgSrc: asImgSrc,
      });
    });

  static uploadFileLikeToCloudinary = (p: {
    secureUploadUrl: string;
    file: FileLike;
  }): Effect.Effect<UploadFileToCloudinaryResponse> =>
    Effect.gen(this, function* () {
      const formData = new FormData();

      // If the file is already a File object (browser case), use it directly
      if (p.file instanceof File) {
        formData.append("file", p.file);
      }
      // Otherwise fetch from uri/path
      else if (p.file.uri) {
        const response = yield* Effect.promise(() => fetch(p.file.uri!));
        const blob = yield* Effect.promise(() => response.blob());
        formData.append("file", blob);
      } else {
        throw new Error(
          "File must have either be a File object or have a uri property"
        );
      }

      const uploadResponse = yield* Effect.promise(() =>
        fetch(p.secureUploadUrl, {
          method: "POST",
          body: formData,
        })
      );

      if (!uploadResponse.ok) {
        const errorText = yield* Effect.promise(() => uploadResponse.text());
        throw new Error(`Cloudinary upload failed: ${errorText}`);
      }

      const result: UploadFileToCloudinaryResponse = yield* Effect.promise(() =>
        uploadResponse.json()
      );
      console.log("Cloudinary upload result:", result);

      return result;
    });
}

export class FileDownloadUtils {
  static downloadFile = (p: {
    presignedDownloadUrl: string;
  }): Effect.Effect<InMemoryFile> =>
    Effect.gen(function* (_) {
      const response = yield* Effect.promise(() =>
        fetch(p.presignedDownloadUrl)
      );
      const blob = yield* Effect.promise(() => response.blob());
      const b64 = yield* FileUtils.blobAsBase64(blob);

      const fileMetadata = FileSchemas.FileMetadata.unsafeDecodeFromFile(blob);

      return { base64String: b64, fileMetadata };
    });

  static imgSrcAsInMemoryFile = (
    imgSrc: ImageSrc
  ): Effect.Effect<InMemoryFile> =>
    Effect.gen(this, function* () {
      if (imgSrc.data._tag === "InMemoryFile") {
        return imgSrc.data.file;
      } else {
        const urlSrc = imgSrc.data.url;

        return yield* FileDownloadUtils.downloadFile({
          presignedDownloadUrl: urlSrc,
        });
      }
    });
}

export const domSelectFile = async (): Promise<FileLike | null> => {
  return new Promise((resolve) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*,video/*,audio/*";

    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0] || null;
      if (file) {
        resolve({
          size: file.size,
          type: file.type,
          name: file.name,
          lastModified: file.lastModified,
          uri: URL.createObjectURL(file),
          // localFile: file, // Additional property for internal use
        });
      } else {
        resolve(null);
      }
    };

    input.oncancel = () => resolve(null);
    input.click();
  });
};
