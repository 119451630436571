import { reflect } from "@effector/reflect";
import { Disclosure } from "@headlessui/react";
import { AvatarCircle } from "@webapp/componentsavatar.tc";
import { TextInput } from "@webapp/componentsform/text.input";
import { TextAreaInput } from "@webapp/componentsform/textarea.input";
import { PrimaryButton } from "@webapp/componentsprimitives/button";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { domSelectFile, FileUploadUtils } from "@webapp/utils/file.utils";
import { Effect } from "effect";
import { useUnit } from "effector-react";
import {
  MyProfileFormVM,
  useSetupMyProfileForm,
} from "frontend-shared/src/users/my-profile-form.vm";
import React, { useEffect, type FC } from "react";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { ModalitySelectorForm } from "src/domains/user/hp/modality-selector.form";

type InputProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  placeholder?: string;
};
const Input: FC<InputProps> = ({ value, onChange, label }) => {
  return (
    <TextInput
      label={label}
      value={value}
      onChange={onChange}
      // placeholder={placeholder}
    />
  );
};

class Views {
  public readonly FirstNameInput: React.FC;
  public readonly LastNameInput: React.FC;
  public readonly BioInput: React.FC;

  constructor(model: MyProfileFormVM) {
    this.FirstNameInput = reflect({
      view: Input,
      bind: {
        value: model.nameVM.$firstName,
        label: "First name",
        onChange: model.nameVM.setFirstName.prepend((e) => e.target.value),
      },
    });

    this.LastNameInput = reflect({
      view: Input,
      bind: {
        value: model.nameVM.$lastName,
        label: "Last name",
        onChange: model.nameVM.setLastName.prepend((e) => e.target.value),
      },
    });

    this.BioInput = reflect({
      view: TextAreaInput,
      bind: {
        value: model.bioVM.$bio,
        label: "Bio",
        onChange: model.bioVM.setBio.prepend((e) => e.target.value),
      },
    });
  }
}

export const MyProfileForm: React.FC<{
  initialData?: {
    firstName?: string;
    lastName?: string;
    selectedModalitySlugs?: string[];
    profilePhoto?: {
      downloadUrl: string;
    };
    bio?: string;
  };
  onSuccessSubmit: () => void;
  button: {
    title: string;
  };
}> = ({ initialData, onSuccessSubmit, button }) => {
  const {
    model,
    isSubmitting,
    isDoneSubmitting,
    displayPhotoUrl,
    isProcessing,
  } = useSetupMyProfileForm({
    initialData,
    selectPhoto: domSelectFile,
    uploadFileLikeToCloudinary: (file, uploadUrl) =>
      Effect.runPromise(
        FileUploadUtils.uploadFileLikeToCloudinary({
          secureUploadUrl: uploadUrl,
          file,
        })
      ),
  });

  const views = React.useMemo(() => new Views(model), [model]);
  const showBio = useUnit(model.bioVM.$showBioInput);

  useEffect(() => {
    if (isDoneSubmitting) {
      onSuccessSubmit();
    }
  }, [isDoneSubmitting]);

  if (isSubmitting) {
    return <FullContainerLoadingSpinner />;
  }

  if (isDoneSubmitting) {
    return <></>;
  }

  return (
    <div className="flex flex-col items-center gap-4 w-[544px]">
      <div onClick={() => model.profilePhotoVM.selectMediaRequested()}>
        <div className="flex items-center justify-center">
          <div className="relative">
            {isProcessing ? (
              <FullContainerLoadingSpinner />
            ) : (
              <AvatarCircle
                mbProfilePhoto={
                  displayPhotoUrl ? ImageSrc.fromMbUrl(displayPhotoUrl) : null
                }
                size={125}
              />
            )}
            <div className="absolute bottom-0 right-0 w-[42px] h-[42px] rounded-full bg-white flex justify-center items-center">
              <img src={"/edit.svg"} alt="edit" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-2">
        <views.FirstNameInput />
        <views.LastNameInput />
      </div>
      <ModalitySelectorForm vm={model.modalitySelectorVM} />
      {showBio ? <views.BioInput /> : <div className="h-12" />}
      <PrimaryButton
        title={button.title}
        onClick={() => model.submitFormEvt()}
      />
    </div>
  );
};

export const ModalitiesDrawer: React.FC<{
  modalities: Array<{ slug: string; name: string }>;
}> = ({ modalities }) => {
  return (
    <Disclosure defaultOpen={modalities.length <= 3}>
      {({ open }) => (
        <>
          <div className="relative">
            <div
              className={`
              flex flex-wrap gap-2 mt-4
              ${open ? "max-h-none" : "max-h-[120px]"}
              ${!open && "overflow-hidden"}
              transition-all duration-300 ease-in-out
            `}
            >
              {modalities.slice(0, open ? undefined : 3).map((m) => (
                <div
                  key={m.slug}
                  className="flex gap-3.5 justify-between px-6 py-3.5 rounded-3xl border border-solid border-[color:var(--Vidalify-Black-200,#DBDAEC)]"
                >
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/825848ad326a0fc7a3e2e461deeea59f9c0bec51a146a512e5d339548ba3e7a0?"
                    className="w-6 aspect-square"
                  />
                  <div className="self-start mt-1">{m.name}</div>
                </div>
              ))}
            </div>
            {!open && modalities.length > 3 && (
              <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-white to-transparent pointer-events-none" />
            )}
          </div>
          {modalities.length > 3 && (
            <Disclosure.Button className="flex items-center gap-1 mt-2 text-sm text-vid-gray hover:text-vid-black-600">
              {open ? (
                <>Show less</>
              ) : (
                <>Show all {modalities.length} modalities</>
              )}
            </Disclosure.Button>
          )}
        </>
      )}
    </Disclosure>
  );
};
