export type ColorKey =
  | "primary"
  | "secondary"
  | "success"
  | "warning"
  | "error"
  | "purple"
  | "blue"
  | "gray"
  | "green"
  | "yellow"
  | "orange"
  | "red"
  | "pink"
  | "teal"
  | "cyan"
  | "indigo";

export const colorMap: Record<ColorKey, string> = {
  primary: "#3B82F6", // Blue-500
  secondary: "#6B7280", // Gray-500
  success: "#10B981", // Green-500
  warning: "#F59E0B", // Amber-500
  error: "#EF4444", // Red-500
  purple: "#690DFF", // Purple-500
  blue: "#6BB7EE", // Blue-500
  gray: "#6B7280", // Gray-500
  green: "#73C143", // Green-500
  yellow: "#F7CD38", // Amber-500
  orange: "#F97316", // Orange-500
  red: "#E95050", // Red-500
  pink: "#EC4899", // Pink-500
  teal: "#14B8A6", // Teal-500
  cyan: "#06B6D4", // Cyan-500
  indigo: "#6366F1", // Indigo-500
} as const;

/**
 * Gets the hex color value for a given color key
 * @param key The color key to look up
 * @returns The corresponding hex color value
 */
export const getColor = (key: ColorKey): string => {
  return colorMap[key];
};

export function pickRandomColor(excluding?: ColorKey[]): ColorKey {
  return Object.keys(colorMap).filter(
    (k) => !excluding?.includes(k as ColorKey)
  )[Math.floor(Math.random() * Object.keys(colorMap).length)] as ColorKey;
}
