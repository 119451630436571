import { FullContainerLoadingSpinner } from "@webapp/loading";
import { Match } from "effect";
import { useEffect, useState } from "react";

export const AppButton: React.FC<{
  onClick: () => void;
  title: string;
  maxWidthPixels?: number;
  disabled?: boolean;
  type?: "button" | "submit";
  isInvertedStyle?: boolean;
}> = ({ onClick, title, maxWidthPixels, disabled, type, isInvertedStyle }) => {
  const button = isInvertedStyle ? (
    <InversePrimaryButton onClick={onClick} title={title} disabled={disabled} />
  ) : (
    <PrimaryButton
      onClick={onClick}
      title={title}
      disabled={disabled}
      type={type}
    />
  );

  if (maxWidthPixels) {
    return <div style={{ maxWidth: `${maxWidthPixels}px` }}>{button}</div>;
  }

  return button;
};

export const PrimaryButton: React.FC<{
  onClick?: () => void;
  title: string;
  maxWidthPixels?: number;
  disabled?: boolean;
  type?: "button" | "submit";
  invertedStyle?: boolean;
  isLoading?: boolean;
  dims?: { width?: number; height?: number };
  fontSize?: number;
}> = ({
  onClick,
  title,
  disabled: propDisabled,
  type,
  isLoading,
  dims,
  fontSize,
}) => {
  const [isDisabled, setIsDisabled] = useState(propDisabled);
  const baseClasses = `inline-flex rounded-buttons p-buttons font-medium text-[16px] justify-center items-center gap-2.5 border`;
  const disabledClasses =
    "bg-vid-black-50 cursor-not-allowed border-vid-black-50";
  const enabledClasses = "bg-vid-purple text-white border-vid-purple";
  const className = isDisabled
    ? `${baseClasses} ${disabledClasses}`
    : `${baseClasses} ${enabledClasses}`;

  useEffect(() => {
    setIsDisabled(propDisabled);
  }, [propDisabled]);

  const handleClick = () => {
    if (onClick) {
      onClick();
      setIsDisabled(true); // Disable the button on click
      setTimeout(() => setIsDisabled(false), 500); // Re-enable after 500ms
    }
  };

  return (
    <button
      onClick={handleClick}
      className={className}
      disabled={isDisabled}
      type={type}
      style={{
        height: dims?.height ? `${dims.height}px` : "49px",
        width: dims?.width ? `${dims.width}px` : "auto",
        fontSize: fontSize ? `${fontSize}px` : "16px",
      }}
    >
      {isLoading ? <FullContainerLoadingSpinner /> : title}
    </button>
  );
};

export const InversePrimaryButton: React.FC<{
  onClick: () => void;
  title: string;
  disabled?: boolean;
  isLoading?: boolean;
  width?: string;
  color?: "black" | "purple" | "red";
}> = ({
  onClick,
  title,
  disabled: propDisabled,
  isLoading,
  width,
  color = "purple",
}) => {
  const [isTemporarilyDisabled, setIsTemporarilyDisabled] = useState(false);

  const handleClick = () => {
    if (onClick) {
      onClick();
      setIsTemporarilyDisabled(true);
      setTimeout(() => setIsTemporarilyDisabled(false), 500);
    }
  };

  const hexColor = Match.value(color).pipe(
    Match.when("black", () => "#1D1626"),
    Match.when("purple", () => "#690DFF"),
    Match.when("red", () => "#FF4343"),
    Match.exhaustive
  );

  return (
    <button
      onClick={handleClick}
      disabled={propDisabled || isTemporarilyDisabled}
      style={{
        width: width ?? "172px",
        color: hexColor,
        borderColor: hexColor,
      }}
      className={`
      bg-white border rounded-buttons py-2 px-8 lg:p-buttons
      flex justify-center items-center
      text-center font-medium leading-tight
      font-outfit
      ${propDisabled ? "cursor-not-allowed" : ""}
      `}
    >
      {isLoading ? <FullContainerLoadingSpinner /> : title}
    </button>
  );
};
