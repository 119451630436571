import React from "react";
import { FormFieldContainer } from "./form-field.container";

export interface TextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  labelClassName?: string;
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  ({ label, error, className = "", labelClassName = "", ...props }, ref) => {
    return (
      <FormFieldContainer
        label={label}
        error={error}
        labelClassName={labelClassName}
        showDownArrow={false}
      >
        <input
          ref={ref}
          className={`
            w-full rounded-xl bg-white
            outline-none transition-colors
            font-roboto-flex text-vid-black-900
            placeholder:text-vid-black-500
            ${error ? "border-red-500" : ""}
            ${className}
          `}
          {...props}
        />
      </FormFieldContainer>
    );
  }
);
