import { PrimaryButton } from "@webapp/componentsprimitives/button";
import { FullContainerLoadingSpinner, LoadingSpinner } from "@webapp/loading";
import { domSelectFile, FileUploadUtils } from "@webapp/utils/file.utils";
import { useAction, useMutation } from "convex/react";
import { Effect, Match } from "effect";
import { useUnit } from "effector-react";
import { CreateCommunityPostVM } from "frontend-shared/src/community/create-community-post.vm";
import React, { useEffect, useMemo, useState } from "react";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";

type PostSetup = {
  postId: Id<"communityPosts">;
  imageUploadUrl: string;
  videoUploadUrl: string;
};

export const CreateCommunityPostFormCool: React.FC<{
  communitySlug: string;
  onSuccessSubmit: (p: { communitySlug: string }) => void;
}> = ({ communitySlug, onSuccessSubmit }) => {
  const onOpenCreatePostForm = useAction(
    api.Community.CommunityScreenFns.onOpenCreatePostForm
  );
  const [postSetup, setupPostSetup] = useState<PostSetup | undefined>(
    undefined
  );

  useEffect(() => {
    onOpenCreatePostForm({ communitySlug }).then((p) => {
      setupPostSetup(p);
    });
  }, []);

  if (!postSetup) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <CreateCommunityPostFormLoaded
      communitySlug={communitySlug}
      postSetup={postSetup}
      onSuccessSubmit={onSuccessSubmit}
    />
  );
};

const CreateCommunityPostFormLoaded: React.FC<{
  communitySlug: string;
  postSetup: PostSetup;
  onSuccessSubmit: (p: { communitySlug: string }) => void;
}> = ({ communitySlug, postSetup, onSuccessSubmit }) => {
  const onMediaSuccessfullyUploadedToCloudinary = useAction(
    api.Community.CommunityScreenFns.onMediaSuccessfullyUploadedToCloudinary
  );

  const saveContentToServer = useMutation(
    api.Community.CommunityScreenFns.onDraftContentChanged
  );

  const getAssumedMediaDownloadUrlThenCache = useAction(
    api.Community.CommunityScreenFns.getAssumedMediaDownloadUrlThenCache
  );

  const onSubmitPost = useMutation(
    api.Community.CommunityScreenFns.onSubmitPost
  );

  useEffect(() => {
    vm.submitPostFx.done.watch(() => {
      onSuccessSubmit({ communitySlug });
    });
  }, []);

  const vm = useMemo(
    () =>
      new CreateCommunityPostVM({
        setup: postSetup,
        communitySlug: communitySlug,
        submitPost: async () => {
          await onSubmitPost({
            postId: postSetup.postId,
          });
        },
        saveContentToServer: async (content) => {
          await saveContentToServer({
            postId: postSetup.postId,
            newContent: content.textContent,
            embedContent: content.embedContent,
          });
        },
        mediaUploadDeps: {
          selectMedia: domSelectFile,
          getPreviewUrl: (fileLike) => fileLike.uri ?? fileLike.path ?? "",
          uploadToCloudinary: (fileLike, uploadUrl) =>
            Effect.runPromise(
              FileUploadUtils.uploadFileLikeToCloudinary({
                secureUploadUrl: uploadUrl,
                file: fileLike,
              })
            ),
        },
        getPresignedUploadUrl: async (p) => {
          return Match.value(p).pipe(
            Match.when({ mediaType: "image" }, () => ({
              uploadUrl: postSetup.imageUploadUrl,
            })),
            Match.when({ mediaType: "video" }, () => ({
              uploadUrl: postSetup.videoUploadUrl,
            })),
            Match.when({ mediaType: "audio" }, () => ({
              uploadUrl: "TODO",
            })),
            Match.exhaustive
          );
        },
        onMediaSuccessfullyUploadedToCloudinary: async (p) => {
          await onMediaSuccessfullyUploadedToCloudinary({
            postId: postSetup.postId,
            publicId: p.publicId,
            format: "TODO",
            mediaType: p.mediaType,
          });
        },
        getProcessedMediaUrl: async (p) => {
          const { url } = await getAssumedMediaDownloadUrlThenCache({
            postId: postSetup.postId,
            publicId: p.publicId,
            mediaType: p.mediaType,
          });

          return {
            processedUrl: url,
          };
        },
      }),
    [postSetup]
  );
  const mediaInputState = useUnit(vm.$mediaInputState);

  return (
    <div className="flex-1 flex flex-col gap-4 p-6">
      <EnterTextSection vm={vm} />
      {Match.value(mediaInputState).pipe(
        Match.when({ _tag: "EMBEDDED_IN_CONTENT" }, ({ embedData }) => (
          <iframe src={embedData.url} className="w-full" allowFullScreen />
        )),
        Match.when({ _tag: "USER_WILL_UPLOAD" }, () => (
          <AddMediaSection vm={vm} />
        )),
        Match.exhaustive
      )}
      <div className="flex-1 flex flex-col justify-end">
        <PrimaryButton
          title="Submit post"
          onClick={() => {
            vm.submitPostEvt();
          }}
        />
      </div>
    </div>
  );
};

const EnterTextSection: React.FC<{
  vm: CreateCommunityPostVM;
}> = ({ vm }) => {
  const [textContent, youtubeEmbedData] = useUnit([
    vm.contentVM.$text,
    vm.contentVM.$youTubeEmbedData,
  ]);

  return (
    <div className="flex flex-col gap-2 px-6 py-5 font-sans border border-vid-black-200 rounded-lg cursor-pointer hover:bg-gray-100">
      <div className="flex items-center gap-2">
        <h4 className="text-vid-black-900">Write something</h4>
      </div>
      <textarea
        value={textContent}
        onChange={(e) => vm.contentVM.textChanged(e.target.value)}
        className="w-full border rounded-lg px-4 py-2 h-[96px] bg-vid-black-100"
      />
    </div>
  );
};

// const MediaInputSection: React.FC = () => {
//   const localStateMgr = useLocalState();
//   const mediaState = useObservableEagerState(
//     localStateMgr.mediaStateUploadMgr.state$
//   );

//   return (
//     <div className="flex flex-col gap-2 px-6 py-5 font-sans border border-vid-black-200 rounded-lg cursor-pointer hover:bg-gray-100">
//       {Match.value(mediaState).pipe(
//         Match.tag("INITIAL", () => <AddMediaSection />),
//         Match.tag("UNPROCESSED", ({ inMemoryFile }) => (
//           <div className="relative h-[200px] w-full">
//             <img
//               src={ImageSrc.fromInMemoryFile(inMemoryFile).src}
//               className="w-full object-cover"
//             />
//             <div className="absolute inset-0 bg-vid-black-200 opacity-50 flex items-center justify-center">
//               <LoadingSpinner />
//               <span className="text-vid-purple">Processing...</span>
//             </div>
//           </div>
//         )),
//         Match.tag("PROCESSED", ({ inMemoryFile }) => (
//           <div>
//             <img
//               src={ImageSrc.fromInMemoryFile(inMemoryFile).src}
//               className="w-full object-cover"
//             />
//           </div>
//         )),
//         Match.exhaustive
//       )}
//     </div>
//   );
// };

const AddMediaSection: React.FC<{
  vm: CreateCommunityPostVM;
}> = ({ vm }) => {
  const mediaInputState = useUnit(vm.$mediaInputState);
  const mbDisplayUrl = useUnit(vm.mediaVM.$displayMediaUrl);
  const mediaType = useUnit(vm.mediaVM.$mediaType);
  const isProcessing = useUnit(vm.mediaVM.$isProcessing);

  if (mediaInputState._tag === "EMBEDDED_IN_CONTENT") {
    return null;
  }

  if (mbDisplayUrl && mediaType) {
    return (
      <div className="relative h-[200px] w-full">
        {Match.value(mediaType).pipe(
          Match.when("image", () => (
            <img src={mbDisplayUrl} className="w-full object-cover" />
          )),
          Match.when("video", () => (
            <video src={mbDisplayUrl} className="w-full  object-cover" />
          )),
          Match.when("audio", () => (
            <audio src={mbDisplayUrl} className="w-full object-cover" />
          )),
          Match.exhaustive
        )}
        {isProcessing && (
          <div className="absolute inset-0 bg-vid-black-200 opacity-50 flex items-center justify-center">
            <LoadingSpinner />
            <span className="text-vid-purple">Processing...</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className="flex flex-col gap-2 px-6 py-5 font-sans border border-vid-black-200 rounded-lg cursor-pointer hover:bg-gray-100"
      onClick={() => {
        vm.mediaVM.selectMediaRequested();
      }}
    >
      <AddMediaIcon />
      <span className="text-sm text-vid-black-900">Add a photo or video</span>
    </div>
  );
};

const AddMediaIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V10"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 5H21.25"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M18.5 7.75V2.25"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M2.67188 18.9496L7.60187 15.6396C8.39187 15.1096 9.53187 15.1696 10.2419 15.7796L10.5719 16.0696C11.3519 16.7396 12.6119 16.7396 13.3919 16.0696L17.5519 12.4996C18.3319 11.8296 19.5919 11.8296 20.3719 12.4996L22.0019 13.8996"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const LeftIndentTextIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M3 4.5H21"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 9.5H12.47"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 14.5H21"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 19.5H12.47"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
