import { RadioGroupCircle } from "@webapp/componentsprimitives/radio-buttons";
import { Match } from "effect";
import { useState } from "react";
import type { Id } from "shared/convex/_generated/dataModel";
import { InvoicesTab } from "./invoices-tab";
import { NotesTabWrapup } from "./notes-tab";
import { WrapupSummaryTab } from "./summary-tab";

type Tab = "Moments" | "Invoices" | "Summary";

export const SessionWrapup: React.FC<{
  sessionId: Id<"sessionConfig">;
}> = ({ sessionId }) => {
  const [tab, setTab] = useState<Tab>("Moments");

  return (
    <div className="flex-1 flex flex-col gap-4 w-[941px]">
      <div className="flex gap-[257px] px-9 py-4 items-center justify-center border rounded-[60px] border-vid-black-200">
        <TabLink
          tab="Moments"
          onClick={() => setTab("Moments")}
          isActive={tab === "Moments"}
        />
        <TabLink
          tab="Summary"
          onClick={() => setTab("Summary")}
          isActive={tab === "Summary"}
        />
        <TabLink
          tab="Invoices"
          onClick={() => setTab("Invoices")}
          isActive={tab === "Invoices"}
        />
      </div>
      <div className="flex-1 min-h-0 flex flex-col gap-4">
        {Match.value(tab).pipe(
          Match.when("Moments", () => <NotesTabWrapup sessionId={sessionId} />),
          Match.when("Invoices", () => <InvoicesTab sessionId={sessionId} />),
          Match.when("Summary", () => (
            <WrapupSummaryTab sessionId={sessionId} />
          )),
          Match.exhaustive
        )}
      </div>
    </div>
  );
};

const TabLink: React.FC<{
  tab: Tab;
  onClick: () => void;
  isActive?: boolean;
}> = ({ tab, onClick, isActive }) => {
  return (
    <button onClick={onClick}>
      <RadioGroupCircle checked={isActive ?? false} text={tab} size={26} />
    </button>
  );
};
