import { ToastVM } from "frontend-shared/src/toasts/toast.vm";
import {
  createGlobalToast,
  useToast,
} from "frontend-shared/src/toasts/use-toast";

export const globalToastVM = new ToastVM();
export const toast = createGlobalToast(globalToastVM);

export function useWebToast() {
  return useToast(globalToastVM);
}
