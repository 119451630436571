import { v } from "convex/values";

const knownTool = v.union(
  v.literal("EMDR"),
  v.literal("MEDITATION"),
  v.literal("NOTES"),
  v.literal("CHAT"),
  v.literal("REVIEW"),
  v.literal("QUICK_SCHEDULE"),
  v.literal("BREAKOUT_ROOMS")
);
type KnownTool = typeof knownTool.type;

const SpecificToolViewState = v.object({
  tag: v.literal("SELECTED_TOOL"),
  tool: knownTool,
});
type SpecificToolViewState = typeof SpecificToolViewState.type;

export const ToolMenuViewState = v.union(
  v.object({ tag: v.literal("MENU") }),
  SpecificToolViewState
);

export const MainRoomMenuOpenState = v.union(
  v.object({ tag: v.literal("SETTINGS_MENU") }),
  v.object({
    tag: v.literal("TOOLS_MENU"),
    viewState: ToolMenuViewState,
  })
);

export const MainRoomMenuState = v.union(
  v.object({ tag: v.literal("CLOSED") }),
  v.object({
    tag: v.literal("OPEN"),
    viewState: MainRoomMenuOpenState,
  })
);

export type ToolMenuViewState = typeof ToolMenuViewState.type;
export type MainRoomMenuOpenState = typeof MainRoomMenuOpenState.type;
export type MainRoomMenuState = typeof MainRoomMenuState.type;

export function isSpecificToolMenuViewState(
  viewState: MainRoomMenuOpenState
): viewState is {
  tag: "TOOLS_MENU";
  viewState: SpecificToolViewState;
} {
  return (
    viewState.tag === "TOOLS_MENU" &&
    viewState.viewState.tag === "SELECTED_TOOL"
  );
}

export const MainRoomMenuTB = {
  open: {
    settings: () =>
      ({
        tag: "OPEN",
        viewState: { tag: "SETTINGS_MENU" },
      }) as MainRoomMenuState,

    tools: {
      all: () =>
        ({
          tag: "OPEN",
          viewState: {
            tag: "TOOLS_MENU",
            viewState: { tag: "MENU" },
          },
        }) as MainRoomMenuState,

      specific: (toolTag: KnownTool) =>
        ({
          tag: "OPEN",
          viewState: {
            tag: "TOOLS_MENU",
            viewState: { tag: "SELECTED_TOOL", tool: toolTag },
          },
        }) as MainRoomMenuState,
    },
  },

  closed: () => ({ tag: "CLOSED" }) as const,
};
