import { useHpState } from "@pages/u/hp/hp.webstatemgr.js";
import { useMyId, useUMgr } from "@pages/u/u.webstatemgr.js";
import { LogoutIcon } from "@webapp/componentsdashboard/main-nav-links.js";
import { useScrollToAnchor } from "@webapp/utils/utils";
import { Effect, Match } from "effect";
import { useObservableEagerState } from "observable-hooks";
import React from "react";
import { Link } from "react-router-dom";
import { useTypedHash } from "react-router-typesafe-routes/dom";
import { ANON_ROUTES } from "shared/routes/routes.js";
import { UROUTES } from "shared/routes/u.routes";
import { MyProfileForm } from "src/domains/user/my-profile-form.js";
import { SettingsContainer } from "../components/settings.components.js";

export const HpDashboardSettingsAccountPage: React.FC<{}> = ({}) => {
  useScrollToAnchor();

  return (
    <div className="flex-1 flex flex-col gap-4">
      <div className="flex gap-8">
        <Sidebar />
        <div className="flex flex-col gap-4 w-[50%]">
          <PracticeInformationView />
          <ExtraView />
          <AccountInformationView />
          {/* <ModalitiesView /> */}
          <DangerZoneView />
          {/* <InviteFriendsView /> */}
        </div>
      </div>
    </div>
  );
};

const Sidebar: React.FC = () => {
  return (
    <div className="sticky top-0 h-[400px] w-[256px] py-6 flex flex-col gap-4">
      <div>
        <SidebarTab tab={"practice-info"} />
      </div>
      <div>
        <SidebarTab tab={"account-info"} />
      </div>
      {/* <div>
        <button onClick={() => handleClick("vidalify-subscription")}>
          Vidalify Subscription
        </button>
      </div> */}
      {/* <div>
        <SidebarTab tab="modalities" />
      </div> */}
      <div>
        <SidebarTab tab="extra" />
      </div>
      <div>
        <SidebarTab tab="invite-friends" />
      </div>
    </div>
  );
};

type SideTabHashes =
  | "practice-info"
  | "account-info"
  | "modalities"
  | "extra"
  | "invite-friends";

function titleForTab(tab: SideTabHashes): string {
  return Match.value(tab).pipe(
    Match.when("practice-info", () => "Practice Information"),
    Match.when("account-info", () => "Account Information"),
    Match.when("modalities", () => "Modalities"),
    Match.when("extra", () => "Extra"),
    Match.when("invite-friends", () => "Invite Friends"),
    Match.exhaustive
  );
}

const SidebarTab: React.FC<{ tab: SideTabHashes }> = ({ tab }) => {
  const curHash = useTypedHash(UROUTES.HP.MY.DASHBOARD.SETTINGS.ACCOUNT);

  return (
    <Link
      to={`${UROUTES.HP.MY.DASHBOARD.SETTINGS.ACCOUNT.buildPath(
        {},
        undefined,
        tab
      )}`}
      className="flex items-center gap-2"
    >
      <div
        className={`rounded-lg pr-4 pl-4 py-2 font-sans ${
          curHash !== undefined && curHash === tab
            ? "bg-vid-black-100"
            : "bg-white"
        }`}
      >
        {titleForTab(tab)}
      </div>
    </Link>
  );
};

const AccountInformationView: React.FC = () => {
  const hpState = useHpState();
  const me = useObservableEagerState(hpState.uMgr.me$);
  return (
    <SettingsContainer id="account-info" title="Account">
      <div className="w-full h-full flex flex-col gap-12">
        <div className="flex flex-col gap-2">
          <h4 className="text-sm font-bold">Email</h4>
          <p>{me?.email}</p>
        </div>
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => {
            Effect.runPromise(hpState.uMgr.signOut).then();
          }}
        >
          <LogoutIcon isSelected={false} />
          <h4 className="text-sm">Sign out</h4>
        </div>
      </div>
    </SettingsContainer>
  );
};

const PracticeInformationView: React.FC = () => {
  const hpState = useHpState();
  return (
    <SettingsContainer id="practice-info" title="Practice Information">
      <div className="w-full h-full flex justify-center items-center">
        <MyProfileForm
          onSuccessSubmit={() => {
            hpState.dashboardState.showBottomToast({
              msg: "Profile updated",
              duration: { _tag: "SECONDS", seconds: 3 },
              reload: true,
            });
          }}
          button={{ title: "Save" }}
        />
      </div>
    </SettingsContainer>
  );
};

const ExtraView: React.FC = () => {
  const myId = useMyId();
  return (
    <div className="flex flex-col gap-4">
      <SettingsContainer id="global-profile" title={"Global Profile"}>
        <Link
          to={ANON_ROUTES.MY_GLOBAL_PROFILE.DISCUSSION.buildPath({
            userId: myId,
          })}
          className="text-vid-purple"
        >
          View my global profile
        </Link>
      </SettingsContainer>
      <SettingsContainer id="extra" title="Extra">
        <div className="flex flex-col gap-4">
          <h4>I am also a client</h4>
          <Link to={UROUTES.CP.DASHBOARD.HOME.path} className="text-blue-500">
            Go to client portal
          </Link>
        </div>
      </SettingsContainer>
    </div>
  );
};

const DangerZoneView: React.FC = () => {
  const uMgr = useUMgr();
  return (
    <SettingsContainer id="danger-zone" title="Danger Zone">
      <div className="flex flex-col gap-4">
        <div className="flex gap-2 items-center justify-between">
          <p>Fully delete your account</p>
          <button
            className="bg-red-100 text-white p-4 rounded-md w-[200px]"
            onClick={() => {}}
          >
            Delete Account
          </button>
        </div>
      </div>
    </SettingsContainer>
  );
};
