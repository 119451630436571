import { FetchRemoteDataView } from "@webapp/utils/utils";
import type { TE } from "shared/base-prelude";

export type DashboardRightDrawerViewProps = {
  topView: React.ReactNode;
  content: React.ReactNode;
};
export const DashboardRightDrawerContainer: React.FC<
  DashboardRightDrawerViewProps & { closeRightNav: () => void }
> = ({ content, topView, closeRightNav }) => {
  return (
    <div className="flex-1 flex flex-col">
      <div className="h-screen flex flex-col">
        <div className="relative w-full h-[200px] bg-white flex">
          <div className="absolute top-4 right-4 z-20">
            <button
              className="rounded-full w-9 h-9 p-2 border text-xs cursor-pointer bg-white"
              onClick={closeRightNav}
            >
              <img src="/close.svg" width={18} height={18} />
            </button>
          </div>
          <div className="absolute inset-0 flex justify-center items-center">
            {topView}
          </div>
        </div>
        <div className="flex-1 flex flex-col  ">{content}</div>
      </div>
    </div>
  );
};

type RemoteProps<V, E> = {
  topView: (v: V) => React.ReactNode;
  content: (v: V) => React.ReactNode;
  fetchTE: TE.TaskEither<E, V>;
  closeRightNav: () => void;
};

export const DashboardRightDrawerRemoteData = <V, E>({
  topView,
  content,
  fetchTE,
  closeRightNav,
}: RemoteProps<V, E>): React.ReactElement => {
  return (
    <FetchRemoteDataView
      fetchTE={fetchTE}
      loadedView={(v) => (
        <DashboardRightDrawerContainer
          topView={topView(v)}
          content={content(v)}
          closeRightNav={closeRightNav}
        />
      )}
    />
  );
};
