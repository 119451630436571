import { Match } from "effect";
import {
  AuthErrorCodes,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useFirebaseJs } from "frontend-shared/src/firebase";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { UROUTES } from "shared/routes/u.routes";
import { RD } from "../../prelude";

export const CpOnboardCreateAccountPage: React.FC = () => {
  const nav = useNavigate();
  return (
    <div className="w-screen h-screen">
      <AuthinCard
        onSuccessLogin={() => {
          nav(UROUTES.CP.DASHBOARD.HOME.path);
        }}
        onSuccessRegister={() => {
          console.log("Success!");
          nav(UROUTES.CP.DASHBOARD.HOME.path);
        }}
      />
    </div>
  );
};

type FirebaseAuthError = {
  code: string;
};

type RegisterError =
  | { _tag: "Authentication"; error: FirebaseAuthError }
  | { _tag: "Backend"; error: unknown };

type EmailPasswordForm = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

function messageForFirebaseAuthError(code: string) {
  if (code === AuthErrorCodes.EMAIL_EXISTS) {
    return "Email already exists";
  }

  return "Error creating account. Please try again later.";
}

type AuthinCardProps = {
  onSuccessRegister: () => void;
  onSuccessLogin: () => void;
};

const AuthinCard: React.FC<AuthinCardProps> = ({ onSuccessLogin }) => {
  const { register, handleSubmit } = useForm<EmailPasswordForm>();
  const [rdLoginResult, setRdLoginResult] = useState<
    RD.RemoteData<RegisterError, unknown>
  >(RD.initial);
  const firebaseJs = useFirebaseJs();

  return (
    <div className="h-screen p-8 flex flex-col justify-center items-center gap-8 lg:gap-16 mb-32">
      <h1 className="font-bold text-2xl lg:text-5xl text-default-purple font-outfit">
        Create Account
      </h1>
      <form
        className="flex flex-col gap-2 max-w-screen"
        onSubmit={handleSubmit((data) => {
          setRdLoginResult(RD.pending);
          createUserWithEmailAndPassword(
            firebaseJs.auth,
            data.email,
            data.password
          )
            .then((uc) => {
              console.log("UC! ", uc);
            })
            .catch((error: FirebaseAuthError) => {
              console.log("CAUGHT FB CREATE Error! ", error);
              const authError: RegisterError = {
                _tag: "Authentication",
                error: error,
              };

              if (error.code === AuthErrorCodes.EMAIL_EXISTS) {
                signInWithEmailAndPassword(
                  firebaseJs.auth,
                  data.email,
                  data.password
                ).then((uc) => {
                  console.log("UC! ", uc);
                  onSuccessLogin();
                });
              } else {
                console.log("SETTING ERRO! ", authError);
                setRdLoginResult(RD.failure(authError));
              }
            });
        })}
      >
        <div className="flex items-center gap-2 flex-wrap">
          <div className="flex flex-col gap-2 w-full lg:w-fit">
            <input
              type="text"
              placeholder="First Name"
              className="text-input"
              id="firstName"
              {...register("firstName", { required: true })}
            />
          </div>
          <div className="flex flex-col gap-2 w-full lg:w-fit">
            <input
              type="text"
              placeholder="Last Name"
              className="text-input"
              id="lastName"
              {...register("lastName", { required: true })}
            />
          </div>
        </div>
        <input
          type="email"
          placeholder="Email"
          className="text-input"
          id="email"
          {...register("email", { required: true })}
        />
        <input
          type="password"
          placeholder="Password"
          className="text-input"
          autoCapitalize="none"
          autoComplete="none"
          autoCorrect="none"
          id="password"
          {...register("password", { required: true })}
        />
        {RD.isFailure(rdLoginResult) && (
          <div className="text-red-500">
            {Match.value(rdLoginResult.error).pipe(
              Match.tag("Authentication", (e) =>
                messageForFirebaseAuthError(e.error.code)
              ),
              Match.tag("Backend", (e) => JSON.stringify(e.error)),
              Match.exhaustive
            )}
          </div>
        )}
        <button
          type="submit"
          className="bg-default-purple text-white px-8 py-4 rounded-lg font-medium font-sans"
        >
          {RD.isPending(rdLoginResult) ? "Submitting..." : "Create an account"}
        </button>
      </form>
    </div>
  );
};
