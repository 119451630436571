import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { CommunityTabMessenger } from "@webapp/components/chat/group-chat";
import React from "react";
import { Outlet } from "react-router-dom";
import { useMe, useMyId, useUMgr } from "../../../u.webstatemgr";
import { useObservableEagerState } from "observable-hooks";

export const CommunityTabLayout: React.FC = () => {
  return (
    <div className="flex-1 flex flex-col static lg:relative">
      <Outlet />
      {/* <CommunityTabMessengerBottomBar /> */}
    </div>
  );
};

const CommunityTabMessengerBottomBar: React.FC = () => {
  const myId = useMyId();
  const me = useMe();
  const uMgr = useUMgr();
  const chatCli = useObservableEagerState(uMgr.chatStateMgr.chatCli$);

  const [isMinimized, setIsMinimized] = React.useState(true);

  if (chatCli === undefined) {
    return null;
  }

  return (
    <div
      className={`
        hidden fixed bottom-0 right-24 z-20  w-[400px] rounded-lg 
        lg:flex flex-col
        overflow-y-auto
        border
        ${isMinimized ? "max-h-[80px]" : "min-h-[400px] max-h-[80%]"}
          `}
      style={{
        boxShadow: "0px 8px 30px 0px rgba(0, 0, 0, 0.3)",
      }}
    >
      <CommunityTabMessenger
        myProfile={{
          id: myId,
          name: me.name!,
          profilePhoto: me.profilePhoto!,
        }}
        onMinimizeClick={() => setIsMinimized((i) => !i)}
        isMinimized={isMinimized}
        chatClient={chatCli}
      />
    </div>
  );
};
