import {
  CalendarDropdown,
  CalendarPicker,
} from "@webapp/componentsform/calendar-picker.input";
import { useOnce } from "frontend-shared/src/util";
import { CalendarPickerVM } from "frontend-shared/src/shared-vms/calendar-picker.vm";

class QuickScheduleToolsPanelVM {
  calendarPickerVM = new CalendarPickerVM();
}

export const QuickScheduleToolsPanel: React.FC = () => {
  const vm = useOnce(() => new QuickScheduleToolsPanelVM());
  return (
    <div className="flex flex-col gap-4">
      <CalendarDropdown vm={vm.calendarPickerVM} autoOpen={true} />
    </div>
  );
};
