import { HpDashbaordRightNav } from "@hppages/dashboard/right-drawer-action/right-nav";
import type { Call, StreamVideoClient } from "@stream-io/video-react-sdk";
import { apiMgr } from "@webapp/backend";
import { DashboardStateMgr } from "@webapp/components/dashboard/dashboard.state";
import type { WebRtcMgr } from "@webapp/mgrs/web-rtc.mgr";
import { Rx } from "@webapp/prelude";
import { HpStateMgr } from "frontend-shared/src/mgrs/state-mgrs/user/hp.statemgr";
import React from "react";
import type { HpProfile } from "shared";
import { StreamChat } from "stream-chat";
import type { UWebStateMgr } from "../u.webstatemgr";

export class PortalWebStateMgr<DashboardRightNav extends {}> extends HpStateMgr<
  StreamChat,
  Call,
  StreamVideoClient,
  WebRtcMgr
> {
  dashboardState: DashboardStateMgr<DashboardRightNav>;
  myProfile$: Rx.BehaviorSubject<HpProfile>;

  constructor(
    readonly uMgr: UWebStateMgr,
    p: { myProfile: HpProfile; myUserId: string }
  ) {
    super(uMgr, {
      myProfile: p.myProfile,
      myUserId: p.myUserId,
      apiMgr: apiMgr,
    });
    this.myProfile$ = this.profileMgr.myProfile$;

    this.dashboardState = new DashboardStateMgr({
      pubSubNotifDisplayRsm: uMgr.pubSubNotifDisplayRsm,
    });
  }

  setMyProfile = (profile: HpProfile) => {
    this.profileMgr.setMyProfile(profile);
  };

  updateMyProfile = (partialProfile: Partial<HpProfile>) => {
    this.myProfile$.next({
      ...this.myProfile$.value,
      ...partialProfile,
    });
  };
}

export const HpStateContext =
  React.createContext<PortalWebStateMgr<HpDashbaordRightNav> | null>(null);

export function useHpState() {
  return React.useContext(HpStateContext)!;
}
