import { PortalWebStateMgr } from "@pages/u/hp/hp.webstatemgr";
import { useMyId, useUMgr } from "@pages/u/u.webstatemgr";
import { FullScreenLoadingSpinner } from "@webapp/loading";
import { signOut } from "firebase/auth";
import { useFirebaseJs } from "frontend-shared/src/firebase";
import { useObservableEagerState } from "observable-hooks";
import React, { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { UROUTES } from "shared/routes/u.routes";
import { DashboardLayout } from "../../../components/dashboard/dashboard-layout.fc";
import { DashboardRightDrawer, type CpDashboardRightNav } from "./right-nav";

export const CpDashboardLayout: React.FC<{}> = () => {
  const firebaseJs = useFirebaseJs();
  const uMgr = useUMgr();
  const myId = useMyId();
  const stateMgr = useMemo(
    () =>
      new PortalWebStateMgr<CpDashboardRightNav>(uMgr, {
        myProfile: { bio: "", modalities: [] },
        myUserId: myId,
      }),
    [uMgr]
  );
  const location = useLocation();
  const chatCli = useObservableEagerState(uMgr.chatStateMgr.chatCli$);

  if (!chatCli) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <DashboardLayout
      stateMgr={stateMgr.dashboardState}
      matchViewForRightNav={(rightNav) => {
        return { view: <DashboardRightDrawer rightNav={rightNav} /> };
      }}
      leftMenu={{
        onSignout: () => {
          signOut(firebaseJs.auth).then(() => {
            window.location.href = "/";
          });
        },
        onLogoClick: () => {
          window.location.href = UROUTES.CP.DASHBOARD.HOME.path;
        },
        topLinks: [
          {
            name: "Home",
            to: UROUTES.CP.DASHBOARD.HOME.path,
          },
          {
            name: "Calendar",
            to: UROUTES.CP.DASHBOARD.CALENDAR.path,
          },
          {
            name: "Settings",
            to: UROUTES.CP.DASHBOARD.SETTINGS.path,
          },
        ],
      }}
      navbar={{
        chatStateMgr: uMgr.chatStateMgr,
        currentDashboardPage: getCurrentDashboardPageFromUrl(location.pathname),
        middleSection: <></>,
        hamburger: {
          links: [],
          onSignout: () => {
            signOut(firebaseJs.auth).then(() => {
              window.location.href = "/";
            });
          },
        },
        profileButton: {
          to: UROUTES.CP.DASHBOARD.SETTINGS.path,
          profilePhoto: null,
        },
      }}
      mainContent={<Outlet />}
    />
  );
};

function getCurrentDashboardPageFromUrl(path: string): string {
  /*
  /cp/dashboard/settings => Settings
  /cp/dashboard/home => Home
  /cp/dashboard/portal/1 => Portal
  */

  const segments = path.split("/");
  const lastSeg = segments[segments.length - 1]; // Returns the last segment
  return lastSeg.charAt(0).toUpperCase() + lastSeg.slice(1);
}
