import type { Call, StreamVideoClient } from "@stream-io/video-react-sdk";
import { WebRtcMgr } from "@webapp/mgrs/web-rtc.mgr";
import type { Rx } from "@webapp/prelude";
import type { ConvexClient } from "convex/browser";
import { Effect, Option } from "effect";
import type { ApiMgr } from "frontend-shared/src/api.mgr";
import { useAppSession } from "frontend-shared/src/app-sessions/use-app-session";
import type { FirebaseJsMgr } from "frontend-shared/src/firebase";
import { UserStateMgr } from "frontend-shared/src/mgrs/state-mgrs/user/user.statemgr";
import { createContextAndHook, createState } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import type { Id } from "shared/convex/_generated/dataModel";
import type { RtcCredentials } from "shared/schemas/call.schemas";
import type { SimpleUserWithProfilePhoto } from "shared/types/user.types";
import { RightDrawerVM } from "src/shared/vms/right-drawer.vm";
import { StreamChat } from "stream-chat";
import type { DefaultStreamChatGenerics } from "stream-chat-react";

export class UWebStateMgr extends UserStateMgr<
  StreamChat,
  Call,
  StreamVideoClient,
  WebRtcMgr
> {
  baseUserId: Id<"users">;
  firebaseMgr: FirebaseJsMgr;

  rightDrawerVM = new RightDrawerVM();
  globalDialogVM = new DialogVM();

  constructor(p: {
    me: SimpleUserWithProfilePhoto;
    baseUserId: Id<"users">;
    apiMgr: ApiMgr;
    convex: ConvexClient;
    firebaseMgr: FirebaseJsMgr;
    chatCreds$: Rx.BehaviorSubject<
      { publicApiKey: string; token: string } | undefined | null
    >;
    rtcCreds$: Rx.BehaviorSubject<RtcCredentials | null>;
  }) {
    console.log("P.ME!!! ", p.me);
    super({
      ...p,
      myId: p.baseUserId,
      chatCreds$: p.chatCreds$,
      rtcCreds$: p.rtcCreds$,
      mkChatCli: ({ apiKey }) => StreamChat.getInstance(apiKey),
      mkRtcMgr: (p) => new WebRtcMgr(p),
    });
    this.baseUserId = p.baseUserId;
    this.firebaseMgr = p.firebaseMgr;
  }

  signOut = Effect.promise(() => this.firebaseMgr.signOut());
}

export const [UWebStateMgrContext, useUMgr] =
  createContextAndHook<UWebStateMgr>();

export function useMbRtcMgr(): WebRtcMgr | null {
  const uMgr = useUMgr();
  const rtcMgr = useObservableEagerState(uMgr.rtcMgr$);

  return rtcMgr;
}

export function useAssumedChatCli(): StreamChat<DefaultStreamChatGenerics> {
  const uMgr = useUMgr();
  const chatCli = useObservableEagerState(uMgr.chatStateMgr.chatCli$);

  if (chatCli === undefined) {
    throw new Error("No chat client");
  }

  return chatCli;
}

export function useAssumedRtcMgr(): WebRtcMgr {
  const mbRtcMgr = useMbRtcMgr();

  if (mbRtcMgr === null) {
    throw new Error("No RTC MGR");
  }

  return mbRtcMgr;
}

export function useAssumedCurrentChannel(): Call {
  const rtcMgr = useAssumedRtcMgr();

  const mbChannelMgr = useObservableEagerState(rtcMgr.mbCurrentChannelMgr$);

  if (Option.isNone(mbChannelMgr)) {
    throw new Error("No current channel");
  }

  return mbChannelMgr.value;
}

export function useMe() {
  const uMgr = useUMgr();
  const me = useObservableEagerState(uMgr.me$);
  return me;
}

export function useMyId() {
  const uMgr = useUMgr();
  return uMgr.baseUserId;
}

export function useWebAppSession(p: { userId: Id<"users"> }) {
  return useAppSession({
    platform: "WEB",
    userId: p.userId,
  });
}

export class DialogVM {
  openVM = createState<React.ReactNode | null>(null);

  openDialogue(node: React.ReactNode) {
    this.openVM.event(node);
  }

  closeDialogue() {
    this.openVM.event(null);
  }
}
