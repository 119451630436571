import { PrimaryButton } from "@webapp/components/primitives/button";
import { LoadingSpinner } from "@webapp/loading";
import { useAction } from "convex/react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { api } from "shared/convex/_generated/api";
import { RemoteData } from "shared/remote-data";
import { UROUTES } from "shared/routes/u.routes";
import type { PsychologyTodayProfile } from "shared/schemas/hp-profile.schemas";

export const HpOnboardChooseProfileMethodPage: React.FC = () => {
  const [enteredUrl, setEnteredUrl] = useState("");
  const nav = useNavigate();

  const onSubmitUrlForProfileImport = useAction(
    api.Onboard.OnboardFns.onSubmitUrlForProfileImport
  );

  const [rdImportResult, setRdImportResult] = useState<
    RemoteData<PsychologyTodayProfile | undefined, string>
  >(RemoteData.initial);

  if (RemoteData.isLoading(rdImportResult)) {
    return (
      <div className="flex flex-col gap-4 w-full h-full items-center justify-center">
        <LoadingSpinner />
        <h4 className="text-vid-purple">
          Importing profile. Buckle up, this can take a couple minutes...
        </h4>
      </div>
    );
  }

  return (
    <div className="flex-1 p-8 flex flex-col items-center justify-center">
      <div className="p-8 flex flex-col gap-[22px]">
        <h1 className="text-[54px] font-medium text-vid-purple">
          Tell us about your practice
        </h1>
        <div className="flex justify-center self-stretch">
          <div className="flex flex-col gap-4 basis-[544px] grow-0 shrink">
            <input
              type="text"
              className="text-input"
              placeholder="Enter a URL (Psychology Today or your website)"
              value={enteredUrl}
              onChange={(e) =>
                setEnteredUrl((e.target as HTMLInputElement).value)
              }
            />
            <PrimaryButton
              title="Submit"
              disabled={enteredUrl.length === 0}
              onClick={() => {
                setRdImportResult(RemoteData.loading);

                onSubmitUrlForProfileImport({
                  url: enteredUrl,
                }).then(() => {
                  nav(
                    UROUTES.HP.ONBOARD_SET_PROFILE.buildPath(
                      {},
                      {
                        fromUrl: enteredUrl,
                      }
                    )
                  );
                });
              }}
            />
          </div>
        </div>
        <Link
          className="text-vid-purple font-medium self-center"
          to={UROUTES.HP.ONBOARD_SET_PROFILE.path}
        >
          Or enter manually
        </Link>
      </div>
    </div>
  );
};
