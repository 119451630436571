// quickActionButton.types.ts
import { useUnit } from "effector-react";
import React from "react";
import type { QuickActionButtonVM, QuickActionState } from "./quick-action.vm";
import { Match } from "effect";

export interface QuickActionConfig {
  id: string;
  icon: React.ReactNode;
  label: string;
}

interface QuickActionButtonProps {
  config: QuickActionConfig;
  vm: QuickActionButtonVM;
  className?: string;
  actionButton?: React.ReactNode;
  onActionClick?: () => void;
  renderOpenIdle?: () => React.ReactNode;
}

export const QUICK_ACTION = {
  BUTTON_SIZE: "h-[55px] w-[55px]",
  ICON_SIZE: "h-6 w-6",
  ICON_CONTAINER_SIZE: "h-[32px] w-[32px]",
} as const;

export const QuickActionButton: React.FC<QuickActionButtonProps> = ({
  config,
  vm,
  className = "",
  actionButton,
  onActionClick,
  renderOpenIdle,
}) => {
  const state = useUnit(vm.$state);
  const { icon: Icon, label } = config;
  const isOpen = state.tag === "OPEN" && state.state !== "CLOSING";

  return (
    <div className={`mb-4 ${className}`}>
      <div
        style={{ width: isOpen ? "auto" : "55px" }}
        className={`absolute flex items-center transition-all duration-500 ${
          state.tag === "OPEN" ? "ease-out" : "ease-in-out"
        } 
          ${
            state.tag === "OPEN"
              ? "bg-[rgba(20,20,20,0.75)] pr-4 opacity-100 scale-100"
              : "bg-[rgba(20,20,20,0.75)] opacity-100 transform"
          }
          ${state.tag === "OPEN" && state.state === "CLOSING" ? "scale-90 opacity-0 -translate-y-2" : ""}
          rounded-full border border-vid-black-800 ${renderOpenIdle ? "" : "overflow-hidden"}`}
      >
        <LeftMostButton onClick={() => vm.toggle()} state={state} icon={Icon} />

        <div
          className={`flex items-center whitespace-nowrap ${renderOpenIdle ? "" : "overflow-hidden"} transition-all duration-300 ease-out ${
            state.tag === "OPEN"
              ? state.state === "CLOSING"
                ? "opacity-0 w-0 max-w-0"
                : "opacity-100 w-auto max-w-[500px]"
              : "w-0 opacity-0 max-w-0"
          }`}
        >
          <OpenMiddleSection
            state={state}
            label={label}
            renderOpenIdle={renderOpenIdle}
          />
          <OpenRightSection
            state={state}
            actionButton={actionButton}
            onActionClick={onActionClick}
            vm={vm}
          />
        </div>
      </div>
    </div>
  );
};

const LeftMostButton: React.FC<{
  onClick: () => void;
  state: QuickActionState;
  icon: React.ReactNode;
}> = ({ onClick, state, icon }) => {
  const isOpen = state.tag === "OPEN" && state.state !== "CLOSING";
  const isSuccess = state.tag === "OPEN" && state.state === "SUCCESS";

  return (
    <button
      onClick={onClick}
      className={`${QUICK_ACTION.BUTTON_SIZE} rounded-full flex items-center justify-center transition-all duration-300 flex-shrink-0 ${
        state.tag === "OPEN" ? "mr-3" : ""
      }`}
    >
      {isSuccess ? (
        <SuccessCheckSvg />
      ) : (
        <div className="flex items-center justify-center h-full w-full">
          <div
            className={`${isOpen ? "text-blue-400" : "text-white"} transition-colors duration-300`}
          >
            {icon}
          </div>
        </div>
      )}
    </button>
  );
};

const OpenMiddleSection: React.FC<{
  state: QuickActionState;
  label: string;
  renderOpenIdle?: () => React.ReactNode;
}> = ({ state, label, renderOpenIdle }) => {
  if (state.tag !== "OPEN") {
    return null;
  }

  return (
    <div className="text-white mr-4">
      {Match.value(state.state).pipe(
        Match.when("CAPTURING", () => <span>Recording...</span>),
        Match.when("SAVING", () => <span>Saving...</span>),
        Match.when("SUCCESS", () => <span>{label} saved</span>),
        Match.when("CLOSING", () => <span></span>),
        Match.when("IDLE", () => renderOpenIdle?.() ?? <span>{label}</span>),
        Match.exhaustive
      )}
    </div>
  );
};

const OpenRightSection: React.FC<{
  state: QuickActionState;
  actionButton?: React.ReactNode;
  onActionClick?: () => void;
  vm: QuickActionButtonVM;
}> = ({ state, actionButton, onActionClick, vm }) => {
  const isSuccess = state.tag === "OPEN" && state.state === "SUCCESS";
  const isSaving = state.tag === "OPEN" && state.state === "SAVING";

  if (isSuccess) {
    return <></>;
  }

  if (actionButton) {
    return actionButton;
  }

  return (
    <button
      onClick={() => onActionClick?.() ?? vm.save()}
      className="bg-white text-black px-6 py-2 rounded-full font-medium hover:bg-gray-100 transition-colors flex-shrink-0"
    >
      {isSaving ? "Saving..." : "Save"}
    </button>
  );
};

const SuccessCheckSvg: React.FC = () => (
  <div className="flex items-center justify-center p-[2px] border border-white rounded-full">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="35"
      viewBox="0 0 34 35"
      fill="none"
    >
      <circle cx="17" cy="17.5" r="17" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6485 11.8697C24.8736 12.1062 25 12.4269 25 12.7613C25 13.0957 24.8736 13.4165 24.6485 13.653L15.6492 23.1059C15.5302 23.2308 15.389 23.3299 15.2336 23.3976C15.0782 23.4652 14.9117 23.5 14.7435 23.5C14.5753 23.5 14.4087 23.4652 14.2533 23.3976C14.0979 23.3299 13.9567 23.2308 13.8378 23.1059L9.36655 18.4101C9.25187 18.2938 9.1604 18.1546 9.09748 18.0007C9.03455 17.8469 9.00143 17.6814 9.00005 17.5139C8.99866 17.3465 9.02904 17.1804 9.08941 17.0254C9.14978 16.8704 9.23893 16.7296 9.35166 16.6112C9.4644 16.4928 9.59845 16.3991 9.74601 16.3357C9.89356 16.2723 10.0517 16.2404 10.2111 16.2418C10.3705 16.2433 10.5281 16.2781 10.6745 16.3442C10.821 16.4103 10.9535 16.5064 11.0643 16.6268L14.7431 20.491L22.9499 11.8697C23.0614 11.7525 23.1938 11.6595 23.3396 11.5961C23.4853 11.5326 23.6415 11.5 23.7992 11.5C23.9569 11.5 24.1131 11.5326 24.2589 11.5961C24.4046 11.6595 24.537 11.7525 24.6485 11.8697Z"
        fill="#1D1626"
      />
    </svg>
  </div>
);
