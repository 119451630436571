import { Match } from "effect";
import type { MeditationStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/tools/meditation.statemgr";
import { useObservableState } from "observable-hooks";
import { useEffect, useState } from "react";
import type { KnownMeditationVisual } from "shared/session-state/shared-session-content-state.types";
import BirdseyeWaves from "../../../../assets/meditation/birdseye-waves.mp4";
import Candle from "../../../../assets/meditation/candle.mp4";
import WaterStream from "../../../../assets/meditation/stream.mp4";
import { NumberedInput } from "../../../../components/form/form-inputs.fc";
import {
  InversePrimaryButton,
  PrimaryButton,
} from "../../../primitives/button";
import { FormRadioCard } from "../../../primitives/radio-buttons";

const matchMeditationVideo = (visual: KnownMeditationVisual) =>
  Match.value(visual).pipe(
    Match.when("None", () => null),
    Match.when("Ocean", () => BirdseyeWaves),
    Match.when("Flame", () => Candle),
    Match.when("Stream", () => WaterStream),
    Match.exhaustive
  );

export const MeditationToolsPanel: React.FC<{
  meditationMgr: MeditationStateMgr;
}> = ({ meditationMgr }) => {
  const meditationState = useObservableState(meditationMgr.mgr.state$, null);
  // useEffect(() => {
  //   meditationMgr.setInitialState();
  // }, []);

  return (
    <div className="flex-1 flex flex-col">
      <div className="flex-1 min-h-0 flex flex-col gap-8">
        <TimerOptionsRadioGroup meditationMgr={meditationMgr} />
        <VisualOptionsRadioGroup meditationMgr={meditationMgr} />
      </div>
      <div className="self-stretch flex flex-col">
        {meditationState &&
          meditationState.timerMinutesSetting !== null &&
          Match.value(meditationState.playState).pipe(
            Match.when("READY", () => (
              <PrimaryButton
                title="Start Meditation"
                onClick={() => {
                  meditationMgr.setPlayState("PLAYING");
                }}
              />
            )),
            Match.when("OFF", () => (
              <PrimaryButton
                title="Start Meditation"
                onClick={() => {
                  meditationMgr.setPlayState("PLAYING");
                }}
              />
            )),
            Match.when("PLAYING", () => (
              <div className="flex gap-2">
                <InversePrimaryButton
                  title="Pause"
                  onClick={() => {
                    meditationMgr.setPlayState("PAUSED");
                  }}
                />
                <PrimaryButton
                  title="Stop"
                  onClick={() => {
                    meditationMgr.setPlayState("OFF");
                  }}
                />
              </div>
            )),
            Match.when("PAUSED", () => (
              <InversePrimaryButton
                title="Resume"
                onClick={() => {
                  meditationMgr.setPlayState("PLAYING");
                }}
              />
            )),
            Match.when("COMPLETE", () => <div>Meditation complete!</div>),
            Match.exhaustive
          )}
      </div>
    </div>
  );
};

export const MeditationPreview: React.FC<{
  meditationMgr: MeditationStateMgr;
}> = ({ meditationMgr }) => {
  const remoteState = useObservableState(meditationMgr.mgr.state$, null);

  return (
    <div className="flex-1 w-full">
      {remoteState?.visual && remoteState.visual !== "None" && (
        <video
          className="absolute inset-0 w-full h-full object-cover"
          src={matchMeditationVideo(remoteState.visual) ?? undefined}
          autoPlay
          loop
          muted
        />
      )}
    </div>
  );
};

const TimerOptionsRadioGroup: React.FC<{
  meditationMgr: MeditationStateMgr;
}> = ({ meditationMgr }) => {
  const [selected, setSelected] = useState("5 min");
  const [customMinutes, setCustomMinutes] = useState<number | null>(5);

  useEffect(() => {
    Match.value(selected).pipe(
      Match.when("None", () => meditationMgr.setRemoteTimerMinutes(null)),
      Match.when("Custom", () =>
        meditationMgr.setRemoteTimerMinutes(customMinutes)
      ),
      Match.orElse((s) => {
        const asNum = parseInt(s.split(" ")[0]);
        meditationMgr.setRemoteTimerMinutes(asNum);
      })
    );
  }, [selected, customMinutes]);

  return (
    <div>
      <FormRadioCard
        radioProps={{
          options: [
            { name: "None", value: "None" },
            { name: "1 min", value: "1 min" },
            { name: "5 min", value: "5 min" },
            { name: "10 min", value: "10 min" },
            { name: "20 min", value: "20 min" },
            { name: "Custom", value: "Custom" },
          ],
          value: selected,
          onChange: (value) => setSelected(value as string),
        }}
        title="Timer"
      />
      {selected === "Custom" && (
        <NumberedInput
          value={customMinutes}
          onChange={setCustomMinutes}
          label="Minutes"
        />
      )}
    </div>
  );
};

const visualOptions: {
  name: KnownMeditationVisual;
  value: KnownMeditationVisual;
}[] = [
  { name: "None", value: "None" },
  { name: "Ocean", value: "Ocean" },
  { name: "Flame", value: "Flame" },
  { name: "Stream", value: "Stream" },
];
const VisualOptionsRadioGroup: React.FC<{
  meditationMgr: MeditationStateMgr;
}> = ({ meditationMgr }) => {
  const [selected, setSelected] = useState<KnownMeditationVisual>("None");

  return (
    <FormRadioCard
      radioProps={{
        options: visualOptions,
        value: selected,
        onChange: (s) => {
          setSelected(s as KnownMeditationVisual);
          meditationMgr.setVisual(s as KnownMeditationVisual);
        },
      }}
      title="Visual Options"
    />
  );
};
