import type { ConvexClient } from "convex/browser";
import { Data, Option } from "effect";
import { createEvent, createStore, sample } from "effector";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import type { ChatChannelUserSetup } from "shared/types/chat.types";
import { isNotNullOrUndefined, isNullOrUndefined } from "shared/util";
import { ApiMgr } from "../../api.mgr";
import { BaseStateMgr } from "./base.statemgr";
import { GlobalMessengerVM } from "webapp/src/components/chat/messenger";

export class ChatStateMgr<ChatCli extends GenericChatCli> extends BaseStateMgr {
  mySimpleProfile: { id: string; name: string };
  chatCli$: Rx.BehaviorSubject<ChatCli | undefined> = new Rx.BehaviorSubject<
    ChatCli | undefined
  >(undefined);
  unreadCount$ = new Rx.BehaviorSubject<Option.Option<UnreadChatCountTracker>>(
    Option.none()
  );

  chatEventListener: any | undefined = undefined;
  unsubEvent: { unsubscribe: () => void } | undefined = undefined;

  messengerVM = new GlobalMessengerVM();

  constructor(p: {
    apiMgr: ApiMgr;
    convex: ConvexClient;
    mySimpleProfile: { id: string; name: string };
    chatCreds$: Rx.BehaviorSubject<
      { publicApiKey: string; token: string } | undefined | null
    >;
    mkChatCli: (p: { apiKey: string }) => ChatCli;
  }) {
    super({ apiMgr: p.apiMgr, convex: p.convex });
    this.mySimpleProfile = p.mySimpleProfile;

    console.log("CONSTRUCTING CHAT STATE MGR! ");

    p.chatCreds$
      .pipe(
        RxO.filter(isNotNullOrUndefined),
        RxO.distinctUntilChanged((prev, curr) => {
          if (prev === curr) return true;
          return JSON.stringify(prev) === JSON.stringify(curr);
        }),
        RxO.auditTime(500)
      )
      .subscribe((mbCreds) => {
        console.log("MB CHAT CREDS SUBSCRIBED! ", mbCreds);
        if (mbCreds === undefined) {
          return;
        }

        const cli = p.mkChatCli({ apiKey: mbCreds.publicApiKey });

        this.lazyConnectUser({
          chatCli: cli,
          user: this.mySimpleProfile,
          token: mbCreds.token,
        })
          .then((r) => {
            console.log("SUCCESSFULLY CONNECTED CHAT USER! ", r);
            this.chatCli$.next(cli);
          })
          .catch((e) => {
            console.log("ERROR CONNECTING USER! ", e);
          });
      });

    this.chatCli$.subscribe((cli) => {
      console.log("CHAT CLI SUBSCRIBED! ", cli);
      if (isNullOrUndefined(cli)) {
        return;
      }

      cli.getUnreadCount().then((r) => {
        console.log("UNREAD COUNT! ", r);
        this.unreadCount$.next(Option.some(new UnreadChatCountTracker(r)));
      });
    });

    // this.chatCli = p.mkChatCli({ apiKey: p.chatKeyAndToken.apiKey });

    // this.unsubEvent = this.chatCli.on(this.onEvent);

    // setTimeout(() => {
    //   this.chatEventListener = this.chatCli.connectUser(
    //     {
    //       id: this.mySimpleProfile.id,
    //       name: this.mySimpleProfile.name,
    //     },
    //     p.chatKeyAndToken.token
    //   );
    // }, 2000);

    // console.log("CHAT CLI! ", this.chatCli.getUnreadCount);

    // this.chatCli
    //   .getUnreadCount()
    //   .then((r) => {
    //     console.log("UNREAD COUNT! ", r);
    //   })
    //   .catch((e) => {
    //     console.log("ERROR GETTING UNREAD COUNT! ", e);
    //   });

    // this.runFetchAndSetUnreadCount();
  }

  lazyConnectUser = async (p: {
    chatCli: ChatCli;
    user: { id: string; name: string };
    token: string;
  }): Promise<void> => {
    try {
      console.log("LAZY DISCONNECTING CHAT USER! ");
      // await p.chatCli.disconnectUser();
      // console.log("SUCCESSFULLY DISCONNECTED CHAT USER! ");
    } catch (e) {
      console.log("ERROR DISCONNECTING CHAT USER ON LAZY THATS OKAY! ", e);
    }
    console.log("LAZY CONNECTING CHAT USER! ", p.user, p.token);
    return p.chatCli.connectUser(p.user, p.token);
  };

  onDismount = () => {
    this.unsubEvent?.unsubscribe();
  };
}

abstract class ChatEvent {
  abstract total_unread_count?: number;
  abstract unread_channels?: number;
}

export abstract class GenericChatCli {
  abstract connectUser: (
    user: { id: string; name: string },
    token: string
  ) => Promise<any>;

  abstract disconnectUser: () => Promise<any>;

  abstract on: (callback: (event: ChatEvent) => void) => {
    unsubscribe: () => void;
  };
  abstract getUnreadCount: () => Promise<UnreadChatResponse>;
}

interface UnreadChatResponse {
  channels: {
    channel_id: string;
    unread_count: number;
  }[];
  threads: {
    parent_message_id: string;
    unread_count: number;
  }[];
  total_unread_count: number;
  total_unread_threads_count: number;
}

class UnreadChatCountTracker extends Data.Class<UnreadChatResponse> {
  get totalChannelsWithAtLeastOneUnreadMessage() {
    return this.channels.filter((c) => c.unread_count > 0).length;
  }
}
