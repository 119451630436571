import * as S from "@effect/schema/Schema";
import { v } from "convex/values";
import { Match } from "effect";
import type { Doc, Id } from "../../../../convex/_generated/dataModel";
import { SpecialClockDisplayInfo } from "../../ongoing/clock.schemas";
import {
  KnownQuickActionToolTag,
  KnownTool,
  KnownToolTag,
} from "../known-tool.schemas";

const ToolMenuViewState = S.Union(
  S.Struct({ tag: S.Literal("MENU") }),
  S.Struct({ tag: S.Literal("SELECTED_TOOL"), tool: KnownToolTag })
);
type ToolMenuViewState = S.Schema.Type<typeof ToolMenuViewState>;

export class ToolMenuViewStateST extends S.Class<ToolMenuViewStateST>(
  "ToolMenuViewStateST"
)({
  viewState: ToolMenuViewState,
}) {
  static from = (viewState: ToolMenuViewState): ToolMenuViewStateST =>
    ToolMenuViewStateST.make({ viewState });

  get encoded(): typeof ToolMenuViewStateST.Encoded {
    return S.encodeUnknownSync(ToolMenuViewStateST)(this);
  }

  static asSelectedTool = (tool: KnownToolTag) =>
    ToolMenuViewStateST.make({ viewState: { tag: "SELECTED_TOOL", tool } });

  static asMenu = () =>
    ToolMenuViewStateST.make({ viewState: { tag: "MENU" } });

  static default = () => ToolMenuViewStateST.asMenu();
}

const knownToolConvexSchema = v.union(
  v.literal("EMDR"),
  v.literal("MEDITATION"),
  v.literal("NOTES"),
  v.literal("CHAT"),
  v.literal("REVIEW"),
  v.literal("QUICK_SCHEDULE"),
  v.literal("BREAKOUT_ROOMS")
);

export class ToolMenuToolsST extends S.Class<ToolMenuToolsST>(
  "ToolMenuToolsST"
)({
  tools: S.Array(KnownToolTag),
}) {
  static convexSchema = v.array(knownToolConvexSchema);

  static knownToolConvexSchema = knownToolConvexSchema;

  static from = (p: { tools: KnownToolTag[] }): ToolMenuToolsST =>
    ToolMenuToolsST.make({ tools: p.tools });

  get encoded(): typeof ToolMenuToolsST.Encoded {
    return S.encodeUnknownSync(ToolMenuToolsST)(this);
  }

  static default = () =>
    ToolMenuToolsST.make({
      tools: ["NOTES", "EMDR", "MEDITATION", "CHAT", "BREAKOUT_ROOMS"],
    });
}

export const CurrentOpenMenu = S.Union(
  S.Literal("TOOLS"),
  S.Literal("SETTINGS")
);
export type CurrentOpenMenu = S.Schema.Type<typeof CurrentOpenMenu>;

const CurrentOpenMenuViewState = S.NullOr(CurrentOpenMenu);
type CurrentOpenMenuViewState = S.Schema.Type<typeof CurrentOpenMenuViewState>;

export class CurrentOpenMenuST extends S.Class<CurrentOpenMenuST>(
  "CurrentOpenMenuST"
)({
  viewState: S.NullOr(CurrentOpenMenu),
}) {
  static from = (viewState: CurrentOpenMenu | null): CurrentOpenMenuST =>
    CurrentOpenMenuST.make({ viewState });

  static mkFromEncoded = (
    encoded: typeof CurrentOpenMenuST.Encoded
  ): CurrentOpenMenuST => S.decodeUnknownSync(CurrentOpenMenuST)(encoded);

  get asSchema(): CurrentOpenMenuViewState {
    return this.viewState;
  }

  static default = () => CurrentOpenMenuST.asClosed();

  get encoded(): typeof CurrentOpenMenuST.Encoded {
    return S.encodeUnknownSync(CurrentOpenMenuST)(this);
  }

  static asClosed = () => CurrentOpenMenuST.make({ viewState: null });

  static asSettings = () => CurrentOpenMenuST.make({ viewState: "SETTINGS" });

  static asTools = () => CurrentOpenMenuST.make({ viewState: "TOOLS" });
}

const MenuViewState = S.Union(
  S.Struct({ tag: S.Literal("SETTINGS") }),
  S.Struct({ tag: S.Literal("TOOLS"), toolMenuViewState: ToolMenuViewState }),
  S.Struct({ tag: S.Literal("CLOSED") })
);
type MenuViewState = S.Schema.Type<typeof MenuViewState>;

export class MenuViewStateST extends S.Class<MenuViewStateST>(
  "MenuViewStateST"
)({
  viewState: MenuViewState,
}) {
  static from = (p: {
    toolMenuViewState: typeof ToolMenuViewStateST.Encoded;
    currentOpenMenu: typeof CurrentOpenMenuST.Encoded;
  }): MenuViewStateST =>
    Match.value(p.currentOpenMenu.viewState).pipe(
      Match.when("TOOLS", () =>
        MenuViewStateST.make({
          viewState: {
            tag: "TOOLS",
            toolMenuViewState: p.toolMenuViewState.viewState,
          },
        })
      ),
      Match.when("SETTINGS", () =>
        MenuViewStateST.make({ viewState: { tag: "SETTINGS" } })
      ),
      Match.when(null, () =>
        MenuViewStateST.make({ viewState: { tag: "CLOSED" } })
      ),
      Match.exhaustive
    );

  get encoded(): typeof MenuViewStateST.Encoded {
    return S.encodeUnknownSync(MenuViewStateST)(this);
  }

  get backDestination(): MenuViewStateST | undefined {
    return Match.value(this.viewState).pipe(
      Match.when({ tag: "TOOLS" }, ({ toolMenuViewState }) =>
        Match.value(toolMenuViewState).pipe(
          Match.when({ tag: "MENU" }, () => undefined),
          Match.when({ tag: "SELECTED_TOOL" }, () =>
            MenuViewStateST.asFullToolMenu()
          ),
          Match.exhaustive
        )
      ),
      Match.when({ tag: "SETTINGS" }, () => undefined),
      Match.when({ tag: "CLOSED" }, () => undefined),
      Match.exhaustive
    );
  }

  static asFullToolMenu = () =>
    MenuViewStateST.make({
      viewState: { tag: "TOOLS", toolMenuViewState: { tag: "MENU" } },
    });

  static asClosed = () =>
    MenuViewStateST.make({ viewState: { tag: "CLOSED" } });

  static asSettings = () =>
    MenuViewStateST.make({ viewState: { tag: "SETTINGS" } });

  get menuTitle(): string {
    return Match.value(this.viewState).pipe(
      Match.when({ tag: "TOOLS" }, ({ toolMenuViewState }) =>
        Match.value(toolMenuViewState).pipe(
          Match.when(
            { tag: "SELECTED_TOOL" },
            ({ tool }) => KnownTool.fromTag(tool).title
          ),
          Match.when({ tag: "MENU" }, () => "Tools"),
          Match.exhaustive
        )
      ),
      Match.when({ tag: "SETTINGS" }, () => "Settings"),
      Match.when({ tag: "CLOSED" }, () => "Menu"),
      Match.exhaustive
    );
  }
}

const SelectableVideoLayout = S.Union(
  S.Literal("PAGINATED_GRID"),
  S.Literal("GALLERY")
);
export type SelectableVideoLayout = S.Schema.Type<typeof SelectableVideoLayout>;

const ContentDisplayMode = S.Union(
  S.Literal("TOP_PREVIEW"),
  S.Literal("FULL_SCREEN")
);
export type ContentDisplayMode = S.Schema.Type<typeof ContentDisplayMode>;

export class SessionParticipantDisplayST extends S.Class<SessionParticipantDisplayST>(
  "SessionParticipantDisplayST"
)({
  baseUserIdStr: S.String,
  isBackgroundBlurred: S.Boolean,
  mbShowSpecialClock: S.NullOr(SpecialClockDisplayInfo),
  currentOpenMenu: CurrentOpenMenuViewState,
  toolMenuViewState: ToolMenuViewState,
  lastShowedScheduleReminderAt: S.NullOr(S.Number),
  lastDisplayedTimeLeftNotifAt: S.NullOr(S.Number),
  preferredVideoLayout: S.NullOr(SelectableVideoLayout),
  contentInFocus: S.NullOr(KnownToolTag),
  contentDisplayMode: ContentDisplayMode,
  tools: S.Array(KnownToolTag),
  quickActionTools: S.Array(KnownQuickActionToolTag),
}) {
  static fromDoc = (p: {
    doc: Doc<"sessionParticipantDisplays">;
  }): SessionParticipantDisplayST =>
    SessionParticipantDisplayST.make({
      ...p.doc,
      baseUserIdStr: p.doc.baseUserId,
    });

  static fromEncoded = (encoded: typeof SessionParticipantDisplayST.Encoded) =>
    S.decodeUnknownSync(SessionParticipantDisplayST)(encoded);

  get baseUserId(): Id<"users"> {
    return this.baseUserIdStr as Id<"users">;
  }

  static default = (p: {
    baseUserId: Id<"users">;
    toolMenuTools: KnownToolTag[];
  }) =>
    SessionParticipantDisplayST.make(
      {
        baseUserIdStr: p.baseUserId,
        isBackgroundBlurred: false,
        mbShowSpecialClock: null,
        currentOpenMenu: null,
        toolMenuViewState: { tag: "MENU" },
        lastShowedScheduleReminderAt: null,
        lastDisplayedTimeLeftNotifAt: null,
        tools: p.toolMenuTools,
        preferredVideoLayout: null,
        contentInFocus: null,
        contentDisplayMode: "TOP_PREVIEW",
        quickActionTools: ["BOOKMARK", "BOOKEND", "NOTES"],
      },
      { disableValidation: true }
    );

  get asDefaultInsert() {
    const { baseUserIdStr, tools, quickActionTools, ...rest } = this.encoded;
    return {
      baseUserId: this.baseUserId,
      ...rest,
      tools: [...tools],
      quickActionTools: [...quickActionTools],
    };
  }

  get encoded() {
    return S.encodeUnknownSync(SessionParticipantDisplayST)(this);
  }
}

const MainRoomTitleDisplaySectionMode = S.Union(
  S.Struct({
    tag: S.Literal("TITLE_AND_PARTICIPANTS"),
    participants: S.Array(
      S.Struct({ name: S.String, profilePhoto: S.NullOr(S.String) })
    ),
    title: S.NullOr(S.String),
  }),
  S.Struct({ tag: S.Literal("APPROVE_USER_ENTERED"), userName: S.String })
);

export class MainRoomTitleDisplaySectionST extends S.Class<MainRoomTitleDisplaySectionST>(
  "MainRoomTitleDisplaySectionST"
)({
  viewState: MainRoomTitleDisplaySectionMode,
}) {
  static default = () =>
    MainRoomTitleDisplaySectionST.make({
      viewState: {
        tag: "TITLE_AND_PARTICIPANTS",
        participants: [],
        title: null,
      },
    });

  static from = (p: {
    participants: { name: string; profilePhoto: string | null }[];
    title: string | null;
    admissionInfo?: {
      nextUserInAdmissionLobby: { name: string } | null;
      amIAnApprovingAdmin: boolean;
    };
  }): MainRoomTitleDisplaySectionST => {
    if (
      p.admissionInfo?.amIAnApprovingAdmin &&
      p.admissionInfo.nextUserInAdmissionLobby !== null
    ) {
      return MainRoomTitleDisplaySectionST.asApproveUserEntered({
        userName: p.admissionInfo.nextUserInAdmissionLobby.name,
      });
    }
    return MainRoomTitleDisplaySectionST.asTitleAndParticipants({
      participants: p.participants,
      title: p.title,
    });
  };

  static asTitleAndParticipants = (p: {
    participants: { name: string; profilePhoto: string | null }[];
    title: string | null;
  }) =>
    MainRoomTitleDisplaySectionST.make({
      viewState: {
        tag: "TITLE_AND_PARTICIPANTS",
        participants: p.participants,
        title: p.title,
      },
    });

  static asApproveUserEntered = (p: { userName: string }) =>
    MainRoomTitleDisplaySectionST.make({
      viewState: { tag: "APPROVE_USER_ENTERED", userName: p.userName },
    });

  get encoded() {
    return S.encodeUnknownSync(MainRoomTitleDisplaySectionST)(this);
  }
}
