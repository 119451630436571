// hooks/use-toast.tsx
import { useUnit } from "effector-react";
import { ToastVM, type ToastProps } from "./toast.vm";

export function useToast(vm: ToastVM) {
  const { toasts, showToast, dismissToast } = useUnit({
    toasts: vm.$toasts,
    showToast: vm.showToast,
    dismissToast: vm.dismissToast,
  });

  const toast = (props: ToastProps) => {
    showToast(props);
  };

  return {
    toasts,
    toast,
    dismiss: dismissToast,
  };
}

// Helper to create a global toast function
export const createGlobalToast = (vm: ToastVM) => {
  return (props: ToastProps) => vm.showToast(props);
};
