import React, { useMemo } from "react";
import { Outlet } from "react-router-dom";
import { HpProfile } from "shared";
import { useMyId, useUMgr } from "../u.webstatemgr";
import { HpStateContext, PortalWebStateMgr } from "./hp.webstatemgr";
import type { HpDashbaordRightNav } from "./dashboard/right-drawer-action/right-nav";

export const HpLayout: React.FC = () => {
  return <MyLayoutContent myProfile={{ bio: "", modalities: [] }} />;
};

const MyLayoutContent: React.FC<{ myProfile: HpProfile }> = ({ myProfile }) => {
  const uMgr = useUMgr();
  const myId = useMyId();
  const state = useMemo(
    () =>
      new PortalWebStateMgr<HpDashbaordRightNav>(uMgr, {
        myProfile,
        myUserId: myId,
      }),
    [uMgr, myProfile]
  );

  return (
    <HpStateContext.Provider value={state}>
      <Outlet />
    </HpStateContext.Provider>
  );
};
