import * as Sentry from "@sentry/react";
import { Effect } from "effect";
import { ApiMgrContext, useRunEffect } from "frontend-shared/src/api.mgr";
import { FirebaseJsContext } from "frontend-shared/src/firebase";
import { useObservableState } from "observable-hooks";
import * as React from "react";
import { useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { RD } from "shared/base-prelude";
import { ROUTES } from "shared/routes/routes";
import { AppConfig, firebaseMgr } from "./app-config";
import { mkWebApiMgr } from "./backend";
import { useConvexCli } from "./convex-cli";
import { globalErrorToastMgr, globalTopInfoToastMgr } from "./global.state";
import { FullScreenLoadingSpinner } from "./loading";
import { ONBOARD_ROUTES } from "./route-views/onboard-route-views";

export const IndexLayout: React.FC = () => {
  const location = useLocation();
  const nav = useNavigate();
  // const convexCli = useSetupConvexCli(firebaseMgr);
  const convexCli = useConvexCli();
  const apiMgr = useMemo(
    () => mkWebApiMgr({ apiUrl: AppConfig.apiUrl!, firebaseMgr, convexCli }),
    [AppConfig.apiUrl, firebaseMgr]
  );
  const mbShowGlobalErrorToast = useObservableState(
    globalErrorToastMgr.showGlobalErrorToast$,
    null
  );
  const mbShowGlobalTopInfoToast = useObservableState(
    globalTopInfoToastMgr.showToast$,
    null
  );
  const rdFirebaseUser = useRunEffect(
    Effect.promise(() => firebaseMgr.getFirebaseUser()),
    []
  );

  useEffect(() => {
    if (RD.isSuccess(rdFirebaseUser) && location.pathname === "/") {
      if (rdFirebaseUser.value) {
        console.log("NAV TO UROUTES! ", ROUTES.U.path);
        nav(ROUTES.U.path);
      } else {
        nav(ONBOARD_ROUTES.PORTAL_SELECTION.path);
      }
    }
  }, [rdFirebaseUser]);

  if (!RD.isSuccess(rdFirebaseUser)) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <ApiMgrContext.Provider value={apiMgr}>
      <FirebaseJsContext.Provider value={firebaseMgr}>
        <Sentry.ErrorBoundary fallback={<ErrorPage />}>
          <div className="w-screen h-screen flex flex-col justify-center relative">
            <Outlet />
            {mbShowGlobalErrorToast && (
              <div className="absolute top-0 left-0 right-0 h-[100px] flex flex-col justify-center items-center bg-red-500 z-50">
                <div className="text-white p-4 rounded-md">
                  {mbShowGlobalErrorToast.title}
                </div>
                <div className="text-white p-4 rounded-md">
                  {mbShowGlobalErrorToast.message}
                </div>
              </div>
            )}
            {mbShowGlobalTopInfoToast && (
              <div className="absolute top-0 left-0 right-0 h-[100px] flex justify-center items-center">
                <div className="bg-vid-black-800 text-white p-4 rounded-md animate-slide-down-top-info-toast">
                  {mbShowGlobalTopInfoToast.title}
                </div>
              </div>
            )}
          </div>
        </Sentry.ErrorBoundary>
      </FirebaseJsContext.Provider>
    </ApiMgrContext.Provider>
  );
};

export const ErrorPage: React.FC = () => {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center bg-vid-black-50">
      <div className="flex flex-col items-center gap-6 p-8 rounded-lg bg-white shadow-default-card max-w-md">
        <img src="/il-logo.svg" alt="Logo" className="w-16 h-16" />
        <div className="flex flex-col items-center gap-2 text-center">
          <h1 className="text-2xl font-medium text-vid-black-900">
            Something went wrong
          </h1>
          <p className="text-vid-black-700">
            An unexpected error has occurred. Please try refreshing the page or
            contact support if the problem persists.
          </p>
        </div>
        <button
          onClick={() => window.location.reload()}
          className="bg-vid-purple hover:bg-btn-purple text-white rounded-buttons px-6 py-3 transition-colors"
        >
          Refresh Page
        </button>
      </div>
    </div>
  );
};
