import { SearchableDropdownV2 } from "@webapp/componentsform/searchabel-dropdown.fc";
import { useQuery } from "convex/react";
import { BaseSearchableDropdownVM } from "frontend-shared/src/shared-vms/searchable-dropdown.vm";
import { useOnce } from "frontend-shared/src/util";
import type React from "react";
import { useEffect } from "react";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";

class ClientSelectVM extends BaseSearchableDropdownVM<{
  id: string;
  label: string;
}> {
  constructor(p: { allMyClients: { id: string; name: string }[] }) {
    super(p.allMyClients.map((c) => ({ id: c.id, label: c.name })));
  }
}

export const ClientSearchableDropdown: React.FC<{
  onSelect: (client: { id: Id<"users">; name: string }) => void;
}> = ({ onSelect }) => {
  const allMyClients = useQuery(api.User.Hp.HpFns.getMyClients);

  const vm = useOnce(() => new ClientSelectVM({ allMyClients: [] }));

  useEffect(() => {
    if (allMyClients !== undefined) {
      vm.itemsSet(allMyClients.map((c) => ({ id: c.clientId, label: c.name })));
    }

    vm.itemSelected.watch((item) => {
      if (item !== null) {
        onSelect({ id: item.id as Id<"users">, name: item.label });
      }
    });
  }, [allMyClients]);

  return (
    <SearchableDropdownV2
      vm={vm}
      label="Client"
      placeholder="Select a client..."
    />
  );
};
