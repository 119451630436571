import { useUMgr } from "@/src/pages/u/u.webstatemgr";
import type { Rx } from "@/src/prelude";
import { cn } from "@/src/utils/utils";
import { Match } from "effect";
import { createEvent, createStore, sample } from "effector";
import { useUnit } from "effector-react";
import type { ChatChannelUserSetup } from "shared/types/chat.types";
import { StreamChat } from "stream-chat";
import type { DefaultStreamChatGenerics } from "stream-chat-react";
import { ChatFC } from "./chat.fc";

type GlobalMessengerViewState =
  | { _tag: "ALL_CHATS_LIST" }
  | { _tag: "CHAT_ROOM"; setup: typeof ChatChannelUserSetup.Encoded };

type OpenState = "OPEN" | "TOP_TEASE" | "HIDDEN";

export class GlobalMessengerVM {
  setViewStateEvt = createEvent<GlobalMessengerViewState>();
  setOpenStateEvt = createEvent<OpenState>();
  setHiddenEvt = createEvent();

  $openState = createStore<OpenState>("HIDDEN");
  $viewState = createStore<GlobalMessengerViewState>({
    _tag: "ALL_CHATS_LIST",
  });

  constructor() {
    sample({
      clock: this.setViewStateEvt,
      target: this.$viewState,
    });

    sample({
      clock: this.setOpenStateEvt,
      target: this.$openState,
    });
  }
}

export const Messenger: React.FC<{
  vm: GlobalMessengerVM;
}> = ({ vm }) => {
  const chatCli$ = useUMgr().chatStateMgr.chatCli$;
  const state = useUnit(vm.$openState);

  const containerClasses = cn(
    "fixed bottom-0 right-4 bg-white rounded-lg shadow-lg transition-all duration-300 z-50",
    {
      "h-[597px] w-[345px] translate-y-0": state === "OPEN",
      "h-[88px] w-[345px] translate-y-0": state === "TOP_TEASE",
      "translate-y-full opacity-0": state === "HIDDEN",
    }
  );

  return (
    <div
      className={`${containerClasses} transform transition-transform duration-300 ease-out`}
    >
      <div className="flex flex-col h-full">
        <header className="flex items-center justify-between p-4 h-[88px] border-b">
          <h2 className="font-semibold">Chat Messenger</h2>
          <div className="flex gap-2">
            <button
              onClick={() =>
                vm.setOpenStateEvt(state === "OPEN" ? "TOP_TEASE" : "OPEN")
              }
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              {state === "OPEN" ? "−" : "+"}
            </button>
            <button
              onClick={() => vm.setOpenStateEvt("HIDDEN")}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              ×
            </button>
          </div>
        </header>

        {state === "OPEN" && (
          <div className="flex flex-col flex-1 p-4">
            <OpenMessengerView chatCli$={chatCli$} vm={vm} />
          </div>
        )}
      </div>
    </div>
  );
};

const OpenMessengerView: React.FC<{
  chatCli$: Rx.BehaviorSubject<
    StreamChat<DefaultStreamChatGenerics> | undefined
  >;
  vm: GlobalMessengerVM;
}> = ({ chatCli$, vm }) => {
  const viewState = useUnit(vm.$viewState);

  return Match.value(viewState).pipe(
    Match.when({ _tag: "ALL_CHATS_LIST" }, () => <div>ALL_CHATS_LIST</div>),
    Match.when({ _tag: "CHAT_ROOM" }, ({ setup }) => (
      <ChatFC chatCli$={chatCli$} userChatSetup={setup} />
    )),
    Match.exhaustive
  );
};
