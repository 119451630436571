import * as S from "@effect/schema/Schema";
import type { Doc, Id } from "../../_generated/dataModel";
import type { SimpleUserWithProfilePhoto } from "../../../types/user.types";

export class KnownModality extends S.Class<KnownModality>("KnownModality")({
  name: S.String,
  slug: S.String,
}) {
  static from(data: typeof KnownModality.Encoded): KnownModality {
    return KnownModality.make(data, { disableValidation: true });
  }

  get encoded(): typeof KnownModality.Encoded {
    return S.encodeUnknownSync(KnownModality)(this);
  }
}

export class KnownModalitiesST extends S.Class<KnownModalitiesST>(
  "KnownModalitiesST"
)({
  modalities: S.Array(KnownModality),
}) {
  static fromDocs = (p: {
    modalityDocs: Doc<"knownModalities">[];
  }): KnownModalitiesST => {
    return KnownModalitiesST.make(
      {
        modalities: p.modalityDocs.map((md) => KnownModality.from(md)),
      },
      { disableValidation: true }
    );
  };

  static default = KnownModalitiesST.make({ modalities: [] });

  get encoded(): typeof KnownModalitiesST.Encoded {
    return S.encodeUnknownSync(KnownModalitiesST)(this);
  }
}

export type ActiveClientInfo = {
  clientId: Id<"users">;
  email: string;
  name: string;
  profilePhoto?: { thumb: string; medium: string };
  relationshipStartDate: number;
};

export interface HpGlobalProfile {
  simpleProfile: SimpleUserWithProfilePhoto;
  myModalities: { name: string; slug: string }[];
  bio: string;
}
