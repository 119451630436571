import { InvitePage } from "@pages/anon/invite.page";
import { MyGlobalProfilePage } from "@/src/pages/anon/dash/community/global-user-profile.page";
import { OnboardLayout } from "@pages/onboard/onboard.layout";
import { type RouteObject } from "react-router-dom";
import { ANON_ROUTES, ROUTES } from "shared/routes/routes";
import {
  AboutTab,
  DiscussionTab,
  EventsTab,
  ScheduleTab,
} from "../domains/communities/global-practitioner-dash";
import { IndexLayout } from "../index.layout";
import { AnonConfirmEmailInviteRedirectPage } from "../pages/anon/confirm-email-invite/confirm-email-redirect.page";
import { AnonConfirmEmailViewOurWorkPage } from "../pages/anon/confirm-email-invite/view-our-work.page";
import { CpOnboardCreateAccountPage } from "../pages/cp-onboard/create-account.page";
import { LoginPage } from "../pages/login.page";
import { onboardRoutes } from "./onboard-route-views";
import { uRouteViews } from "./u-route-views";
import { AnonDashLayout } from "../domains/anon/anon.dashboard";
import { MockBreakoutRoomsSimulator } from "../domains/sessions/rtc/rooms/breakout-rooms-dnd";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <IndexLayout />,
    children: [
      {
        path: "mbr",
        element: <MockBreakoutRoomsSimulator />,
      },
      {
        element: <OnboardLayout />,
        children: [
          {
            path: ROUTES.LOGIN.path,
            element: <LoginPage />,
          },
        ],
      },
      onboardRoutes,
      uRouteViews,
      {
        path: ROUTES.INVITES.INVITE_ID.path,
        element: <InvitePage />,
      },
      {
        path: "onboard-cp/create-account",
        element: <CpOnboardCreateAccountPage />,
      },
      {
        path: ANON_ROUTES.DASH.path,
        element: <AnonDashLayout />,
        children: [
          {
            path: ANON_ROUTES.DASH.COMMUNITY.MEMBERS.MEMBER_ID.DISCUSSION.path,
            element: <div>DISCUSSION YO!</div>,
          },
        ],
      },
      {
        children: [
          {
            children: [
              {
                path: ANON_ROUTES.CONFIRM_EMAIL_INVITE.path,
                element: <AnonConfirmEmailInviteRedirectPage />,
              },
              {
                path: "view-our-work",
                element: <AnonConfirmEmailViewOurWorkPage />,
              },
              {
                path: ANON_ROUTES.MY_GLOBAL_PROFILE.path,
                element: <MyGlobalProfilePage />,
                children: [
                  {
                    path: ANON_ROUTES.MY_GLOBAL_PROFILE.DISCUSSION.path,
                    element: <DiscussionTab />,
                  },
                  {
                    path: ANON_ROUTES.MY_GLOBAL_PROFILE.EVENTS.path,
                    element: <EventsTab />,
                  },
                  {
                    path: ANON_ROUTES.MY_GLOBAL_PROFILE.ABOUT.path,
                    element: <AboutTab />,
                  },
                  {
                    path: ANON_ROUTES.MY_GLOBAL_PROFILE.SCHEDULE.path,
                    element: <ScheduleTab />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

console.log("ROUTES", routes);
