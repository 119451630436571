import { createEvent, createStore } from "effector";

export class FormFocusVM {
  static instance: FormFocusVM;

  $focusedField = createStore<string | null>(null);
  setFocusedField = createEvent<string | null>();

  constructor() {
    this.$focusedField.on(this.setFocusedField, (_, field) => field);
  }

  static getInstance() {
    if (!FormFocusVM.instance) {
      FormFocusVM.instance = new FormFocusVM();
    }
    return FormFocusVM.instance;
  }
}
