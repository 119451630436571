import { useWebToast } from "@/src/components/toast/global-toast";
import { SelectCommunityAndThenPostForm } from "@/src/domains/communities/community/community-post/create-community-post-form.fc";
import { CommunityEventCardContainer } from "@/src/domains/communities/community/events/community-event-card";
import { MemberCard } from "@/src/domains/communities/community/members/member-card";
import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { apiMgr } from "@webapp/backend";
import { AvatarCircles } from "@webapp/componentsavatar.tc";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { ConvexClient } from "convex/browser";
import { useQuery } from "convex/react";
import type { ApiMgr } from "frontend-shared/src/api.mgr";
import {
  MyCommunityProfileRSM,
  UpcomingEventsForMeRSM,
} from "frontend-shared/src/mgrs/remote-state-mgrs/community/community-tag.rsm";
import { BaseStateMgr } from "frontend-shared/src/mgrs/state-mgrs/base.statemgr";
import { useOnce } from "frontend-shared/src/util";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import type { SimpleUserWithProfilePhoto } from "shared";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import type { CommunityCardST } from "shared/convex/Screens/Community/CommunityScreens.Types";
import { UROUTES } from "shared/routes/u.routes";
import { useConvexCli } from "src/convex-cli";
import {
  CommunityPostCvx,
  WriteAPostView,
} from "../../../../../../domains/communities/community/community-post/community-post.fc";
import { useMyId, useUMgr } from "../../../../u.webstatemgr";
import { CommunityCardContainer } from "../components/community-card";
import { MyCommunitiesProfileCard } from "../components/my-profile-card";

class CommunitiesLandingPageStateMgr extends BaseStateMgr {
  myProfileRSM: MyCommunityProfileRSM;
  upcomingEventsForMeRSM: UpcomingEventsForMeRSM;

  constructor(
    readonly p: { myUserId: string; apiMgr: ApiMgr; convex: ConvexClient }
  ) {
    super({
      convex: p.convex,
      apiMgr: p.apiMgr,
    });
    this.myProfileRSM = new MyCommunityProfileRSM({
      userId: p.myUserId,
      convexCli: p.convex,
    });

    this.upcomingEventsForMeRSM = new UpcomingEventsForMeRSM({
      userId: p.myUserId,
      convexCli: p.convex,
    });
  }
}

export const CommunitiesLandingPage: React.FC = () => {
  const myId = useMyId();
  const convex = useConvexCli();
  const uMgr = useUMgr();
  const toast = useWebToast();
  const navigate = useNavigate();

  const stateMgr = useOnce(
    () =>
      new CommunitiesLandingPageStateMgr({
        myUserId: myId,
        apiMgr: apiMgr,
        convex,
      })
  );

  useEffect(() => {
    stateMgr.myProfileRSM.subscribeSync();
    stateMgr.upcomingEventsForMeRSM.subscribeSync();

    return () => {
      stateMgr.myProfileRSM.dispose();
      stateMgr.upcomingEventsForMeRSM.dispose();
    };
  }, []);

  const now = useOnce(() => Date.now());

  // const upcomingEventsForMe = useObservableEagerState(
  //   stateMgr.upcomingEventsForMeRSM.state$
  // );

  const globalProfile = useQuery(api.User.Hp.HpFns.getMyGlobalProfile);

  const upcomingEventsForMe = useQuery(
    api.Community.CommunitiesHomeScreenFns.getUpcomingEventsForMe,
    { now }
  );

  const communitiesForMeList = useQuery(
    api.Community.CommunitiesHomeScreenFns.getCommunitiesForMeList,
    {}
  );

  const recentlyJoinedUsers = useQuery(
    api.Community.CommunitiesHomeScreenFns.getRecentlyJoinedUsers,
    {}
  );

  if (
    communitiesForMeList === undefined ||
    upcomingEventsForMe === undefined ||
    recentlyJoinedUsers === undefined ||
    globalProfile === undefined
  ) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex-1 flex flex-col gap-8 static lg:relative max-w-full">
      <div className="flex-1 flex gap-16">
        <div className="flex flex-col gap-9 max-w-full lg:w-[714px]">
          <WriteAPostView
            profilePhotoUrl={globalProfile.simpleProfile.profilePhoto}
            onWritePostClick={() => {
              uMgr.rightDrawerVM.openEvt(
                <SelectCommunityAndThenPostForm
                  onSuccessSubmit={({ communitySlug }) => {
                    toast.toast({
                      id: "post-submitted",
                      title: "Your post is live!",
                      duration: 1000,
                    });

                    setTimeout(() => {
                      navigate(
                        UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.DISCUSSION.buildPath(
                          {
                            community: communitySlug,
                          }
                        )
                      );
                    }, 1000);

                    uMgr.rightDrawerVM.closeEvt();
                  }}
                />
              );
            }}
          />
          <RecentlyJoinedUsersSection
            recentlyJoinedUsers={recentlyJoinedUsers}
          />
          <div className="flex flex-col gap-4">
            <SectionTitle title="Communities" />
            <CommunitiesListSection communityCards={communitiesForMeList} />
          </div>
          {upcomingEventsForMe.cards.length > 0 ? (
            <div className="flex flex-col gap-4">
              <SectionTitle title="Upcoming events" />
              <div className="flex gap-2 items-center overflow-x-auto">
                {upcomingEventsForMe.cards.map((e) => (
                  <CommunityEventCardContainer
                    key={e.instance._id}
                    initialCardData={e}
                    showCommunityName={true}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <TimelineFeedSection />
        </div>
        <div className="flex flex-col">
          <MyCommunitiesProfileCard globalProfile={globalProfile} />
        </div>
      </div>
    </div>
  );
};

const TimelineFeedSection: React.FC = () => {
  const timelineFeedPosts = useQuery(
    api.Community.CommunitiesHomeScreenFns.getTimelineFeed,
    {}
  );

  if (timelineFeedPosts === undefined) {
    return (
      <div className="h-[300px] flex flex-col">
        <FullContainerLoadingSpinner />
      </div>
    );
  }

  if (timelineFeedPosts.length === 0) {
    return <div></div>;
  }

  return (
    <div className="flex flex-col gap-4 mt-4">
      <h4 className="text-xl font-medium leading-6 ">Recent posts</h4>
      {timelineFeedPosts.map((post) => (
        <CommunityPostCvx key={post._id} enrichedPost={post} />
      ))}
    </div>
  );
};

const SectionTitle: React.FC<{
  title: string;
}> = ({ title }) => {
  return <h4 className="text-[24px]  leading-6 ">{title}</h4>;
};

const RecentlyJoinedUsersSection: React.FC<{
  recentlyJoinedUsers: SimpleUserWithProfilePhoto[];
}> = ({ recentlyJoinedUsers }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-4">
      <SectionTitle title="Recently joined" />
      <div className="self-stretch flex justify-between gap-4 lg:min-w-0 overflow-x-auto">
        {recentlyJoinedUsers.map((u, idx) => (
          <MemberCard
            key={u.id}
            name={u.name}
            profilePhoto={u.profilePhoto}
            userId={u.id as Id<"users">}
            index={idx}
            modalities={[]}
            isAvailable={true}
          />
        ))}
      </div>
    </div>
  );
};

const CommunitiesListSection: React.FC<{
  communityCards: (typeof CommunityCardST.Encoded)[];
}> = ({ communityCards }) => {
  const navigate = useNavigate();

  const hpState = useHpState();

  return (
    <div className="self-stretch flex justify-between gap-4 lg:min-w-0 overflow-x-auto">
      {communityCards.map((c) => (
        <CommunityCardContainer
          key={c.community.slug}
          height="medium"
          headerBackgroundType={{
            _tag: "FIXED",
            color: c.community.color ?? "#690DFF",
          }}
          innerCircleComponent={
            <div className="w-10 h-10 lg:w-[100px] lg:h-[100px] bg-vid-black-200 rounded-full" />
          }
          title={c.community.name}
          content={
            <div className="flex flex-col items-center gap-2 mt-2">
              {c.members.profilePhotos.length > 0 && (
                <AvatarCircles
                  sources={[...c.members.profilePhotos.slice(0, 3)]}
                />
              )}
              <div className="text-vid-black-400">{c.members.countMsg}</div>
            </div>
          }
          button={{
            title: "View",
            isDisabled: false,
          }}
          onClick={() => {
            navigate(
              UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.DISCUSSION.buildPath(
                {
                  community: c.community.slug,
                }
              )
            );
          }}
        />
      ))}

      <CommunityCardContainer
        key={"create-community"}
        height="medium"
        headerBackgroundType={{
          _tag: "FIXED",
          color: "#000000",
        }}
        innerCircleComponent={
          <div className="w-10 h-10 lg:w-[100px] lg:h-[100px] bg-vid-black-200 rounded-full" />
        }
        title={"Create a community"}
        content={
          <div className="flex flex-col gap-2">
            <div></div>
            <div className="text-vid-black-400">members</div>
          </div>
        }
        onClick={() => {
          hpState.dashboardState.openRightNav({
            _tag: "CREATE_COMMUNITY",
          });
        }}
        button={{
          title: "Create",
        }}
      />
    </div>
  );
};
