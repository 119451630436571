import * as S from "@effect/schema/Schema";
import type { Id } from "../convex/_generated/dataModel";

const AppChatChannelTypeId = S.Union(
  S.Literal("communities-dm"),
  S.Literal("hpcp-private-chat"),
  S.Literal("live-session-private"),
  S.Literal("community-group")
);
export type AppChatChannelTypeId = S.Schema.Type<typeof AppChatChannelTypeId>;

export class AppChatChannel extends S.Class<AppChatChannel>("AppChatChannel")({
  channelType: AppChatChannelTypeId,
  channelId: S.String,
  name: S.String,
}) {
  constructor(p: {
    channelType: AppChatChannelTypeId;
    mkChannelId: () => string;
    mkName: () => string;
  }) {
    console.log("AppChatChannel", p);
    console.log("AppChatChannel", p.mkName());
    console.log("AppChatChannel", p.mkChannelId());
    super(
      {
        channelType: p.channelType,
        channelId: p.mkChannelId(),
        name: p.mkName(),
      },
      { disableValidation: true }
    );
  }

  static sortIds = (v1: { id: string }, v2: { id: string }): string => {
    console.log("sortIds", v1, v2);
    if (v1.id.localeCompare(v2.id) <= 0) {
      return `${v1.id.slice(0, 5)}-${v2.id.slice(0, 5)}`;
    } else {
      return `${v2.id.slice(0, 5)}-${v1.id.slice(0, 5)}`;
    }
  };

  static sortNames = (v1: { name: string }, v2: { name: string }): string => {
    console.log("sortNames", v1, v2);
    if (v1.name.localeCompare(v2.name) <= 0) {
      return `${v1.name}-${v2.name}`;
    } else {
      return `${v2.name}-${v1.name}`;
    }
  };
}

class CommunitiesPrivateChatChannel extends AppChatChannel {
  constructor(p: {
    member1: { id: string; name: string };
    member2: { id: string; name: string };
  }) {
    super({
      channelType: "communities-dm",
      mkChannelId: () => AppChatChannel.sortIds(p.member1, p.member2),
      mkName: () =>
        `C-${AppChatChannel.sortNames(
          { name: p.member1.name },
          { name: p.member2.name }
        )}`,
    });
  }
}

class HpCpPrivateChatChannel extends AppChatChannel {
  constructor(
    readonly p: {
      hp: { id: string; name: string };
      cp: { id: string; name: string };
    }
  ) {
    super({
      channelType: "hpcp-private-chat",
      mkChannelId: () => `${p.hp.id.slice(0, 10)}-${p.cp.id.slice(0, 10)}`,
      mkName: () =>
        AppChatChannel.sortNames({ name: p.hp.name }, { name: p.cp.name }),
    });
  }
}

class LiveSessionPrivateChatChannel extends AppChatChannel {
  constructor(readonly p: { sessionId: string }) {
    super({
      channelType: "live-session-private",
      mkChannelId: () => `live-session-private-${p.sessionId}`,
      mkName: () => `live-session-private-${p.sessionId}`,
    });
  }
}

class CommunityGroupChatChannel extends AppChatChannel {
  constructor(readonly p: { communitySlug: string; communityName: string }) {
    super({
      channelType: "community-group",
      mkChannelId: () => `community-group-${p.communitySlug}`,
      mkName: () =>
        AppChatChannel.sortNames(
          { name: p.communityName },
          { name: p.communityName }
        ),
    });
  }
}

export const AppChatChannels = {
  HpCpPrivateChatChannel,
  CommunitiesPrivateChatChannel,
  LiveSessionPrivateChatChannel,
  CommunityGroupChatChannel,
};

export interface StartOrJoinChatResponse {
  token: string;
  channel: {
    id: string;
    name: string;
    type: AppChatChannelTypeId;
  };
  apiKey: string;
  allMemberIds: string[];
}

export class ChatChannelConfig extends S.Class<ChatChannelConfig>(
  "ChatChannelConfig"
)({
  channelType: AppChatChannelTypeId,
  channelId: S.String,
  name: S.String,
}) {}

export class ChatChannelUserSetupData extends S.Class<ChatChannelUserSetupData>(
  "ChatChannelUserSetupData"
)({
  userId: S.String,
  channelConfig: ChatChannelConfig,
  memberIds: S.Array(S.String),
}) {
  get encoded() {
    return S.encodeUnknownSync(ChatChannelUserSetupData)(this);
  }

  static fromSession = (p: {
    userId: string;
    baseSessionId: Id<"sessionConfig">;
    sessionType: "hc" | "community-group";
    attendingParticipants: { userId: string }[];
  }) => {
    const channelConfig = ChatChannelConfig.make({
      channelType: "live-session-private",
      channelId: p.baseSessionId,
      name: "Chat",
    });

    return ChatChannelUserSetupData.make({
      userId: p.userId,
      channelConfig,
      memberIds: p.attendingParticipants.map((p) => p.userId),
    });
  };
}

export class ChatChannelUserSetup extends S.Class<ChatChannelUserSetup>(
  "ChatChannelUserSetup"
)({
  setup: S.NullOr(ChatChannelUserSetupData),
}) {
  static default = () =>
    ChatChannelUserSetup.make({
      setup: null,
    });

  get encoded() {
    return S.encodeUnknownSync(ChatChannelUserSetup)(this);
  }

  static fromSession = (p: {
    userId: string;
    baseSessionId: Id<"sessionConfig">;
    sessionType: "hc" | "community-group";
    attendingParticipants: { userId: string }[];
  }) => {
    return ChatChannelUserSetup.make({
      setup: ChatChannelUserSetupData.fromSession(p),
    });
  };
}
