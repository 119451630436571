import { Effect, Match } from "effect";
import {
  AuthErrorCodes,
  isSignInWithEmailLink,
  type User,
  type UserCredential,
} from "firebase/auth";
import {
  FirebaseJsMgr,
  messageForFirebaseAuthError,
  useFirebaseJs,
  type FirebaseAuthError,
} from "frontend-shared/src/firebase";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RD } from "shared/base-prelude";
import { type SimplestUserWithProfilePhoto } from "shared/index";
import { UROUTES } from "shared/routes/u.routes";
import { ScreenActions } from "shared/types/screen-action.types";
import { ErrorTracking } from "src/error-tracking";
import { TextInput } from "../../../components/form/form-inputs.fc";
import { PrimaryButton } from "../../../components/primitives/button";
import { LoadingSpinner } from "../../../loading";

type Source = "PRIVATE_CHAT_HP_CP" | "HP_NEW_CLIENT_INVITE";

type OtherQueryParams = {
  clientId: string | null;
};

function pathForSource(source: Source, otherQueryParams: OtherQueryParams) {
  return Match.value(source).pipe(
    Match.when("PRIVATE_CHAT_HP_CP", () =>
      UROUTES.HP.MY.DASHBOARD.CLIENTS.CLIENT_ID.CHAT.buildPath({
        clientId: otherQueryParams.clientId!,
      })
    ),
    Match.when("HP_NEW_CLIENT_INVITE", () => UROUTES.CP.DASHBOARD.HOME.path),
    Match.exhaustive
  );
}

export const AnonConfirmEmailInviteRedirectPage: React.FC = () => {
  const nav = useNavigate();
  const url = useLocation();
  const firebaseJs = useFirebaseJs();
  const emailQueryParam = new URLSearchParams(url.search).get("email")!;
  const redirectToQueryParam = new URLSearchParams(url.search).get(
    "redirectTo"
  );
  const actionQueryParam = new URLSearchParams(url.search).get("action")!;
  const sourceQueryParam = new URLSearchParams(url.search).get("source")!;
  const otherQueryParams: OtherQueryParams = {
    clientId: new URLSearchParams(url.search).get("clientId"),
  };

  const [rdSubmitResult, setRdSubmitResult] = useState<
    RD.RemoteData<
      SignInWithEmailLinkError,
      { me: SimplestUserWithProfilePhoto; fbUser: User }
    >
  >(RD.initial);

  // const [showNewUserLoginModal, setShowNewUserLoginModal] = useState<
  //   O.Option<{ email: string; firebaseUid: string }>
  // >(O.none);

  const navOnSuccess = () => {
    console.log("NAV ON SUCCESS!");

    if (redirectToQueryParam) {
      window.location.href = redirectToQueryParam;
      return;
    }
    if (actionQueryParam) {
      console.log("ACTION QUERY PARAM! ", actionQueryParam);
      const action =
        ScreenActions.AnonRedirect.decodeActionFromString(actionQueryParam);

      console.log("ACTION! ", action);

      const nextRoute = matchNextRouteFromAction(action);
      console.log("NEXT ROUTE! ", nextRoute);

      nav(nextRoute);
      return;
    }

    nav(pathForSource(sourceQueryParam as Source, otherQueryParams));
  };

  useEffect(() => {
    const siwl = isSignInWithEmailLink(firebaseJs.auth, window.location.href);
    console.log("IS SIGN IN WITH EMAIL LINK! ", siwl);

    if (!siwl) {
      ErrorTracking.reportError({
        message:
          "User tried to sign in with email link but it was not a valid email link",
        extra: {
          tags: {
            source: sourceQueryParam,
            redirectTo: redirectToQueryParam,
            clientId: otherQueryParams.clientId,
            href: window.location.href,
          },
        },
      });
      window.location.href = "/";
    } else {
      setTimeout(() => {
        console.log("signInWithEmailLink", emailQueryParam);

        setRdSubmitResult(RD.pending);
      }, 1000);
    }
  }, []);

  if (RD.isFailure(rdSubmitResult)) {
    return (
      <div className="w-screen h-screen flex flex-col justify-center items-center">
        {rdSubmitResult.error._tag === "FirebaseAuthError" ? (
          <h1>
            Uh oh!{" "}
            {messageForFirebaseAuthError(rdSubmitResult.error.fbError.code)}
          </h1>
        ) : (
          <NewUserLoginModal
            email={emailQueryParam}
            firebaseUid={rdSubmitResult.error.firebaseUid}
            navOnSuccess={() => {
              navOnSuccess();
            }}
          />
        )}
      </div>
    );
  }

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center">
      <div className="flex flex-col gap-2 items-center">
        <h1>{`Hi ${emailQueryParam}! You are being redirected`}</h1>
        {RD.isPending(rdSubmitResult) ? <LoadingSpinner /> : null}
      </div>
    </div>
  );
};

function matchNextRouteFromAction(
  action: ScreenActions.AnonRedirect.Action
): string {
  return Match.value(action).pipe(
    Match.tag("TO_HP_PRIVATE_CALENDAR", (r) =>
      UROUTES.HP.MY.DASHBOARD.CALENDAR.buildPath(
        {},
        {
          action: "view-appointment",
          requestApptId: r.apptId,
        }
      )
    ),
    Match.tag("TO_COMMUNITY_SCREEN", (r) =>
      UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.DISCUSSION.buildPath(
        {
          community: r.communitySlug,
        },
        {
          action: JSON.stringify(r.action),
        }
      )
    ),
    Match.tag("TO_GENERIC_HP_DASHBOARD", (r) =>
      Match.value(r.screen).pipe(
        Match.when("calendar", () => UROUTES.HP.MY.DASHBOARD.CALENDAR.path),
        Match.when("clients", () => UROUTES.HP.MY.DASHBOARD.CLIENTS.path),
        Match.exhaustive
      )
    ),
    Match.tag("TO_GENERIC_CP_DASHBOARD", (r) =>
      Match.value(r.screen).pipe(
        Match.when("home", () => UROUTES.CP.DASHBOARD.HOME.path),
        Match.when("calendar", () => UROUTES.CP.DASHBOARD.HOME.path),
        Match.exhaustive
      )
    ),
    Match.tag("TO_CP_HOME", (r) =>
      UROUTES.CP.DASHBOARD.HOME.buildPath(
        {},
        {
          acceptHpRequest: r.acceptHpRequest,
        }
      )
    ),
    Match.tag("TO_COMMUNITY_EVENT_INSTANCE_VIEW", (r) =>
      UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.EVENTS.EVENT_INSTANCE_ID.buildPath(
        {
          community: r.communitySlug,
          eventInstanceId: r.eventInstanceId,
        }
      )
    ),
    Match.tag("TO_PRIVATE_SESSION_MAIN_ROOM", (r) =>
      UROUTES.SESSIONS.SESSION_ID.buildPath({
        sessionId: r.privateSessionId,
      })
    ),
    Match.tag("TO_SESSION", (r) =>
      UROUTES.SESSIONS.SESSION_ID.buildPath({
        sessionId: r.sessionId,
      })
    ),
    Match.exhaustive
  );
}

const NewUserLoginModal: React.FC<{
  email: string;
  firebaseUid: string;
  navOnSuccess: () => void;
}> = () => {
  const firebaseJs = useFirebaseJs();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  return (
    <div className="flex flex-col gap-4 border-4 w-[500px] p-4 rounded-2xl">
      <h4 className="text-lg font-bold">Create your account</h4>
      <TextInput
        label="First Name"
        value={firstName}
        onChange={(firstName) => setFirstName(firstName)}
      />
      <TextInput
        label="Last Name"
        value={lastName}
        onChange={(lastName) => setLastName(lastName)}
      />
      <div className="flex flex-col gap-2">
        <h4 className="text-sm text-gray-500">
          Add a password for easier future logins
        </h4>
        <TextInput
          label="Password"
          value={password}
          onChange={(password) => setPassword(password)}
        />
      </div>
      <PrimaryButton
        title="Create Account"
        onClick={() => {
          async function createAccountAndUpdatePassword() {
            const mbUser = await firebaseJs.getFirebaseUser();
            console.log("MB USER! ", mbUser);
            if (mbUser) {
              const updatePwdRes = await firebaseJs.updatePasswordTE(
                mbUser,
                password
              )();
              console.log("UPDATE PWD RES! ", updatePwdRes);
            }
          }

          createAccountAndUpdatePassword().catch((err) => {
            console.log("UH OH!", err);
          });
        }}
      />
    </div>
  );
};

type SignInWithEmailLinkError =
  | { _tag: "FirebaseAuthError"; fbError: FirebaseAuthError }
  | { _tag: "UncreatedUserError"; firebaseUid: string };

function mkSigninWithEmailLinkEff(p: {
  firebaseJs: FirebaseJsMgr;
  emailQueryParam: string;
  href: string;
}): Effect.Effect<UserCredential, SignInWithEmailLinkError, never> {
  return p.firebaseJs
    .signinWithEmailLinkEff({
      email: p.emailQueryParam,
      href: p.href,
    })
    .pipe(
      Effect.mapError(
        (err) =>
          ({
            _tag: "FirebaseAuthError",
            fbError: err,
          }) as SignInWithEmailLinkError
      )
    );
}

function getFirebaseUserEff(p: {
  firebaseJs: FirebaseJsMgr;
}): Effect.Effect<User, SignInWithEmailLinkError, never> {
  return Effect.tryPromise({
    try: () => p.firebaseJs.getFirebaseUser(),
    catch: (_) =>
      ({
        _tag: "FirebaseAuthError",
        fbError: { code: AuthErrorCodes.NULL_USER },
      }) as SignInWithEmailLinkError,
  }).pipe(
    Effect.flatMap((mbUser) => {
      if (mbUser === null) {
        return Effect.fail({
          _tag: "FirebaseAuthError",
          fbError: { code: AuthErrorCodes.NULL_USER },
        } as SignInWithEmailLinkError);
      }

      return Effect.succeed(mbUser);
    })
  );
}
