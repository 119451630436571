import {
  SfuModels,
  type StreamVideoParticipant,
} from "@stream-io/video-react-sdk";
import { Effect } from "effect";
import * as Rx from "rxjs";
import { O, RD, erp } from "shared/base-prelude";
import { RUN_PING_SECONDS_INTERVAL } from "shared/constants";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import type { ToolMenuViewStateST } from "shared/schemas/session/ongoing/participant/participant-display.schemas";
import type { SharedToolSettings } from "shared/session-state/tools/shared-tool-settings";
import type { StreamChat } from "stream-chat";
import type { AbstractAudioPlayer } from "../../media-player.statemgr";
import type { SessionRSM } from "../../remote-state-mgrs/main-room.rsm";
import type { ChatStateMgr } from "../chat.statemgr";
import { MeditationStateMgr } from "./tools/meditation.statemgr";
import { AudioMediaStateMgr } from "./tools/shared/audio-media.statemgr";
import { EmdrSyncStateMgr } from "./tools/shared/emdr.statemgr";
import {
  MainRoomMenuTB,
  type MainRoomMenuState,
} from "shared/convex/Sessions/Participants/Views/MainRoom.Types";

export type OpenRightPanelViewState =
  | {
      state: "THERAPY_TOOLS";
      initialViewState?: ToolMenuViewStateST;
    }
  | { state: "SETTINGS" };

export type TopPreviewContent<Extra> =
  | { _tag: "Meditation"; videoSrc?: string; minutes: number; seconds: number }
  | {
      _tag: "Review";
      sessionType:
        | { _tag: "PRIVATE"; sessionId: string }
        | { _tag: "GROUP"; groupSessionId: string };
    }
  | {
      _tag: "EMDR_BALL";
      mode: SharedToolSettings.Emdr.State;
      ballFrequency: number;
    }
  | { _tag: "Extra"; extraState: Extra };

export interface SpecialClockData {
  secondsLeft: number;
  description: "meditation" | "warningTimeLeft";
  message?: string;
}

class PingMgr {
  pingInterval: Timer | null = null;
  pingResult$ = new Rx.ReplaySubject<unknown>();
  sendPing: () => void;

  constructor(p: { sendPingEff: Effect.Effect<unknown, unknown, never> }) {
    this.sendPing = () => {
      erp(p.sendPingEff).then((pr) => {
        this.pingResult$.next(pr);
      });
    };

    this.pingInterval = setInterval(() => {
      this.sendPing();
    }, 1000 * RUN_PING_SECONDS_INTERVAL);
  }

  cleanup() {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }
  }
}

export class MainRoomStateMgr {
  baseSessionId: Id<"sessionConfig">;
  rightNav$ = new Rx.BehaviorSubject<O.Option<OpenRightPanelViewState>>(O.none);
  isLeftNavToolsOpen$ = new Rx.BehaviorSubject<boolean>(true);

  stateSyncMgr: SessionRSM;

  pingMgr: PingMgr;

  tools: {
    meditationMgr: MeditationStateMgr;
    emdrMgr: EmdrSyncStateMgr;
    audioMediaMgr: AudioMediaStateMgr;
    chatMgr: ChatStateMgr<StreamChat>;
  };

  audioPlayer: AbstractAudioPlayer;

  constructor(
    readonly p: {
      baseSessionId: Id<"sessionConfig">;
      leaveChannel: () => Promise<void>;
      sessionRSM: SessionRSM;
      audioPlayer: AbstractAudioPlayer;
      chatStateMgr: ChatStateMgr<StreamChat>;

      actions: {
        setMyMenuViewState: (viewState: MainRoomMenuState) => Promise<null>;
      };
    }
  ) {
    this.baseSessionId = p.baseSessionId;
    this.audioPlayer = p.audioPlayer;
    this.pingMgr = new PingMgr({
      sendPingEff: Effect.promise(() =>
        p.sessionRSM.convexCli.mutation(
          api.Sessions.SessionPingFns.registerPingFromParticipant,
          {
            baseSessionId: p.baseSessionId,
          }
        )
      ),
    });

    this.stateSyncMgr = p.sessionRSM;

    this.tools = {
      chatMgr: p.chatStateMgr,
      audioMediaMgr: new AudioMediaStateMgr(
        {
          remoteBaseStateMgr: p.sessionRSM,
        },
        p.audioPlayer
      ),
      meditationMgr: new MeditationStateMgr(
        {
          remoteBaseStateMgr: p.sessionRSM,
        },
        p.audioPlayer
      ),
      emdrMgr: new EmdrSyncStateMgr({
        remoteBaseStateMgr: p.sessionRSM,
      }),
    };
  }

  cleanup() {
    this.pingMgr.cleanup();
  }

  leavingSessionResult$ = new Rx.BehaviorSubject<
    RD.RemoteData<unknown, unknown>
  >(RD.initial);
  async onCloseCallClick() {
    this.leavingSessionResult$.next(RD.pending);
    await this.stateSyncMgr.convexCli.mutation(
      api.Sessions.Participants.Views.MainRoomFns.onCloseCallClick,
      {
        baseSessionId: this.baseSessionId,
      }
    );

    await this.p.leaveChannel();
  }

  hasScreenShare = (p: StreamVideoParticipant) =>
    p.publishedTracks.includes(SfuModels.TrackType.SCREEN_SHARE);

  closePanel() {
    this.stateSyncMgr.participantSyncMgr.openMenuMgr.onCloseAnyMenu();
    this.rightNav$.next(O.none);
  }

  onSettingsMenuClicked() {
    this.stateSyncMgr.participantSyncMgr.openMenuMgr.onSettingsMenuClicked();
    this.rightNav$.next(O.some({ state: "SETTINGS" }));
  }

  onToolsMenuClicked() {
    console.log("onToolsMenuClicked");
    this.stateSyncMgr.participantSyncMgr.openMenuMgr.onToolsMenuClicked();
  }

  toggleLeftToolsNav() {
    this.isLeftNavToolsOpen$.next(!this.isLeftNavToolsOpen$.value);
  }

  openPanel(rightNav: OpenRightPanelViewState) {
    this.rightNav$.next(O.some(rightNav));
  }
}
