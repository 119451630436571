import { useUMgr } from "@pages/u/u.webstatemgr";
import { RxO, type Rx } from "@webapp/prelude";
import { useMutation } from "convex/react";
import { Match } from "effect";
import { createEvent, createStore } from "effector";
import { useUnit } from "effector-react";
import { useTherapySessionId } from "frontend-shared/src/mgrs/remote-state-mgrs/main-room.rsm";
import type { MainRoomStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/main-room.statemgr";
import { useOnce, useQuery$ } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import React, { useEffect } from "react";
import { api } from "shared/convex/_generated/api";
import { MainRoomMenuTB } from "shared/convex/Sessions/Participants/Views/MainRoom.Types";
import {
  QUICK_ACTION,
  QuickActionButton,
  QuickActionConfig,
} from "./quick-action-button";
import { QuickActionButtonVM } from "./quick-action.vm";

interface QuickActionButtonPanelProps {
  mainRoomStateMgr: MainRoomStateMgr;
  actions?: QuickActionConfig[];
}

const RecordSpanButton: React.FC<{
  mainRoomStateMgr: MainRoomStateMgr;
  isRecording$: Rx.BehaviorSubject<boolean | undefined>;
}> = ({ mainRoomStateMgr, isRecording$ }) => {
  const baseSessionId = useTherapySessionId();

  const onStartRecordingClick = useMutation(
    api.Sessions.Participants.Views.MainRoomFns.onRecordingQuickActionClick
  ).withOptimisticUpdate((localState, args) => {
    const isRecording = localState.getQuery(
      api.Sessions.Participants.Views.MainRoomFns.isCurrentlySpanRecording,
      {
        baseSessionId: args.baseSessionId,
      }
    );

    if (isRecording !== undefined) {
      localState.setQuery(
        api.Sessions.Participants.Views.MainRoomFns.isCurrentlySpanRecording,
        {
          baseSessionId: args.baseSessionId,
        },
        true
      );
    }
  });

  const onStopRecordingClick = useMutation(
    api.Sessions.Participants.Views.MainRoomFns.onStopRecordingQuickActionClick
  );

  const vm = useOnce(
    () =>
      new QuickActionButtonVM({
        onCapture: () => onStartRecordingClick({ baseSessionId }),
        onSave: async () => {
          await onStopRecordingClick({ baseSessionId });
          mainRoomStateMgr.p.actions
            .setMyMenuViewState(MainRoomMenuTB.open.tools.specific("NOTES"))
            .catch();
        },
      })
  );
  const [state] = useUnit([vm.$state]);
  const isCapturing = state.tag === "OPEN" && state.state === "CAPTURING";

  useEffect(() => {
    isRecording$.pipe(RxO.distinctUntilChanged()).subscribe((isRecording) => {
      if (isRecording) {
        vm.setCaptureStateOnly();
      }
    });
  }, [isRecording$]);

  return (
    <QuickActionButton
      config={{
        id: "record-span",
        icon: <RecordSpanIcon isRecording={isCapturing} />,
        label: "Recording",
      }}
      vm={vm}
      actionButton={
        <button
          onClick={() => (isCapturing ? vm.save() : vm.startCapturingEvt())}
          className="bg-white text-black px-6 py-2 rounded-full font-medium hover:bg-gray-100 transition-colors flex-shrink-0"
        >
          {isCapturing ? "Stop Recording" : "Start Recording"}
        </button>
      }
    />
  );
};

class NoteInputVM {
  noteChangeEvt = createEvent<string>();
  clearNoteEvt = createEvent();
  note = createStore<string>("")
    .on(this.noteChangeEvt, (_, note) => note)
    .reset(this.clearNoteEvt);
}

const NoteInput: React.FC<{ vm: NoteInputVM }> = ({ vm }) => {
  const [note] = useUnit([vm.note]);
  return (
    <div className="relative w-[200px] h-full">
      <textarea
        className="absolute left-0 right-0 -top-8 -bottom-8 z-50 rounded-[12px] text-black p-2"
        value={note}
        onChange={(e) => vm.noteChangeEvt(e.target.value)}
      />
    </div>
  );
};

const NotesButton: React.FC<{
  mainRoomStateMgr: MainRoomStateMgr;
}> = ({ mainRoomStateMgr }) => {
  const baseSessionId = useTherapySessionId();
  const saveNoteMutation = useMutation(api.Sessions.Base.BookmarkFns.saveNote);
  const noteInputVm = useOnce(() => new NoteInputVM());

  const vm = useOnce(() => {
    const saveNote = async () => {
      const currentNote = noteInputVm.note.getState();
      console.log("saving note", currentNote);
      await saveNoteMutation({ baseSessionId, note: currentNote });
      noteInputVm.clearNoteEvt();

      mainRoomStateMgr.p.actions
        .setMyMenuViewState(MainRoomMenuTB.open.tools.specific("NOTES"))
        .catch();
    };

    return new QuickActionButtonVM({
      onSave: saveNote,
    });
  });

  useEffect(() => {
    const unsub = vm.$state.watch((state) => {
      if (state.tag === "CLOSED") {
        noteInputVm.clearNoteEvt();
      }
    });
    return () => unsub();
  }, [vm, noteInputVm]);

  return (
    <QuickActionButton
      config={{
        id: "notes",
        icon: <NotesIconSvg />,
        label: "Notes",
      }}
      vm={vm}
      renderOpenIdle={() => <NoteInput vm={noteInputVm} />}
    />
  );
};

const BookmarkButton: React.FC<{
  mainRoomStateMgr: MainRoomStateMgr;
}> = ({ mainRoomStateMgr }) => {
  const baseSessionId = useTherapySessionId();

  const onBookmarkClick = useMutation(
    api.Sessions.Participants.Views.MainRoomFns.onBookmarkClick
  );

  const vm = useOnce(
    () =>
      new QuickActionButtonVM({
        onSave: async () => {
          console.log("saving bookmark");
          await onBookmarkClick({ baseSessionId });
          mainRoomStateMgr.p.actions
            .setMyMenuViewState(MainRoomMenuTB.open.tools.specific("NOTES"))
            .catch();
        },
      })
  );

  return (
    <QuickActionButton
      config={{
        id: "bookmark",
        icon: <BookmarkIconSvg />,
        label: "Bookmark",
      }}
      vm={vm}
    />
  );
};

const QuickScheduleButton: React.FC<{}> = () => {
  const uMgr = useUMgr();
  const vm = useOnce(
    () =>
      new QuickActionButtonVM({
        onSave: async () => {
          uMgr.rightDrawerVM.openEvt(<></>);
          // mainRoomStateMgr.p.actions
          //   .setMyMenuViewState(
          //     MainRoomMenuTB.open.tools.specific("QUICK_SCHEDULE")
          //   )
          //   .catch();
        },
      })
  );

  return (
    <QuickActionButton
      vm={vm}
      actionButton={
        <button
          onClick={() => {
            vm.save();
          }}
          className="bg-white text-black px-6 py-2 rounded-full font-medium hover:bg-gray-100 transition-colors flex-shrink-0"
        >
          Schedule
        </button>
      }
      config={{
        id: "schedule",
        icon: (
          <img
            src="/clipboard-tick.svg"
            alt="schedule"
            width={24}
            height={24}
          />
        ),
        label: "Schedule",
      }}
    />
  );
};

export const QuickActionButtonPanel: React.FC<QuickActionButtonPanelProps> = ({
  mainRoomStateMgr,
}) => {
  const quickActionToolsST = useObservableEagerState(
    mainRoomStateMgr.stateSyncMgr.quickActionToolsMgr.state$
  );

  const isCurrentlyRecording$ = useQuery$(
    api.Sessions.Participants.Views.MainRoomFns.isCurrentlySpanRecording,
    {
      baseSessionId: mainRoomStateMgr.baseSessionId,
    }
  );

  return (
    <div className="w-[55px] pt-4 relative z-50 h-[300px]">
      <div className="flex flex-col justify-end gap-14 h-full pb-12">
        {quickActionToolsST.qaTools.map((qaTool) =>
          Match.value(qaTool).pipe(
            Match.when("BOOKMARK", () => {
              return <BookmarkButton mainRoomStateMgr={mainRoomStateMgr} />;
            }),
            Match.when("BOOKEND", () => {
              return (
                <RecordSpanButton
                  mainRoomStateMgr={mainRoomStateMgr}
                  isRecording$={isCurrentlyRecording$}
                />
              );
            }),
            Match.when("NOTES", () => (
              <NotesButton mainRoomStateMgr={mainRoomStateMgr} />
            )),
            Match.when("QUICK_SCHEDULE", () => <QuickScheduleButton />),
            Match.exhaustive
          )
        )}
      </div>
    </div>
  );
};

/* Icons */

interface BookmarkIconSvgProps {
  color?: string;
}

const BookmarkIconSvg: React.FC<BookmarkIconSvgProps> = ({
  color = "white",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M14.0171 2.16602H5.98376C4.20876 2.16602 2.76709 3.61602 2.76709 5.38268V17.1244C2.76709 18.6244 3.84209 19.2577 5.15876 18.5327L9.22542 16.2743C9.65876 16.0327 10.3588 16.0327 10.7838 16.2743L14.8504 18.5327C16.1671 19.266 17.2421 18.6327 17.2421 17.1244V5.38268C17.2338 3.61602 15.7921 2.16602 14.0171 2.16602Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

interface NotesIconSvgProps {
  color?: string;
}

const NotesIconSvg: React.FC<NotesIconSvgProps> = ({ color = "white" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M8 2.5V5.5"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 2.5V5.5"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 11.5H15"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 15.5H12"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 22.5H9C4 22.5 3 20.44 3 16.32V10.15C3 5.45 4.67 4.19 8 4H16C19.33 4.18 21 5.45 21 10.15V16.5"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 16.5L15 22.5V19.5C15 17.5 16 16.5 18 16.5H21Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const RecordSpanIcon: React.FC<{ isRecording: boolean }> = ({
  isRecording,
}) => {
  const src = isRecording
    ? "/bookendRecording.gif"
    : "/live-record-section-static-icon.png";

  return (
    <div className={`relative ${QUICK_ACTION.BUTTON_SIZE}`}>
      <div className="absolute inset-0 flex items-center justify-center">
        <img
          src={src}
          className={`object-contain ${
            isRecording ? QUICK_ACTION.ICON_CONTAINER_SIZE : "w-[18px] h-[18px]"
          }`}
        />
      </div>
    </div>
  );
};
