import { Disclosure } from "@headlessui/react";
import { AvatarCircle } from "@webapp/components/avatar.tc";
import { CommunityStateMgr } from "frontend-shared/src/mgrs/state-mgrs/community.statemgr";
import type { HpGlobalProfile } from "shared/convex/User/Hp/Hp.Types";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { ModalitiesDrawer } from "src/domains/user/my-profile-form";
import {
  CommunityCardContainer,
  CommunityCardContainerSkeleton,
} from "./community-card";

export const MyProfileCardSkeleton = () => {
  return (
    <div className="hidden lg:flex flex-col max-w-[345px] min-w-[320px] rounded-lg min-h-0 self-start animate-pulse">
      <div className="flex flex-col items-center pb-8 w-full bg-white rounded-xl border border-vid-black-200">
        <div className="h-[151px] self-stretch bg-gray-200"></div>
        <div className="flex flex-col px-8 w-full">
          <div className="h-8 bg-gray-200 rounded mt-4 w-3/4 mx-auto"></div>
          <div className="flex flex-col self-stretch mt-4 w-full rounded-xl">
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            <div className="h-20 bg-gray-200 rounded mt-4"></div>
          </div>
          <div className="flex flex-col self-stretch w-full mt-8">
            <div className="h-4 bg-gray-200 rounded w-1/3"></div>
            <div className="flex flex-wrap gap-2 mt-2">
              <div className="h-8 bg-gray-200 rounded w-20"></div>
              <div className="h-8 bg-gray-200 rounded w-24"></div>
              <div className="h-8 bg-gray-200 rounded w-16"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MyCommunitiesProfileCard: React.FC<{
  globalProfile: HpGlobalProfile | undefined;
}> = ({ globalProfile }) => {
  const widthPx = "330px";
  if (globalProfile === undefined) {
    return <CommunityCardContainerSkeleton widthPx={widthPx} height="large" />;
  }

  return (
    <CommunityCardContainer
      title={""}
      width={widthPx}
      height="auto"
      content={
        <div className="flex flex-col items-center gap-6 mt-4">
          <h4 className="text-[24px]">{globalProfile.simpleProfile.name}</h4>
          {/* <div className="text-center">
            {myHpProfile.myModalities
              .map((modality) => modality.name)
              .join(" | ")}
          </div> */}
          <BioSection bio={globalProfile.bio} />
          <div className="flex flex-col self-stretch w-full text-base leading-5 text-black">
            <div className="font-medium text-gray-900">Modalities offered</div>
            <div className="flex flex-wrap gap-2">
              <ModalitiesDrawer modalities={globalProfile.myModalities} />
            </div>
          </div>
          {/* {mode._tag === "COMMUNITY" && (
            <AvailableForPracticeSession sam={mode.communitySam} />
          )} */}
        </div>
      }
      headerBackgroundType={{
        _tag: "CUSTOM",
        color: "purple",
        content: <></>,
        hideCircleThings: false,
      }}
      headerHeight={156}
      onClick={() => {}}
      button={null}
      innerCircleComponent={
        <div className="rounded-full w-[108px] bg-white aspect-square flex justify-center items-center rounded-full">
          <AvatarCircle
            size={100}
            mbProfilePhoto={
              globalProfile.simpleProfile.profilePhoto
                ? ImageSrc.fromURL(globalProfile.simpleProfile.profilePhoto)
                : null
            }
          />
        </div>
      }
    />
  );
};

const BioSection: React.FC<{ bio: string }> = ({ bio }) => {
  return (
    <div className="flex flex-col gap-4 self-stretch w-full text-base leading-5 text-black">
      <h4 className="text-[16px] font-medium">Bio</h4>
      <Disclosure>
        {({ open }) => (
          <div className="">
            <div
              className={`relative ${
                !open ? "h-[72px]" : "h-auto"
              } overflow-hidden`}
            >
              <div className="text-base leading-6 text-vid-black-500">
                {bio}
              </div>
              {!open && (
                <div className="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-t from-white to-transparent" />
              )}
            </div>
            {bio.length > 100 && (
              <Disclosure.Button className="mt-2 text-sm font-medium text-vid-black-700 hover:text-vid-purple/90">
                {open ? "Show less" : "Show more"}
              </Disclosure.Button>
            )}
          </div>
        )}
      </Disclosure>
    </div>
  );
};

const AvailableForPracticeSession: React.FC<{
  sam: CommunityStateMgr;
}> = () => {
  return <></>;
};
