// App.tsx
import React, { useState } from "react";
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
  UniqueIdentifier,
  useDroppable,
  useDraggable,
} from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { RoomLayoutView } from "./room-container";
import { SetupBreakoutRoomsRightDrawer } from "./setup-breakout-rooms.form";

type User = { id: string; label: number };
type Room = { id: string; users: User[] };

const initialUsers: User[] = [
  { id: "user-1", label: 1 },
  { id: "user-2", label: 2 },
  { id: "user-3", label: 3 },
  { id: "user-4", label: 4 },
  { id: "user-5", label: 5 },
];

const initialRooms: Room[] = [
  { id: "room-1", users: [] },
  { id: "room-2", users: [] },
  { id: "room-3", users: [] },
  { id: "room-4", users: [] },
  { id: "room-5", users: [] },
  { id: "room-6", users: [] },
];

export const MockBreakoutRoomsSimulator: React.FC = () => {
  return (
    <RoomLayoutView
      stageAreaView={
        <div className="flex-1 flex flex-col">
          <MockBreakoutRoomsDnd />
        </div>
      }
      isRightDrawerOpen={true}
      rightDrawerProps={{
        menuTitle: "Breakout Rooms",
        onBackClick: () => {},
        onClose: () => {},
        content: <SetupBreakoutRoomsRightDrawer />,
      }}
      controlPanelProps={{
        isAudioMute: false,
        isVideoMute: false,
        onAudioButtonClick: () => {},
        onVideoButtonClick: () => {},
        onEndCallButtonClick: () => {},
        onMagicPenClick: () => {},
        onSettingsClick: () => {},
      }}
    />
  );
};

export const MockBreakoutRoomsDnd: React.FC = () => {
  const [rooms, setRooms] = useState<Room[]>(initialRooms);
  const [unassigned, setUnassigned] = useState<User[]>(initialUsers);
  const [activeUser, setActiveUser] = useState<User | null>(null);

  const sensors = useSensors(useSensor(PointerSensor));

  function handleDragStart(event: DragStartEvent) {
    const id = String(event.active.id);
    const foundInUnassigned = unassigned.find((user) => user.id === id);
    if (foundInUnassigned) {
      setActiveUser(foundInUnassigned);
    } else {
      for (const room of rooms) {
        const found = room.users.find((user) => user.id === id);
        if (found) {
          setActiveUser(found);
          break;
        }
      }
    }
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (!activeUser) return;
    const activeId = active.id.toString();

    if (!over) {
      // Dropped outside any area, do nothing or revert if needed
      setActiveUser(null);
      return;
    }

    if (over.id === "unassigned") {
      // Move user back to unassigned pool
      setRooms((prev) =>
        prev.map((r) => ({
          ...r,
          users: r.users.filter((u) => u.id !== activeUser.id),
        }))
      );
      setUnassigned((prev) => {
        if (!prev.find((u) => u.id === activeUser.id)) {
          return [...prev, activeUser];
        }
        return prev;
      });
    } else if (over.id !== activeId) {
      // Move user into the room
      setUnassigned((prev) => prev.filter((u) => u.id !== activeUser.id));
      setRooms((prev) =>
        prev.map((r) =>
          r.id === over.id
            ? {
                ...r,
                users: [
                  ...r.users.filter((u) => u.id !== activeUser.id),
                  activeUser,
                ],
              }
            : { ...r, users: r.users.filter((u) => u.id !== activeUser.id) }
        )
      );
    }

    setActiveUser(null);
  }

  return (
    <div className="flex-1 flex flex-col bg-vid-black-900 text-white p-6">
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <h1 className="text-2xl font-bold mb-4">Drag and Drop Members</h1>
        <div className="flex flex-wrap gap-4 mb-6">
          <DroppableArea
            id="unassigned"
            label="Unassigned"
            className="bg-gray-800"
          >
            {unassigned.map((user) => (
              <DraggableUser key={user.id} user={user} />
            ))}
          </DroppableArea>
        </div>

        <div className="grid grid-cols-3 gap-4">
          {rooms.map((room) => (
            <DroppableArea
              key={room.id}
              id={room.id}
              label={room.id.replace("room-", "Group ")}
              className="bg-gray-800 w-full h-40 flex flex-wrap p-2 justify-center items-start gap-2"
            >
              {room.users.map((user) => (
                <DraggableUser key={user.id} user={user} />
              ))}
            </DroppableArea>
          ))}
        </div>
      </DndContext>
    </div>
  );
};

interface DroppableAreaProps {
  id: UniqueIdentifier;
  label: string;
  className?: string;
  children?: React.ReactNode;
}
function DroppableArea({ id, label, className, children }: DroppableAreaProps) {
  const { setNodeRef } = useDroppable({ id });
  return (
    <div
      ref={setNodeRef}
      className={`relative flex flex-wrap items-start p-4 rounded border border-gray-700 ${className}`}
    >
      <div className="absolute top-2 left-2 bg-black/50 rounded px-2 py-1 text-sm">
        {label}
      </div>
      {children}
    </div>
  );
}

interface DraggableUserProps {
  user: User;
}
function DraggableUser({ user }: DraggableUserProps) {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: user.id,
    });

  const style = {
    transform: transform ? CSS.Translate.toString(transform) : undefined,
    zIndex: isDragging ? 50 : undefined,
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      className="cursor-grab w-8 h-8 flex items-center justify-center rounded-full bg-blue-500 text-white"
    >
      {user.label}
    </div>
  );
}
