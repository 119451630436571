import {
  combine,
  createEffect,
  createEvent,
  createStore,
  type Store,
} from "effector";
import type {
  FileLike,
  UploadFileToCloudinaryResponse,
} from "shared/schemas/file.schemas";
import { FileSchemas } from "shared/schemas/file.schemas";

export interface MediaSelectDeps {
  selectMedia: () => Promise<FileLike | null>;
  // Platform-specific function to get preview URL
  getPreviewUrl: (file: FileLike) => string;
  // Platform-specific upload implementation
  uploadToCloudinary: (
    file: FileLike,
    uploadUrl: string
  ) => Promise<UploadFileToCloudinaryResponse>;
}

// src/features/profilePhoto/ProfilePhotoModel.ts

export class MediaSelectAndUploadVM {
  // Updated events
  public readonly selectMediaRequested = createEvent();
  public readonly setInitialDownloadUrl = createEvent<{
    downloadUrl: string;
  }>();
  public readonly onMediaSuccessfullyUploadedToCloudinary = createEvent<{
    publicId: string;
    format: string;
    mediaType: FileSchemas.MediaType;
  }>();
  public readonly reset = createEvent();

  // Updated effects
  private readonly selectMediaFx = createEffect<void, FileLike | null>();

  private readonly getPresignedUrlFx = createEffect<
    { mediaType: FileSchemas.MediaType },
    { uploadUrl: string }
  >();

  private readonly uploadToCloudinaryFx = createEffect<
    { file: FileLike; uploadUrl: string },
    UploadFileToCloudinaryResponse
  >();

  private readonly getProcessedMediaUrlFx = createEffect<
    { publicId: string; mediaType: FileSchemas.MediaType }, // public_id as input
    { processedUrl: string }
  >();

  private readonly preCacheImageFx = createEffect<string, void>();

  // Updated store for media type to handle initial profile photo
  public readonly $mediaType: Store<FileSchemas.MediaType | null>;

  // New store to track initial media type
  private readonly $initialMediaType =
    createStore<FileSchemas.MediaType | null>(null).on(
      this.setInitialDownloadUrl,
      (_, data) => {
        console.log("setting initial media type with downloadUrl", data);
        // Determine mediaType from downloadUrl or another relevant parameter
        return "image";
        // const mediaType = this.determineMediaTypeFromUrl(data.downloadUrl);
        // return mediaType;
      }
    );

  public readonly $selectedFile = createStore<FileLike | null>(null)
    .on(this.selectMediaFx.doneData, (_, file) => file)
    .reset(this.reset);

  public readonly $previewUrl = createStore<string | null>(null)
    .on(this.$selectedFile, (_, file) => {
      console.log("getPreviewUrl", file);

      const previewUrl = file ? this.p.deps.getPreviewUrl(file) : null;
      console.log("DERIVED PREVIEW URL! ", previewUrl);
      return previewUrl;
    })
    .reset(this.reset);

  public readonly $uploadUrl = createStore<string | null>(null)
    .on(this.getPresignedUrlFx.doneData, (_, data) => data.uploadUrl)
    .reset(this.reset);

  public readonly $publicId = createStore<string | null>(null)
    .on(this.uploadToCloudinaryFx.doneData, (_, data) => data.public_id)
    .reset(this.reset);

  public readonly $uploadedUrl = createStore<string | null>(null)
    .on(this.uploadToCloudinaryFx.doneData, (_, data) => data.secure_url)
    .reset(this.reset)
    .reset(this.selectMediaRequested);

  public readonly $processedMediaUrl = createStore<string | null>(null)
    .on(this.getProcessedMediaUrlFx.doneData, (_, data) => data.processedUrl)
    .on(this.setInitialDownloadUrl, (_, data) => data.downloadUrl)
    .reset(this.reset)
    .reset(this.selectMediaRequested);

  public readonly $isLoading = combine(
    this.selectMediaFx.pending,
    this.getPresignedUrlFx.pending,
    this.uploadToCloudinaryFx.pending,
    (isSelecting, isGettingUrl, isUploading) =>
      isSelecting || isGettingUrl || isUploading
  );

  public readonly $isProcessing = combine(
    this.getPresignedUrlFx.pending,
    this.uploadToCloudinaryFx.pending,
    this.getProcessedMediaUrlFx.pending,
    this.preCacheImageFx.pending,
    this.$selectedFile,
    (
      isGettingUrl,
      isUploading,
      isGettingProcessedUrl,
      isPreCaching,
      mediaType
    ) =>
      isGettingUrl ||
      isUploading ||
      isGettingProcessedUrl ||
      // Only include pre-caching in processing state for photos
      (this.asMediaType(mediaType) === "image" ? isPreCaching : false)
  );

  asMediaType(file: FileLike | null): FileSchemas.MediaType | null {
    if (!file) return null;
    const fileMetadata = FileSchemas.FileMetadata.unsafeDecodeFromFile(file);
    return fileMetadata.mediaType;
  }

  public readonly $displayMediaUrl = combine(
    this.$previewUrl,
    this.$uploadedUrl,
    this.$processedMediaUrl,
    this.$isProcessing,
    (previewUrl, uploadedUrl, processedUrl) => {
      if (processedUrl) return processedUrl;
      if (uploadedUrl) return uploadedUrl;
      if (previewUrl) return previewUrl;
      return null;
    }
  );

  public readonly $error = createStore<string | null>(null)
    .on(this.selectMediaFx.failData, (_, error) => error.message)
    .on(this.getPresignedUrlFx.failData, (_, error) => error.message)
    .on(this.uploadToCloudinaryFx.failData, (_, error) => error.message)
    .reset(this.selectMediaRequested)
    .reset(this.reset);

  private configureResets() {
    // Media selection related
    this.$selectedFile.reset(this.reset);
    this.$previewUrl.reset(this.reset);

    // Upload related
    this.$uploadUrl.reset(this.reset);
    this.$publicId.reset(this.reset);
    this.$uploadedUrl.reset(this.reset);

    // Final processed media related
    this.$processedMediaUrl.reset(this.reset);

    // Status related
    this.$error.reset(this.reset);
  }

  constructor(
    readonly p: {
      deps: MediaSelectDeps;
      getPresignedUrl: (p: { mediaType: FileSchemas.MediaType }) => Promise<{
        uploadUrl: string;
      }>;
      getProcessedMediaUrl: (p: {
        publicId: string;
        mediaType: FileSchemas.MediaType;
      }) => Promise<{ processedUrl: string }>;
      onMediaSuccessfullyUploadedToCloudinary?: (p: {
        publicId: string;
        format: string;
        mediaType: FileSchemas.MediaType;
      }) => Promise<any>;
    }
  ) {
    // Combine media type from selected file and initial media
    this.$mediaType = combine(
      this.$selectedFile,
      this.$initialMediaType,
      (selectedFile, initialMediaType) => {
        if (selectedFile) {
          return this.asMediaType(selectedFile);
        }
        return initialMediaType;
      }
    );

    // Configure effects
    this.selectMediaFx.use(async () => {
      return await p.deps.selectMedia();
    });

    this.getPresignedUrlFx.use(async (purl) => {
      return await p.getPresignedUrl(purl);
    });

    this.uploadToCloudinaryFx.use(async ({ file, uploadUrl }) => {
      return await p.deps.uploadToCloudinary(file, uploadUrl);
    });

    this.getProcessedMediaUrlFx.use(async (publicId) => {
      return await p.getProcessedMediaUrl(publicId);
    });

    this.preCacheImageFx.use(async (imageUrl) => {
      await fetch(imageUrl);
    });

    // Updated flow to listen to the renamed event
    this.setInitialDownloadUrl.watch(({ downloadUrl }) => {
      console.log("Initial download URL set:", downloadUrl);
      // If setting the download URL should trigger additional processes,
      // add them here. For example:
      // this.someEffect({ downloadUrl });
    });

    this.selectMediaRequested.watch(() => {
      this.selectMediaFx();
    });

    this.uploadToCloudinaryFx.doneData.watch(({ public_id }) => {
      const forFile = this.$selectedFile.getState();

      if (!forFile) return;

      const fileMetadata =
        FileSchemas.FileMetadata.unsafeDecodeFromFile(forFile);
      p.onMediaSuccessfullyUploadedToCloudinary?.({
        publicId: public_id,
        format: "TODO?",
        mediaType: fileMetadata.mediaType,
      });
    });

    // When media is selected, get presigned URL
    this.$selectedFile.watch((file) => {
      if (file) {
        const fileMetadata =
          FileSchemas.FileMetadata.unsafeDecodeFromFile(file);
        this.getPresignedUrlFx({
          mediaType: fileMetadata.asUnsafeAsVideoOrImageOnly,
        });
      }
    });

    // When we have both file and upload URL, start upload
    combine(this.$selectedFile, this.$uploadUrl, (file, uploadUrl) => ({
      file,
      uploadUrl,
    })).watch(({ file, uploadUrl }) => {
      if (file && uploadUrl) {
        this.uploadToCloudinaryFx({ file, uploadUrl });
      }
    });

    // When upload completes, fetch the processed media URL
    this.$publicId.watch((publicId) => {
      const selectedFile = this.$selectedFile.getState();
      if (publicId && selectedFile) {
        const fileMetadata =
          FileSchemas.FileMetadata.unsafeDecodeFromFile(selectedFile);
        this.getProcessedMediaUrlFx({
          publicId,
          mediaType: fileMetadata.mediaType,
        });
      }
    });

    // Only pre-cache images, not videos or audios
    combine(this.$processedMediaUrl, this.$mediaType, (url, mediaType) => ({
      url,
      mediaType,
    })).watch(({ url, mediaType }) => {
      if (url && mediaType === "image") {
        this.preCacheImageFx(url);
      }
    });

    this.configureResets();
  }
}
