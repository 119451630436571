import { Data, Effect, Either } from "effect";
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  merge,
  sample,
} from "effector";
import { createContextAndHook } from "../util";
import { messageForFirebaseAuthError, type FirebaseJsMgr } from "../firebase";
import { epipe } from "shared/base-prelude";
import { combineEvents } from "patronum";

type SignupResult = Either.Either<
  { firebaseUid: string; email: string },
  string
>;
type SigninResult = Either.Either<any, string>;

interface AuthSvcI {
  signupEmailPwd: (form: {
    email: string;
    password: string;
  }) => Promise<SignupResult>;
  signinEmailPwd: (form: {
    email: string;
    password: string;
  }) => Promise<SigninResult>;
}

type AuthMode = "SIGNUP" | "SIGNIN";

interface SomeNextStep {}
class ContinueOnboarding
  extends Data.Class<{
    tag: "CONTINUE_ONBOARDING";
  }>
  implements SomeNextStep {}
class RegisterUser
  extends Data.Class<{
    tag: "REGISTER_USER";
    firebaseUid: string;
    email: string;
  }>
  implements SomeNextStep {}
class GoToDashboard
  extends Data.Class<{
    tag: "GO_TO_DASHBOARD";
  }>
  implements SomeNextStep {}

type NextStep = ContinueOnboarding | RegisterUser | GoToDashboard;

export class FakeAuthSvc implements AuthSvcI {
  async signupEmailPwd(form: {
    email: string;
    password: string;
  }): Promise<SignupResult> {
    console.log("signupEmailPwd!!", form);
    return Either.right(null) as unknown as SignupResult;
  }

  async signinEmailPwd(form: {
    email: string;
    password: string;
  }): Promise<SigninResult> {
    console.log("signinEmailPwd!!", form);
    return Either.right(null) as unknown as SigninResult;
  }
}

export class FirebaseAuthSvc implements AuthSvcI {
  constructor(readonly mgr: FirebaseJsMgr) {}

  signupEmailPwd = (form: {
    email: string;
    password: string;
  }): Promise<SignupResult> => {
    console.log("attempting signupEmailPwd!!", form);
    return Effect.runPromise(
      this.mgr.createUserWithEmailAndPasswordEff(form).pipe(
        Effect.mapError((err) => messageForFirebaseAuthError(err.code)),
        Effect.map((user) => ({
          firebaseUid: user.user.uid,
          email: form.email,
        })),
        Effect.either
      )
    );
  };

  async signinEmailPwd(form: {
    email: string;
    password: string;
  }): Promise<SigninResult> {
    console.log("attempitng signinEmailPwd!!", form);
    return Effect.runPromise(
      this.mgr.signinWithEmailPasswordEff(form).pipe(
        Effect.either,
        Effect.map(
          (er) =>
            epipe(
              er,
              Either.mapLeft((er) => er.code)
            ) as unknown as SigninResult
        )
      )
    );
  }
}

export class PasswordAuthVM {
  constructor(p: { authSvc: AuthSvcI; authMode: AuthMode }) {
    this.authMode = p.authMode;
    this.signupEmailPwdFx.use(p.authSvc.signupEmailPwd.bind(p.authSvc));
    this.signinEmailPwdFx.use(p.authSvc.signinEmailPwd.bind(p.authSvc));

    this._bindEvents();
  }

  authMode: AuthMode = "SIGNUP";
  // events
  emailTextEvt = createEvent<string>();
  passwordTextEvt = createEvent<string>();
  submitEvt = createEvent();
  authSvcLocallyValidatedEvt = createEvent<boolean>();
  userCreatedOnServerEvt = createEvent<boolean>();
  allSigninValidationEvts = createEvent<{
    locallyValidated: boolean;
    signinSuccess: boolean;
  }>();
  allSignupValidationEvts = createEvent<{
    locallyValidated: boolean;
    signupSuccess: { firebaseUid: string; email: string };
  }>();

  // stores
  $email = createStore<string>("");
  $password = createStore<string>("");
  $errorMsg = createStore<string | null>(null);
  $canProceedToNextStep = createStore<NextStep | null>(null);
  $isLoading = createStore<boolean>(false);

  // effects
  signinEmailPwdFx = createEffect<
    { email: string; password: string },
    SigninResult
  >();

  signupEmailPwdFx = createEffect<
    { email: string; password: string },
    SignupResult
  >();

  _bindEvents() {
    this._setupEmailBinding();
    this._setupPasswordBinding();
    this._setupWatchAllSigninValidatedEvts();
    this._setupWatchAllSignupValidatedEvts();
    this._setupSubmitHandler();
    this._setupSignupErrorHandling();
    this._setupNextStepOnSignInFullyValidated();
    this._setupNextStepOnSignUpWithAuthSvcFullyValidated();
    this._setupNextStepOnUserFullyRegistered();
    this._setupSignupOnSigninFailure();

    this.allSigninValidationEvts.watch((r) => {
      console.log("allSigninValidationEvts", r);
    });

    this.authSvcLocallyValidatedEvt.watch((r) => {
      console.log("authSvcLocallyValidatedEvt", r);
    });

    this.signupEmailPwdFx.doneData.watch((r) => {
      console.log("signupEmailPwdFx DONE DATA! ", r);
    });

    this.$canProceedToNextStep.watch((r) => {
      console.log("CAN PROCEED TO NEXT STEP! ", r);
    });

    this.userCreatedOnServerEvt.watch((r) => {
      console.log("USER CREATED ON SERVER! ", r);
    });

    this._setupLoadingState();
  }

  _setupWatchAllSigninValidatedEvts = () => {
    combineEvents({
      events: {
        locallyValidated: this.authSvcLocallyValidatedEvt.filter({
          fn: (r) => r,
        }),
        signinSuccess: this.signinEmailPwdFx.doneData
          .map(Either.isRight)
          .filter({
            fn: (r) => r,
          }),
      },
      target: this.allSigninValidationEvts,
    });
  };

  _setupWatchAllSignupValidatedEvts = () => {
    combineEvents({
      events: {
        locallyValidated: this.authSvcLocallyValidatedEvt.filter({
          fn: (r) => r,
        }),
        signupSuccess: this.signupEmailPwdFx.doneData.filterMap((res) =>
          Either.isRight(res)
            ? {
                firebaseUid: res.right.firebaseUid,
                email: res.right.email,
              }
            : undefined
        ),
      },
      target: this.allSignupValidationEvts,
    });
  };

  _setupEmailBinding = () => {
    sample({
      source: this.emailTextEvt,
      target: this.$email,
    });
  };

  _setupPasswordBinding = () => {
    sample({
      source: this.passwordTextEvt,
      target: this.$password,
    });
  };

  _setupSubmitHandler = () => {
    sample({
      clock: this.submitEvt,
      source: {
        email: this.$email,
        password: this.$password,
      },
      target: this.signinEmailPwdFx,
    });
  };

  _setupSignupErrorHandling = () => {
    sample({
      source: this.signupEmailPwdFx.doneData,
      fn: (res) => (Either.isLeft(res) ? res.left : null),
      target: this.$errorMsg,
    });
  };

  _setupNextStepOnSignInFullyValidated = () => {
    sample({
      source: this.allSigninValidationEvts,
      filter: (r) => r.locallyValidated && r.signinSuccess,
      //   filter: ({ locallyValidated, signinSuccess }) =>
      //     locallyValidated && signinSuccess,
      fn: () => new GoToDashboard({ tag: "GO_TO_DASHBOARD" }),
      target: this.$canProceedToNextStep,
    });
  };

  _setupNextStepOnSignUpWithAuthSvcFullyValidated = () => {
    sample({
      source: this.allSignupValidationEvts,
      filter: (r) => r.locallyValidated,
      fn: (r) => new RegisterUser({ ...r.signupSuccess, tag: "REGISTER_USER" }),
      target: this.$canProceedToNextStep,
    });
  };

  _setupNextStepOnUserFullyRegistered = () => {
    sample({
      source: this.userCreatedOnServerEvt,
      filter: (r) => r,
      fn: () => new ContinueOnboarding({ tag: "CONTINUE_ONBOARDING" }),
      target: this.$canProceedToNextStep,
    });
  };

  _setupSignupOnSigninFailure = () => {
    sample({
      clock: this.signinEmailPwdFx.doneData.filter({
        fn: (res: SigninResult) =>
          Either.isLeft(res) && this.authMode === "SIGNUP",
      }),
      source: {
        email: this.$email,
        password: this.$password,
      },
      target: this.signupEmailPwdFx,
    });
  };

  _setupLoadingState = () => {
    // Start loading on submit
    sample({
      clock: this.submitEvt,
      fn: () => true,
      target: this.$isLoading,
    });

    // Stop loading on error or success
    const stopLoadingEvents = merge([
      sample({
        source: this.$errorMsg,
        filter: (msg) => msg !== null,
        fn: () => false,
      }),
      sample({
        source: this.$canProceedToNextStep,
        filter: (step) => step !== null,
        fn: () => false,
      }),
    ]);

    this.$isLoading.on(stopLoadingEvents, () => false);
  };
}

export const [PasswordAuthVMProvider, usePasswordAuthVM] =
  createContextAndHook<PasswordAuthVM>();
