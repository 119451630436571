import { MockAvatarCircles } from "@/src/components/avatar.tc";
import { PrimaryButton } from "@/src/components/primitives/button";
import {
  DialogContent,
  DialogTrigger,
} from "@/src/components/primitives/dialogue";

import { Dialog } from "@/src/components/primitives/dialogue";
import { useNavigate } from "react-router-dom";

export const CreateAProfileDialog: React.FC<{
  trigger: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}> = ({ trigger, open, onOpenChange }) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger>{trigger}</DialogTrigger>
      <DialogContent>
        <div className="flex flex-col py-4 justify-center items-center">
          <CreateAProfileCard />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const CreateAProfileCard: React.FC = () => {
  const nav = useNavigate();
  return (
    <div
      className="w-[345px] h-[555px] flex flex-col gap-4 justify-center items-center px-8 rounded-[12px]"
      style={{
        backgroundColor: "#F5F1FD",
      }}
    >
      <MockAvatarCircles size={44} />
      <div className="flex justify-center items-center rounded-full px-6 py-[10px] text-sm bg-white border">
        Trusted by 1000+ practitioners
      </div>
      <h4 className="font-medium text-[36px] text-vid-purple text-center text-leading">
        Create a profile
      </h4>
      <span className="text-center text-sm text-black-26">
        {`InsightLive makes it easier for you to connect with fellow practitioners and clients globally.`}
      </span>
      <PrimaryButton title="Sign up" onClick={() => nav("/u")} />
    </div>
  );
};
