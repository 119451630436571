import { useMutation, useQuery } from "convex/react";
import { useTherapySessionId } from "frontend-shared/src/mgrs/remote-state-mgrs/main-room.rsm";
import type { AnnotationsPanelMgr } from "frontend-shared/src/mgrs/remote-state-mgrs/sessions/quickaction-tools.rsm";
import { createContextAndHook } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import { api } from "shared/convex/_generated/api";
import {
  RecordedSpansCard,
  SavedBookmarkItem,
} from "../../bookmark.components";

const [AnnotationsPanelMgrContext, useAnnotationsPanelMgr] =
  createContextAndHook<AnnotationsPanelMgr>();

export const NoteToolsPanel: React.FC<{
  annotationsPanelMgr: AnnotationsPanelMgr;
}> = ({ annotationsPanelMgr }) => {
  const baseSessionId = useTherapySessionId();
  const onShowWrapupToAllClick = useMutation(
    api.Screens.U.Sessions.Rtc.MainRoomScreenFns.onShowWrapupToAllClick
  );
  return (
    <AnnotationsPanelMgrContext.Provider value={annotationsPanelMgr}>
      <div className="flex flex-col gap-4">
        <NotesPanelSection />
        <BookmarksSection />
        <RecordedBookends />
      </div>
      <button
        onClick={() => onShowWrapupToAllClick({ baseSessionId })}
        className="text-vid-purple mt-8"
      >
        View full review with all participants
      </button>
    </AnnotationsPanelMgrContext.Provider>
  );
};

const SectionContainer: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ children, title }) => {
  return (
    <div className="flex flex-col gap-3 border border-vid-black-200 rounded-lg p-6">
      <div className="flex self-stretch justify-between">
        <h1 className="text-sm font-bold">{title}</h1>
      </div>
      {children}
    </div>
  );
};

const NotesPanelSection: React.FC = () => {
  const annotationsPanelMgr = useAnnotationsPanelMgr();
  const notesSchema = useObservableEagerState(
    annotationsPanelMgr.notesMgr.state$
  );
  return (
    <SectionContainer title="Notes">
      <textarea
        className="flex-1 flex flex-col self-stretch border rounded-lg p-2 bg-vid-black-100 min-h-[108px]"
        value={notesSchema.rawString}
        onChange={() => {
          // annotationsPanelMgr.notesMgr.updateNotes(e.target.value);
        }}
      />
    </SectionContainer>
  );
};

const BookmarksSection: React.FC = () => {
  const annotationsPanelMgr = useAnnotationsPanelMgr();

  const updateMomentLabel = useMutation(
    api.Sessions.Participants.Views.MainRoomFns.updateMomentLabel
  );

  const allMoments = useQuery(
    api.Sessions.Participants.Views.MainRoomFns.getAllBookmarks,
    {
      baseSessionId: annotationsPanelMgr.p.baseSessionId,
    }
  );

  if (allMoments === undefined) {
    return null;
  }

  return (
    <SectionContainer title="Bookmarks">
      <div className="flex flex-col gap-2 self-stretch">
        {allMoments.map((m) => (
          <SavedBookmarkItem
            key={m.momentId}
            label={m.label}
            color={m.color}
            secondsIntoSession={m.startSis}
            onClick={{
              tag: "EDIT",
              onSave: (newLabel) => {
                updateMomentLabel({ momentId: m.momentId, newLabel }).catch(
                  () => {
                    // TODO: handle error
                  }
                );
              },
            }}
          />
        ))}
      </div>
    </SectionContainer>
  );
};

const RecordedBookends: React.FC = () => {
  const sessionId = useTherapySessionId();
  const updateMomentLabel = useMutation(
    api.Sessions.Participants.Views.MainRoomFns.updateMomentLabel
  );
  const spans = useQuery(
    api.Sessions.Participants.Views.MainRoomFns.getAllSpans,
    {
      baseSessionId: sessionId,
    }
  );

  if (spans === undefined) {
    return null;
  }

  return (
    <RecordedSpansCard
      spans={spans}
      onClick={{
        tag: "EDIT",
        onSave: (bk, newLabel) => {
          updateMomentLabel({ momentId: bk.momentId, newLabel }).catch(
            () => {}
          );
        },
      }}
    />
  );
};
