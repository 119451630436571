import { FullScreenLoadingSpinner } from "@webapp/loading";
import { useAction } from "convex/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "shared/convex/_generated/api";

export const OnboardCompletePage: React.FC = () => {
  const navigate = useNavigate();
  const onOnboardCompleteMount = useAction(
    api.Onboard.OnboardFns.onOnboardCompleteMount
  );

  useEffect(() => {
    onOnboardCompleteMount().then((r) => {
      console.log("R! ", r);
      navigate(r.path);
    });
  }, []);

  return (
    <div className="flex-1 flex flex-col justify-center items-center">
      <FullScreenLoadingSpinner />
    </div>
  );
};
