import { Match } from "effect";
import { useUnit } from "effector-react";
import type { RightDrawerVM } from "src/shared/vms/right-drawer.vm";
import { PersonPlusIcon } from "../assets/icons/person-plus.icon";

interface RightDrawerProps {
  vm: RightDrawerVM;
}

export const RightDrawer = ({ vm }: RightDrawerProps) => {
  const [isOpen, closeEvt] = useUnit([vm.isOpen$, vm.closeEvt]);

  return (
    <div
      className={`
          fixed inset-y-0 right-0 w-96 bg-white shadow-lg transform transition-transform duration-300 ease-in-out
          rounded-tl-[12px] rounded-bl-[12px]
          ${isOpen ? "translate-x-0" : "translate-x-full"}
        `}
      style={{
        boxShadow: "0px 3.141px 13.12px 0px rgba(99, 100, 113, 0.27)",
      }}
    >
      {/* Header */}
      <button
        onClick={() => closeEvt()}
        className="absolute top-4 right-4 p-2 hover:bg-gray-100 rounded-full transition-colors"
      >
        <img src="/close.svg" width={18} height={18} />
      </button>

      {/* Content */}
      {isOpen && (
        <div className="h-[calc(100vh-4rem)] overflow-y-auto flex flex-col">
          {isOpen}
        </div>
      )}
    </div>
  );
};

export const RightDrawerContentContainer: React.FC<{
  header:
    | { _tag: "CUSTOM"; view: React.ReactNode }
    | {
        _tag: "ICON_TITLE";
        icon:
          | { _tag: "CUSTOM"; view: React.ReactNode }
          | { _tag: "PLUS_PERSON" };
        title: string;
      };
  children: React.ReactNode;
}> = ({ header, children }) => {
  return (
    <div className="flex flex-col flex-1 gap-8">
      {Match.value(header).pipe(
        Match.when({ _tag: "CUSTOM" }, ({ view }) => view),
        Match.when({ _tag: "ICON_TITLE" }, ({ icon, title }) => (
          <div className="flex flex-col items-center gap-2 mt-8 px-6">
            {Match.value(icon).pipe(
              Match.when({ _tag: "CUSTOM" }, ({ view }) => view),
              Match.when({ _tag: "PLUS_PERSON" }, () => (
                <div className="w-[100px] aspect-square rounded-full flex justify-center items-center bg-bg-gray">
                  <PersonPlusIcon size={50} />
                </div>
              )),
              Match.exhaustive
            )}
            <h4 className="text-[24px] text-center">{title}</h4>
          </div>
        )),
        Match.exhaustive
      )}
      <div className="flex-1 flex flex-col">{children}</div>
    </div>
  );
};
