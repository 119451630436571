import { SetupBreakoutRoomsRightDrawer } from "@/src/domains/sessions/rtc/rooms/setup-breakout-rooms.form";
import { HpStateContext, useHpState } from "@pages/u/hp/hp.webstatemgr";
import { useMe } from "@pages/u/u.webstatemgr";
import { DashboardLayout } from "@webapp/componentsdashboard/dashboard-layout.fc";
import {
  CalendarIcon,
  ClientsIcon,
  CommunityIcon,
  InvoicesIcon,
  SettingsIcon,
} from "@webapp/componentsdashboard/main-nav-links";
import { DashboardSearch } from "@webapp/componentsdashboard/navbar/global-search.input";
import { PrimaryButton } from "@webapp/componentsprimitives/button";
import { useQuery } from "convex/react";
import { Effect } from "effect";
import type { SearchableEntity } from "frontend-shared/src/shared-vms/searchable-dropdown.vm";
import { BaseSearchableDropdownVM } from "frontend-shared/src/shared-vms/searchable-dropdown.vm";
import { useOnce, useQuery$ } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import React, { useEffect, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import type { SimplestUserWithProfilePhoto } from "shared";
import { O } from "shared/base-prelude";
import { api } from "shared/convex/_generated/api";
import { UROUTES } from "shared/routes/u.routes";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { DashboardRightDrawer } from "./right-drawer-action/right-nav";

export const HpDashboardLayout: React.FC = () => {
  const curUrl = useLocation().pathname;
  const hpState = useHpState();
  const myHpProfile = useObservableEagerState(hpState.myProfile$);
  const me = useMe();
  const myProfile = { ...me, ...myHpProfile };
  const myClients$ = useQuery$(
    api.Screens.Hp.Dashboard.ClientsScreenFns.getMyActiveClients
  );

  const searchVM = useOnce(() => {
    return new BaseSearchableDropdownVM<SearchableEntity>();
  });

  useEffect(() => {
    myClients$.subscribe((myClients) => {
      if (myClients !== undefined) {
        searchVM.itemsSet(
          myClients.clients.map((c) => ({
            id: c.clientId,
            label: c.name,
            type: "client",
          }))
        );
      }
    });
  }, [myClients$]);

  return (
    <HpStateContext.Provider value={hpState}>
      <DashboardLayout
        stateMgr={hpState.dashboardState}
        matchViewForRightNav={(rightNav) => ({
          view: <DashboardRightDrawer rightNav={rightNav} />,
        })}
        mainContent={<Outlet />}
        navbar={{
          chatStateMgr: hpState.uMgr.chatStateMgr,
          currentDashboardPage: getCurrentDashboardPageFromUrl(curUrl),
          middleSection: <DashboardSearch vm={searchVM} />,
          profileButton: {
            to: UROUTES.HP.MY.DASHBOARD.SETTINGS.ACCOUNT.path,
            profilePhoto: myProfile.profilePhoto
              ? ImageSrc.fromURL(myProfile.profilePhoto)
              : null,
          },
          hamburger: {
            links: [
              {
                to: UROUTES.HP.MY.DASHBOARD.CLIENTS.path,
                name: "Dashboard",
                icon: () => <DashboardIcon />,
              },
              {
                to: UROUTES.HP.MY.DASHBOARD.CLIENTS.path,
                name: "Clients",
                icon: (isSelected) => <ClientsIcon isSelected={isSelected} />,
              },
              {
                to: UROUTES.HP.MY.DASHBOARD.CALENDAR.path,
                name: "Calendar",
                icon: (isSelected) => <CalendarIcon isSelected={isSelected} />,
              },
              {
                to: UROUTES.HP.MY.DASHBOARD.INVOICES.path,
                name: "Invoices",
                icon: (isSelected) => <InvoicesIcon isSelected={isSelected} />,
              },
              {
                to: UROUTES.HP.MY.DASHBOARD.SETTINGS.ACCOUNT.path,
                name: "Settings",
                icon: (isSelected) => <SettingsIcon isSelected={isSelected} />,
              },
            ],
            onSignout: () => {
              Effect.runPromise(hpState.uMgr.signOut).then((_) => {
                window.location.href = "/";
              });
            },
          },
        }}
        leftMenu={{
          onLogoClick: () => {
            window.location.href = UROUTES.HP.MY.DASHBOARD.CLIENTS.path;
          },
          onSignout: () => {
            Effect.runPromise(hpState.uMgr.signOut).then((_) => {
              window.location.href = "/";
            });
          },
          extraTop: <StartSessionButton />,
          topLinks: [
            {
              to: UROUTES.HP.MY.DASHBOARD.CLIENTS.path,
              name: "Clients",
              icon: (isSelected) => <ClientsIcon isSelected={isSelected} />,
            },
            {
              to: UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.path,
              name: "Community",
              icon: (isSelected) => <CommunityIcon isSelected={isSelected} />,
            },
            {
              to: UROUTES.HP.MY.DASHBOARD.CALENDAR.path,
              name: "Calendar",
              icon: (isSelected) => <CalendarIcon isSelected={isSelected} />,
            },
            {
              to: UROUTES.HP.MY.DASHBOARD.INVOICES.path,
              name: "Invoices",
              icon: (isSelected) => <InvoicesIcon isSelected={isSelected} />,
            },
            {
              to: UROUTES.HP.MY.DASHBOARD.SETTINGS.ACCOUNT.path,
              name: "Settings",
              icon: (isSelected) => <SettingsIcon isSelected={isSelected} />,
            },
          ],
        }}
      />
    </HpStateContext.Provider>
  );
};

function getCurrentDashboardPageFromUrl(url: string) {
  const dashboardPageRegex = /\/hp\/my\/dashboard\/(\w+)(\/|$)/;
  const match = url.match(dashboardPageRegex);
  if (match && match[1]) {
    return match[1].charAt(0).toUpperCase() + match[1].slice(1);
  }
  return "Dashboard"; // Default return if no specific page is found
}

const StartSessionButton: React.FC = () => {
  const dashboardState = useHpState();

  const myActiveClients = useQuery(
    api.Screens.Hp.Dashboard.ClientsScreenFns.getMyActiveClients
  );

  const myClients = useMemo(() => {
    return myActiveClients
      ? myActiveClients.clients.map(
          (c) =>
            ({
              id: c.clientId,
              email: c.email,
              name: c.name,
              profilePhoto: c.profilePhoto?.medium ?? null,
            }) satisfies SimplestUserWithProfilePhoto
        )
      : [];
  }, [myActiveClients]);

  return (
    <PrimaryButton
      title="Start session"
      onClick={() => {
        dashboardState.dashboardState.rightNav$.next(
          O.some({
            _tag: "START_SESSION",
            myClients,
          })
        );
      }}
    />
  );
};

function DashboardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <g>
        <g
          stroke="#690DFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        >
          <path d="M10.5 20.23V4.43c0-1.5-.64-2.1-2.23-2.1H4.23c-1.59 0-2.23.6-2.23 2.1v15.8c0 1.5.64 2.1 2.23 2.1h4.04c1.59 0 2.23-.6 2.23-2.1z"></path>
          <path d="M22 8.85V4.31c0-1.41-.64-1.98-2.23-1.98h-4.04c-1.59 0-2.23.57-2.23 1.98v4.53c0 1.42.64 1.98 2.23 1.98h4.04c1.59.01 2.23-.56 2.23-1.97z"></path>
          <path d="M22 20.1v-4.04c0-1.59-.64-2.23-2.23-2.23h-4.04c-1.59 0-2.23.64-2.23 2.23v4.04c0 1.59.64 2.23 2.23 2.23h4.04c1.59 0 2.23-.64 2.23-2.23z"></path>
        </g>
      </g>
    </svg>
  );
}
