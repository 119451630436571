import { InsightLiveLogoWithText } from "@/src/assets/il-logo-with-text.fc";
import { EyeIcon } from "@webapp/componentsicons/eyeicon";
import { PrimaryButton } from "@webapp/componentsprimitives/button";
import { LoadingSpinner } from "@webapp/loading";
import { useConvexAuth, useMutation } from "convex/react";
import { Match } from "effect";
import { useUnit } from "effector-react";
import { useFirebaseJs } from "frontend-shared/src/firebase";
import {
  FirebaseAuthSvc,
  PasswordAuthVM,
  PasswordAuthVMProvider,
  usePasswordAuthVM,
} from "frontend-shared/src/onboard/password-auth.vm";
import { useOnce } from "frontend-shared/src/util";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTypedSearchParams } from "react-router-typesafe-routes/dom";
import { api } from "shared/convex/_generated/api";
import { UROUTES } from "shared/routes/u.routes";
import { ONBOARD_ROUTES } from "src/route-views/onboard-route-views";

export const HpRegisterV2Page: React.FC = () => {
  const nav = useNavigate();
  const firebaseJsMgr = useFirebaseJs();
  const [{ email: initialEmail }] = useTypedSearchParams(
    ONBOARD_ROUTES.HP.REGISTER
  );
  const vm = useOnce(
    () =>
      new PasswordAuthVM({
        authSvc: new FirebaseAuthSvc(firebaseJsMgr),
        authMode: "SIGNUP",
      })
  );
  const [canProceedToNextStep] = useUnit([vm.$canProceedToNextStep]);
  const { isAuthenticated } = useConvexAuth();
  const createUser = useMutation(api.User.UserFns.createUser);

  useEffect(() => {
    if (initialEmail !== undefined) {
      vm.emailTextEvt(initialEmail);
    }
  }, [initialEmail]);

  useEffect(() => {
    vm.authSvcLocallyValidatedEvt(isAuthenticated);
  }, [isAuthenticated]);

  useEffect(() => {
    console.log("canProceedToNextStep", canProceedToNextStep);

    Match.value(canProceedToNextStep).pipe(
      Match.when({ tag: "GO_TO_DASHBOARD" }, () => {
        nav(UROUTES.HP.MY.DASHBOARD.CLIENTS.path);
      }),
      Match.when({ tag: "REGISTER_USER" }, ({ firebaseUid, email }) => {
        console.log("CONTINUE_ONBOARD", firebaseUid, email);

        createUser({
          firebaseUid,
          email,
          onboardedAs: "hp",
          preferredPortal: "HP",
        }).then((userId) => {
          console.log("USER ID! ", userId);
          vm.userCreatedOnServerEvt(true);
        });
      }),
      Match.when({ tag: "CONTINUE_ONBOARDING" }, () => {
        nav(UROUTES.HP.ONBOARD_CHOOSE_IMPORT_PROFILE_METHOD.path);
      }),
      Match.when(null, () => {}),
      Match.exhaustive
    );
  }, [canProceedToNextStep]);

  useEffect(() => {
    firebaseJsMgr.signOut().catch();
  }, []);

  return (
    <div className="w-screen h-screen flex flex-col items-center bg-vid-purple pt-36">
      <div className="flex flex-col items-center gap-8 bg-white rounded-[12px] p-8 min-w-[550px]">
        <div className="p-8 flex flex-col items-center">
          <InsightLiveLogoWithText />
          {/* <h1 className="onboard-header">Sign in</h1> */}
        </div>
        <div className="flex flex-col items-center gap-8">
          <div className="flex flex-col items-center gap-2">
            <SigninWithGoogleButton />
          </div>
          <div className="flex items-center w-full gap-3">
            <div className="h-px bg-gray-300 flex-1" />
            <div className="text-sm text-gray-500">Or sign in with</div>
            <div className="h-px bg-gray-300 flex-1" />
          </div>
          <PasswordAuthVMProvider.Provider value={vm}>
            <EmailPasswordSignupForm />
          </PasswordAuthVMProvider.Provider>
        </div>
      </div>
    </div>
  );
};

const EmailPasswordSignupForm: React.FC = () => {
  const vm = usePasswordAuthVM();
  const [email, mbError] = useUnit([vm.$email, vm.$errorMsg]);
  const [isLoading] = useUnit([vm.$isLoading]);
  return (
    <form
      className="flex flex-col gap-2 max-w-screen"
      onSubmit={(e) => {
        e.preventDefault();
        vm.submitEvt();
      }}
    >
      <input
        type="email"
        placeholder="Email"
        className="text-input"
        id="email"
        value={email}
        autoComplete="email"
        autoCapitalize="none"
        autoCorrect="off"
        onChange={(e) => {
          vm.emailTextEvt(e.target.value);
        }}
      />
      <PasswordInput />
      {mbError && (
        <div className="text-red-500 self-center font-medium">{mbError}</div>
      )}
      {isLoading && (
        <div className="self-stretch flex justify-center items-center">
          <LoadingSpinner />
        </div>
      )}
      <PrimaryButton type="submit" title="Signup" />
    </form>
  );
};

const SigninWithGoogleButton: React.FC = () => {
  const firebaseJsMgr = useFirebaseJs();
  const createUser = useMutation(api.User.UserFns.createUser);
  const nav = useNavigate();

  return (
    <button
      className="w-[300px] google-button"
      onClick={() => {
        firebaseJsMgr
          .signinWithGoogle()
          .then((r) => {
            console.log("SIGNED IN WITH GOOGLE!", r);

            createUser({
              firebaseUid: r.user.uid,
              email: r.user.email!,
              firstName: "",
              lastName: "",
              onboardedAs: "hp",
              preferredPortal: "HP",
            }).then((createRes) => {
              if (createRes._tag === "NEW_USER_CREATED") {
                nav(UROUTES.HP.ONBOARD_CHOOSE_IMPORT_PROFILE_METHOD.path);
              } else {
                nav(UROUTES.HP.MY.DASHBOARD.CLIENTS.path);
              }
            });
          })
          .catch((e) => {
            console.error("ERROR SIGNING IN WITH GOOGLE", e);
          });
      }}
    >
      <img
        src="https://developers.google.com/identity/images/g-logo.png"
        alt="Google Logo"
        className="google-logo"
      />
      <div className="font-medium">Sign in with Google</div>
    </button>
  );
};

const PasswordInput: React.FC = () => {
  const vm = usePasswordAuthVM();
  const [password] = useUnit([vm.$password]);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="relative">
      <input
        type={showPassword ? "text" : "password"}
        placeholder="Password"
        className="text-input pr-10 w-full"
        id="password"
        value={password}
        autoComplete="off"
        autoCapitalize="off"
        autoCorrect="off"
        onChange={(e) => {
          vm.passwordTextEvt(e.target.value);
        }}
      />
      <button
        type="button"
        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none"
        onClick={() => setShowPassword(!showPassword)}
      >
        <EyeIcon showPassword={showPassword} />
      </button>
    </div>
  );
};
