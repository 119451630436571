import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { AvatarCircle } from "@webapp/componentsavatar.tc";
import { DashboardRightDrawerContainer } from "@webapp/componentsdashboard/right-nav.components";
import { Match } from "effect";
import React from "react";
import { SessionInvoice, type SimplestUserWithProfilePhoto } from "shared";
import type { Id } from "shared/convex/_generated/dataModel";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { CreateCommunityPostFormCool } from "@/src/domains/communities/community/community-post/create-community-post.form";
import { CommunityEventDetailsPanel } from "@/src/domains/communities/community/events/view-community-event-details";
import { CreateCommunityPostFormTopView } from "./create-community-post.form.fc";
import { CreateCommunityForm } from "./create-community.form";
import { InvoiceDownloadForm } from "./invoice-download-form.fc";
import { RegisterNewClientForm } from "./register-new-client.form";
import { StartASessionForm } from "./start-a-session-form.fc";

export interface SimpleClient {
  id: string;
  name: string;
}

export type HpDashbaordRightNav =
  | {
      _tag: "START_CLIENT_SESSION";
      client: SimplestUserWithProfilePhoto;
    }
  | { _tag: "REGISTER_NEW_CLIENT" }
  | { _tag: "START_SESSION"; myClients: SimplestUserWithProfilePhoto[] }
  | {
      _tag: "NEW_APPOINTMENT";
      date?: Date;
      client: { id: string; name: string } | undefined;
      requestApptId: string | undefined;
    }
  | { _tag: "DOWNLOAD_INVOICE"; invoice: SessionInvoice }
  | { _tag: "CREATE_COMMUNITY_POST"; posterId: string; communitySlug: string }
  | {
      _tag: "VIEW_COMMUNITY_EVENT";
      communitySlug: string;
      eventTemplateId: string;
    }
  | {
      _tag: "VIEW_COMMUNITY_EVENT_INSTANCE";
      communitySlug: string;
      instanceId: string;
    }
  | {
      _tag: "CREATE_COMMUNITY";
    }
  | { _tag: "CUSTOM"; topView: React.ReactNode; content: React.ReactNode };

export const DashboardRightDrawer: React.FC<{
  rightNav: HpDashbaordRightNav;
}> = ({ rightNav }) => {
  const hpState = useHpState();
  const closeRightNav = hpState.dashboardState.closeRightNav;
  return Match.value(rightNav).pipe(
    Match.tag("START_CLIENT_SESSION", (res) => (
      <DashboardRightDrawerContainer
        topView={
          <div className="flex flex-col gap-3 items-center">
            <AvatarCircle
              mbProfilePhoto={ImageSrc.fromMbUrl(res.client.profilePhoto)}
              size={72}
            />
            <h4>{res.client.name}</h4>
          </div>
        }
        content={
          <StartASessionForm
            onClose={() => {
              window.location.reload();
            }}
            forClient={{ _tag: "EXISTING", client: res.client }}
          />
        }
        closeRightNav={closeRightNav}
      />
    )),
    Match.tag("REGISTER_NEW_CLIENT", () => (
      <DashboardRightDrawerContainer
        topView={<div className="text-xl">Register a new client</div>}
        content={<RegisterNewClientForm />}
        closeRightNav={closeRightNav}
      />
    )),
    Match.tag("START_SESSION", ({ myClients }) => (
      <DashboardRightDrawerContainer
        topView={<ProfileAddIconView title="Start a session" />}
        content={
          <StartASessionForm
            onClose={() => {
              window.location.reload();
            }}
            forClient={{ _tag: "SELECT", myClients }}
          />
        }
        closeRightNav={closeRightNav}
      />
    )),
    Match.tag("NEW_APPOINTMENT", () => (
      <DashboardRightDrawerContainer
        topView={<ProfileAddIconView title="New appointment" />}
        content={<div></div>}
        closeRightNav={closeRightNav}
      />
    )),
    Match.tag("DOWNLOAD_INVOICE", (res) => (
      <DashboardRightDrawerContainer
        topView={<div>Download Invoice</div>}
        content={<InvoiceDownloadForm invoice={res.invoice} />}
        closeRightNav={closeRightNav}
      />
    )),
    Match.tag("CREATE_COMMUNITY", () => (
      <DashboardRightDrawerContainer
        topView={<div>Create a new community</div>}
        content={<CreateCommunityForm />}
        closeRightNav={closeRightNav}
      />
    )),
    Match.tag("CREATE_COMMUNITY_POST", (res) => (
      <DashboardRightDrawerContainer
        topView={<CreateCommunityPostFormTopView />}
        content={
          <CreateCommunityPostFormCool
            communitySlug={res.communitySlug}
            onSuccessSubmit={() => {
              hpState.dashboardState.showBottomToast({
                msg: "Your post is live!",
                duration: { _tag: "SECONDS", seconds: 3 },
                closeRightNav: true,
              });
            }}
            // posterId={res.posterId}
          />
        }
        closeRightNav={closeRightNav}
      />
    )),
    Match.tag("VIEW_COMMUNITY_EVENT", (res) => {
      return <div>{JSON.stringify(res)}</div>;
    }),
    Match.tag("VIEW_COMMUNITY_EVENT_INSTANCE", (res) => {
      return (
        <CommmunityEventInstanceRightNav
          instanceId={res.instanceId as Id<"calEventInstances">}
          communitySlug={res.communitySlug}
          closeRightNav={closeRightNav}
        />
      );
    }),
    Match.tag("CUSTOM", (res) => (
      <DashboardRightDrawerContainer
        topView={<></>}
        content={res.content}
        closeRightNav={closeRightNav}
      />
    )),
    Match.exhaustive
  );
};

const CommmunityEventInstanceRightNav: React.FC<{
  instanceId: Id<"calEventInstances">;
  communitySlug: string;
  closeRightNav: () => void;
}> = ({ instanceId, communitySlug, closeRightNav }) => {
  const hpState = useHpState();
  return (
    <DashboardRightDrawerContainer
      topView={<ProfileAddIconView title="" />}
      content={
        <CommunityEventDetailsPanel
          instanceId={instanceId}
          communitySlug={communitySlug}
          closePanel={(mbMessage) => {
            if (mbMessage) {
              hpState.dashboardState.showBottomToast({
                msg: mbMessage.message,
                reload: true,
                closeRightNav: true,
              });
            }
          }}
        />
      }
      closeRightNav={closeRightNav}
    />
  );
};

export const ProfileAddIconView: React.FC<{
  title?: string;
}> = ({ title }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-[100px] aspect-square flex items-center justify-center rounded-full bg-bg-gray mb-2">
        <ProfileAddIconSvg />
      </div>
      {title && <h2 className="text-[24px]">{title}</h2>}
    </div>
  );
};

export const ProfileAddIconSvg = () => (
  <svg width={50} height={50} viewBox="0 0 50 50" fill="none">
    <path
      d="M38.5404 40.625H30.207"
      stroke="#3A3A3A"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.375 44.7918V36.4585"
      stroke="#3A3A3A"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.3334 22.6457C25.1251 22.6248 24.8751 22.6248 24.6459 22.6457C19.6876 22.479 15.7501 18.4165 15.7501 13.4165C15.7292 8.31234 19.8751 4.1665 24.9792 4.1665C30.0834 4.1665 34.2292 8.31234 34.2292 13.4165C34.2292 18.4165 30.2709 22.479 25.3334 22.6457Z"
      stroke="#3A3A3A"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.9805 45.4377C21.1888 45.4377 17.418 44.4793 14.543 42.5627C9.5013 39.1877 9.5013 33.6877 14.543 30.3335C20.2721 26.5002 29.668 26.5002 35.3971 30.3335"
      stroke="#3A3A3A"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
