import { Array, Console, Effect, Option, String } from "effect";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import type {
  KNOWN_CALL_TYPE,
  RtcCredentials,
} from "shared/schemas/call.schemas";

export abstract class GenericRtcMgr<
  RtcEngine extends GenericRtcEngine<ChannelMgr>,
  ChannelMgr extends GenericRtcChannelMgr,
> {
  readonly client: RtcEngine;

  readonly mbCurrentChannelMgr$ = new Rx.BehaviorSubject<
    Option.Option<ChannelMgr>
  >(Option.none());

  readonly hasInitialized$ = new Rx.BehaviorSubject<boolean>(false);

  constructor(
    readonly creds: RtcCredentials,
    mkEngine: (creds: RtcCredentials) => RtcEngine
  ) {
    this.client = mkEngine(creds);

    Effect.runPromise(this.initializeRtc).then((res) => {
      console.log("INITIALIZED RTC! ", res);
      this.hasInitialized$.next(true);
    });
  }

  initializeRtc: Effect.Effect<any> = Effect.gen(this, function* () {
    yield* this.safeDisconnect();
    yield* this._connectUser();
    yield* this._setupObservables();
  });

  _setupObservables = (): Effect.Effect<any> =>
    Effect.sync(() => {
      this.client.state.calls$
        .pipe(
          RxO.tap((calls) => {
            console.log("RAW CLIENT CALLS: ", calls);
          }),
          RxO.map((calls) => Array.head(calls)),
          RxO.distinctUntilChanged((a, b) => {
            console.log("COMPARING CALLS! ", a, b);
            const mbACid = a.pipe(Option.map((call) => call.cid));
            const mbBCid = b.pipe(Option.map((call) => call.cid));
            const isSame = Option.getEquivalence(String.Equivalence)(
              mbACid,
              mbBCid
            );
            console.log("IS SAME: ", isSame);
            return isSame;
          })
        )
        .subscribe((mbCall) => {
          console.log("RTC MGR SETTING CURRENT CHANNEL MGR! ", mbCall);
          this.mbCurrentChannelMgr$.next(mbCall);
        });
    });

  _connectUser = (): Effect.Effect<any> =>
    Effect.promise(() =>
      this.client.connectUser(this.creds.user, this.creds.token)
    );

  lazyJoinChannel = (p: {
    channelType: KNOWN_CALL_TYPE;
    channelId: string;
  }): Effect.Effect<ChannelMgr> =>
    Effect.gen(this, function* () {
      return yield* this.disconnectThenJoin(p);
    });

  handleDismountInCall = Effect.gen(this, function* () {
    yield* this.leaveCurChannel();
    yield* Effect.promise(() => this.client.disconnectUser());
  });

  withAssumedCurrentChannel = <V>(
    f: (channelMgr: ChannelMgr) => Effect.Effect<V>
  ): Effect.Effect<V> => {
    if (Option.isNone(this.mbCurrentChannelMgr$.value)) {
      return Effect.dieMessage("No current channel");
    }
    return f(this.mbCurrentChannelMgr$.value.value);
  };

  leaveCurChannel = (): Effect.Effect<void> =>
    this.withAssumedCurrentChannel((call) =>
      Effect.promise(() => call.leave())
    ).pipe(Effect.catchAll((_) => Console.log("ERROR LEAVING CHANNEL! ", _)));

  safeDisconnect = (): Effect.Effect<void> =>
    Effect.promise(() => this.client.disconnectUser()).pipe(
      Effect.catchAll((_) => Console.log("ERROR DISCONNECTING USER! ", _))
    );

  disconnectThenJoin = (p: {
    channelType: KNOWN_CALL_TYPE;
    channelId: string;
  }) =>
    Effect.gen(this, function* () {
      const disconnectRes = yield* this.safeDisconnect();
      console.log("DISCONNECTED USER BEFORE JOIN! ", disconnectRes);

      console.log("CONNECTING USER! ", this.creds.user, this.creds.token);
      const connectRes = yield* Effect.promise(() =>
        this.client.connectUser(this.creds.user, this.creds.token)
      ).pipe(
        Effect.tapDefect((e) => Console.log("ERROR CONNECTING USER! ", e))
      );

      console.log("CONNECTED USER! ", connectRes);

      const channelMgr = this.client.call(p.channelType, p.channelId);

      console.log("JOINING CHANNEL! ", p.channelType, p.channelId);

      const joinRes = yield* Effect.promise(() =>
        channelMgr.join({ create: true })
      ).pipe(
        Effect.catchAllDefect((e) => Console.log("ERROR JOINING CHANNEL! ", e))
      );
      console.log("JOINED CHANNEL! ", joinRes);
      return channelMgr;
    });
}

export abstract class GenericRtcChannelMgr {
  abstract readonly cid: string;

  abstract readonly state: {
    remoteParticipants$: Rx.Observable<GenericRtcRemoteParticipant[]>;
  };

  abstract join(p?: { create?: boolean }): Promise<void>;

  abstract leave(): Promise<void>;
}

export abstract class GenericRtcEngine<
  ChannelMgr extends GenericRtcChannelMgr,
> {
  abstract readonly state: {
    calls$: Rx.Observable<ChannelMgr[]>;
  };

  abstract disconnectUser(): Promise<void>;

  abstract connectUser(user: { id: string }, token: string): Promise<any>;

  abstract call(channelType: KNOWN_CALL_TYPE, channelId: string): ChannelMgr;
}

abstract class GenericRtcRemoteParticipant {
  abstract readonly audioStream?: any;
}
