import { useTypedSearchParams } from "react-router-typesafe-routes/dom";
import { ROUTES } from "shared/routes/routes";
import { LoginForm } from "src/domains/onboard/login.form";

export const LoginPage: React.FC = () => {
  const [{ prefilledEmail }] = useTypedSearchParams(ROUTES.LOGIN);

  return (
    <div className="flex-1 flex flex-col justify-center items-center gap-2">
      <h1 className="font-outfit font-medium text-[54px] leading-[160%] text-center text-vid-purple w-[750px]">
        Login
      </h1>
      <LoginForm prefilledEmail={prefilledEmail} />
    </div>
  );
};
