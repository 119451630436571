import type React from "react";

export const PersonPlusIcon: React.FC<{ size?: number }> = ({ size = 24 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 50 50" fill="none">
      <path
        d="M38.5404 40.625H30.207"
        stroke="#3A3A3A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.375 44.7918V36.4585"
        stroke="#3A3A3A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3334 22.6457C25.1251 22.6248 24.8751 22.6248 24.6459 22.6457C19.6876 22.479 15.7501 18.4165 15.7501 13.4165C15.7292 8.31234 19.8751 4.1665 24.9792 4.1665C30.0834 4.1665 34.2292 8.31234 34.2292 13.4165C34.2292 18.4165 30.2709 22.479 25.3334 22.6457Z"
        stroke="#3A3A3A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9805 45.4377C21.1888 45.4377 17.418 44.4793 14.543 42.5627C9.5013 39.1877 9.5013 33.6877 14.543 30.3335C20.2721 26.5002 29.668 26.5002 35.3971 30.3335"
        stroke="#3A3A3A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
