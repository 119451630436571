import { Either } from "effect";
import { createEffect, createEvent, createStore, sample } from "effector";
import type { UserCredential } from "firebase/auth";
import {
  messageForFirebaseAuthError,
  type FirebaseAuthError,
} from "frontend-shared/src/firebase";

export class LoginFormVM {
  // effector events
  emailTextEvt = createEvent<string>();
  passwordTextEvt = createEvent<string>();
  authLocalVerifiedEvt = createEvent<void>();
  toggleShowPasswordEvt = createEvent<boolean>();
  submitEvt = createEvent<void>();
  forgotPwdClickEvt = createEvent<void>();
  setForgotPwdErrorEvt = createEvent<string | null>();

  // stores
  $email = createStore<string>("");
  $password = createStore<string>("");
  $isLocalAuthVerified = createStore<boolean>(false);
  $isShowingPassword = createStore<boolean>(false);
  $submitErrorMsg = createStore<string | null>(null);
  $forgotPwdError = createStore<string | null>(null);

  // effects
  submitEffect = createEffect<
    { email: string; password: string },
    Either.Either<UserCredential, FirebaseAuthError>
  >();

  forgotPasswordEffect = createEffect<string, void, FirebaseAuthError>();

  constructor(p: {
    submitForm: (p: {
      email: string;
      password: string;
    }) => Promise<Either.Either<UserCredential, FirebaseAuthError>>;
    forgotPassword: (email: string) => Promise<void>;
    prefilledEmail?: string;
  }) {
    this.$email.on(this.emailTextEvt, (_, email) => email);
    this.$password.on(this.passwordTextEvt, (_, password) => password);
    this.$isLocalAuthVerified.on(this.authLocalVerifiedEvt, () => true);
    this.$isShowingPassword.on(
      this.toggleShowPasswordEvt,
      (_, isShowing) => isShowing
    );

    if (p.prefilledEmail) {
      this.emailTextEvt(p.prefilledEmail);
    }

    this.submitEffect.use(async (formVals) => {
      return p.submitForm(formVals);
    });

    this.$isLocalAuthVerified.watch((isVerified) => {
      console.log("IS VERIFIED", isVerified);
    });

    this.$submitErrorMsg.on(this.submitEffect.doneData, (_, r) => {
      if (Either.isLeft(r)) {
        return messageForFirebaseAuthError(r.left.code);
      }
      return null;
    });

    sample({
      clock: this.submitEvt,
      source: {
        email: this.$email,
        password: this.$password,
      },
      target: this.submitEffect,
    });

    this.forgotPasswordEffect.use(async (email) => {
      return p.forgotPassword(email);
    });

    this.$forgotPwdError.on(this.forgotPasswordEffect.failData, (_, error) =>
      messageForFirebaseAuthError(error.code)
    );

    this.$forgotPwdError.on(
      this.forgotPasswordEffect.doneData,
      () => "Password reset email sent! Please check your inbox."
    );

    sample({
      clock: this.forgotPwdClickEvt,
      source: this.$email,
      fn: (email) => email.trim(),
      target: createEffect((email: string) => {
        if (!email) {
          this.setForgotPwdErrorEvt("Please enter your email address first");
          return;
        }
        if (!email.includes("@")) {
          this.setForgotPwdErrorEvt("Please enter a valid email address");
          return;
        }

        this.forgotPasswordEffect(email);
      }),
    });

    this.$forgotPwdError.on(this.setForgotPwdErrorEvt, (_, error) => error);
  }
}
