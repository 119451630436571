import type { ConvexClient } from "convex/browser";
import * as Rx from "rxjs";
import type { HpProfile } from "shared";
import { ApiMgr } from "../../../api.mgr";
import { createContextAndHook } from "../../../util";
import { BaseStateMgr } from "../base.statemgr";
import type { GenericChatCli } from "../chat.statemgr";
import type {
  GenericRtcChannelMgr,
  GenericRtcEngine,
  GenericRtcMgr,
} from "../rtc.statemgr";
import type { UserStateMgr } from "./user.statemgr";

export class HpStateMgr<
  StreamChatCli extends GenericChatCli,
  ChannelMgr extends GenericRtcChannelMgr,
  RtcEngine extends GenericRtcEngine<ChannelMgr>,
  RtcMgr extends GenericRtcMgr<RtcEngine, ChannelMgr>,
> extends BaseStateMgr {
  profileMgr: HpProfileMgr;
  myUserId: string;

  constructor(
    readonly uMgr: UserStateMgr<StreamChatCli, ChannelMgr, RtcEngine, RtcMgr>,
    p: { myProfile: HpProfile; myUserId: string; apiMgr: ApiMgr }
  ) {
    super({ apiMgr: p.apiMgr, convex: uMgr.convex });
    this.profileMgr = new HpProfileMgr({
      apiMgr: p.apiMgr,
      convex: uMgr.convex,
      myProfile: p.myProfile,
      myUserId: p.myUserId,
    });
    this.myUserId = p.myUserId;
  }
}

export class HpProfileMgr extends BaseStateMgr {
  myProfile$: Rx.BehaviorSubject<HpProfile>;

  constructor(
    readonly p: {
      apiMgr: ApiMgr;
      convex: ConvexClient;
      myProfile: HpProfile;
      myUserId: string;
    }
  ) {
    super({ apiMgr: p.apiMgr, convex: p.convex });
    this.myProfile$ = new Rx.BehaviorSubject(p.myProfile);

    this.refreshMyProfile();
  }

  refreshMyProfile = () => {};

  setMyProfile = (profile: HpProfile) => {
    this.myProfile$.next(profile);
  };
}

const [HpProfileMgrContext, useHpProfileMgr] =
  createContextAndHook<HpProfileMgr>();

export { HpProfileMgrContext, useHpProfileMgr };
