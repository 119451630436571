import { ConvexClient } from "convex/browser";
import type { FirebaseJsMgr } from "frontend-shared/src/firebase";
import { createContextAndHook, useOnce } from "frontend-shared/src/util";
import { useEffect, useState } from "react";

const CONVEX_URL = import.meta.env["VITE_CONVEX_URL"]!;
console.log("CONVEX URL! ", CONVEX_URL);
export const webConvex = new ConvexClient(CONVEX_URL);

export function useSetupConvexCli(
  firebaseJs: FirebaseJsMgr
): ConvexClient | undefined {
  const convexCli = useOnce(() => new ConvexClient(CONVEX_URL));
  const [mbAuthSet, setMbAuthSet] = useState(false);

  useEffect(() => {
    convexCli.setAuth(firebaseJs.authTokenFetcher);
    setMbAuthSet(true);
  }, []);

  if (!mbAuthSet) {
    return undefined;
  }

  return convexCli;
}

export const [ConvexCliProver, useConvexCli] =
  createContextAndHook<ConvexClient>();
