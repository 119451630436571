import { FullCalendar } from "@webapp/componentscalendar/full-calendar.fc";
import { useConvex } from "convex/react";
import { pipe } from "fp-ts/lib/function";
import { CalendarStateMgr } from "frontend-shared/src/mgrs/state-mgrs/calendar.statemgr";
import { useOnce } from "frontend-shared/src/util";
import { TE } from "shared/base-prelude";
import { api } from "shared/convex/_generated/api";
import { useCpState } from "./cpdashboard.state";

export const CpDashboardCalendarPage = () => {
  const cpState = useCpState();
  const webConvexReact = useConvex();
  const stateMgr = useOnce(
    () =>
      new CalendarStateMgr({
        fetchApptsTE: (dayInFocus) =>
          pipe(
            TE.fromTask(() =>
              webConvexReact.query(
                api.Screens.Hp.Dashboard.CalendarScreenFns // TODO: Make for cp endpoint
                  .getMyNearbyMonthCalendarAppts,
                {
                  now: dayInFocus.getTime(),
                }
              )
            ),
            TE.chainFirst((r) =>
              TE.fromIO(() => {
                console.log("fetchApptsTE result", r);
              })
            )
          ),
      })
  );
  return (
    <FullCalendar
      stateMgr={stateMgr}
      fetchOnMount
      onApptClick={(appt) => {
        console.log("appt clicked", appt);
      }}
      onDayClick={(day) => {
        console.log(day);
      }}
      newApptButton={{
        onClick: () => {
          cpState.dashboardMgr.openRightNav({
            _tag: "NEW_APPOINTMENT",
          });
          // hpState.dashboardState.openRightNav({
          //   _tag: "NEW_APPOINTMENT",
          // });
        },
        title: "New appointment",
      }}
    />
  );
};
