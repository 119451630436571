import { Match } from "effect";

type InfoHeaderContent =
  | { _tag: "STANDARD"; title: string; rightHeader?: React.ReactNode }
  | { _tag: "CUSTOM"; content: React.ReactNode };

export const InfoHeader: React.FC<{
  content:
    | { _tag: "STANDARD"; title: string; rightHeader?: React.ReactNode }
    | { _tag: "CUSTOM"; content: React.ReactNode };
}> = ({ content }) => {
  return (
    <div
      className={`
      bg-vid-black-100 py-4 px-6
        rounded-tr-[12px] rounded-tl-[12px]
        flex justify-between items-center
    `}
    >
      {Match.value(content).pipe(
        Match.when({ _tag: "STANDARD" }, ({ title, rightHeader }) => (
          <>
            <h3 className="font-sans font-semibold text-sm text-vid-black-900">
              {title}
            </h3>
            {rightHeader}
          </>
        )),
        Match.when({ _tag: "CUSTOM" }, ({ content }) => content),
        Match.exhaustive
      )}
    </div>
  );
};

export const InfoSection: React.FC<{
  header: InfoHeaderContent;
  children: React.ReactNode;
  omitPadding?: boolean;
}> = ({ header, children, omitPadding }) => {
  return (
    <div className="flex flex-col rounded-[12px] border border-vid-black-200">
      <InfoHeader content={header} />
      <div
        className={`
        flex flex-col gap-4 
        ${omitPadding ? "" : "p-6"}
        rounded-br-[12px] rounded-bl-[12px]`}
      >
        {children}
      </div>
    </div>
  );
};
