export interface RoomRightDrawerMenuContainerProps {
  menuTitle: string;
  onBackClick?: () => void;
  onClose: () => void;
  content: React.ReactNode;
}

export const RoomRightDrawerMenuContainer: React.FC<
  RoomRightDrawerMenuContainerProps
> = ({ menuTitle, onBackClick, onClose, content }) => {
  return (
    <div className="flex-1 w-full md:w-[360px] bg-white rounded-[12px] flex flex-col overflow-y-auto">
      <div className="flex items-center px-4 py-6">
        <div className="w-8">
          {onBackClick && (
            <button
              className="w-8 h-8 flex items-center justify-center"
              onClick={onBackClick}
            >
              <img src="/arrow-left.svg" alt="Back" className="w-6 h-6" />
            </button>
          )}
        </div>
        <h4 className="flex-1 text-center text-[22px] font-sans font-normal leading-[120%] text-vid-black-900">
          {menuTitle}
        </h4>
        <button
          className="w-9 h-9 flex items-center justify-center"
          onClick={() => {
            onClose();
          }}
        >
          <img src="/close.svg" alt="Close" />
        </button>
      </div>
      <div className="flex-1 flex flex-col min-h-0 items-center px-4 overflow-y-auto pb-4">
        {content}
      </div>
    </div>
  );
};
