export const ArrowRightSvg: React.FC<{
  color?: string;
  size?: number;
}> = ({ color = "#4D4C4E", size }) => {
  return (
    <svg
      width={size ?? "24"}
      height={size ?? "24"}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
    >
      <path
        d="M8.91 19.9201L15.43 13.4001C16.2 12.6301 16.2 11.3701 15.43 10.6001L8.91 4.08008"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ArrowLeftSvg: React.FC<{
  color?: string;
  size?: number;
}> = ({ color = "#4D4C4E", size }) => {
  return (
    <svg
      width={size ?? "24"}
      height={size ?? "24"}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
    >
      <path
        d="M15.09 19.9201L8.57 13.4001C7.8 12.6301 7.8 11.3701 8.57 10.6001L15.09 4.08008"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
