import type { ConvexClient } from "convex/browser";
import { api } from "shared/convex/_generated/api";
import { MyProfileForCommunitiesST } from "shared/convex/Screens/Community/CommunityScreens.Types";
import { ConvexStateSyncMgr } from "../remote-state-sync.mgr";
import { UpcomingCommunityCalEventInstanceCards } from "shared/convex/Screens/Community/CommunityEventsScreen.Types";

export class MyCommunityProfileRSM extends ConvexStateSyncMgr<
  typeof MyProfileForCommunitiesST.Encoded,
  MyProfileForCommunitiesST
> {
  userId: string;
  snapshotQueryFunction =
    api.Community.CommunityUsersFns.getMyGenericCommunityProfile;
  snapshotQueryArgs = () => ({ baseUserId: this.userId });

  constructor(p: { userId: string; convexCli: ConvexClient }) {
    super({
      convexCli: p.convexCli,
      initialState: MyProfileForCommunitiesST.default.encoded,
      schema: MyProfileForCommunitiesST,
    });
    this.userId = p.userId;
  }
}

export class UpcomingEventsForMeRSM extends ConvexStateSyncMgr<
  typeof UpcomingCommunityCalEventInstanceCards.Encoded,
  UpcomingCommunityCalEventInstanceCards
> {
  userId: string;
  snapshotQueryFunction =
    api.Community.CommunitiesHomeScreenFns.getUpcomingEventsForMe;
  snapshotQueryArgs = () => ({ now: Date.now() });

  constructor(p: { userId: string; convexCli: ConvexClient }) {
    super({
      convexCli: p.convexCli,
      initialState: UpcomingCommunityCalEventInstanceCards.default.encoded,
      schema: UpcomingCommunityCalEventInstanceCards,
    });
    this.userId = p.userId;
  }
}
