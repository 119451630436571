import * as S from "@effect/schema/Schema";
import { v } from "convex/values";
import { Match } from "effect";

export const KnownToolTag = S.Union(
  S.Literal("EMDR"),
  S.Literal("MEDITATION"),
  S.Literal("CHAT"),
  S.Literal("NOTES"),
  S.Literal("REVIEW"),
  S.Literal("QUICK_SCHEDULE"),
  S.Literal("BREAKOUT_ROOMS")
);
export type KnownToolTag = S.Schema.Type<typeof KnownToolTag>;

export abstract class KnownTool {
  abstract readonly tag: KnownToolTag;

  abstract readonly title: string;
  abstract readonly description: string;
  abstract readonly iconName: string | undefined;

  static fromTag(tag: KnownToolTag): KnownTool {
    return Match.value(tag).pipe(
      Match.when("EMDR", () => new EmdrTool()),
      Match.when("MEDITATION", () => new MeditationTool()),
      Match.when("CHAT", () => new ChatTool()),
      Match.when("NOTES", () => new NotesTool()),
      Match.when("REVIEW", () => new ReviewTool()),
      Match.when("QUICK_SCHEDULE", () => new QuickScheduleTool()),
      Match.when("BREAKOUT_ROOMS", () => new BreakoutRoomsTool()),
      Match.exhaustive
    );
  }
}

export class EmdrTool extends KnownTool {
  readonly tag = "EMDR";
  readonly title = "Emdr";
  readonly description = "Emdr";
  readonly iconName = "emdr-balls-icon";
}

export class MeditationTool extends KnownTool {
  readonly tag = "MEDITATION";
  readonly title = "Meditation";
  readonly description = "Meditation";
  readonly iconName = "meditation-icon";
}

export class ChatTool extends KnownTool {
  readonly tag = "CHAT";
  readonly title = "Chat";
  readonly description = "Chat";
  readonly iconName = "note"; // TODO: change to chat icon
}

export class NotesTool extends KnownTool {
  readonly tag = "NOTES";
  readonly title = "Notes";
  readonly description = "Notes";
  readonly iconName = "note";
}

export class ReviewTool extends KnownTool {
  readonly tag = "REVIEW";
  readonly title = "Review";
  readonly description = "Review";
  readonly iconName = undefined;
}

export class QuickScheduleTool extends KnownTool {
  readonly tag = "QUICK_SCHEDULE";
  readonly title = "Quick Schedule";
  readonly description = "Quick Schedule";
  readonly iconName = undefined;
}

export class BreakoutRoomsTool extends KnownTool {
  readonly tag = "BREAKOUT_ROOMS";
  readonly title = "Breakout Rooms";
  readonly description = "Breakout Rooms";
  readonly iconName = undefined;
}

export const KnownQuickActionToolTag = S.Union(
  S.Literal("BOOKMARK"),
  S.Literal("BOOKEND"),
  S.Literal("NOTES"),
  S.Literal("QUICK_SCHEDULE")
);
export type KnownQuickActionToolTag = S.Schema.Type<
  typeof KnownQuickActionToolTag
>;

export class QuickActionToolsST extends S.Class<QuickActionToolsST>(
  "QuickActionToolsST"
)({
  qaTools: S.Array(KnownQuickActionToolTag),
}) {
  static convexSchema = v.array(
    v.union(
      v.literal("BOOKMARK"),
      v.literal("BOOKEND"),
      v.literal("NOTES"),
      v.literal("QUICK_SCHEDULE")
    )
  );

  static fromConvexDocData(convexData: typeof this.convexSchema.type) {
    return QuickActionToolsST.make({
      qaTools: convexData,
    });
  }

  get encoded() {
    return S.encodeUnknownSync(QuickActionToolsST)(this);
  }

  static default = () =>
    QuickActionToolsST.make({
      qaTools: [],
    });

  withAppended(tag: KnownQuickActionToolTag) {
    return QuickActionToolsST.make({
      qaTools: [...this.qaTools, tag],
    });
  }

  withRemovedItem(tag: KnownQuickActionToolTag) {
    return QuickActionToolsST.make({
      qaTools: this.qaTools.filter((t) => t !== tag),
    });
  }
}
