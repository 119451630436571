import { Link, useLocation } from "react-router-dom";

export const UNSELECTED_COLOR = "#161616";
export const SELECTED_COLOR = "#5500C2";

export interface LeftMenuLinkProps {
  to: string | null;
  hash?: string;
  name: string;
  onClick?: () => void;
  icon?: (isSelected: boolean) => React.ReactNode;
  overrideSelected?: boolean;
}

export const LeftMenuLink: React.FC<LeftMenuLinkProps> = ({
  to,
  name,
  icon,
  onClick,
  overrideSelected,
}) => {
  const location = useLocation();
  const isSelected =
    overrideSelected ?? location.pathname + location.hash === to;
  return (
    <Link
      to={to ?? "#"}
      className="px-5 py-4 flex gap-4 items-center rounded-[10px]"
      onClick={onClick}
      style={{
        backgroundColor: isSelected ? "#F8F8FF" : "white",
      }}
    >
      {icon && <div className="h-6 w-6">{icon(isSelected)}</div>}
      <div
        style={{
          color: isSelected ? SELECTED_COLOR : UNSELECTED_COLOR,
          fontWeight: 500,
        }}
      >
        {name}
      </div>
    </Link>
  );
};

export const LogoutLeftMenuLink: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  return (
    <LeftMenuLink
      to=""
      icon={(isSelected) => <LogoutIcon isSelected={isSelected} />}
      onClick={onClick}
      name="Logout"
    />
  );
};

export const LogoutIcon: React.FC<{ isSelected: boolean }> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M8.90002 7.56023C9.21002 3.96023 11.06 2.49023 15.11 2.49023H15.24C19.71 2.49023 21.5 4.28023 21.5 8.75023V15.2702C21.5 19.7402 19.71 21.5302 15.24 21.5302H15.11C11.09 21.5302 9.24002 20.0802 8.91002 16.5402"
        stroke="#1D1626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 12H3.62"
        stroke="#1D1626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.85 8.6499L2.5 11.9999L5.85 15.3499"
        stroke="#1D1626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const InvoicesIcon: React.FC<{ isSelected: boolean }> = ({
  isSelected,
}) => {
  const fill = isSelected ? SELECTED_COLOR : UNSELECTED_COLOR;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M16.767 1.488c-.7.002-1.267.568-1.267 1.256v6.218h2.67c.63 0 1.07-.195 1.352-.476.282-.28.478-.72.478-1.347V4.727c0-.887-.363-1.698-.952-2.294a3.276 3.276 0 00-2.281-.945zM14 2.744c0-1.524 1.25-2.75 2.77-2.75h.007c1.29.011 2.476.53 3.333 1.384l.004.004A4.75 4.75 0 0121.5 4.727v2.412c0 .947-.304 1.793-.917 2.404s-1.462.914-2.413.914h-3.42A.749.749 0 0114 9.71V2.744z"
        clipRule="evenodd"
      ></path>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M1.057 1.42C1.862.49 3.097-.008 4.75-.008h12a.749.749 0 110 1.495c-.686 0-1.25.562-1.25 1.246v16.941c0 1.437-1.646 2.25-2.797 1.398l-.002-.002-1.718-1.281a.242.242 0 00-.313.025L8.99 21.49c-.683.68-1.797.68-2.48 0l-.002-.002-1.658-1.663a.259.259 0 00-.34-.03l-1.707 1.273-.002.002C1.643 21.942 0 21.114 0 19.675V4.727c0-1.199.27-2.4 1.057-3.308zm13.245.068H4.75c-1.346 0-2.112.395-2.557.908-.463.535-.693 1.327-.693 2.33v14.95c0 .215.235.323.397.201l.004-.003L3.61 18.6a1.762 1.762 0 012.3.17h.002l1.658 1.664a.26.26 0 00.36 0l1.68-1.675a1.746 1.746 0 012.283-.157l1.704 1.272c.17.125.403 0 .403-.198V2.735c0-.448.109-.872.302-1.246z"
        clipRule="evenodd"
      ></path>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M7 11.713c0-.413.336-.748.75-.748h3a.749.749 0 110 1.495h-3a.749.749 0 01-.75-.747zM7 7.726c0-.412.336-.747.75-.747h3c.414 0 .75.335.75.747a.749.749 0 01-.75.748h-3A.749.749 0 017 7.726zM3.746 11.703c0-.55.447-.996 1-.996h.009c.552 0 1 .446 1 .996s-.448.997-1 .997h-.01c-.552 0-1-.446-1-.997zM3.746 7.717c0-.55.447-.997 1-.997h.009c.552 0 1 .446 1 .997 0 .55-.448.996-1 .996h-.01c-.552 0-1-.446-1-.996z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const ClientsIcon: React.FC<{ isSelected: boolean }> = ({
  isSelected,
}) => {
  const stroke = isSelected ? SELECTED_COLOR : UNSELECTED_COLOR;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9.16 10.87c-.1-.01-.22-.01-.33 0a4.42 4.42 0 01-4.27-4.43C4.56 3.99 6.54 2 9 2a4.435 4.435 0 01.16 8.87zM16.41 4c1.94 0 3.5 1.57 3.5 3.5 0 1.89-1.5 3.43-3.37 3.5a1.13 1.13 0 00-.26 0M4.16 14.56c-2.42 1.62-2.42 4.26 0 5.87 2.75 1.84 7.26 1.84 10.01 0 2.42-1.62 2.42-4.26 0-5.87-2.74-1.83-7.25-1.83-10.01 0zM18.34 20c.72-.15 1.4-.44 1.96-.87 1.56-1.17 1.56-3.1 0-4.27-.55-.42-1.22-.7-1.93-.86"
      ></path>
    </svg>
  );
};

export const CommunityIcon: React.FC<{ isSelected: boolean }> = ({
  isSelected,
}) => {
  const fill = isSelected ? SELECTED_COLOR : UNSELECTED_COLOR;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18 7.16C17.94 7.15 17.87 7.15 17.81 7.16C16.43 7.11 15.33 5.98 15.33 4.58C15.33 3.15 16.48 2 17.91 2C19.34 2 20.49 3.16 20.49 4.58C20.48 5.98 19.38 7.11 18 7.16Z"
      />
      <path
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.9703 14.4402C18.3403 14.6702 19.8503 14.4302 20.9103 13.7202C22.3203 12.7802 22.3203 11.2402 20.9103 10.3002C19.8403 9.59016 18.3103 9.35016 16.9403 9.59016"
      />
      <path
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.97047 7.16C6.03047 7.15 6.10047 7.15 6.16047 7.16C7.54047 7.11 8.64047 5.98 8.64047 4.58C8.64047 3.15 7.49047 2 6.06047 2C4.63047 2 3.48047 3.16 3.48047 4.58C3.49047 5.98 4.59047 7.11 5.97047 7.16Z"
      />
      <path
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.00043 14.44C5.63043 14.67 4.12043 14.43 3.06043 13.72C1.65043 12.78 1.65043 11.24 3.06043 10.3C4.13043 9.59004 5.66043 9.35003 7.03043 9.59003"
      />
      <path
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.0001 14.6297C11.9401 14.6197 11.8701 14.6197 11.8101 14.6297C10.4301 14.5797 9.33008 13.4497 9.33008 12.0497C9.33008 10.6197 10.4801 9.46973 11.9101 9.46973C13.3401 9.46973 14.4901 10.6297 14.4901 12.0497C14.4801 13.4497 13.3801 14.5897 12.0001 14.6297Z"
      />
      <path
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.08973 17.7797C7.67973 18.7197 7.67973 20.2597 9.08973 21.1997C10.6897 22.2697 13.3097 22.2697 14.9097 21.1997C16.3197 20.2597 16.3197 18.7197 14.9097 17.7797C13.3197 16.7197 10.6897 16.7197 9.08973 17.7797Z"
      />
    </svg>
  );
};

export const BillingIcon: React.FC<{ isSelected: boolean }> = ({
  isSelected,
}) => {
  const fill = isSelected ? SELECTED_COLOR : UNSELECTED_COLOR;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="19"
      fill="none"
      viewBox="0 0 22 19"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M0 5.75A.75.75 0 01.75 5h20a.75.75 0 010 1.5h-20A.75.75 0 010 5.75zM4 13.75a.75.75 0 01.75-.75h2a.75.75 0 010 1.5h-2a.75.75 0 01-.75-.75zM8.5 13.75a.75.75 0 01.75-.75h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75z"
        clipRule="evenodd"
      ></path>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M2.166 2.162c-.425.42-.666 1.22-.666 2.978v8.22c0 1.76.241 2.557.666 2.976.427.422 1.24.659 3.022.654H16.31c1.781 0 2.596-.239 3.024-.662.425-.42.666-1.22.666-2.978V5.14c0-1.759-.241-2.557-.667-2.977-.43-.424-1.247-.663-3.033-.663H5.19c-1.781 0-2.596.239-3.024.662zM1.111 1.095C2.016.201 3.421 0 5.19 0H16.3c1.774 0 3.181.201 4.087 1.095.91.897 1.113 2.294 1.113 4.045v8.21c0 1.751-.204 3.147-1.111 4.045-.905.894-2.31 1.095-4.079 1.095H5.19c-1.768.005-3.173-.193-4.078-1.086C.204 16.508 0 15.11 0 13.36V5.14c0-1.751.204-3.147 1.111-4.045z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const SettingsIcon: React.FC<{ isSelected: boolean }> = ({
  isSelected,
}) => {
  const stroke = isSelected ? SELECTED_COLOR : UNSELECTED_COLOR;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M12 15a3 3 0 100-6 3 3 0 000 6z"
      ></path>
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M2 12.88v-1.76c0-1.04.85-1.9 1.9-1.9 1.81 0 2.55-1.28 1.64-2.85-.52-.9-.21-2.07.7-2.59l1.73-.99c.79-.47 1.81-.19 2.28.6l.11.19c.9 1.57 2.38 1.57 3.29 0l.11-.19c.47-.79 1.49-1.07 2.28-.6l1.73.99c.91.52 1.22 1.69.7 2.59-.91 1.57-.17 2.85 1.64 2.85 1.04 0 1.9.85 1.9 1.9v1.76c0 1.04-.85 1.9-1.9 1.9-1.81 0-2.55 1.28-1.64 2.85.52.91.21 2.07-.7 2.59l-1.73.99c-.79.47-1.81.19-2.28-.6l-.11-.19c-.9-1.57-2.38-1.57-3.29 0l-.11.19c-.47.79-1.49 1.07-2.28.6l-1.73-.99a1.899 1.899 0 01-.7-2.59c.91-1.57.17-2.85-1.64-2.85-1.05 0-1.9-.86-1.9-1.9z"
      ></path>
    </svg>
  );
};

export const CalendarIcon: React.FC<{ isSelected: boolean }> = ({
  isSelected,
}) => {
  const color = isSelected ? SELECTED_COLOR : UNSELECTED_COLOR;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 2V5"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 2V5"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 22H9C4 22 3 19.94 3 15.82V9.65C3 4.95 4.67 3.69 8 3.5H16C19.33 3.68 21 4.95 21 9.65V16"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 16L15 22V19C15 17 16 16 18 16H21Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 10c0-.552.448-1 1-1h.009c.552 0 1 .448 1 1s-.448 1-1 1H9c-.552 0-1-.448-1-1Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 10c0-.552.448-1 1-1h.009c.552 0 1 .448 1 1s-.448 1-1 1H12c-.552 0-1-.448-1-1Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 10c0-.552.448-1 1-1h.009c.552 0 1 .448 1 1s-.448 1-1 1H15c-.552 0-1-.448-1-1Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 13c0-.552.448-1 1-1h.009c.552 0 1 .448 1 1s-.448 1-1 1H9c-.552 0-1-.448-1-1Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 13c0-.552.448-1 1-1h.009c.552 0 1 .448 1 1s-.448 1-1 1H12c-.552 0-1-.448-1-1Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 13c0-.552.448-1 1-1h.009c.552 0 1 .448 1 1s-.448 1-1 1H15c-.552 0-1-.448-1-1Z"
        fill={color}
      />
    </svg>
  );
};
