import { useQuery } from "convex/react";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";

import { InvoiceDetailView } from "src/domains/invoices/invoice-detail-view";

export const InvoicesTab: React.FC<{
  sessionId: Id<"sessionConfig">;
}> = ({ sessionId }) => {
  const invoice = useQuery(api.Screens.U.Sessions.ReviewScreenFns.getInvoice, {
    baseSessionId: sessionId,
  });

  if (invoice === undefined) {
    return null;
  }

  return <InvoiceDetailView invoice={invoice} />;
};
