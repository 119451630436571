import React from "react";
import { FormFieldContainer } from "./form-field.container";

export interface TextAreaInputProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
  labelClassName?: string;
  rows?: number;
}

export const TextAreaInput = React.forwardRef<
  HTMLTextAreaElement,
  TextAreaInputProps
>(
  (
    { label, error, className = "", labelClassName = "", rows = 3, ...props },
    ref
  ) => {
    return (
      <FormFieldContainer
        label={label}
        error={error}
        labelClassName={labelClassName}
        showDownArrow={false}
      >
        <textarea
          ref={ref}
          rows={rows}
          className={`
            w-full rounded-xl bg-white
            outline-none transition-colors
            font-roboto-flex text-vid-black-900
            placeholder:text-vid-black-500
            resize-none
            ${error ? "border-red-500" : ""}
            ${className}
          `}
          {...props}
        />
      </FormFieldContainer>
    );
  }
);
