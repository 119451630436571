import { api } from "shared/convex/_generated/api";
import {
  MeditationStateSchema,
  type MeditationStateData,
  type MeditationVisual,
} from "shared/schemas/session/state/meditation-state.schemas";
import { SharedToolSettings } from "shared/session-state/tools/shared-tool-settings";
import {
  ConvexSessionStateSyncMgr,
  RemoteStateSyncMgr,
} from "../remote-state-sync.mgr";

export type KnownEmdrBallColors = "PURPLE" | "RED" | "GREEN" | "BLUE";
type EmdrState = SharedToolSettings.Emdr.State;

abstract class EmdrSessionSyncMgr extends RemoteStateSyncMgr<
  SharedToolSettings.Emdr.State,
  SharedToolSettings.Emdr.State
> {
  abstract setBallFrequency(ballFrequency: number): void;

  abstract setBallColor(ballColor: KnownEmdrBallColors): void;

  abstract setPlayState(playState: SharedToolSettings.Emdr.PlayState): void;

  abstract subscribeSync(): void;
}

export class EmdrRSM
  extends ConvexSessionStateSyncMgr<
    typeof SharedToolSettings.Emdr.State.Encoded,
    EmdrState
  >
  implements EmdrSessionSyncMgr
{
  snapshotQueryFunction = api.EmdrStateFns.getEmdrSettings;
  snapshotQueryArgs = () => ({ baseSessionId: this.baseSessionId });
  schema = SharedToolSettings.Emdr.State;

  setBallFrequency(ballFrequency: number): void {
    this.convex
      .mutation(api.EmdrStateFns.setBallFrequency, {
        baseSessionId: this.baseSessionId,
        ballFrequency,
      })
      .catch((e) => {
        console.error("Error setting ball frequency", e);
      });
  }

  setPlayState(playState: SharedToolSettings.Emdr.PlayState): void {
    this.convex
      .mutation(api.EmdrStateFns.setPlayState, {
        baseSessionId: this.baseSessionId,
        playState,
      })
      .catch((e) => {
        console.error("Error setting play state", e);
      });
  }

  setBallColor(ballColor: KnownEmdrBallColors): void {
    this.convex
      .mutation(api.EmdrStateFns.setBallColor, {
        baseSessionId: this.baseSessionId,
        ballColor,
      })
      .catch((e) => {
        console.error("Error setting ball color", e);
      });
  }
}

export class MeditationSessionRSM extends ConvexSessionStateSyncMgr<
  typeof MeditationStateSchema.Encoded,
  MeditationStateSchema
> {
  snapshotQueryFunction =
    api.Sessions.MeditationStateFns.getAssumedMeditationState;
  snapshotQueryArgs = () => ({ baseSessionId: this.baseSessionId });
  schema = MeditationStateSchema;

  setVisual(visual: MeditationVisual): void {
    this.convex
      .mutation(api.Sessions.MeditationStateFns.setVisual, {
        baseSessionId: this.baseSessionId,
        visual,
      })
      .catch((e) => {
        console.error("Error setting visual", e);
      });
  }

  setTimerMinutes(timerMinutes: number | null): void {
    this.convex
      .mutation(api.Sessions.MeditationStateFns.setTimerSettingMinutes, {
        baseSessionId: this.baseSessionId,
        timerMinutes,
      })
      .catch((e) => {
        console.error("Error setting timer minutes", e);
      });
  }

  setStartTimerSeconds(startTimerSeconds: number | null): void {
    this.convex
      .mutation(api.Sessions.MeditationStateFns.setStartTimerSeconds, {
        baseSessionId: this.baseSessionId,
        startTimerSeconds,
      })
      .catch((e) => {
        console.error("Error setting start timer seconds", e);
      });
  }

  setPlayState(playState: MeditationStateData["playState"]): void {
    console.log("setPlayState!", playState);
    this.convex
      .mutation(api.Sessions.MeditationStateFns.setPlayState, {
        baseSessionId: this.baseSessionId,
        playState,
        autoShowForAllParticipants: true,
      })
      .catch((e) => {
        console.error("Error setting play state", e);
      });
  }
}
