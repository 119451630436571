import type { Rx } from "@webapp/prelude";
import { useKeyOfObservableAsState } from "frontend-shared/src/util";
import { useEffect, useRef, useState } from "react";
import type { SharedToolSettings } from "shared/session-state/tools/shared-tool-settings";
import meditationBeep from "frontend-shared/src/resources/meditation.mp3";
import { useResizeObserver } from "usehooks-ts";
import { getColor, type ColorKey } from "shared/utils/color.utils";

interface EMDRComponentProps {
  state$: Rx.Observable<SharedToolSettings.Emdr.State>;
  soundType: "beep" | "click" | "white-noise";
  soundDuration?: number;
  onPass?: () => void;
}

export const EMDRBallStageViewCode: React.FC<EMDRComponentProps> = (props) => {
  const [passCount, setPassCount] = useState(0);

  return (
    <div className="w-full h-full overflow-hidden flex items-center justify-center relative rounded-[12px] border border-vid-black-800">
      <DotBackground
        className="absolute inset-0 z-0"
        dotSpacing={30}
        dotSize={2}
        dotColor="rgba(107, 107, 128, 0.2)"
        backgroundColor="#1a1625"
      />
      <div className="absolute top-4 left-4 z-20 text-white/80 font-medium">
        Passes: {passCount}
      </div>
      <div className="relative z-10 w-full h-full flex items-center justify-center">
        <EMDRBallVideoCode
          {...props}
          onPass={() => setPassCount((c) => c + 1)}
        />
      </div>
    </div>
  );
};

export const EMDRBallVideoCode: React.FC<EMDRComponentProps> = ({
  state$,
  soundType,
  soundDuration = 0.07,
  onPass,
}) => {
  const frequency = useKeyOfObservableAsState(state$, "ballFrequency", 3);
  const playState = useKeyOfObservableAsState(state$, "playState", "off");
  const color = useKeyOfObservableAsState(state$, "ballColor", "purple");
  const isPlaying = playState === "playing";

  const [position, setPosition] = useState(0);
  const [direction, setDirection] = useState(1);
  const audioContext = useRef<AudioContext | null>(null);
  const audioBuffer = useRef<AudioBuffer | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);
  const size = useResizeObserver({ ref: containerRef });

  const config = {
    ballSize: 24,
    containerWidth: size.width || 600,
    transitionDuration: 4000 / frequency,
  };

  useEffect(() => {
    audioContext.current = new (window.AudioContext ||
      (window as any).webkitAudioContext)();
    loadAudio();

    return () => {
      if (audioContext.current) {
        audioContext.current.close();
      }
    };
  }, []);

  useEffect(() => {
    loadAudio();
  }, [soundType]);

  useEffect(() => {
    if (!isPlaying) return;

    const frameTime = 1000 / 60;
    const distancePerFrame =
      (config.containerWidth / (4000 / frameTime)) * frequency;

    const interval = setInterval(() => {
      setPosition((prev) => {
        const range = config.containerWidth - config.ballSize;
        const newPos = prev + distancePerFrame * direction;

        if (newPos >= range) {
          setDirection(-1);
          playSound();
          onPass?.();
          return range;
        }
        if (newPos <= 0) {
          setDirection(1);
          playSound();
          onPass?.();
          return 0;
        }

        return newPos;
      });
    }, frameTime);

    return () => clearInterval(interval);
  }, [isPlaying, frequency, direction, config.containerWidth, onPass]);

  const loadAudio = async () => {
    if (!audioContext.current) return;

    try {
      const response = await fetch(meditationBeep);
      const arrayBuffer = await response.arrayBuffer();
      audioBuffer.current =
        await audioContext.current.decodeAudioData(arrayBuffer);
      console.log("AUDIO BUFFER! ", audioBuffer.current);
    } catch (error) {
      console.error("Failed to load audio:", error);
    }
  };

  const playSound = () => {
    console.log("playSound! ");
    if (!audioContext.current || !audioBuffer.current) return;

    const source = audioContext.current.createBufferSource();
    source.buffer = audioBuffer.current;

    const gainNode = audioContext.current.createGain();
    source.connect(gainNode);
    gainNode.connect(audioContext.current.destination);

    const startOffset = 0.4;
    gainNode.gain.setValueAtTime(5, audioContext.current.currentTime);
    gainNode.gain.linearRampToValueAtTime(
      0,
      audioContext.current.currentTime + soundDuration
    );

    source.start(0, startOffset);
    source.stop(audioContext.current.currentTime + soundDuration);
  };

  return (
    <div
      className="w-full bg-vid-black-900 rounded-lg relative box-border"
      ref={containerRef}
    >
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="relative h-16" style={{ width: config.containerWidth }}>
          <div
            className="absolute top-1/2 rounded-full"
            style={{
              width: config.ballSize,
              height: config.ballSize,
              left: position,
              transform: "translateY(-50%)",
              transition: isPlaying
                ? "none"
                : `all ${config.transitionDuration}ms ease-in-out`,
              backgroundColor: getColor(color as ColorKey),
            }}
          />
        </div>
      </div>
    </div>
  );
};

const DotBackground = ({
  dotSpacing = 20,
  dotSize = 1,
  dotColor = "rgba(107, 107, 128, 0.2)",
  backgroundColor = "#1a1625",
  className = "",
}) => {
  return (
    <div className={`w-full h-full ${className}`}>
      <svg
        className="w-full h-full"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid slice"
      >
        <rect width="100%" height="100%" fill={backgroundColor} />
        <defs>
          <pattern
            id="dotPattern"
            x="0"
            y="0"
            width={dotSpacing}
            height={dotSpacing}
            patternUnits="userSpaceOnUse"
          >
            <circle
              cx={dotSpacing / 2}
              cy={dotSpacing / 2}
              r={dotSize}
              fill={dotColor}
            />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#dotPattern)" />
      </svg>
    </div>
  );
};
