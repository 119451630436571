import { RadioGroup } from "@headlessui/react";
import { FormComponents } from "@webapp/components/primitives/form.primitives";
import { AvatarCircle } from "@webapp/componentsavatar.tc";
import { InversePrimaryButton } from "@webapp/componentsprimitives/button";
import {
  SimpleTable,
  type SimpleColumn,
  type SimpleTableProps,
} from "@webapp/componentstable.components";
import { Rx } from "@webapp/prelude";
import { format } from "date-fns";
import { useObservableEagerState } from "observable-hooks";
import React from "react";
import { Link } from "react-router-dom";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { SimpleClient } from "../right-drawer-action/right-nav";
import type { PastHcApptsRowItemETO } from "shared/convex/Screens/Hp/Dashboard/ClientsScreen.Types";
import type { Id } from "shared/convex/_generated/dataModel";
import { ArrowRightSvg } from "@/src/assets/icons/arrow.icons";

export namespace ClientsPageComponents {
  export type NewSessionConfig = {
    client: SimpleClient | null;
    durationInMinutes: number | null;
    minutesBeforeEndReminder: number | null;
    sendInviteEmail: boolean;
    audioOnly: boolean;
  };
  export const DurationRadioGroup: React.FC<{
    sessionConfig$: Rx.BehaviorSubject<NewSessionConfig>;
  }> = ({ sessionConfig$ }) => {
    const sessionConfig = useObservableEagerState(sessionConfig$);

    return (
      <RadioGroup
        value={sessionConfig.durationInMinutes}
        onChange={(v) => {
          sessionConfig$.next({ ...sessionConfig, durationInMinutes: v });
        }}
        className={`flex flex-col gap-4`}
      >
        <RadioGroup.Label className="flex flex-col">
          <div className={`font-bold text-lg`}>Duration</div>
          <div className="italic">
            This will only be used to assist in sending notifications. It will
            not close the session.
          </div>
        </RadioGroup.Label>
        <div className="flex gap-4">
          <RadioGroup.Option value={90}>
            {({ checked }) => (
              <FormComponents.RadioBtn checked={checked} text="90 mins" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={60}>
            {({ checked }) => (
              <FormComponents.RadioBtn checked={checked} text="60 mins" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={30}>
            {({ checked }) => (
              <FormComponents.RadioBtn checked={checked} text="30 mins" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={10}>
            {({ checked }) => (
              <FormComponents.RadioBtn checked={checked} text="10 mins" />
            )}
          </RadioGroup.Option>
        </div>
      </RadioGroup>
    );
  };

  export const SetTimeWarningRadioGroup: React.FC<{
    sessionConfig$: Rx.BehaviorSubject<NewSessionConfig>;
  }> = ({ sessionConfig$ }) => {
    const sessionConfig = useObservableEagerState(sessionConfig$);

    return (
      <RadioGroup
        value={sessionConfig.minutesBeforeEndReminder}
        onChange={(v) => {
          sessionConfig$.next({
            ...sessionConfig,
            minutesBeforeEndReminder: v,
          });
        }}
        className={`flex flex-col gap-4`}
      >
        <RadioGroup.Label className="flex flex-col">
          <div className={`font-bold text-lg`}>Ending session reminder</div>
        </RadioGroup.Label>
        <div className="flex gap-4">
          <RadioGroup.Option value={5}>
            {({ checked }) => (
              <FormComponents.RadioBtn checked={checked} text="5 mins" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={10}>
            {({ checked }) => (
              <FormComponents.RadioBtn checked={checked} text="10 mins" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={15}>
            {({ checked }) => (
              <FormComponents.RadioBtn checked={checked} text="15 mins" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={20}>
            {({ checked }) => (
              <FormComponents.RadioBtn checked={checked} text="20 mins" />
            )}
          </RadioGroup.Option>
        </div>
      </RadioGroup>
    );
  };
}

interface BaseClientRowItemData {
  clientInfo: { name: string; profilePhoto: string | null };
}

const ClientColumnView: React.FC<{
  clientInfo: { name: string; profilePhoto: string | null };
  extra?: React.ReactNode;
}> = ({ clientInfo, extra }) => {
  return (
    <div className="flex items-center gap-4">
      <AvatarCircle
        size={72}
        mbProfilePhoto={ImageSrc.fromMbUrl(clientInfo.profilePhoto)}
      />
      <div className="flex flex-col gap-1">
        <h5 className="text-vid-black-900">{clientInfo.name}</h5>
        {extra && <div className="flex-1">{extra}</div>}
      </div>
    </div>
  );
};

interface ActiveClientsRowItemData extends BaseClientRowItemData {
  startSession: {
    onClick: () => void;
  };
  clientId: Id<"users">;
  linkTo?: string;
}
const activeClientsColumns: SimpleColumn<ActiveClientsRowItemData>[] = [
  {
    key: "client",
    header: "Client",
    render: (item) => (
      <ClientColumnView
        clientInfo={item.clientInfo}
        extra={item.linkTo && <Link to={item.linkTo}>View</Link>}
      />
    ),
  },
  {
    key: "startSession",
    header: "Start session",
    render: (item) => (
      <InversePrimaryButton
        title="Start session"
        onClick={item.startSession.onClick}
      />
    ),
  },
];

export const activeClientsTableProps = (
  onClick: (v: ActiveClientsRowItemData) => void,
  rows: ActiveClientsRowItemData[]
): SimpleTableProps<ActiveClientsRowItemData> => ({
  columns: activeClientsColumns,
  data: rows,
  action: {
    icon: <RightArrowNextActionView />,
    onClick,
  },
});

interface PendingClientsRowItemData extends BaseClientRowItemData {
  cta: {
    title: string;
    onClick: () => void;
  };
}
const pendingClientsColumns: SimpleColumn<PendingClientsRowItemData>[] = [
  {
    key: "pending",
    header: "Pending",
    render: (item) => <ClientColumnView clientInfo={item.clientInfo} />,
  },
  {
    key: "cta",
    header: "Resend invite",
    render: (item) => (
      <InversePrimaryButton title={item.cta.title} onClick={item.cta.onClick} />
    ),
  },
];

export const pendingClientsTableProps = (
  rows: PendingClientsRowItemData[]
): SimpleTableProps<PendingClientsRowItemData> => ({
  columns: pendingClientsColumns,
  data: rows,
});

export const PendingClientsTable: React.FC<{
  roundedTop?: boolean;
  rows: PendingClientsRowItemData[];
}> = ({ roundedTop, rows }) => {
  return (
    <SimpleTable
      roundedTop={roundedTop}
      columns={pendingClientsColumns}
      data={rows}
    />
  );
};

interface UpcomingHcApptsRowItemData extends BaseClientRowItemData {
  appt: { date: Date };
  sessionType?: string | null;
  clientId: Id<"users">;
}
const upcomingHcApptsColumns: SimpleColumn<UpcomingHcApptsRowItemData>[] = [
  {
    key: "upcoming",
    header: "Upcoming",
    render: (item) => (
      <ClientColumnView
        clientInfo={item.clientInfo}
        extra={
          <ScheduledTimePill
            appt={item.appt}
            title={format(item.appt.date, "MMM d | h:mm a")}
          />
        }
      />
    ),
  },
  {
    key: "sessionType",
    header: "Session type",
    render: (item) => (
      <PillContainer>
        <span className="text-xs font-sans font-medium">
          {item.sessionType ?? "Emdr | Hakomi | ..."}
        </span>
      </PillContainer>
    ),
  },
];

export const upcomingHcApptsTableProps = (
  rows: UpcomingHcApptsRowItemData[]
): SimpleTableProps<UpcomingHcApptsRowItemData> => ({
  columns: upcomingHcApptsColumns,
  data: rows,
  action: {
    icon: <RightArrowNextActionView />,
    onClick: (v) => {
      console.log("NAVIGATING TO CLIENT! ", v.clientId);
    },
  },
});

interface OngoingHcApptsRowItemData extends BaseClientRowItemData {
  rejoinCta: { onClick: () => void };
  endSessionCta: { onClick: () => void };
}
const ongoingHcApptsColumns: SimpleColumn<OngoingHcApptsRowItemData>[] = [
  {
    key: "ongoing",
    header: "Ongoing",
    render: (item) => (
      <div className="flex items-center gap-[22px]">
        <AvatarCircle size={72} mbProfilePhoto={null} />
        <div className="flex flex-col gap-1">
          <h5 className="text-vid-black-900">{item.clientInfo.name}</h5>
        </div>
      </div>
    ),
  },
  {
    key: "rejoinCta",
    header: "Rejoin",
    render: (item) => (
      <InversePrimaryButton title="Rejoin" onClick={item.rejoinCta.onClick} />
    ),
  },
  {
    key: "endSessionCta",
    header: "End session",
    render: (item) => (
      <InversePrimaryButton
        title="End session"
        onClick={item.endSessionCta.onClick}
        color="red"
      />
    ),
  },
];

export const ongoingHcApptsTableProps = (
  rows: OngoingHcApptsRowItemData[]
): SimpleTableProps<OngoingHcApptsRowItemData> => ({
  columns: ongoingHcApptsColumns,
  data: rows,
});

export const OngoingHcApptsTable: React.FC<{
  rows: OngoingHcApptsRowItemData[];
}> = ({ rows }) => {
  return <SimpleTable columns={ongoingHcApptsColumns} data={rows} />;
};

const pastHcApptsColumns: SimpleColumn<PastHcApptsRowItemETO>[] = [
  {
    key: "past",
    header: "Past",
    render: (item) => (
      <ClientColumnView
        clientInfo={{
          name: item.clientInfo.name,
          profilePhoto: item.clientInfo.profilePhoto?.medium ?? null,
        }}
      />
    ),
  },
];

export const pastHcApptsTableProps = (
  rows: PastHcApptsRowItemETO[]
): SimpleTableProps<PastHcApptsRowItemETO> => ({
  columns: pastHcApptsColumns,
  data: rows,
});

export const ScheduledTimePill: React.FC<{
  title: string;
  appt?: { date: Date };
}> = ({ title, appt }) => {
  return (
    <PillContainer>
      <img src="/schedule.svg" width={16} height={16} />
      <span className="uppercase text-xs font-sans font-semibold ml-2">
        {appt?.date ? format(appt?.date, "MMM d | h:mm a") : title}
      </span>
    </PillContainer>
  );
};

export const PillContainer: React.FC<{
  children: React.ReactNode;
  onClick?: () => void;
}> = ({ children, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`bg-vid-black-100 hover:cursor-pointer rounded-full w-fit px-[22px] py-3 flex items-center justify-between
    `}
    >
      {children}
    </div>
  );
};

const RightArrowNextActionView: React.FC = () => {
  return (
    <div className="hover:cursor-pointer w-[60px] aspect-square flex items-center justify-center rounded-full border border-vid-black-200 text-vid-black-900 hover:text-blue-500">
      <ArrowRightSvg />
    </div>
  );
};
