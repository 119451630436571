import { Link, useLocation } from "react-router-dom";

interface TabViewProps {
  title: string;
  linkTo: string;
  onClick?: () => void;
}

export const TabView: React.FC<TabViewProps> = ({ title, linkTo, onClick }) => {
  const location = useLocation();
  const isCurrent = location.pathname.includes(linkTo);

  return (
    <Link
      to={linkTo}
      className={`
        text-[24px] font-normal leading-[28.80px] pb-[5px] cursor-pointer
        ${isCurrent ? "text-vid-purple border-b-2 border-vid-purple" : "text-vid-black-900"}
      `}
      onClick={onClick ?? (() => {})}
    >
      {title}
    </Link>
  );
};

export const HorizontalTabs: React.FC<{ tabs: TabViewProps[] }> = ({
  tabs,
}) => {
  return (
    <div className="flex flex-col lg:flex-row gap-9">
      {tabs.map((tab) => (
        <TabView key={tab.linkTo} {...tab} />
      ))}
    </div>
  );
};
