// calendar/types/calendar.types.ts
import * as S from "@effect/schema/Schema";
import { v } from "convex/values";
import { format } from "date-fns";
import { Match, Option } from "effect";
import { capitalizeFirstLetter } from "frontend-shared/src/util";
import {
  type MonthOfYear,
  type NearbyCalendarMonths,
} from "../../types/calendar.types";
import { SimpleUserWithProfilePhoto } from "../../types/user.types";
import { CalendarUtils, RRuleUtils } from "../../utils/calendar.utils";
import { type Doc, type Id } from "../_generated/dataModel";

export type EventType =
  | "PRIVATE_SESSION"
  | "GROUP_SESSION"
  | "COMMUNITY_EVENT"
  | "OTHER";
export type EventVisibility = "PRIVATE" | "PUBLIC" | "UNLISTED";
const ParticipantRole = S.Union(
  S.Literal("ORGANIZER"),
  S.Literal("CO_ORGANIZER"),
  S.Literal("PARTICIPANT")
);
export type ParticipantRole = typeof ParticipantRole.Type;

const ParticipantStatus = S.Union(
  S.Literal("INVITED"),
  S.Literal("ACCEPTED"),
  S.Literal("DECLINED"),
  S.Literal("REMOVED")
);
export type ParticipantStatus = typeof ParticipantStatus.Type;

const MyInstanceAttendanceStatus = S.Union(
  S.Literal("ATTENDING"),
  S.Literal("DECLINED"),
  S.Literal("MAYBE"),
  S.Literal("CANCELLED")
);
export type MyInstanceAttendanceStatus = typeof MyInstanceAttendanceStatus.Type;

export interface ProjectedEventInstance {
  id: string;
  templateId: Id<"calendarEventTemplates">;
  title: string;
  description: string | null;
  startTime: number;
  endTime: number;
  organizerId: string;
  visibility: EventVisibility;
  eventType: EventType;
  info: Record<string, any> | null;
  status: "ACTIVE" | "CANCELLED" | "MODIFIED";
  //   exception?: Doc<"calen">;
  participants: Array<{
    userId: string;
    role: ParticipantRole;
    status: ParticipantStatus | MyInstanceAttendanceStatus;
    // exception?: Doc<"calendarEventParticipantExceptions">;
  }>;
}

export interface EventFilters {
  eventTypes?: EventType[];
  roles?: ParticipantRole[];
  visibility?: EventVisibility[];
}

export interface HcCalInstanceSchema {
  template: {
    _id: Id<"calendarEventTemplates">;
    title: string;
  };
  hp: SimpleUserWithProfilePhoto;
  client: SimpleUserWithProfilePhoto;
  instance: {
    startAt: number;
    endsAt: number;
    generatedId: string;
  };
}

const BaseTemplateInfo = S.Struct({
  _id: S.String,
  title: S.String,
  description: S.NullOr(S.String),
  rrule: S.NullOr(S.String),
});

const BaseCommunityInfo = S.Struct({
  _id: S.String,
  slug: S.String,
  name: S.String,
});

export class CalEventInstanceST extends S.Class<CalEventInstanceST>(
  "CalEventInstanceST"
)({
  template: BaseTemplateInfo,
  participants: S.Array(
    S.Struct({
      _userId: S.String,
      user: S.Struct({
        id: S.String,
        firstName: S.String,
        lastName: S.String,
        name: S.String,
        email: S.String,
        profilePhoto: S.NullOr(S.String),
      }),
      role: ParticipantRole,
      attendanceStatus: S.NullOr(MyInstanceAttendanceStatus),
    })
  ),
  instance: S.Struct({
    _id: S.String,
    startTime: S.Number,
    endTime: S.Number,
    sessionId: S.NullOr(S.String),
  }),
}) {
  static from(data: typeof CalEventInstanceST.Encoded): CalEventInstanceST {
    const participants = data.participants.map((p) => ({
      ...p,
    }));
    return CalEventInstanceST.make(
      { ...data, participants },
      { disableValidation: true }
    );
  }

  get encoded(): typeof CalEventInstanceST.Encoded {
    return S.encodeUnknownSync(CalEventInstanceST)(this);
  }

  get startTime(): number {
    return this.instance.startTime;
  }

  get templateId(): Id<"calendarEventTemplates"> {
    return this.template._id as Id<"calendarEventTemplates">;
  }

  get rrule(): string | null {
    return this.template.rrule;
  }

  get occurenceMessage(): string {
    const rrule = this.rrule;
    return rrule ? RRuleUtils.getRRuleSimpleDescription(rrule) : "Occurs once";
  }

  get occursAtStr(): string {
    return format(new Date(this.startTime), "MMM d, yyyy 'at' h:mm a");
  }

  withBaseSessionId = (
    baseSessionId: Id<"sessionConfig">
  ): CalEventInstanceST => {
    return CalEventInstanceST.make(
      {
        ...this,
        instance: {
          ...this.instance,
          sessionId: baseSessionId,
        },
      },
      { disableValidation: true }
    );
  };

  static fromDocData = (p: {
    participantsWithUserInfo: Array<
      {
        user: SimpleUserWithProfilePhoto;
      } & Doc<"calendarEventParticipants">
    >;
    template: Doc<"calendarEventTemplates">;
    instance: Doc<"calEventInstances">;
  }): CalEventInstanceST => {
    return CalEventInstanceST.make(
      {
        template: p.template,
        instance: p.instance,
        participants: p.participantsWithUserInfo.map((pwu) => ({
          ...pwu,
          user: pwu.user,
          _userId: pwu.user.id,
          attendanceStatus:
            pwu.attendanceStatus as MyInstanceAttendanceStatus | null,
        })),
      },
      { disableValidation: true }
    );
  };

  getMyParticipationInfo(p: { myUserId: Id<"users"> }): Option.Option<{
    user: SimpleUserWithProfilePhoto;
    role: ParticipantRole;
    attendanceStatus: MyInstanceAttendanceStatus | null;
  }> {
    return Option.fromNullable(
      this.participants.find(
        (participant) => participant._userId === p.myUserId
      )
    ).pipe(
      Option.map((v) => ({
        ...v,
        user: { ...v.user, id: v._userId as Id<"users"> },
      }))
    );
  }

  isInStartableWindow(p: { now: number }): boolean {
    const thirtyMinutes = 1000 * 60 * 30;

    console.log("NOW! ", new Date(p.now));
    console.log("START TIME! ", new Date(this.startTime));

    // Check if we're within the 10-minute window before or after start time
    return (
      p.now >= this.startTime - thirtyMinutes && // after or at 30 minutes before start
      p.now <= this.startTime + thirtyMinutes // before or at 30 minutes after start
    );
  }

  getMyCta(p: {
    myUserId: Id<"users">;
    now: number;
    mbSessionLifecycle: Doc<"sessionLifecycle"> | null;
  }): CommunityEventInstanceCtaST {
    const myParticipationInfo = this.getMyParticipationInfo(p);
    return myParticipationInfo.pipe(
      Option.match({
        onNone: () => CommunityEventInstanceCtaST.asRsvp(),
        onSome: (v) => {
          const isInStartableWindow = this.isInStartableWindow(p);

          const amIHost = v.role === "ORGANIZER";

          console.log("isInStartableWindow!!!", isInStartableWindow);
          console.log("amIHost!!!", amIHost);

          if (isInStartableWindow) {
            if (amIHost) {
              if (p.mbSessionLifecycle?.startedAt) {
                return CommunityEventInstanceCtaST.asJoinSession({
                  mode: {
                    tag: "JOIN",
                    baseSessionIdStr: p.mbSessionLifecycle.baseSessionId,
                  },
                });
              } else {
                return CommunityEventInstanceCtaST.asJoinSession({
                  mode: { tag: "START" },
                });
              }
            } else {
              if (this.instance.sessionId === null) {
                return CommunityEventInstanceCtaST.asJoinSession({
                  mode: { tag: "WAIT_FOR_HOST" },
                });
              } else {
                return CommunityEventInstanceCtaST.asJoinSession({
                  mode: {
                    tag: "JOIN",
                    baseSessionIdStr: this.instance.sessionId,
                  },
                });
              }
            }
          }

          return Match.value(v.attendanceStatus).pipe(
            Match.when(null, () => CommunityEventInstanceCtaST.asRsvp()),
            Match.orElse((status) => CommunityEventInstanceCtaST.asView(status))
          );
        },
      })
    );
  }

  getMyCtaButtonInfo(p: {
    myUserId: Id<"users">;
    now: number;
    mbSessionLifecycle: Doc<"sessionLifecycle"> | null;
  }): {
    title: string;
    isDisabled: boolean;
    cta: typeof CommunityCalEventInstanceCtaAction.Type;
    style: "INVERSE" | "REGULAR";
  } {
    const myCta = this.getMyCta(p);
    return {
      title: myCta.title,
      isDisabled: true,
      cta: myCta.action,
      style: Match.value(myCta.action).pipe(
        Match.when({ tag: "VIEW" }, () => "REGULAR" as const),
        Match.when({ tag: "RSVP" }, () => "INVERSE" as const),
        Match.when({ tag: "NONE" }, () => "REGULAR" as const),
        Match.when({ tag: "JOIN_SESSION" }, () => "INVERSE" as const),
        Match.exhaustive
      ),
    };
  }
}

export class CommunityEventInstanceST extends S.Class<CommunityEventInstanceST>(
  "CommunityEventInstanceST"
)({
  instanceST: CalEventInstanceST,
  community: BaseCommunityInfo,
}) {
  static from(
    data: typeof CommunityEventInstanceST.Encoded
  ): CommunityEventInstanceST {
    return S.decodeUnknownSync(CommunityEventInstanceST)(data);
  }

  static fromCalEventInstanceST = (
    instanceSTEncoded: typeof CalEventInstanceST.Encoded,
    community: Doc<"communityInfo">
  ): CommunityEventInstanceST => {
    const instanceST = CalEventInstanceST.from(instanceSTEncoded);
    return CommunityEventInstanceST.make(
      {
        instanceST,
        community,
      },
      { disableValidation: true }
    );
  };

  get encoded(): typeof CommunityEventInstanceST.Encoded {
    return S.encodeUnknownSync(CommunityEventInstanceST)(this);
  }

  get startTime(): number {
    return this.instanceST.startTime;
  }

  get endTime(): number {
    return this.instanceST.instance.endTime;
  }

  get durationInMinutes(): number {
    const diffInMs = this.endTime - this.startTime;
    return Math.floor(diffInMs / 60000);
  }

  get templateId(): Id<"calendarEventTemplates"> {
    return this.instanceST.templateId as Id<"calendarEventTemplates">;
  }

  get rrule(): string | null {
    return this.instanceST.rrule;
  }

  get occurenceMessage(): string {
    const rrule = this.rrule;
    return rrule ? RRuleUtils.getRRuleSimpleDescription(rrule) : "Occurs once";
  }

  get occursAtStr(): string {
    return format(new Date(this.startTime), "MMM d, yyyy 'at' h:mm a");
  }

  static fromDocData = (p: {
    participantsWithUserInfo: Array<
      {
        user: SimpleUserWithProfilePhoto;
      } & Doc<"calendarEventParticipants">
    >;
    template: Doc<"calendarEventTemplates">;
    community: Doc<"communityInfo">;
    instance: Doc<"calEventInstances">;
    baseSessionId: Id<"sessionConfig"> | null;
  }): CommunityEventInstanceST => {
    const instanceST = CalEventInstanceST.fromDocData(p);
    return CommunityEventInstanceST.make(
      {
        instanceST,
        community: p.community,
        baseSessionId: p.baseSessionId,
      },
      { disableValidation: true }
    );
  };
}

export class CommunityEventInstancesST extends S.Class<CommunityEventInstancesST>(
  "CommunityEventInstancesST"
)({
  instances: S.Array(CommunityEventInstanceST),
}) {
  static from(
    data: typeof CommunityEventInstancesST.Encoded
  ): CommunityEventInstancesST {
    return S.decodeUnknownSync(CommunityEventInstancesST)(data);
  }

  get encoded(): typeof CommunityEventInstancesST.Encoded {
    return S.encodeUnknownSync(CommunityEventInstancesST)(this);
  }

  getNearbyMonths(p: {
    moy: MonthOfYear;
  }): NearbyCalendarMonths<typeof CommunityEventInstanceST.Encoded> {
    return CalendarUtils.asNearbyCalendarMonths({
      moy: p.moy,
      events: this.instances.map((v) => v.encoded),
      pickStartTime: (evt) => new Date(evt.instanceST.instance.startTime),
    });
  }
}

const StartOrJoinMode = S.Union(
  S.Struct({ tag: S.Literal("JOIN"), baseSessionIdStr: S.String }),
  S.Struct({ tag: S.Literal("START") }),
  S.Struct({ tag: S.Literal("WAIT_FOR_HOST") })
);

export const CommunityCalEventInstanceCtaAction = S.Union(
  S.Struct({ tag: S.Literal("VIEW"), status: MyInstanceAttendanceStatus }),
  S.Struct({ tag: S.Literal("RSVP") }),
  S.Struct({ tag: S.Literal("NONE") }),
  S.Struct({
    tag: S.Literal("JOIN_SESSION"),
    mode: StartOrJoinMode,
  })
);
export type CommunityCalEventInstanceCtaAction =
  typeof CommunityCalEventInstanceCtaAction.Type;

export class CommunityEventInstanceCtaST extends S.Class<CommunityEventInstanceCtaST>(
  "CommunityEventCardCtaST"
)({
  action: CommunityCalEventInstanceCtaAction,
}) {
  static fromAction(
    action: typeof CommunityCalEventInstanceCtaAction.Type
  ): CommunityEventInstanceCtaST {
    return CommunityEventInstanceCtaST.make(
      { action },
      { disableValidation: true }
    );
  }

  get encoded(): typeof CommunityEventInstanceCtaST.Encoded {
    return S.encodeUnknownSync(CommunityEventInstanceCtaST)(this);
  }

  get title(): string {
    return Match.value(this.action).pipe(
      Match.when({ tag: "VIEW" }, ({ status }) =>
        capitalizeFirstLetter(status)
      ),
      Match.when({ tag: "RSVP" }, () => "RSVP"),
      Match.when({ tag: "NONE" }, () => "None"),
      Match.when({ tag: "JOIN_SESSION" }, ({ mode }) =>
        Match.value(mode).pipe(
          Match.when({ tag: "JOIN" }, () => "Join"),
          Match.when({ tag: "START" }, () => "Start"),
          Match.when({ tag: "WAIT_FOR_HOST" }, () => "Waiting for host"),
          Match.exhaustive
        )
      ),
      Match.exhaustive
    );
  }

  static asView = (
    status: MyInstanceAttendanceStatus
  ): CommunityEventInstanceCtaST => {
    return CommunityEventInstanceCtaST.make(
      { action: { tag: "VIEW", status } },
      { disableValidation: true }
    );
  };

  static asRsvp = (): CommunityEventInstanceCtaST => {
    return CommunityEventInstanceCtaST.make(
      { action: { tag: "RSVP" } },
      { disableValidation: true }
    );
  };

  static asNone = (): CommunityEventInstanceCtaST => {
    return CommunityEventInstanceCtaST.make(
      { action: { tag: "NONE" } },
      { disableValidation: true }
    );
  };

  static asJoinSession = (p: {
    mode: typeof StartOrJoinMode.Type;
  }): CommunityEventInstanceCtaST => {
    return CommunityEventInstanceCtaST.make(
      {
        action: {
          tag: "JOIN_SESSION",
          mode: p.mode,
        },
      },
      { disableValidation: true }
    );
  };

  static asConvexSchema = v.union(
    v.object({
      tag: v.literal("VIEW"),
      status: v.union(
        v.literal("ATTENDING"),
        v.literal("MAYBE"),
        v.literal("DECLINED"),
        v.literal("CANCELLED")
      ),
    }),
    v.object({ tag: v.literal("RSVP") }),
    v.object({ tag: v.literal("NONE") }),
    v.object({
      tag: v.literal("JOIN_SESSION"),
      mode: v.union(
        v.object({ tag: v.literal("JOIN"), baseSessionIdStr: v.string() }),
        v.object({ tag: v.literal("START") }),
        v.object({ tag: v.literal("WAIT_FOR_HOST") })
      ),
    })
  );
}

export type KnownCalendarApptTag = "COMMUNITY_EVENT" | "HC_EVENT";

export abstract class KnownCalendarAppt {
  abstract tag: KnownCalendarApptTag;
  abstract defaultColor: string;

  instanceId: string;
  title: string;
  startTime: number;
  endTime: number;

  constructor(p: {
    title: string;
    startTime: number;
    endTime: number;
    instanceId: string;
  }) {
    this.title = p.title;
    this.startTime = p.startTime;
    this.endTime = p.endTime;
    this.instanceId = p.instanceId;
  }
}

export class CommunityEventAppt extends KnownCalendarAppt {
  tag: KnownCalendarApptTag = "COMMUNITY_EVENT";
  defaultColor = "purple";

  get encoded() {
    return {
      tag: this.tag,
      defaultColor: this.defaultColor,
      instanceId: this.instanceId,
      title: this.title,
      startTime: this.startTime,
      endTime: this.endTime,
    };
  }
}

export class HcEventAppt extends KnownCalendarAppt {
  tag: KnownCalendarApptTag = "HC_EVENT";
  defaultColor = "blue";

  get encoded() {
    return {
      tag: this.tag,
      defaultColor: this.defaultColor,
      instanceId: this.instanceId,
      title: this.title,
      startTime: this.startTime,
      endTime: this.endTime,
    };
  }
}

export class CommunityEventDetailsPanelST extends S.Class<CommunityEventDetailsPanelST>(
  "CommunityEventDetailsPanelST"
)({
  templateId: S.String,
  title: S.String,
  description: S.NullOr(S.String),
  occurenceMessage: S.String,
  occursAtStr: S.String,
  button: S.Struct({
    title: S.String,
    isDisabled: S.Boolean,
    cta: CommunityCalEventInstanceCtaAction,
    style: S.Union(S.Literal("INVERSE"), S.Literal("REGULAR")),
  }),
  attendees: S.Array(
    S.Struct({
      user: S.Struct({
        profilePhoto: S.NullOr(S.String),
      }),
      attendanceStatus: S.NullOr(MyInstanceAttendanceStatus),
    })
  ),
}) {
  get encoded(): typeof CommunityEventDetailsPanelST.Encoded {
    return S.encodeUnknownSync(CommunityEventDetailsPanelST)(this);
  }

  get templateId(): Id<"calendarEventTemplates"> {
    return this.templateId as Id<"calendarEventTemplates">;
  }
}
