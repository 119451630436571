import {
  InversePrimaryButton,
  PrimaryButton,
} from "@webapp/componentsprimitives/button";
import { Match } from "effect";
import { createEvent, createStore, sample } from "effector";
import { useUnit } from "effector-react";
import { EmdrSyncStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/tools/shared/emdr.statemgr";
import { createContextAndHook, useOnce } from "frontend-shared/src/util";
import { CheckIcon } from "lucide-react";
import { useObservableEagerState, useObservableState } from "observable-hooks";
import { useEffect, useMemo, useState } from "react";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ColorKey, getColor } from "shared/utils/color.utils";
import {
  EmdrControlFormVM,
  EmdrControlFormProvider,
  useEmdrControlForm,
  type EmdrSound,
} from "frontend-shared/src/sessions/emdr-control-form.vm";
import { useMutation, useQuery } from "convex/react";
import { api } from "shared/convex/_generated/api";
import { useTherapySessionId } from "frontend-shared/src/mgrs/remote-state-mgrs/main-room.rsm";
import { FullContainerLoadingSpinner } from "@webapp/loading";

export const EmdrToolsPanel: React.FC<{
  emdrMgr: EmdrSyncStateMgr;
}> = ({ emdrMgr }) => {
  return (
    <div className="flex-1 flex flex-col justify-between">
      <EmdrControlForm emdrMgr={emdrMgr} />
    </div>
  );
};

export const EmdrControlForm: React.FC<{
  emdrMgr: EmdrSyncStateMgr;
}> = ({ emdrMgr }) => {
  const vm = useOnce(() => new EmdrControlFormVM());

  const mediaButtonState = useObservableState(
    emdrMgr.mediaButtonState$,
    "PLAY"
  );

  const settings = useObservableEagerState(emdrMgr.stateSyncMgr.state$);

  useEffect(() => {
    if (settings) {
      // vm.soundSelectedEvt(settings.ballSound as EmdrSound);
      vm.colorSelectedEvt(settings.ballColor as ColorKey);
    }
  }, [settings]);

  return (
    <EmdrControlFormProvider.Provider value={vm}>
      <div className="flex-1 flex flex-col justify-between">
        <div className="flex flex-col gap-4">
          <SoundSelectSection />

          <ToolsSectionContainer
            title="Speed"
            content={
              <FrequencySlider
                min={1}
                max={10}
                value={settings?.ballFrequency ?? 5}
                onChange={(value) => {
                  console.log("FREQUENCY! ", value);
                  emdrMgr.setBouncingBallFrequency(value);
                }}
              />
            }
          />
          <ColorSelectSection />
        </div>

        {Match.value(mediaButtonState).pipe(
          Match.when("PLAY", () => (
            <PrimaryButton
              title="Start"
              onClick={() => {
                emdrMgr.setStatePreparing();
              }}
            />
          )),
          Match.when("PAUSE", () => (
            <div className="flex gap-2">
              <InversePrimaryButton
                title="Pause"
                onClick={() => {
                  emdrMgr.setStatePaused();
                }}
              />
              <PrimaryButton
                title="Stop"
                onClick={() => {
                  emdrMgr.setStateOff();
                }}
              />
            </div>
          )),
          Match.when("PREPARING", () => (
            <PrimaryButton title="Preparing" isLoading={true} />
          )),
          Match.when("STOP", () => (
            <PrimaryButton
              title="Stop"
              onClick={() => {
                emdrMgr.setStateOff();
              }}
            />
          )),
          Match.when(null, () => <PrimaryButton title="Start" />),
          Match.exhaustive
        )}
      </div>
    </EmdrControlFormProvider.Provider>
  );
};

interface ToolsSectionContainerProps {
  title: string;
  content: React.ReactNode;
}

const ToolsSectionContainer: React.FC<ToolsSectionContainerProps> = ({
  title,
  content,
}) => {
  return (
    <div className="flex flex-col items-start w-[312px] p-6 gap-4 border border-vid-black-200 rounded-lg">
      <h2 className="w-full text-vid-black-900 font-sans text-base font-semibold leading-[120%]">
        {title}
      </h2>
      {content}
    </div>
  );
};

interface FrequencySliderProps {
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
}

const FrequencySlider: React.FC<FrequencySliderProps> = ({
  min,
  max,
  value,
  onChange,
}) => {
  // Local state for immediate visual feedback
  const [localValue, setLocalValue] = useState(value);
  const debouncedSubject = useMemo(() => new Subject<number>(), []);

  // Update local value when prop value changes
  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    const subscription = debouncedSubject
      .pipe(debounceTime(300))
      .subscribe(onChange);

    return () => subscription.unsubscribe();
  }, [onChange]);

  const handleChange = (newValue: number) => {
    setLocalValue(newValue); // Immediate update for UI
    debouncedSubject.next(newValue); // Debounced update for onChange
  };

  return (
    <div className="relative w-full">
      <input
        type="range"
        min={min}
        max={max}
        value={localValue}
        onChange={(e) => handleChange(Number(e.target.value))}
        className="w-full appearance-none bg-vid-black-100 h-[2px] rounded-full focus:outline-none focus:ring-2 focus:ring-vid-purple slider-thumb"
        style={{
          background: `linear-gradient(to right, #690DFF 0%, #690DFF ${
            ((localValue - min) / (max - min)) * 100
          }%, #E5E7EB ${((localValue - min) / (max - min)) * 100}%, #E5E7EB 100%)`,
        }}
      />
    </div>
  );
};

const SoundSelectSection: React.FC<{}> = ({}) => {
  const vm = useEmdrControlForm();

  const SoundRowItem: React.FC<{
    title: string;
    slug: EmdrSound;
    icon: string;
  }> = ({ title, slug, icon }) => {
    const selectedSound = useUnit(vm.$selectedSound);
    const isSelected = selectedSound === slug;

    return (
      <div
        className="self-stretch flex flex-row items-center justify-between gap-2 cursor-pointer"
        onClick={() => {
          vm.soundSelectedEvt(slug);
        }}
      >
        <div className="flex flex-row items-center gap-2">
          <div
            className={`w-4 h-4 rounded-full border border-vid-black-900 ${
              isSelected ? "bg-vid-purple" : "bg-transparent"
            }`}
          />
          <p className="text-vid-black-900 font-sans text-base font-normal leading-[120%]">
            {title}
          </p>
        </div>
        <img src={icon} alt={title} />
      </div>
    );
  };

  return (
    <ToolsSectionContainer
      title="Sound"
      content={
        <div className="flex flex-col w-full gap-6">
          <SoundRowItem
            title="Beep"
            slug="beep"
            icon="/tools/cloud-beep-icon.svg"
          />
          <SoundRowItem
            title="Chime"
            slug="chime"
            icon="/tools/cloud-beep-icon.svg"
          />
        </div>
      }
    />
  );
};

const ALL_COLORS: ColorKey[] = ["blue", "green", "purple", "red", "yellow"];

const ColorSelectSection: React.FC<{}> = ({}) => {
  const sessionId = useTherapySessionId();
  const vm = useEmdrControlForm();

  const ballColor = useQuery(
    api.Screens.U.Sessions.Rtc.MainRoom.EmdrPanelFns.getBallColor,
    {
      baseSessionId: sessionId,
    }
  );

  const setBallColor = useMutation(
    api.Screens.U.Sessions.Rtc.MainRoom.EmdrPanelFns.onSetBallColor
  ).withOptimisticUpdate((localStore, args) => {
    const { ballColor } = args;
    const currentValue = localStore.getQuery(
      api.Screens.U.Sessions.Rtc.MainRoom.EmdrPanelFns.getBallColor,
      { baseSessionId: sessionId }
    );
    if (currentValue !== undefined) {
      localStore.setQuery(
        api.Screens.U.Sessions.Rtc.MainRoom.EmdrPanelFns.getBallColor,
        { baseSessionId: sessionId },
        ballColor
      );
    }
  });

  if (ballColor === undefined) {
    return null;
  }

  return (
    <ToolsSectionContainer
      title="Color"
      content={
        <div className="flex items-center justify-between gap-2 w-full">
          {ALL_COLORS.map((color) => {
            return (
              <div
                key={color}
                className={`w-[34px] aspect-square rounded-full border border-vid-black-900 flex items-center justify-center cursor-pointer`}
                style={{
                  backgroundColor: getColor(color),
                }}
                onClick={() => {
                  setBallColor({
                    baseSessionId: sessionId,
                    ballColor: color,
                  }).then();
                }}
              >
                {ballColor === color && (
                  <CheckIcon className="w-4 h-4 text-vid-white" color="white" />
                )}
              </div>
            );
          })}
        </div>
      }
    />
  );
};
