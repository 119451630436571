import {
  useAutoJoinChannel,
  useSetupMainRoom,
} from "@/src/domains/sessions/rtc/rtc.hooks";
import { WaitingRoom } from "@/src/domains/sessions/rtc/waitin-room";
import { useMbRtcMgr, useMyId } from "@pages/u/u.webstatemgr";
import {
  BackgroundFiltersProvider,
  StreamCall,
  StreamVideo,
} from "@stream-io/video-react-sdk";
import { MainRoomContainer } from "@webapp/componentslive-session/main-room/main-room-container";
import { FullScreenLoadingSpinner } from "@webapp/loading";
import { useMutation } from "convex/react";
import { Match } from "effect";
import {
  SessionRSM,
  SessionRSMProvider,
} from "frontend-shared/src/mgrs/remote-state-mgrs/main-room.rsm";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import {
  type RegisterUserEnteredSessionResultST,
  type RtcAreaST,
} from "shared/convex/Sessions/Base/Session.Types";
import { isNullOrUndefined } from "shared/util";
import { useConvexCli } from "src/convex-cli";

type RegisterEnteredResult = typeof RegisterUserEnteredSessionResultST.Encoded;

export const USessionRtcPage: React.FC = () => {
  const { sessionId } = useParams();
  const registerUserEnteredSession = useMutation(
    api.Sessions.Base.SessionFns.registerUserEnteredSession
  );

  const [enteredSessionResult, setEnteredSessionResult] =
    useState<RegisterEnteredResult | null>(null);

  useEffect(() => {
    if (sessionId) {
      registerUserEnteredSession({
        baseSessionId: sessionId as Id<"sessionConfig">,
      }).then((r) => {
        console.log("R! ", r);
        setEnteredSessionResult(r);
      });
    }
  }, [sessionId]);

  const mbRtcMgr = useMbRtcMgr();

  if (!enteredSessionResult || mbRtcMgr === null) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <div className="flex-1 flex flex-col">
      <LoadedView registerEnteredResult={enteredSessionResult} />
    </div>
  );
};

const LoadedView: React.FC<{
  registerEnteredResult: RegisterEnteredResult;
}> = ({ registerEnteredResult }) => {
  const myId = useMyId();
  const convexCli = useConvexCli();
  const sessionRSM = useMemo(() => {
    return new SessionRSM({
      convexCli,
      ...registerEnteredResult.sessionConfig,
      userId: myId,
      baseSessionId: registerEnteredResult.sessionConfig
        .baseSessionId as Id<"sessionConfig">,
      startsAt: registerEnteredResult.sessionConfig.startTime,
    });
  }, [registerEnteredResult]);

  return (
    <SessionRSMProvider.Provider value={sessionRSM}>
      {Match.value(registerEnteredResult.buildingAreaToGoTo.area).pipe(
        Match.when({ tag: "ADMISSIONS_LOBBY" }, () => {
          return <div>Lobby!</div>;
        }),
        Match.when({ tag: "RTC" }, (rtcArea) => {
          return <RtcAreaView rtcArea={rtcArea} />;
        }),
        Match.exhaustive
      )}
    </SessionRSMProvider.Provider>
  );
};

const RtcAreaView: React.FC<{
  rtcArea: typeof RtcAreaST.Encoded;
}> = ({ rtcArea }) => {
  const { mbJoinedCall, rtcClient, isLoading } = useAutoJoinChannel(rtcArea);

  if (isNullOrUndefined(mbJoinedCall) || isLoading) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <div className="flex-1 flex flex-col">
      <StreamVideo client={rtcClient}>
        <StreamCall call={mbJoinedCall}>
          <BackgroundFiltersProvider>
            {Match.value(rtcArea.area).pipe(
              Match.when({ tag: "WAITING_ROOM" }, () => {
                return <WaitingRoom />;
              }),
              Match.when({ tag: "LIVE" }, () => {
                return <MainRoomAreaView />;
              }),
              Match.exhaustive
            )}
          </BackgroundFiltersProvider>
        </StreamCall>
      </StreamVideo>
    </div>
  );
};

const MainRoomAreaView: React.FC = () => {
  const { mainRoomStateMgr, meAsParticipant } = useSetupMainRoom();

  if (!meAsParticipant) {
    return <FullScreenLoadingSpinner />;
  }

  // const baseSessionConfig = useObservableEagerState(
  //   mainRoomStateMgr.stateSyncMgr.baseSessionConfigMgr.state$
  // );

  return (
    <MainRoomContainer
      mainRoomStateMgr={mainRoomStateMgr}
      // settings={{
      //   shareableLink: baseSessionConfig.inviteLink,
      // }}
    />
  );
};
