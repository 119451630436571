import {
  DashboardLayoutContainer,
  LeftMenu,
  NavbarContainer,
} from "@/src/components/dashboard/dashboard-layout.fc";
import {
  CalendarIcon,
  ClientsIcon,
  CommunityIcon,
  InvoicesIcon,
  LeftMenuLink,
  SettingsIcon,
} from "@/src/components/dashboard/main-nav-links";
import { PrimaryButton } from "@/src/components/primitives/button";
import { ONBOARD_ROUTES } from "@/src/route-views/onboard-route-views";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { CreateAProfileDialog } from "./create-a-profile";

export const AnonDashLayout: React.FC = () => {
  return (
    <AnonDashboardContainer>
      <Outlet />
    </AnonDashboardContainer>
  );
};

export const AnonDashboardContainer: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <div className="flex flex-col w-full h-full">
      <DashboardLayoutContainer
        leftMenu={
          <LeftMenu
            links={[
              <LeftMenuLink
                to={null}
                name="Clients"
                onClick={() => {
                  setDialogOpen(true);
                }}
                icon={(isSelected) => <ClientsIcon isSelected={isSelected} />}
              />,
              <LeftMenuLink
                to={null}
                name="Community"
                icon={() => <CommunityIcon isSelected={true} />}
                overrideSelected={true}
              />,
              <LeftMenuLink
                to={null}
                name="Calendar"
                onClick={() => {
                  setDialogOpen(true);
                }}
                icon={(isSelected) => <CalendarIcon isSelected={isSelected} />}
              />,
              <LeftMenuLink
                to={null}
                name="Invoices"
                onClick={() => {
                  setDialogOpen(true);
                }}
                icon={(isSelected) => <InvoicesIcon isSelected={isSelected} />}
              />,
              <LeftMenuLink
                to={null}
                name="Settings"
                onClick={() => {
                  setDialogOpen(true);
                }}
                icon={(isSelected) => <SettingsIcon isSelected={isSelected} />}
              />,
            ]}
            onLogoClick={() => {
              window.location.href = "/";
            }}
            extraTop={
              <PrimaryButton title="Schedule session" onClick={() => {}} />
            }
          />
        }
        mainContent={children}
        navbar={
          <NavbarContainer
            title="Community"
            rightSection={<NavbarRightSection />}
          />
        }
        rightNav={<div></div>}
        showReloading={false}
        showBottomToast={null}
        closeToast={() => {}}
      />
      <CreateAProfileDialog
        open={dialogOpen}
        onOpenChange={setDialogOpen}
        trigger={<button className="hidden" />}
      />
    </div>
  );
};

const NavbarRightSection: React.FC = () => {
  const nav = useNavigate();
  return (
    <div className="flex items-center gap-4">
      <button
        className="text-vid-purple font-medium flex justify-center items-center px-6 py-3"
        onClick={() => {
          nav(ONBOARD_ROUTES.HP.REGISTER.path);
        }}
      >
        Sign up
      </button>
      <PrimaryButton
        title="Login"
        onClick={() => {
          nav("/login");
        }}
      />
    </div>
  );
};
