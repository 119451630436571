import { Messenger } from "@/src/components/chat/messenger";
import { globalToastVM } from "@/src/components/toast/global-toast";
import type { Call, StreamVideoClient } from "@stream-io/video-react-sdk";
import { apiMgr } from "@webapp/backend";
import {
  Dialog,
  DialogContent,
  DialogHeader,
} from "@webapp/components/primitives/dialogue";
import { ToastProvider, ToastViewport } from "@webapp/components/toast/toast";
import { RightDrawer } from "@webapp/componentsright-drawer";
import { FullScreenLoadingSpinner } from "@webapp/loading";
import { WebRtcMgr } from "@webapp/mgrs/web-rtc.mgr";
import { Rx } from "@webapp/prelude";
import { useAction, useConvex, useConvexAuth } from "convex/react";
import { Match, Option } from "effect";
import { useUnit } from "effector-react";
import { useFirebaseJs } from "frontend-shared/src/firebase";
import { useSetupUStateMgr } from "frontend-shared/src/mgrs/state-mgrs/user/user.statemgr";
import { useNotificationToasts } from "frontend-shared/src/toasts/use-notifications-toast";
import { useOnce } from "frontend-shared/src/util";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { api } from "shared/convex/_generated/api";
import type { UserPrimaryRole } from "shared/db";
import { UROUTES } from "shared/routes/u.routes";
import type { RtcCredentials } from "shared/schemas/call.schemas";
import type { SimpleUserWithProfilePhoto } from "shared/types/user.types";
import { useConvexCli } from "src/convex-cli";
import { ONBOARD_ROUTES } from "src/route-views/onboard-route-views";
import { StreamChat } from "stream-chat";
import {
  useWebAppSession,
  UWebStateMgr,
  UWebStateMgrContext,
  type DialogVM,
} from "./u.webstatemgr";

export const ULayout: React.FC = () => {
  const nav = useNavigate();
  const { isLoading, isAuthenticated } = useConvexAuth();

  useEffect(() => {
    console.log("IS LOADING IN U LAYOUT! ", isLoading);
    console.log("IS AUTHENTICATED IN U LAYOUT! ", isAuthenticated);
    if (!isLoading && !isAuthenticated) {
      nav(ONBOARD_ROUTES.PORTAL_SELECTION.path);
    }
  }, [isAuthenticated, isLoading]);

  if (isLoading || !isAuthenticated) {
    return <FullScreenLoadingSpinner />;
  }

  return <AuthorizedView />;
};

const AuthorizedView: React.FC = () => {
  const convexCli = useConvex();
  const [me, setMe] = useState<SimpleUserWithProfilePhoto | undefined>(
    undefined
  );

  useEffect(() => {
    convexCli.query(api.User.UserFns.getAssumedSimpleMe, {}).then(setMe);
  }, []);

  if (me === undefined) {
    return <FullScreenLoadingSpinner />;
  }

  return <LoadedAuthorizedView mbPrimaryRole={"hp"} me={me} />;
};

const LoadedAuthorizedView: React.FC<{
  mbPrimaryRole: UserPrimaryRole | null;
  me: SimpleUserWithProfilePhoto;
}> = ({ mbPrimaryRole, me }) => {
  const location = useLocation();
  const nav = useNavigate();
  const firebaseJs = useFirebaseJs();

  useWebAppSession({ userId: me.id });

  const convex = useConvexCli();

  const refreshUserChatToken = useAction(
    api.Chat.ChatTokenMgmtFns.refreshUserChatToken
  );

  const rtcCreds$ = useOnce(
    () => new Rx.BehaviorSubject<RtcCredentials | null>(null)
  );

  const getMyRtcCreds = useAction(
    api.ThirdPartyServices.CallNodeFns.getMyRtcCreds
  );

  useEffect(() => {
    getMyRtcCreds().then((r) => {
      rtcCreds$.next(r);
    });
  }, []);

  useEffect(() => {
    refreshUserChatToken().then((r) => {
      console.log("REFRESHED USER CHAT TOKEN! ", r);
    });

    console.log("MOUNTING LOADED AUTHORIZED VIEW!");
  }, []);

  const mbUStateMgr = useSetupUStateMgr<
    StreamChat,
    Call,
    StreamVideoClient,
    WebRtcMgr,
    UWebStateMgr
  >(
    apiMgr,
    convex,
    firebaseJs,
    me,
    rtcCreds$,
    (p) => StreamChat.getInstance(p.apiKey),
    (p) => new UWebStateMgr({ ...p, baseUserId: me.id })
  );

  console.log("MB U STATE MGR! ", mbUStateMgr);

  useNotificationToasts(globalToastVM);

  useEffect(() => {
    if (location.pathname === UROUTES.path) {
      Match.value(mbPrimaryRole).pipe(
        Match.when("client", () => {
          nav(UROUTES.CP.DASHBOARD.HOME.path);
        }),
        Match.when("hp", () => {
          nav(UROUTES.HP.MY.DASHBOARD.CLIENTS.path);
        }),
        Match.when(null, () => {
          nav(UROUTES.HP.MY.DASHBOARD.CLIENTS.path);
        }),
        Match.exhaustive
      );
    }
  }, [mbPrimaryRole, location.pathname]);

  if (Option.isNone(mbUStateMgr)) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <UWebStateMgrContext.Provider value={mbUStateMgr.value}>
      <ToastProvider>
        <Outlet />
        <RightDrawer vm={mbUStateMgr.value.rightDrawerVM} />
        <Messenger vm={mbUStateMgr.value.chatStateMgr.messengerVM} />
        <ToastViewport vm={globalToastVM} />
        <AppDialog vm={mbUStateMgr.value.globalDialogVM} />
      </ToastProvider>
    </UWebStateMgrContext.Provider>
  );
};

const AppDialog: React.FC<{
  vm: DialogVM;
}> = ({ vm }) => {
  const [mbOpen] = useUnit([vm.openVM.store]);

  return (
    <Dialog>
      <DialogContent>
        <DialogHeader>
          {/* {title && <DialogTitle>{title}</DialogTitle>} */}
          <div className="w-[400px] h-[400px] bg-red-500">MEOW!</div>
        </DialogHeader>
        <div className="w-[400px] h-[400px] bg-red-500">MEOW!</div>
      </DialogContent>
    </Dialog>
  );

  if (mbOpen === null) {
    return null;
  }

  return (
    <Dialog>
      <DialogContent>
        <DialogHeader>
          {/* {title && <DialogTitle>{title}</DialogTitle>} */}
        </DialogHeader>
        {mbOpen}
      </DialogContent>
    </Dialog>
  );
};
