import { ChatFC } from "@webapp/componentschat/chat.fc";
import { SettingsRightNav } from "@webapp/componentslive-session/live-session.components";
import { QuickScheduleToolsPanel } from "@webapp/componentslive-session/right-nav/quick-schedule-tools-panel";
import { EmdrToolsPanel } from "@webapp/componentslive-session/right-nav/therapy-tools/emdr/emdr-tools";
import { MeditationToolsPanel } from "@webapp/componentslive-session/right-nav/therapy-tools/meditation.tools";
import { NoteToolsPanel } from "@webapp/componentslive-session/right-nav/therapy-tools/notes-tools-panel";
import { ReviewToolsPanel } from "@webapp/componentslive-session/right-nav/therapy-tools/review-tools-panel.fc";
import { TherapyToolMenuButton } from "@webapp/componentslive-session/right-nav/therapy-tools/therapy-tools.components";
import { useMutation, useQuery } from "convex/react";
import { Match } from "effect";
import {
  useTherapySessionId,
  type SessionRSM,
} from "frontend-shared/src/mgrs/remote-state-mgrs/main-room.rsm";
import type { ChatStateMgr } from "frontend-shared/src/mgrs/state-mgrs/chat.statemgr";
import type { MainRoomStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/main-room.statemgr";
import type { MeditationStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/tools/meditation.statemgr";
import type { EmdrSyncStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/tools/shared/emdr.statemgr";
import { useQuery$ } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import type React from "react";
import { useEffect, useState } from "react";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import {
  isSpecificToolMenuViewState,
  MainRoomMenuTB,
  type MainRoomMenuOpenState,
  type ToolMenuViewState,
} from "shared/convex/Sessions/Participants/Views/MainRoom.Types";
import {
  KnownTool,
  type KnownToolTag,
} from "shared/schemas/session/ongoing/known-tool.schemas";
import type { ChatChannelUserSetup } from "shared/types/chat.types";
import { isNotNullOrUndefined } from "shared/util";
import type { Channel, DefaultGenerics, StreamChat } from "stream-chat";
import type { DefaultStreamChatGenerics } from "stream-chat-react";
import { RoomRightDrawerMenuContainer } from "./rtc/rooms/room-right-drawer";
import { SetupBreakoutRoomsRightDrawer } from "./rtc/rooms/setup-breakout-rooms.form";

export const MainRoomMenu: React.FC<{
  mainRoomStateMgr: MainRoomStateMgr;
  chatStateMgr: ChatStateMgr<StreamChat>;
}> = ({ mainRoomStateMgr, chatStateMgr }) => {
  const sessionId = useTherapySessionId();
  const viewState = useQuery(
    api.Sessions.Participants.Views.MainRoomFns.getMyMenuViewState,
    {
      baseSessionId: sessionId,
    }
  );

  const setMyMenuViewState = useMutation(
    api.Sessions.Participants.Views.MainRoomFns.setMyMenuViewState
  ).withOptimisticUpdate((localStore, { viewState }) => {
    localStore.setQuery(
      api.Sessions.Participants.Views.MainRoomFns.getMyMenuViewState,
      { baseSessionId: sessionId },
      viewState
    );
  });

  if (viewState === undefined) {
    return null;
  }

  if (viewState.tag === "CLOSED") {
    return null;
  }

  return (
    <RoomRightDrawerMenuContainer
      menuTitle={titleForMenuViewState(viewState.viewState)}
      onBackClick={
        isSpecificToolMenuViewState(viewState.viewState)
          ? () => {
              setMyMenuViewState({
                baseSessionId: sessionId,
                viewState: MainRoomMenuTB.open.tools.all(),
              });
            }
          : undefined
      }
      onClose={() => {
        setMyMenuViewState({
          baseSessionId: sessionId,
          viewState: MainRoomMenuTB.closed(),
        }).catch();
      }}
      content={Match.value(viewState).pipe(
        Match.when({ tag: "OPEN" }, ({ viewState }) => {
          return Match.value(viewState).pipe(
            Match.when({ tag: "TOOLS_MENU" }, ({ viewState }) => (
              <ToolsMenu
                chatStateMgr={chatStateMgr}
                mainRoomRSM={mainRoomStateMgr.stateSyncMgr}
                emdrMgr={mainRoomStateMgr.tools.emdrMgr}
                meditationMgr={mainRoomStateMgr.tools.meditationMgr}
                viewState={viewState}
                setMyMenuViewState={(toolTag) => {
                  setMyMenuViewState({
                    baseSessionId: sessionId,
                    viewState: MainRoomMenuTB.open.tools.specific(toolTag),
                  }).catch();
                }}
              />
            )),
            Match.when({ tag: "SETTINGS_MENU" }, () => <SettingsMenu />),
            Match.exhaustive
          );
        }),
        Match.exhaustive
      )}
    />
  );
};

const SettingsMenu: React.FC = () => {
  const sessionId = useTherapySessionId();

  const inviteUrl$ = useQuery$(
    api.Screens.U.Sessions.Rtc.MainRoomScreenFns.getInviteUrl,
    {
      baseSessionId: sessionId,
    }
  );

  const inviteUrl = useObservableEagerState(inviteUrl$);

  return (
    <SettingsRightNav
      shareableLink={inviteUrl ?? null}
      setRemoteIsSharingAudioOnly={() => {}}
    />
  );
};

const ToolsMenu: React.FC<{
  viewState: ToolMenuViewState;
  setMyMenuViewState: (toolTag: KnownToolTag) => void;
  chatStateMgr: ChatStateMgr<StreamChat>;
  mainRoomRSM: SessionRSM;
  emdrMgr: EmdrSyncStateMgr;
  meditationMgr: MeditationStateMgr;
}> = ({
  viewState,
  setMyMenuViewState,
  chatStateMgr,
  mainRoomRSM,
  emdrMgr,
  meditationMgr,
}) => {
  const sessionId = useTherapySessionId();
  const allTools = useQuery(
    api.Sessions.Participants.Views.MainRoomFns.getToolMenuTools,
    { baseSessionId: sessionId }
  );

  if (allTools === undefined) {
    return null;
  }

  return Match.value(viewState).pipe(
    Match.when({ tag: "MENU" }, () => (
      <div className="flex-1 self-stretch flex flex-col gap-4">
        {allTools.tools.map((toolTag) => {
          const knownTool = KnownTool.fromTag(toolTag);
          return (
            <TherapyToolMenuButton
              key={toolTag}
              content={{
                title: knownTool.title,
                description: knownTool.description,
                iconName: knownTool.iconName,
              }}
              onClick={() => {
                setMyMenuViewState(toolTag);
              }}
            />
          );
        })}
      </div>
    )),
    Match.when({ tag: "SELECTED_TOOL" }, ({ tool }) => (
      <div className="flex-1 self-stretch flex flex-col gap-4">
        {componentForToolTag(
          sessionId,
          tool,
          chatStateMgr,
          mainRoomRSM,
          emdrMgr,
          meditationMgr
        )}
      </div>
    )),
    Match.exhaustive
  );
};

function titleForMenuViewState(viewState: MainRoomMenuOpenState) {
  return Match.value(viewState).pipe(
    Match.when({ tag: "TOOLS_MENU" }, ({ viewState: tvs }) =>
      Match.value(tvs).pipe(
        Match.when({ tag: "MENU" }, () => "Tools"),
        Match.when(
          { tag: "SELECTED_TOOL" },
          ({ tool }) => KnownTool.fromTag(tool).title
        ),
        Match.exhaustive
      )
    ),
    Match.when({ tag: "SETTINGS_MENU" }, () => "Settings"),
    Match.exhaustive
  );
}

function componentForToolTag(
  baseSessionId: Id<"sessionConfig">,
  toolTag: KnownToolTag,
  chatStateMgr: ChatStateMgr<StreamChat>,
  mainRoomRSM: SessionRSM,
  emdrMgr: EmdrSyncStateMgr,
  meditationMgr: MeditationStateMgr
) {
  return Match.value(toolTag).pipe(
    Match.when("EMDR", () => <EmdrToolsPanel emdrMgr={emdrMgr} />),
    Match.when("MEDITATION", () => (
      <MeditationToolsPanel meditationMgr={meditationMgr} />
    )),
    Match.when("CHAT", () => (
      <ChatPanel
        sessionId={baseSessionId}
        mainRoomRSM={mainRoomRSM}
        chatStateMgr={chatStateMgr}
      />
    )),
    Match.when("NOTES", () => (
      <NoteToolsPanel annotationsPanelMgr={mainRoomRSM.annotationsPanelMgr} />
    )),
    Match.when("REVIEW", () => <ReviewToolsPanel sessionId={baseSessionId} />),
    Match.when("QUICK_SCHEDULE", () => <QuickScheduleToolsPanel />),
    Match.when("BREAKOUT_ROOMS", () => <SetupBreakoutRoomsRightDrawer />),
    Match.exhaustive
  );
}

const ChatPanel: React.FC<{
  sessionId: string;
  mainRoomRSM: SessionRSM;
  chatStateMgr: ChatStateMgr<StreamChat>;
}> = ({ sessionId, mainRoomRSM, chatStateMgr }) => {
  console.log("CHAT STATE MGR! ", chatStateMgr, sessionId);
  const chatSetup = useObservableEagerState(mainRoomRSM.chat.state$);
  const chatCli = useObservableEagerState(chatStateMgr.chatCli$);

  const [mbChannel, setMbChannel] =
    useState<Channel<DefaultStreamChatGenerics> | null>(null);

  useEffect(() => {
    if (chatCli === undefined) {
      return;
    }

    async function setupChannelWatch(
      cus: ChatChannelUserSetup,
      chatCli: StreamChat<DefaultGenerics>
    ) {
      if (isNotNullOrUndefined(cus) && chatCli !== undefined) {
        const { channelConfig, memberIds } = cus.setup!;
        const channel = chatCli.channel(
          channelConfig.channelType,
          channelConfig.channelId,
          {
            members: [...memberIds],
          }
        );
        await channel.watch();

        return channel;
      }

      return null;
    }

    if (chatSetup.setup) {
      setupChannelWatch(chatSetup, chatCli).then(setMbChannel);
    }
  }, [chatSetup, chatCli]);

  if (chatCli === undefined) {
    return null;
  }

  return <ChatFC chatCli$={chatStateMgr.chatCli$} userChatSetup={chatSetup} />;
};
