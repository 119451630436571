import { GenericTable } from "@webapp/componentsTable";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { useQuery } from "convex/react";
import { format } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";
import { api } from "shared/convex/_generated/api";
import { SessionInvoice } from "shared/index";
import { UROUTES } from "shared/routes/u.routes";

export const HpMyDashboardInvoicesPage: React.FC = () => {
  const myInvoices = useQuery(
    api.Screens.Hp.Dashboard.InvoiceScreenFns.getMyInvoices
  );

  if (myInvoices === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex flex-col h-full">
      <EmptyState />
      <DisclaimerFooter />
    </div>
  );
};

const EmptyState: React.FC = () => {
  return (
    <div className="flex flex-col justify-center items-center h-full">
      <h1 className="font-outfit font-semibold text-2xl text-center lg:text-4xl text-default-purple leading-5 mb-4">
        You don't have any invoices yet
      </h1>
      <p className="font-sans font-light text-xs lg:text-base text-center">
        Invoices will appear here automatically after your sessions.
      </p>
    </div>
  );
};

const DisclaimerFooter: React.FC = () => {
  return (
    <div className="w-full hidden lg:flex justify-center">
      <div className="w-fit flex text-xs justify-center items-center rounded-[10px] bg-vid-black-100 p-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2Z"
            stroke="#5500C2"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 16V11"
            stroke="#5500C2"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.0059 8H11.9969"
            stroke="#5500C2"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p className="mx-2 text-[#3A3A3A]">
          Sessions with clients are protected with our auto-pay system. Once a
          session ends your client they will be automatically billed.
        </p>
        <p className="hover:cursor-pointer font-semibold text-default-purple">
          Learn more
        </p>
      </div>
    </div>
  );
};

const columns = [
  { key: "client", header: "Client", showArrow: true },
  { key: "sessionId", header: "Session ID", showArrow: true },
  { key: "created_date", header: "Date", showArrow: true },
  { key: "total", header: "Total", showArrow: true },
  { key: "status", header: "Status", showArrow: true },
  { key: "download", header: "", showArrow: false },
];

const InvoicesTable: React.FC<{
  invoices: SessionInvoice[];
  onDownloadClick: (inv: SessionInvoice) => void;
}> = ({ invoices, onDownloadClick }) => {
  return (
    <GenericTable
      columns={columns}
      data={invoices.map((inv) => ({
        client: (
          <Link
            to={UROUTES.HP.MY.DASHBOARD.CLIENTS.CLIENT_ID.buildPath({
              clientId: inv.client.id,
            })}
            className="flex items-center gap-2 font-sans font-light"
          >
            {inv.client.profilePhotoDownloadUrl && (
              <img
                src={inv.client.profilePhotoDownloadUrl}
                alt={inv.client.name}
                className="w-8 h-8 rounded-full object-fill"
              />
            )}
            <span>{inv.client.name}</span>
          </Link>
        ),
        sessionId: (
          <div>{inv.session_id.slice(0, 8)}</div>
          // <Link
          //   to={UROUTES.HP.MY.PRIVATE_SESSIONS.SESSION_ID.REVIEW.buildPath({
          //     sessionId: inv.session_id,
          //   })}
          //   className="font-light font-sans"
          // >
          //   {inv.session_id.slice(0, 8)}
          // </Link>
        ),
        created_date: (
          <div className="font-light font-sans">
            {format(new Date(inv.created_date), "dd/MM/yyyy")}
          </div>
        ),
        total: <div className="font-light font-sans">{`$${inv.total}`}</div>,
        status: <div className="font-light font-sans">{inv.status}</div>,
        download: (
          <div className="cursor-pointer" onClick={() => onDownloadClick(inv)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M16.44 8.90039C20.04 9.21039 21.51 11.0604 21.51 15.1104V15.2404C21.51 19.7104 19.72 21.5004 15.25 21.5004H8.73998C4.26998 21.5004 2.47998 19.7104 2.47998 15.2404V15.1104C2.47998 11.0904 3.92998 9.24039 7.46998 8.91039"
                stroke="#1D1626"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 2V14.88"
                stroke="#1D1626"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.3499 12.6504L11.9999 16.0004L8.6499 12.6504"
                stroke="#1D1626"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        ),
      }))}
    />
  );
};
