import type { ConvexClient } from "convex/browser";
import { Effect, Option } from "effect";
import { useQuery$ } from "frontend-shared/src/util";
import { useMemo } from "react";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import type { RtcCredentials } from "shared/schemas/call.schemas";
import { ImageSrc } from "shared/types/miscellaneous.types";
import type { SimpleUserWithProfilePhoto } from "shared/types/user.types";
import { type ApiMgr } from "../../../api.mgr";
import type { FirebaseJsMgr } from "../../../firebase";
import { PubSubNotificationsRsm } from "../../remote-state-mgrs/users/me.rsm";
import { BaseStateMgr } from "../base.statemgr";
import { ChatStateMgr, type GenericChatCli } from "../chat.statemgr";
import {
  GenericRtcMgr,
  type GenericRtcChannelMgr,
  type GenericRtcEngine,
} from "../rtc.statemgr";

/*
    Common states/actions for all users (hp, cp, etc)
*/

export abstract class UserStateMgr<
  StreamChatCli extends GenericChatCli,
  RtcChannelMgr extends GenericRtcChannelMgr,
  RtcEngine extends GenericRtcEngine<RtcChannelMgr>,
  RtcMgr extends GenericRtcMgr<RtcEngine, RtcChannelMgr>,
> extends BaseStateMgr {
  pubSubNotifDisplayRsm: PubSubNotificationsRsm;

  me$: Rx.BehaviorSubject<SimpleUserWithProfilePhoto>;
  profilePhoto$: Rx.Observable<Option.Option<ImageSrc>>; // derivced value for easier access

  chatStateMgr: ChatStateMgr<StreamChatCli>;
  rtcMgr$ = new Rx.BehaviorSubject<RtcMgr | null>(null);

  constructor(
    readonly p: {
      myId: Id<"users">;
      me: SimpleUserWithProfilePhoto;
      apiMgr: ApiMgr;
      convex: ConvexClient;
      chatCreds$: Rx.BehaviorSubject<
        { publicApiKey: string; token: string } | undefined | null
      >;
      rtcCreds$: Rx.BehaviorSubject<RtcCredentials | null>;
      firebaseMgr: FirebaseJsMgr;
      mkChatCli: (p: { apiKey: string }) => StreamChatCli;
      mkRtcMgr: (p: RtcCredentials) => RtcMgr;
    }
  ) {
    super({ apiMgr: p.apiMgr, convex: p.convex });
    this.me$ = new Rx.BehaviorSubject(p.me);
    this.profilePhoto$ = this.me$.pipe(
      RxO.map((me) =>
        Option.fromNullable(me.profilePhoto).pipe(
          Option.map((url) => new ImageSrc({ _tag: "URL", url }))
        )
      )
    );

    this.convex.onUpdate(api.User.UserFns.getMbMe, {}, (mbMe) => {
      console.log("$$$$$$$MB ME! ", mbMe);
      if (mbMe !== null) {
        this.me$.next(mbMe);
      }
    });

    this.pubSubNotifDisplayRsm = new PubSubNotificationsRsm({
      userId: p.myId,
      convexCli: p.convex,
    });
    this.pubSubNotifDisplayRsm.subscribeSync();

    this.pubSubNotifDisplayRsm.state$.subscribe((notif) => {
      if (notif.notification !== null) {
        console.log("$$$$$$PUB SUB NOTIF DISPLAY RSM! ", notif);
      }
    });

    this.chatStateMgr = new ChatStateMgr<StreamChatCli>({
      apiMgr: p.apiMgr,
      convex: p.convex,
      mySimpleProfile: { id: p.myId, name: p.me.name },
      chatCreds$: p.chatCreds$,
      mkChatCli: p.mkChatCli,
    });

    p.rtcCreds$.subscribe((rtcCreds) => {
      console.log("$$$$$$RTC CREDENTIALS! ", rtcCreds);
      if (rtcCreds !== null) {
        this.rtcMgr$.next(p.mkRtcMgr(rtcCreds));
      } else {
        this.rtcMgr$.next(null);
      }
    });
  }

  signout = Effect.gen(this, function* () {
    const signoutRes = yield* Effect.promise(() =>
      this.p.firebaseMgr.signOut()
    );
    return signoutRes;
  });
}

export function useSetupUStateMgr<
  StreamChatCli extends GenericChatCli,
  RtcChannelMgr extends GenericRtcChannelMgr,
  RtcEngine extends GenericRtcEngine<RtcChannelMgr>,
  RtcMgr extends GenericRtcMgr<RtcEngine, RtcChannelMgr>,
  PlatUStateMgr extends UserStateMgr<
    StreamChatCli,
    RtcChannelMgr,
    RtcEngine,
    RtcMgr
  >,
>(
  apiMgr: ApiMgr,
  convex: ConvexClient,
  firebaseMgr: FirebaseJsMgr,
  me: SimpleUserWithProfilePhoto,
  rtcCreds$: Rx.BehaviorSubject<RtcCredentials | null>,
  mkChatCli: (p: { apiKey: string }) => StreamChatCli,
  mkPlatUStateMgr: (p: {
    me: SimpleUserWithProfilePhoto;
    apiMgr: ApiMgr;
    convex: ConvexClient;
    firebaseMgr: FirebaseJsMgr;
    chatCreds$: Rx.BehaviorSubject<
      { publicApiKey: string; token: string } | undefined | null
    >;
    rtcCreds$: Rx.BehaviorSubject<RtcCredentials | null>;
    mkChatCli: (p: { apiKey: string }) => StreamChatCli;
  }) => PlatUStateMgr
): Option.Option<PlatUStateMgr> {
  const myChatCreds$ = useQuery$(api.User.UserFns.getMyChatCreds, {});

  const mbUStateMgr = useMemo(
    () =>
      mkPlatUStateMgr({
        me,
        apiMgr,
        convex,
        firebaseMgr,
        chatCreds$: myChatCreds$,
        rtcCreds$: rtcCreds$,
        mkChatCli,
      }),
    [rtcCreds$, convex]
  );

  // const mbUStateMgr = useObservableEagerState(mbUStateMgr$);

  return Option.some(mbUStateMgr);
}
