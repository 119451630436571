function EllipseIcon(p: { size: 2 | 3; fill: "gray" | "black" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={p.size.toString()}
      height={p.size.toString()}
      viewBox={`0 0 ${p.size} ${p.size}`}
      fill="none"
    >
      <circle
        cx={p.size}
        cy={p.size}
        r={p.size}
        fill={p.fill === "black" ? "#161616" : "#DBDAEC"}
      />
    </svg>
  );
}

export const EllipseMenuIcon: React.FC<{
  size: 2 | 3;
  fill: "gray" | "black";
}> = (p) => {
  return (
    <div className="flex flex-col gap-[2px]">
      <EllipseIcon {...p} />
      <EllipseIcon {...p} />
      <EllipseIcon {...p} />
    </div>
  );
};

export const CircleRingsIcon: React.FC<{ size?: 2 | 3 }> = ({ size = 2 }) => {
  return (
    <svg width={size * 27} height={size * 17} viewBox="0 0 54 34" fill="none">
      <path
        d="M52.7552 17C52.7552 25.5508 45.806 32.5 37.2552 32.5C33.2769 32.5 29.6602 31.0017 26.9219 28.5217C30.0994 25.7058 32.0885 21.5725 32.0885 17C32.0885 12.4275 30.0994 8.29417 26.9219 5.47833C29.6602 2.99833 33.2769 1.5 37.2552 1.5C45.806 1.5 52.7552 8.44917 52.7552 17Z"
        stroke="#1D1626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.0898 17C32.0898 21.5725 30.1007 25.7058 26.9232 28.5217C24.1848 31.0017 20.5682 32.5 16.5898 32.5C8.03901 32.5 1.08984 25.5508 1.08984 17C1.08984 8.44917 8.03901 1.5 16.5898 1.5C20.5682 1.5 24.1848 2.99833 26.9232 5.47833C30.1007 8.29417 32.0898 12.4275 32.0898 17Z"
        stroke="#1D1626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
