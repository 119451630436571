import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { InversePrimaryButton } from "@webapp/components/primitives/button";
import { SimpleTableList } from "@webapp/componentstable.components";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { useMutation, useQuery } from "convex/react";
import { useOnce } from "frontend-shared/src/util";
import React from "react";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import { useNavigate } from "react-router-dom";
import { api } from "shared/convex/_generated/api";
import { UROUTES } from "shared/routes/u.routes";
import {
  activeClientsTableProps,
  ongoingHcApptsTableProps,
  pastHcApptsTableProps,
  pendingClientsTableProps,
  upcomingHcApptsTableProps,
} from "./clients-page.components";
import { RegisterNewClientFormRightDrawer } from "../right-drawer-action/register-new-client.form";
import { useUMgr } from "../../../u.webstatemgr";

export const HpMyDashboardClientsPage: React.FC = () => {
  const navigate = useNavigate();
  const hpState = useHpState();
  const now = useOnce(() => new Date().getTime());

  const resendInviteClientEmail = useMutation(
    api.Screens.Hp.Dashboard.ClientsScreenFns.onResendInviteClientEmail
  );

  const myPendingClientRequests = useQuery(
    api.Screens.Hp.Dashboard.ClientsScreenFns.getMyPendingClientRequests,
    {}
  );

  const myActiveClients = useQuery(
    api.Screens.Hp.Dashboard.ClientsScreenFns.getMyActiveClients,
    {}
  );

  const myOngoingHcSessions = useQuery(
    api.Sessions.Hc.HcSessionFns.getMyOngoingHcSessionsForHpPortal,
    {}
  );

  const myUpcomingHcAppts = useQuery(
    api.Screens.Hp.Dashboard.ClientsScreenFns.getMyUpcomingHcAppts,
    { now }
  );

  const myPastHcAppts = useQuery(
    api.Screens.Hp.Dashboard.ClientsScreenFns.getMyPastHcAppts,
    {}
  );

  const endHcSession = useMutation(
    api.Screens.Hp.Dashboard.ClientsScreenFns.onEndSessionClick
  );

  if (
    myOngoingHcSessions === undefined ||
    myPendingClientRequests === undefined ||
    myActiveClients === undefined ||
    myUpcomingHcAppts === undefined ||
    myPastHcAppts === undefined
  ) {
    return <FullContainerLoadingSpinner />;
  }

  if (
    myOngoingHcSessions.length === 0 &&
    myActiveClients.clients.length === 0 &&
    myPendingClientRequests.length === 0 &&
    myUpcomingHcAppts.length === 0
  ) {
    return <EmptyState />;
  }

  return (
    <div className="relative">
      <div className="flex justify-between items-center pb-8">
        <h3 className="font-outfit text-[24px] text-vid-black-900">
          Appointments
        </h3>
        <div>
          <InversePrimaryButton
            title="Invite a client"
            onClick={() => {
              hpState.dashboardState.openRightNav({
                _tag: "REGISTER_NEW_CLIENT",
              });
            }}
          />
        </div>
      </div>

      <div className="">
        <SimpleTableList
          tables={[
            pendingClientsTableProps(
              myPendingClientRequests.map((c) => ({
                clientInfo: {
                  name: c.clientEmail,
                  profilePhoto: null,
                },
                cta: {
                  title: "Resend invitation",
                  onClick: () => {
                    resendInviteClientEmail({
                      clientEmail: c.clientEmail,
                    }).then((r) => {
                      console.log(
                        "RESULT OF RESENDING INVITE CLIENT EMAIL! ",
                        r
                      );
                      hpState.dashboardState.showBottomToast({
                        msg: "Invite resent!",
                      });
                    });
                  },
                },
              }))
            ),
            ongoingHcApptsTableProps(
              myOngoingHcSessions.map((ohc) => ({
                clientInfo: {
                  name: ohc.clientInfo.name,
                  profilePhoto: ohc.clientInfo.image,
                },
                rejoinCta: {
                  onClick: () => {
                    navigate(
                      UROUTES.SESSIONS.SESSION_ID.RTC.buildPath({
                        sessionId: ohc.baseSessionId,
                      })
                    );
                  },
                },
                endSessionCta: {
                  onClick: () => {
                    endHcSession({ baseSessionId: ohc.baseSessionId }).then(
                      (r) => {
                        console.log("RESULT OF ENDING HC SESSION! ", r);
                        hpState.dashboardState.showBottomToast({
                          msg: "Successfully ended session",
                        });
                      }
                    );
                  },
                },
              }))
            ),
            upcomingHcApptsTableProps(
              myUpcomingHcAppts.map((u) => ({
                clientInfo: u.clientInfo,
                clientId: u.clientInfo.id,
                appt: {
                  date: new Date(u.startsAt),
                },
              }))
            ),
            activeClientsTableProps(
              (c) => {
                navigate(
                  UROUTES.HP.MY.DASHBOARD.CLIENTS.CLIENT_ID.PAST_SESSIONS.buildPath(
                    {
                      clientId: c.clientId,
                    }
                  )
                );
              },
              myActiveClients.clients.map((c) => ({
                clientInfo: {
                  id: c.clientId,
                  name: c.name!,
                  profilePhoto: c.profilePhoto?.medium ?? null,
                },
                clientId: c.clientId,
                startSession: {
                  onClick: () => {
                    hpState.dashboardState.openRightNav({
                      _tag: "START_CLIENT_SESSION",
                      client: {
                        id: c.clientId,
                        name: c.name!,
                        email: c.email,
                        profilePhoto: c.profilePhoto?.medium ?? null,
                      },
                    });
                  },
                },
              }))
            ),
            pastHcApptsTableProps(myPastHcAppts),
          ]}
        />
      </div>
    </div>
  );
};

const EmptyState: React.FC = () => {
  const uMgr = useUMgr();
  return (
    <div className="flex flex-col flex-1 justify-center items-center">
      <div className="flex gap-8">
        <AddAClientCard
          onClick={() => {
            uMgr.rightDrawerVM.openEvt(<RegisterNewClientFormRightDrawer />);
          }}
        />
      </div>
    </div>
  );
};

const AddAClientCard: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="flex flex-col justify-center items-center cursor-pointer p-8"
    >
      <h4 className="font-outfit font-medium text-default-purple text-4xl leading-9 mb-4">
        Invite your first client
      </h4>
      <p className="font-sans font-light text-md mb-4">
        We'll send an invitation and let you know when it's accepted.
      </p>
      <div className="bg-vid-purple p-buttons px-24 rounded-buttons text-white flex justify-center items-center">
        {`Invite`}
      </div>
    </div>
  );
};
