/// <reference lib="dom" />
/// <reference lib="dom.iterable" />

import * as Sentry from "@sentry/react";
import { ConvexProviderWithAuth, ConvexReactClient } from "convex/react";
import { useAuthFromFirebase } from "frontend-shared/src/firebase/use-firebase-auth";
import { useOnce } from "frontend-shared/src/util";
import type React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { AppConfig, firebaseMgr } from "./app-config";
import "./index.css";
import { router } from "./router";
import { FullScreenLoadingSpinner } from "./loading";
import { ConvexCliProver, useSetupConvexCli } from "./convex-cli";

console.log("INITING APP ENV! ", AppConfig.appEnv);

Sentry.init({
  dsn: "https://8844421cb03ca5925e6a30733a607b84@o4507470297694208.ingest.us.sentry.io/4507470302019584",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const CONVEX_URL = import.meta.env["VITE_CONVEX_URL"]!;

const WithProviders: React.FC = () => {
  const webConvexReact = useOnce(() => new ConvexReactClient(CONVEX_URL));
  const useAuth = useAuthFromFirebase({ firebaseMgr: firebaseMgr });

  const convexCli = useSetupConvexCli(firebaseMgr);

  if (convexCli === undefined) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <ConvexCliProver.Provider value={convexCli}>
      <ConvexProviderWithAuth client={webConvexReact} useAuth={() => useAuth}>
        <RouterProvider router={router} />
      </ConvexProviderWithAuth>
    </ConvexCliProver.Provider>
  );
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <WithProviders />
);
