import { ControlPanelSectionView } from "@/src/domains/sessions/rtc/rooms/control-panel";
import { useAssumedCurrentChannel } from "@pages/u/u.webstatemgr";
import { useCallStateHooks } from "@stream-io/video-react-sdk";
import { PaginatedGridView } from "@webapp/componentslive-session/main-room/stage-participant-layouts/paginated-grid.view";
import { MainRoomGroupSpeakerViewLayout } from "@webapp/componentslive-session/main-room/stage-participant-layouts/speaker-view";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { useMutation, useQuery } from "convex/react";
import { Match } from "effect";
import { MainRoomStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/main-room.statemgr";
import { useObservableEagerState } from "observable-hooks";
import React, { useEffect, useMemo } from "react";
import { RD } from "shared/base-prelude";
import { api } from "shared/convex/_generated/api";
import { MainRoomMenuTB } from "shared/convex/Sessions/Participants/Views/MainRoom.Types";
import type { KnownToolTag } from "shared/schemas/session/ongoing/known-tool.schemas";
import type { ParticipantsViewMode } from "shared/schemas/session/state/stage-layout.schemas";
import { SessionWrapup } from "src/domains/sessions/analysis/wrapup/wrapup";
import { MainRoomMenu } from "src/domains/sessions/main-room-menu.components";
import { QuickActionButtonPanel } from "src/domains/sessions/quick-action/quick-action-button-panel";
import { LiveSessionComponents } from "../live-session.components";
import { EMDRBallStageViewCode } from "../right-nav/therapy-tools/emdr/emdr-ball-vis-code";
import { MeditationPreview } from "../right-nav/therapy-tools/meditation.tools";
import { StageTopNav } from "./components/stage-top-nav.fc";
import {
  MaximizedContentView,
  MeOnlyParticipantView,
  MinimizedContentView,
  OneOnOneParticipantView,
} from "./participant-layouts";
import { MockBreakoutRoomsDnd } from "@/src/domains/sessions/rtc/rooms/breakout-rooms-dnd";

export interface MainRoomContainerProps {
  mainRoomStateMgr: MainRoomStateMgr;
}

export const MainRoomContainer = ({
  mainRoomStateMgr,
}: MainRoomContainerProps) => {
  const { useMicrophoneState, useCameraState } = useCallStateHooks();
  const { isMute: isAudioMute } = useMicrophoneState();
  const { isMute: isVideoMute } = useCameraState();

  const channelMgr = useAssumedCurrentChannel();

  const isLeftNavToolsOpen = useObservableEagerState(
    mainRoomStateMgr.isLeftNavToolsOpen$
  );

  useEffect(() => {
    mainRoomStateMgr.stateSyncMgr.registerEnteredMainRoom();

    const cleanup = () => {
      mainRoomStateMgr.stateSyncMgr.registerClickedLeaveSession();
    };

    return () => {
      cleanup();
    };
  }, []);

  const stageLayout = useQuery(
    api.Sessions.Participants.ParticipantFns.getDerivedSessionStageState,
    {
      baseSessionId: mainRoomStateMgr.baseSessionId,
    }
  );

  const setMyMenuViewState = useMutation(
    api.Sessions.Participants.Views.MainRoomFns.setMyMenuViewState
  ).withOptimisticUpdate((localStore, { viewState }) => {
    localStore.setQuery(
      api.Sessions.Participants.Views.MainRoomFns.getMyMenuViewState,
      { baseSessionId: mainRoomStateMgr.baseSessionId },
      viewState
    );
  });

  const sessionConfig = useObservableEagerState(
    mainRoomStateMgr.stateSyncMgr.baseSessionConfigMgr.state$
  );

  const leavingSessionResult = useObservableEagerState(
    mainRoomStateMgr.leavingSessionResult$
  );

  useEffect(() => {
    console.log("SESSION CONFIG! ", sessionConfig);
  }, [sessionConfig]);

  if (stageLayout === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  if (
    stageLayout.mode.tag === "CONTENT" &&
    stageLayout.mode.contentScreensizeMode.mode === "FULL_SCREEN"
  ) {
    return (
      <FullScreenContentView
        content={stageLayout.mode.content}
        mainRoomStateMgr={mainRoomStateMgr}
      />
    );
  }

  return (
    <div className="w-screen h-screen bg-vid-black-900 flex flex-col relative overflow-hidden">
      {RD.isPending(leavingSessionResult) && (
        <div className="absolute inset-0 bg-black bg-opacity-80 z-50  flex items-center justify-center">
          <FullContainerLoadingSpinner />
        </div>
      )}
      <div className="flex-1 flex gap-8 min-h-0 py-8 px-2 md:px-[36px] max-w-full">
        {isLeftNavToolsOpen && (
          <div className="basis-[60px] self-stretch grow-0 shrink-0 relative hidden md:flex">
            <div className="absolute inset-0 z-20 flex flex-col-reverse items-start gap-4 py-8">
              <LiveSessionComponents.BottomLeftButtons.ToggleNavButton
                arrowDirection="right"
                onClick={() => {
                  mainRoomStateMgr.toggleLeftToolsNav();
                }}
              />
              {/* <QuickActionToolsPanel mainRoomStateMgr={mainRoomStateMgr} /> */}
              <QuickActionButtonPanel mainRoomStateMgr={mainRoomStateMgr} />
            </div>
          </div>
        )}
        <div className="flex-1 min-h-0 flex flex-col gap-4">
          {/* <NotesTabWrapup sessionId={mainRoomStateMgr.baseSessionId} /> */}
          <StageArea
            mainRoomStateMgr={mainRoomStateMgr}
            isLeftNavToolsOpen={isLeftNavToolsOpen}
            onCloseTopPreview={() => {
              mainRoomStateMgr.stateSyncMgr.stageLayoutMgr.closeContentViewForAllParticipants();
            }}
          />
        </div>
        <div className="absolute right-0 top-0 bottom-0 md:relative flex flex-col py-0">
          <MainRoomMenu
            mainRoomStateMgr={mainRoomStateMgr}
            chatStateMgr={mainRoomStateMgr.tools.chatMgr}
          />
          {/* <MainRoomRightNav
            baseSessionId={mainRoomStateMgr.baseSessionId}
            chatStateMgr={mainRoomStateMgr.tools.chatMgr}
            mainRoomStateMgr={mainRoomStateMgr}
            settings={settings}
          /> */}
        </div>
      </div>
      <div className="basis-[100px] grow-0 shrink-0 bg-white flex flex-col justify-center">
        <ControlPanelSectionView
          isAudioMute={isAudioMute}
          isVideoMute={isVideoMute}
          onAudioButtonClick={() => {
            channelMgr.microphone
              .toggle()
              .then((r) => {
                console.log("RESULT OF MICROPHONE TOGGLE! ", r);
              })
              .catch((e) => {
                console.log("ERROR TOGGLE MICROPHONE! ", e);
              });
          }}
          onVideoButtonClick={() => {
            channelMgr.camera
              .toggle()
              .then((r) => {
                console.log("RESULT OF CAMERA TOGGLE! ", r);
              })
              .catch((e) => {
                console.log("ERROR TOGGLE CAMERA! ", e);
              });
          }}
          onEndCallButtonClick={() => {
            mainRoomStateMgr.onCloseCallClick();
          }}
          onMagicPenClick={() => {
            setMyMenuViewState({
              baseSessionId: mainRoomStateMgr.baseSessionId,
              viewState: MainRoomMenuTB.open.tools.all(),
            }).catch();
          }}
          onSettingsClick={() => {
            console.log("SETTINGS CLICKED!");
            setMyMenuViewState({
              baseSessionId: mainRoomStateMgr.baseSessionId,
              viewState: MainRoomMenuTB.open.settings(),
            }).catch();
          }}
        />
      </div>
    </div>
  );
};

const StageArea = ({
  mainRoomStateMgr,
  isLeftNavToolsOpen,
  onCloseTopPreview,
}: {
  mainRoomStateMgr: MainRoomStateMgr;
  isLeftNavToolsOpen: boolean;
  onCloseTopPreview: () => void;
}) => {
  const stageLayout = useObservableEagerState(
    mainRoomStateMgr.stateSyncMgr.stageLayoutMgr.state$
  );

  const channelMgr = useAssumedCurrentChannel();

  const otherParticipants$ = channelMgr.state.remoteParticipants$;

  return (
    <div className="flex-1 min-h-0 flex flex-col gap-4">
      <StageTopNav mainRoomStateMgr={mainRoomStateMgr} />
      <div className="flex-1 flex flex-col  min-w-0 min-h-0 gap-4 max-w-full relative">
        {Match.value(stageLayout.mode).pipe(
          Match.when(
            { tag: "CONTENT" },
            ({ content, contentScreensizeMode }) => {
              const contentView = matchContentView(content, mainRoomStateMgr);
              return Match.value(contentScreensizeMode.mode).pipe(
                Match.when("FULL_SCREEN", () => {
                  return (
                    <MaximizedContentView
                      contentView={contentView}
                      otherParticipants$={otherParticipants$}
                      mainRoomStateMgr={mainRoomStateMgr}
                    />
                  );
                }),
                Match.when("TOP_PREVIEW", () => {
                  return (
                    <MinimizedContentView
                      contentView={contentView}
                      otherParticipants$={otherParticipants$}
                      onCloseTopPreview={() => {
                        onCloseTopPreview();
                      }}
                      mainRoomStateMgr={mainRoomStateMgr}
                    />
                  );
                }),
                Match.exhaustive
              );
            }
          ),
          Match.when(
            { tag: "PARTICIPANTS_VIEW" },
            ({ participantsViewMode }) => {
              return <ParticipantsView mode={participantsViewMode.mode} />;
            }
          ),
          Match.exhaustive
        )}
        {!isLeftNavToolsOpen && (
          <div className="absolute bottom-4 left-4 ">
            <LiveSessionComponents.BottomLeftButtons.ToggleNavButton
              arrowDirection="left"
              onClick={() => {
                mainRoomStateMgr.toggleLeftToolsNav();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const FullScreenContentView: React.FC<{
  content: KnownToolTag;
  mainRoomStateMgr: MainRoomStateMgr;
}> = ({ content, mainRoomStateMgr }) => {
  const otherParticipants$ =
    useAssumedCurrentChannel().state.remoteParticipants$;
  const contentView = useMemo(
    () => matchContentView(content, mainRoomStateMgr),
    [content, mainRoomStateMgr]
  );

  return (
    <div className="w-screen h-screen bg-vid-black-900 flex flex-col relative overflow-hidden p-6">
      <MaximizedContentView
        contentView={contentView}
        otherParticipants$={otherParticipants$}
        mainRoomStateMgr={mainRoomStateMgr}
      />
    </div>
  );
};

function matchContentView(
  content: KnownToolTag,
  mainRoomStateMgr: MainRoomStateMgr
): React.ReactNode {
  return Match.value(content).pipe(
    Match.when("EMDR", () => {
      return (
        <div className="flex-1 flex flex-col justify-center">
          <EMDRBallStageViewCode
            state$={mainRoomStateMgr.stateSyncMgr.tools.emdr.state$}
            soundType="beep"
            soundDuration={0.3}
          />
        </div>
      );
    }),
    // Match.tag("SESSION_SPECIFIC", (r) =>
    //   stageRenders.sessionSpecificContentView(r.tool)
    // ),
    Match.when("MEDITATION", () => {
      return (
        <MeditationPreview
          meditationMgr={mainRoomStateMgr.tools.meditationMgr}
        />
      );
    }),
    Match.when("REVIEW", () => {
      return (
        <div className="flex-1 flex flex-col items-center bg-white p-16">
          <SessionWrapup sessionId={mainRoomStateMgr.baseSessionId} />
        </div>
      );
    }),
    Match.when("BREAKOUT_ROOMS", () => {
      return <MockBreakoutRoomsDnd />;
    }),
    Match.orElse(() => <></>)
  );
}

const ParticipantsView = ({
  mode,
}: {
  mode: ParticipantsViewMode;
}): React.ReactNode => {
  const channelMgr = useAssumedCurrentChannel();
  const me$ = channelMgr.state.localParticipant$;
  const otherParticipants$ = channelMgr.state.remoteParticipants$;

  return Match.value(mode).pipe(
    Match.when({ tag: "SELF_VIEW" }, () => {
      return <MeOnlyParticipantView meParticipant$={me$} />;
    }),
    Match.when({ tag: "ONE_ON_ONE" }, ({ otherParticipantId }) => {
      return (
        <OneOnOneParticipantView
          meAsParticipant$={me$}
          otherParticipants$={otherParticipants$}
          otherParticipantId={otherParticipantId}
          aspectRatio="16/9"
        />
      );
    }),
    Match.when({ tag: "GROUP" }, ({ groupViewMode }) => {
      return Match.value(groupViewMode.mode).pipe(
        Match.when({ tag: "GALLERY" }, () => {
          return <PaginatedGridView />;
        }),
        Match.when({ tag: "SPOTLIGHT_SPEAKER" }, () => {
          return <MainRoomGroupSpeakerViewLayout />;
        }),
        Match.exhaustive
      );
    }),
    Match.exhaustive
  );
};
