import { createEvent, createStore, sample } from "effector";
import type { ReactNode } from "react";

export class RightDrawerVM {
  closeEvt = createEvent();
  openEvt = createEvent<ReactNode>();

  isOpen$ = createStore<ReactNode | null>(null);

  constructor() {
    sample({
      clock: this.openEvt,
      source: this.isOpen$,
      fn: (_, node) => node,
      target: this.isOpen$,
    });

    sample({
      source: this.isOpen$,
      clock: this.closeEvt,
      fn: (_) => null,
      target: this.isOpen$,
    });
  }
}
