import {
  ControlPanelSectionView,
  type ControlPanelSectionViewProps,
} from "./control-panel";
import {
  RoomRightDrawerMenuContainer,
  type RoomRightDrawerMenuContainerProps,
} from "./room-right-drawer";

export const RoomLayoutView: React.FC<{
  stageAreaView: React.ReactNode;
  rightDrawerProps: RoomRightDrawerMenuContainerProps;
  isRightDrawerOpen: boolean;
  controlPanelProps: ControlPanelSectionViewProps;
}> = ({
  stageAreaView,
  rightDrawerProps,
  controlPanelProps,
  isRightDrawerOpen,
}) => {
  return (
    <div className="w-screen h-screen bg-vid-black-900 flex flex-col relative overflow-hidden">
      <div className="flex-1 flex min-h-0 gap-4 relative">
        <div className="flex-1 flex flex-col gap-4 relative">
          {stageAreaView}
        </div>
        {isRightDrawerOpen && (
          <div className="basis-[360px] grow-0 shrink-0 py-6 px-6 bg-transparent flex flex-col">
            <RoomRightDrawerMenuContainer {...rightDrawerProps} />
          </div>
        )}
      </div>

      <div className="basis-[100px] grow-0 shrink-0 bg-white flex flex-col justify-center">
        <ControlPanelSectionView {...controlPanelProps} />
      </div>
    </div>
  );
};
