import { Option } from "effect";
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
} from "effector";
import { debounce } from "patronum";
import { epipe } from "shared/base-prelude";
import type { Id } from "shared/convex/_generated/dataModel";
import type { EmbedContent } from "shared/convex/Community/CommunityDiscussion.Types";
import type { FileSchemas } from "shared/schemas/file.schemas";
import {
  MediaSelectAndUploadVM,
  type MediaSelectDeps,
} from "../media/media-select-upload.vm";

type SubmitPostAction = (args: {
  postId: Id<"communityPosts">;
  content?: string;
  media?: {
    publicId: string;
    mediaType: FileSchemas.MediaType;
  } | null;
  embedContent?: EmbedContent | null;
}) => Promise<void>;

type PostSetup = {
  postId: Id<"communityPosts">;
  imageUploadUrl: string;
  videoUploadUrl: string;
};

type MediaInputState =
  | { _tag: "USER_WILL_UPLOAD" }
  | { _tag: "EMBEDDED_IN_CONTENT"; embedData: EmbedContent };

export class CreateCommunityPostVM {
  readonly contentVM: PostTextContentVM;
  readonly mediaVM: MediaSelectAndUploadVM;

  submitPostEvt = createEvent();
  submitPostFx = createEffect<void, void>();
  $isSubmitting = this.submitPostFx.pending;
  $isSubmitComplete = createStore<boolean>(false);

  readonly $mediaInputState = createStore<MediaInputState>({
    _tag: "USER_WILL_UPLOAD",
  });

  constructor(p: {
    setup: PostSetup;
    communitySlug: string;
    submitPost: SubmitPostAction;
    mediaUploadDeps: MediaSelectDeps;
    saveContentToServer: (p: {
      textContent: string;
      embedContent: EmbedContent | null;
    }) => Promise<void>;
    getPresignedUploadUrl: (p: {
      mediaType: FileSchemas.MediaType;
    }) => Promise<{
      uploadUrl: string;
    }>;
    getProcessedMediaUrl: (p: {
      publicId: string;
      mediaType: FileSchemas.MediaType;
    }) => Promise<{ processedUrl: string }>;
    onMediaSuccessfullyUploadedToCloudinary: (p: {
      publicId: string;
      format: string;
      mediaType: FileSchemas.MediaType;
    }) => Promise<any>;
  }) {
    this.contentVM = new PostTextContentVM({
      saveContentToServer: p.saveContentToServer,
    });
    this.mediaVM = new MediaSelectAndUploadVM({
      deps: p.mediaUploadDeps,
      getPresignedUrl: p.getPresignedUploadUrl,
      getProcessedMediaUrl: (r) => p.getProcessedMediaUrl(r),
      onMediaSuccessfullyUploadedToCloudinary:
        p.onMediaSuccessfullyUploadedToCloudinary,
    });

    const media$ = combine({
      publicId: this.mediaVM.$publicId,
      mediaType: this.mediaVM.$mediaType,
    }).map(({ publicId, mediaType }) =>
      epipe(
        Option.all([
          Option.fromNullable(publicId),
          Option.fromNullable(mediaType),
        ]),
        Option.map(([publicId, mediaType]) => ({
          publicId,
          mediaType,
        })),
        Option.getOrNull
      )
    );

    const content = this.contentVM.getCombinedData();
    const formData$ = combine({
      content,
      media: media$,
    });

    this.submitPostFx.use(async () => {
      const data = formData$.getState();
      if (!data.content) {
        throw new Error("Content is required");
      }

      await p.submitPost({
        postId: p.setup.postId,
        content: data.content.text,
        media: data.media,
        embedContent: data.content.youtube,
      });
    });

    this.$isSubmitComplete.on(this.submitPostFx.done, () => true);

    this.submitPostEvt.watch(() => {
      this.submitPostFx();
    });

    // Update media input state when YouTube embed is detected
    sample({
      clock: this.contentVM.$youTubeEmbedData,
      fn: (youTubeData) => {
        return {
          _tag: "EMBEDDED_IN_CONTENT",
          embedData: youTubeData,
        } as MediaInputState;
      },
      target: this.$mediaInputState,
    });

    // Clear any uploaded media when switching to embedded content
    sample({
      clock: this.$mediaInputState,
      filter: (state) => state._tag === "EMBEDDED_IN_CONTENT",
      target: this.mediaVM.reset,
    });
  }
}

type SaveContentToServerArgs = {
  textContent: string;
  embedContent: EmbedContent | null;
};

class PostTextContentVM {
  public textChanged = createEvent<string>();
  public $text = createStore<string>("");
  public $debouncedText = createStore<string>("");
  public $youTubeEmbedData = createStore<EmbedContent | null>(null);

  public debouncedTextChanged = debounce(this.textChanged, 1000);
  public saveContentEff = createEffect<SaveContentToServerArgs, void>();

  private youtubeRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:shorts\/|(?:watch\?v=))|(?:youtu\.be\/))([a-zA-Z0-9_-]{11})/;

  constructor(p: {
    saveContentToServer: (content: {
      textContent: string;
      embedContent: EmbedContent | null;
    }) => Promise<void>;
  }) {
    this.$text.on(this.textChanged, (_, text) => text);
    this.$debouncedText.on(this.debouncedTextChanged, (_, text) => text);

    sample({
      clock: this.textChanged,
      fn: (text) => {
        console.log("text", text);
        const match = this.youtubeRegex.exec(text);
        console.log("match", match, typeof match);
        if (!match) return null;

        const videoId = match[1];
        console.log("videoId", videoId);
        return {
          url: `https://www.youtube.com/embed/${videoId}`,
          type: "youtube",
          // title: "YouTube Video",
          // description: "A YouTube video",
          // thumbnail: `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`,
          // metadata: null,
        } as EmbedContent;
      },
      target: this.$youTubeEmbedData,
    });

    this.saveContentEff.use(async (content) => {
      await p.saveContentToServer(content);
    });

    const combinedData = this.getCombinedData();

    sample({
      clock: combinedData,
      fn: (combinedData) => {
        return {
          textContent: combinedData.text,
          embedContent: combinedData.youtube,
        };
      },
      target: this.saveContentEff,
    });
  }

  public getCombinedData() {
    return combine({
      text: this.$debouncedText,
      youtube: this.$youTubeEmbedData,
    });
  }
}
