import { InversePrimaryButton } from "@/src/components/primitives/button";
import { createEvent, createStore, sample } from "effector";
import { useUnit } from "effector-react";
import { ArrowLeft, ChevronLeft, ChevronRight } from "lucide-react";
import React from "react";
import { InfoSection } from "@/src/components/primitives/info-section";
import { ArrowLeftSvg, ArrowRightSvg } from "@/src/assets/icons/arrow.icons";

type DateInfo = {
  day: string;
  date: number;
  month: string;
};

export class AvailabilityCalendarVM {
  // Events
  readonly headerLeftArrowClickEvt = createEvent();
  readonly headerRightArrowClickEvt = createEvent();
  readonly timeSlotClickEvt = createEvent<{ date: Date; time: string }>();

  // Stores
  readonly $currentDate = createStore<Date>(new Date());
  readonly $displayDates = this.$currentDate.map((currentDate) =>
    Array.from({ length: 3 }).map((_, i) => {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() + i);
      return date;
    })
  );

  readonly $formattedDates = this.$displayDates.map((dates) =>
    dates.map((date) => this.formatDate(date))
  );

  // Time slots store - in real app this would be populated from API
  readonly $timeSlots = createStore<string[]>(["8:30am", "9:30am", "10:30am"]);

  constructor() {
    // Handle navigation
    sample({
      clock: this.headerLeftArrowClickEvt,
      source: this.$currentDate,
      fn: (currentDate) => {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() - 3);
        return newDate;
      },
      target: this.$currentDate,
    });

    sample({
      clock: this.headerRightArrowClickEvt,
      source: this.$currentDate,
      fn: (currentDate) => {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() + 3);
        return newDate;
      },
      target: this.$currentDate,
    });

    // Log time slot selections - replace with actual booking logic
    this.timeSlotClickEvt.watch(({ date, time }) => {
      console.log(`Selected ${time} on ${date.toDateString()}`);
    });
  }

  private formatDate(date: Date): DateInfo {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return {
      day: days[date.getDay()],
      date: date.getDate(),
      month: months[date.getMonth()],
    };
  }
}

interface Props {
  vm: AvailabilityCalendarVM;
}

interface HeaderProps {
  vm: AvailabilityCalendarVM;
  formattedDates: DateInfo[];
}

export const AvailabilityCalendarHeader: React.FC<HeaderProps> = ({
  vm,
  formattedDates,
}) => {
  return (
    <div className="grid grid-cols-[auto_1fr_auto] items-center gap-4 w-full">
      <button
        onClick={() => vm.headerLeftArrowClickEvt()}
        className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-100"
      >
        <ArrowLeftSvg color={"#4D4C4E"} />
      </button>

      <div className="grid grid-cols-3 items-center">
        {formattedDates.map((dateInfo, i) => (
          <div
            key={i}
            className="flex items-center justify-center gap-1 font-roboto-flex text-vid-black-900"
          >
            <span>{dateInfo.day}</span>
            <span className="font-bold">{`${dateInfo.month} ${dateInfo.date}`}</span>
          </div>
        ))}
      </div>

      <button
        onClick={() => vm.headerRightArrowClickEvt()}
        className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-100"
      >
        <ArrowRightSvg color={"#4D4C4E"} />
      </button>
    </div>
  );
};

export const AvailabilityCalendar: React.FC<Props> = ({ vm }) => {
  const displayDates = useUnit(vm.$displayDates);
  const formattedDates = useUnit(vm.$formattedDates);
  const timeSlots = useUnit(vm.$timeSlots);

  return (
    <InfoSection
      header={{
        _tag: "CUSTOM",
        content: (
          <AvailabilityCalendarHeader vm={vm} formattedDates={formattedDates} />
        ),
      }}
      omitPadding
    >
      <div className="grid grid-cols-3 gap-4 px-4 py-6">
        {timeSlots.map((time, timeIndex) => (
          <>
            {displayDates.map((date, dateIndex) => (
              <InversePrimaryButton
                key={`${dateIndex}-${timeIndex}`}
                onClick={() => vm.timeSlotClickEvt({ date, time })}
                title={time}
              />
            ))}
          </>
        ))}
      </div>
    </InfoSection>
  );
};
