import DateTimePicker from "react-datetime-picker";

export const FormCardContainer: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <div className={`flex flex-col border rounded-lg p-6`}>{children}</div>
  );
};

export namespace FormComponents {
  export const Input: React.FC<{
    label: string;
    value: string;
    onChange: (value: string) => void;
    type?: string;
    placeholder?: string;
  }> = ({ label, value, onChange, type = "text", placeholder }) => (
    <div className="flex flex-col gap-2 rounded-full">
      <label className="text-gray-500">{label}</label>
      <input
        className="border border-gray-200 rounded-lg p-4"
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );

  export const RadioBtn: React.FC<{ checked: boolean; text?: string }> = ({
    checked,
    text,
  }) => (
    <div
      className={`flex items-center}
      `}
    >
      <div
        className={`flex justify-center items-center border-[1.6px] rounded-full w-8 h-8 cursor-pointer text-lg
      ${checked ? "bg-default-purple text-default-purple" : ""}
      `}
      />
      {text && <div className="ml-3 text-vid-black-900">{text}</div>}
    </div>
  );

  type CalendarPickerProps = {
    onChange: (date: Date | null) => void;
    value: Date | null;
  };

  export const CalendarPicker: React.FC<CalendarPickerProps> = ({
    onChange,
    value,
  }) => {
    return <DateTimePicker onChange={onChange} value={value} />;
  };
}

export const Checkbox: React.FC<{
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  onClick?: (e: React.MouseEvent) => void;
}> = ({ isChecked, onChange, onClick }) => {
  return (
    <div className="relative h-4 aspect-square">
      <input
        type="checkbox"
        checked={isChecked}
        onClick={(e) => {
          onChange(e.currentTarget.checked);
          onClick?.(e);
        }}
        className="peer absolute h-5 aspect-square opacity-0 cursor-pointer z-10"
      />
      <div className="absolute inset-0 rounded-[6px]">
        {isChecked ? (
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
            <rect width="20" height="20" rx="6" fill="#1D1626" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.4979 6.68816C14.6304 6.82728 14.7047 7.01594 14.7047 7.21266C14.7047 7.40937 14.6304 7.59804 14.4979 7.73716L9.20424 13.2977C9.13428 13.3712 9.05123 13.4295 8.95982 13.4693C8.8684 13.5091 8.77043 13.5295 8.67148 13.5295C8.57254 13.5295 8.47456 13.5091 8.38315 13.4693C8.29173 13.4295 8.20868 13.3712 8.13872 13.2977L5.50858 10.5355C5.44113 10.467 5.38732 10.3852 5.35031 10.2947C5.31329 10.2042 5.29381 10.1068 5.293 10.0083C5.29218 9.9098 5.31005 9.81211 5.34556 9.72094C5.38107 9.62977 5.43352 9.54694 5.49983 9.47728C5.56614 9.40763 5.645 9.35254 5.7318 9.31524C5.81859 9.27794 5.91159 9.25917 6.00537 9.26002C6.09915 9.26088 6.19183 9.28134 6.27799 9.32022C6.36416 9.3591 6.44209 9.41562 6.50724 9.48648L8.67125 11.7596L13.4988 6.68816C13.5644 6.61922 13.6423 6.56453 13.728 6.52722C13.8137 6.48991 13.9056 6.4707 13.9984 6.4707C14.0912 6.4707 14.183 6.48991 14.2688 6.52722C14.3545 6.56453 14.4324 6.61922 14.4979 6.68816Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
            <rect
              x="0.5"
              y="0.5"
              width="19"
              height="19"
              rx="5.5"
              fill="white"
              stroke="#1D1626"
            />
            <path
              d="M14.1357 7.0328L14.1358 7.03286C14.1773 7.07655 14.2047 7.14081 14.2047 7.21266C14.2047 7.28451 14.1773 7.34877 14.1358 7.39245L8.84211 12.9529L8.84207 12.953C8.81734 12.979 8.78933 12.9982 8.76031 13.0108C8.73135 13.0234 8.70125 13.0295 8.67148 13.0295C8.64172 13.0295 8.61161 13.0234 8.58266 13.0108C8.55363 12.9982 8.52562 12.979 8.5009 12.953L8.50083 12.9529L5.87069 10.1907L5.87074 10.1906L5.86468 10.1845C5.84381 10.1633 5.82588 10.1367 5.8131 10.1054C5.80031 10.0741 5.79327 10.0396 5.79298 10.0042C5.79268 9.9687 5.79915 9.93403 5.81147 9.90241C5.82377 9.87082 5.84133 9.84371 5.86197 9.82204C5.88254 9.80042 5.90564 9.78474 5.92922 9.77461C5.95272 9.76451 5.97698 9.75978 6.00081 9.76C6.02465 9.76022 6.04889 9.76539 6.07235 9.77598L6.27354 9.33008L6.07235 9.77598C6.09588 9.78659 6.11885 9.80278 6.13919 9.8249L6.13913 9.82495L6.14511 9.83123L8.30911 12.1043L8.67126 12.4847L9.0334 12.1043L13.861 7.0329L13.8611 7.0328C13.8814 7.01141 13.9042 6.99582 13.9276 6.98567L13.728 6.52722L13.9276 6.98567C13.9508 6.97555 13.9748 6.9707 13.9984 6.9707C14.022 6.9707 14.046 6.97555 14.0692 6.98567L14.2688 6.52722L14.0692 6.98567C14.0925 6.99582 14.1153 7.0114 14.1357 7.0328Z"
              fill="white"
              stroke="white"
            />
          </svg>
        )}
      </div>
    </div>
  );
};
