import { combine, createEffect, createEvent, createStore } from "effector";

// Basic types
export type SearchableEntity = {
  id: string;
  label: string;
  details?: string;
  type?: string;
  [key: string]: any;
};

// Base VM that handles just the search/select functionality
export class BaseSearchableDropdownVM<T extends SearchableEntity> {
  // Core Events
  itemSelected = createEvent<T | null>();
  searchTextChanged = createEvent<string>();
  menuOpened = createEvent<void>();
  menuClosed = createEvent<void>();
  itemsSet = createEvent<Array<T>>(); // Renamed from itemsLoaded to be more generic

  // Core Stores
  $items = createStore<Array<T>>([]);
  $selectedItem = createStore<T | null>(null);
  $searchText = createStore<string>("");
  $isOpen = createStore<boolean>(false);
  $filteredItems = combine(
    this.$items,
    this.$searchText,
    (items, searchText) => {
      if (!searchText) return items;
      return items.filter((item) =>
        item.label.toLowerCase().includes(searchText.toLowerCase())
      );
    }
  );

  // Add isLoading store to base class
  $isLoading = createStore<boolean>(false);

  constructor(initialItems?: Array<T>) {
    // Initialize stores
    this.$items.on(this.itemsSet, (_, items) => items);

    // Set initial items if provided
    if (initialItems) {
      this.itemsSet(initialItems);
    }

    this.$selectedItem.on(this.itemSelected, (_, item) => item);
    this.$searchText.on(this.searchTextChanged, (_, text) => text);
    this.$isOpen
      .on(this.menuOpened, () => true)
      .on(this.menuClosed, () => false);

    // Clear search when menu closes
    this.$searchText.reset(
      this.$isOpen.updates.filter({ fn: (isOpen) => !isOpen })
    );

    // Add this line to automatically close menu when item is selected
    this.menuClosed.prepend(this.itemSelected);

    // Initialize isLoading as false and never change it in base class
    this.$isLoading.on(this.itemsSet, () => false);
  }
}

// Extended VM that adds loading capability
export class LoadableSearchableDropdownVM<
  T extends SearchableEntity,
> extends BaseSearchableDropdownVM<T> {
  // Loading specific events and effects
  loadingStarted = createEvent<void>();
  loadingFailed = createEvent<Error>();

  $isLoading = createStore(false);
  $error = createStore<Error | null>(null);

  loadItemsFx = createEffect<void, Array<T>>();

  constructor(loadFn: () => Promise<Array<T>>, initialItems?: Array<T>) {
    super(initialItems);

    // Bind loading effect
    this.loadItemsFx.use(loadFn);

    // Handle loading states
    this.$isLoading
      .on(this.loadingStarted, () => true)
      .on(this.loadItemsFx.doneData, () => false)
      .on(this.loadItemsFx.fail, () => false);

    this.$error
      .on(this.loadItemsFx.failData, (_, error) => error)
      .reset(this.loadItemsFx);

    // Update items when loaded
    this.$items.on(this.loadItemsFx.doneData, (_, items) => items);

    // Trigger loading if no initial items
    if (!initialItems) {
      this.loadItemsFx();
    }
  }

  // Method to refresh data
  reload() {
    this.loadItemsFx();
  }
}
