import { AvatarCircle } from "@webapp/componentsavatar.tc";
import { useQuery } from "convex/react";
import type React from "react";
import { useMemo } from "react";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import { DisplayableTranscriptST } from "shared/schemas/session/review/transcript.schemas";
import { ImageSrc } from "shared/types/miscellaneous.types";

export const ReviewToolsPanel: React.FC<{
  sessionId: Id<"sessionConfig">;
}> = ({ sessionId }) => {
  const recentTranscript = useQuery(
    api.Sessions.Participants.Views.MainRoomFns.getRecentTranscript,
    {
      baseSessionId: sessionId,
    }
  );

  if (recentTranscript === undefined) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4 border-4 border-red-400 h-[300px]">
      <TranscriptDisplay transcript={recentTranscript} />
    </div>
  );
};

const TranscriptDisplay: React.FC<{
  transcript: typeof DisplayableTranscriptST.Encoded;
}> = ({ transcript }) => {
  const asST = useMemo(
    () => DisplayableTranscriptST.fromEncoded(transcript),
    [transcript]
  );

  const asStandardDisplaySegments = useMemo(
    () => asST.asStandardDisplaySegments,
    [asST]
  );

  return (
    <div className="flex flex-col gap-4">
      {asStandardDisplaySegments.map((segment) => (
        <TranscriptSegment key={segment.start} {...segment} />
      ))}
    </div>
  );
};

const TranscriptSegment: React.FC<{
  start: number;
  header: {
    profilePhotoUrl: string | null;
    metaTitle: string;
  };
  body: {
    transcriptText: string;
  };
}> = ({ header, body }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <AvatarCircle
          mbProfilePhoto={ImageSrc.fromMbUrl(header.profilePhotoUrl)}
          size={32}
        />
        <h4 className="text-sm font-semibold">{header.metaTitle}</h4>
      </div>
      <div className="text-sm">{body.transcriptText}</div>
    </div>
  );
};
