import { createEvent, createStore, sample } from "effector";
import { type ColorKey } from "shared/utils/color.utils";
import { createContextAndHook } from "../util";

export type EmdrSound = "beep" | "chime";

export class EmdrControlFormVM {
  // effector events
  soundSelectedEvt = createEvent<EmdrSound>();
  colorSelectedEvt = createEvent<ColorKey>();

  $selectedSound = createStore<EmdrSound>("beep");
  $selectedColor = createStore<ColorKey>("blue");

  _bindEvents() {
    sample({
      source: this.soundSelectedEvt,
      target: this.$selectedSound,
    });

    sample({
      source: this.colorSelectedEvt,
      target: this.$selectedColor,
    });
  }

  constructor() {
    this._bindEvents();
  }
}

export const [EmdrControlFormProvider, useEmdrControlForm] =
  createContextAndHook<EmdrControlFormVM>();
