import { WebkitAudioPlayer } from "@/src/mgrs/web-audio-player";
import { useAssumedRtcMgr, useMe, useUMgr } from "@/src/pages/u/u.webstatemgr";
import { RD, Rx } from "@/src/prelude";
import {
  useCall,
  useCallStateHooks,
  type Call,
} from "@stream-io/video-react-sdk";
import { useMutation } from "convex/react";
import { Effect } from "effect";
import { useSessionRSM } from "frontend-shared/src/mgrs/remote-state-mgrs/main-room.rsm";
import { MainRoomStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/main-room.statemgr";
import { useOnce } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import { useEffect, useState } from "react";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import type { RtcAreaST } from "shared/convex/Sessions/Base/Session.Types";

export function useAutoJoinChannel(rtcSetup: typeof RtcAreaST.Encoded) {
  const rtcMgr = useAssumedRtcMgr();
  const hasRtcInitialized = useObservableEagerState(rtcMgr.hasInitialized$);
  const [mbJoinedCall, setMbJoinedCall] = useState<Call | null>(null);

  useEffect(() => {
    if (!hasRtcInitialized) {
      return;
    }

    const call = rtcMgr.client.call(
      rtcSetup.channelConfig.channelType,
      rtcSetup.channelConfig.channelId
    );
    Effect.runPromise(
      rtcMgr.lazyJoinChannel({
        channelType: rtcSetup.channelConfig.channelType,
        channelId: rtcSetup.channelConfig.channelId,
      })
    ).then((jc) => {
      console.log("JOIN CALL RESULT! ", jc);
      setMbJoinedCall(jc);
    });

    return () => {
      call.leave().catch(() => console.error("Failed to leave the call"));
      setMbJoinedCall(null);
    };
  }, [rtcSetup.channelConfig, hasRtcInitialized]);

  return {
    mbJoinedCall,
    rtcClient: rtcMgr.client,
    isLoading: !hasRtcInitialized,
  };
}

export function useSetupMainRoom() {
  const sessionRSM = useSessionRSM();
  const me = useMe();
  const uMgr = useUMgr();
  const call = useCall()!;
  const { useLocalParticipant } = useCallStateHooks();
  const meAsParticipant = useLocalParticipant();
  const audioPlayer = useOnce(() => new WebkitAudioPlayer(() => {}));
  const registerEnteredMainRoom = useMutation(
    api.Sessions.Participants.ParticipantAttendanceFns.setUserEnteredMainRoom
  );

  const setMyMenuViewState = useMutation(
    api.Sessions.Participants.Views.MainRoomFns.setMyMenuViewState
  ).withOptimisticUpdate((localState, args) => {
    localState.setQuery(
      api.Sessions.Participants.Views.MainRoomFns.getMyMenuViewState,
      { baseSessionId: args.baseSessionId },
      args.viewState
    );
  });

  const mainRoomStateMgr = useOnce(() => {
    return new MainRoomStateMgr({
      baseSessionId: sessionRSM.p.baseSessionId,
      sessionRSM,
      audioPlayer,
      chatStateMgr: uMgr.chatStateMgr,
      leaveChannel: async () => {
        await call.leave();
        window.location.href = "/";
      },
      actions: {
        setMyMenuViewState: (vs) =>
          setMyMenuViewState({
            baseSessionId: sessionRSM.p.baseSessionId,
            viewState: vs,
          }),
      },
    });
  });

  useEffect(() => {
    mainRoomStateMgr.pingMgr.sendPing();

    registerEnteredMainRoom({
      baseSessionId: sessionRSM.p.baseSessionId as Id<"sessionConfig">,
      now: Date.now(),
    }).catch();

    mainRoomStateMgr.stateSyncMgr.subscribeSyncAll();

    return () => {
      mainRoomStateMgr.stateSyncMgr.disposeAll();
    };
  }, []);

  const rdClosedCallResult$ = useOnce(() => Rx.of(RD.initial));

  return {
    mainRoomStateMgr,
    rdClosedCallResult$,
    meAsParticipant,
    call,
    me,
  };
}
