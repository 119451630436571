import { useAction, useMutation, useQuery } from "convex/react";
import { combine, createEffect, createEvent, createStore } from "effector";
import { useUnit } from "effector-react";
import React, { useEffect } from "react";
import { api } from "shared/convex/_generated/api";
import type {
  FileLike,
  UploadFileToCloudinaryResponse,
} from "shared/schemas/file.schemas";
import {
  MediaSelectAndUploadVM,
  type MediaSelectDeps,
} from "../media/media-select-upload.vm";
import { SelectModalitiesVM } from "./hp/select-modality.vm";

type UpdateSimpleProfileMutation = (args: {
  publicId: string | null;
  firstName: string;
  lastName: string;
  selectedModalitySlugs?: string[];
  bio?: string;
}) => Promise<void>;

type InitialFormData = {
  firstName?: string;
  lastName?: string;
  selectedModalitySlugs?: string[];
  profilePhoto?: {
    downloadUrl: string;
  } | null;
  bio?: string;
};

export class MyProfileFormVM {
  readonly nameVM: NameVM;
  readonly bioVM: BioVm;
  readonly profilePhotoVM: MediaSelectAndUploadVM;
  readonly modalitySelectorVM = new SelectModalitiesVM();

  submitFormEvt = createEvent();
  receivedInitialValues = createEvent<InitialFormData | null>();

  submitFormFx = createEffect<void, void>();
  $isSubmitting = this.submitFormFx.pending;
  $isSubmitComplete = createStore<boolean>(false);

  constructor(p: {
    updateSimpleProfileMutation: UpdateSimpleProfileMutation;
    photoUploadDeps: MediaSelectDeps;
    getPresignedUploadUrl: () => Promise<{
      uploadUrl: string;
      // publicId: string;
    }>;
    getProcessedImageUrl: (publicId: string) => Promise<{
      processedUrl: string;
    }>;
  }) {
    this.nameVM = new NameVM();
    this.bioVM = new BioVm();
    this.profilePhotoVM = new MediaSelectAndUploadVM({
      deps: p.photoUploadDeps,
      getPresignedUrl: p.getPresignedUploadUrl,
      getProcessedMediaUrl: (r) => p.getProcessedImageUrl(r.publicId),
      onMediaSuccessfullyUploadedToCloudinary: async () => {},
    });

    this.receivedInitialValues.watch((initialData) => {
      console.log("WATCHED receivedInitialValues", initialData);
      if (!initialData) return;

      if (initialData.firstName || initialData.lastName) {
        this.nameVM.setInitialName({
          firstName: initialData.firstName ?? "",
          lastName: initialData.lastName ?? "",
        });
      }

      if (initialData.bio) {
        this.bioVM.setBio(initialData.bio);
        this.bioVM.toggleBioInput(true);
      }

      if (initialData.selectedModalitySlugs) {
        console.log(
          "initialData.selectedModalitySlugs",
          initialData.selectedModalitySlugs
        );
        this.modalitySelectorVM.setInitialModalities(
          initialData.selectedModalitySlugs
        );
      }

      if (initialData.profilePhoto) {
        this.profilePhotoVM.setInitialDownloadUrl(initialData.profilePhoto);
      }
    });

    const formData$ = combine({
      firstName: this.nameVM.$firstName,
      lastName: this.nameVM.$lastName,
      publicId: this.profilePhotoVM.$publicId,
      bio: this.bioVM.$bio,
    });

    this.submitFormFx.use(async () => {
      const data = formData$.getState();
      console.log("data", data);
      if (!data.firstName || !data.lastName) {
        throw new Error("Missing required fields");
      }

      const selectedModalitySlugs = this.modalitySelectorVM.$selectedModalities
        .getState()
        ?.map((m) => m.slug);

      await p.updateSimpleProfileMutation({
        publicId: data.publicId,
        firstName: data.firstName,
        lastName: data.lastName,
        selectedModalitySlugs,
        bio: data.bio,
      });
    });

    this.$isSubmitComplete.on(this.submitFormFx.done, () => true);

    this.submitFormEvt.watch(() => {
      console.log("submitFormEvt");
      this.submitFormFx();
    });
  }
}

class BioVm {
  setBio = createEvent<string>();
  toggleBioInput = createEvent<boolean>();
  $showBioInput = createStore<boolean>(false).on(
    this.toggleBioInput,
    (_, show) => show
  );
  $bio = createStore<string>("").on(this.setBio, (_, bio) => bio);
}

class NameVM {
  setFirstName = createEvent<string>();
  setLastName = createEvent<string>();

  $firstName = createStore<string>("").on(
    this.setFirstName,
    (_, firstName) => firstName
  );
  $lastName = createStore<string>("").on(
    this.setLastName,
    (_, lastName) => lastName
  );

  setInitialName = (p: { firstName: string; lastName: string }) => {
    this.setFirstName(p.firstName);
    this.setLastName(p.lastName);
  };

  constructor() {
    this.$firstName = createStore<string>("").on(
      this.setFirstName,
      (_, firstName) => firstName
    );
    this.$lastName = createStore<string>("").on(
      this.setLastName,
      (_, lastName) => lastName
    );
  }
}

export function useSetupMyProfileForm(p: {
  initialData?: InitialFormData;
  selectPhoto: () => Promise<FileLike | null>;
  uploadFileLikeToCloudinary: (
    file: FileLike,
    uploadUrl: string
  ) => Promise<UploadFileToCloudinaryResponse>;
}) {
  const myCurrentProfile = useQuery(api.User.UserFns.getMyCurrentProfile);

  const getProfilePhotoUploadUrl = useAction(
    api.User.UserFns.getProfilePhotoUploadUrl
  );

  const getDownloadUrlsForProfilePhoto = useAction(
    api.User.UserFns.getDownloadUrlsForProfilePhoto
  );

  const saveSimpleProfileMutation = useMutation(
    api.User.Hp.HpFns.onSubmitMySimpleProfile
  );

  const model = React.useMemo(
    () =>
      new MyProfileFormVM({
        updateSimpleProfileMutation: async (p) => {
          // add artificial delay
          await new Promise((resolve) => setTimeout(resolve, 500));
          await saveSimpleProfileMutation({
            firstName: p.firstName,
            lastName: p.lastName,
            profilePhoto: p.publicId ? { publicId: p.publicId } : null,
            mySelectedModalitySlugs: p.selectedModalitySlugs,
            bio: p.bio,
          });
        },
        photoUploadDeps: {
          selectMedia: p.selectPhoto,
          uploadToCloudinary: p.uploadFileLikeToCloudinary,
          getPreviewUrl: (file) => file.uri ?? file.path ?? "",
        },
        getPresignedUploadUrl: async () => {
          console.log("getPresignedUploadUrl! ");
          const uploadUrl = await getProfilePhotoUploadUrl();
          console.log("uploadUrl!!", uploadUrl);
          return { uploadUrl };
        },
        getProcessedImageUrl: async (publicId) => {
          const { mediumUrl } = await getDownloadUrlsForProfilePhoto({
            publicId,
          });

          return { processedUrl: mediumUrl };
        },
      }),
    []
  );

  useEffect(() => {}, [myCurrentProfile, model]);

  useEffect(() => {
    if (p.initialData !== undefined) {
      model.receivedInitialValues(p.initialData);
    } else if (myCurrentProfile !== undefined) {
      model.receivedInitialValues(myCurrentProfile);
    }
  }, [p.initialData, myCurrentProfile, model]);

  const [displayPhotoUrl, isProcessing] = useUnit([
    model.profilePhotoVM.$processedMediaUrl,
    model.profilePhotoVM.$isProcessing,
  ]);

  const [isSubmitting, isDoneSubmitting] = useUnit([
    model.submitFormFx.pending,
    model.$isSubmitComplete,
  ]);

  return {
    model,
    isSubmitting,
    isDoneSubmitting,
    displayPhotoUrl,
    isProcessing,
  };
}
