import { InversePrimaryButton } from "@webapp/componentsprimitives/button";
import {
  SimpleTableList,
  type SimpleTableInfo,
} from "@webapp/componentstable.components";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { useMutation, useQuery } from "convex/react";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "shared/convex/_generated/api";
import { UROUTES } from "shared/routes/u.routes";
import { useCpState } from "./cpdashboard.state";
import { AvatarCircle } from "@webapp/componentsavatar.tc";
import { ImageSrc } from "shared/types/miscellaneous.types";

export const CpDashboardHomePage: React.FC = () => {
  const nav = useNavigate();
  const cpState = useCpState();
  const myPendingHps = useQuery(api.User.Cp.CpFns.getMyPendingHps);

  const myOngoingHcSessions = useQuery(
    api.User.Cp.CpFns.getMyOngoingHcSessionsForCpPortal
  );
  const myActiveHps = useQuery(api.User.Cp.CpFns.getMyActiveHps);
  const acceptHpRequest = useMutation(api.User.Cp.CpFns.acceptHpRequest);

  if (
    myPendingHps === undefined ||
    myOngoingHcSessions === undefined ||
    myActiveHps === undefined
  ) {
    return <FullContainerLoadingSpinner />;
  }

  const myActiveHpsTableConfig: SimpleTableInfo<any> = {
    columns: [
      {
        key: "name",
        header: "Practitioner",
        render: ({ name, profilePhoto }) => (
          <div className="flex items-center gap-2">
            <AvatarCircle
              mbProfilePhoto={ImageSrc.fromMbUrl(profilePhoto)}
              size={56}
            />
            <h4>{name}</h4>
          </div>
        ),
      },
    ],
    data: myActiveHps.map((hp) => ({
      name: hp.name,
      profilePhoto: hp.profilePhoto,
    })),
  };

  return (
    <div className="flex flex-col gap-4">
      <SimpleTableList
        tables={[
          {
            columns: [
              { key: "ongoingHcSessions", header: "Ongoing sessions" },
              {
                key: "cta",
                header: "End session",
                render: (s) => (
                  <InversePrimaryButton
                    title="Join session"
                    onClick={() => {
                      const navRoute =
                        UROUTES.SESSIONS.SESSION_ID.RTC.buildPath({
                          sessionId: s.sessionId,
                        });
                      nav(navRoute);
                    }}
                  />
                ),
              },
            ],
            data: myOngoingHcSessions.map((session) => ({
              ongoingHcSessions: session.hpInfo.name,
              sessionId: session.baseSessionId,
            })),
          },
          {
            columns: [
              {
                key: "pendingHpRequest",
                header: "Pending invite from",
                render: ({ pendingHpRequest }) => (
                  <div className="flex flex-col gap-2">
                    <h4>{pendingHpRequest}</h4>
                  </div>
                ),
              },
              {
                key: "cta",
                header: "Accept",
                render: (s) => (
                  <InversePrimaryButton
                    title="Accept"
                    onClick={() => {
                      acceptHpRequest({ reqId: s.req._id }).then((_) => {
                        cpState.dashboardMgr.showBottomToast({
                          msg: "Accepted HP request",
                        });
                      });
                    }}
                  />
                ),
              },
            ],
            data: myPendingHps.map((hp) => ({
              pendingHpRequest: hp.hp.name,
              req: hp.req,
            })),
          },
          myActiveHpsTableConfig,
        ]}
      />
    </div>
  );
};
