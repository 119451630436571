import * as S from "@effect/schema/Schema";

export namespace SharedToolSettings {
  export namespace Emdr {
    const PlayState = S.Union(
      S.Literal("playing"),
      S.Literal("paused"),
      S.Literal("preparing"),
      S.Literal("off")
    );
    export type PlayState = typeof PlayState.Type;

    export class State extends S.Class<State>("EmdrState")({
      ballFrequency: S.Number,
      ballColor: S.String,
      playState: PlayState,
    }) {
      static default = State.make({
        ballFrequency: 1,
        ballColor: "purple",
        playState: "off",
      });
    }
  }
}
