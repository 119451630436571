import { Console, Effect, Match, Option } from "effect";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { FirebaseJsMgr } from "../firebase";

export function useAuthFromFirebase(p: { firebaseMgr: FirebaseJsMgr }): {
  isLoading: boolean;
  isAuthenticated: boolean;
  fetchAccessToken: (args: {
    forceRefreshToken: boolean;
  }) => Promise<string | null>;
} {
  const [isAuthenticated, setIsAuthenticated] = useState<undefined | boolean>(
    undefined
  );

  useEffect(() => {
    console.log("MOUNTING USE AUTH FROM FIREBASE! ");
  }, []);

  useEffect(() => {
    console.log(
      "IS AUTHENTICATED IN USE AUTH FROM FIREBASE! ",
      isAuthenticated
    );
  }, [isAuthenticated]);

  useEffect(() => {
    p.firebaseMgr.firebaseAuthState$.subscribe((authState) => {
      console.log("AUTH STATE! ", authState);
      Match.value(authState).pipe(
        Match.when({ _tag: "KNOWN" }, ({ authState }) =>
          Match.value(authState).pipe(
            Match.when({ _tag: "LOGGED_IN" }, () => {
              console.log("SETTING IS AUTHENTICATED TO TRUE! ");
              setIsAuthenticated(true);
            }),
            Match.when({ _tag: "LOGGED_OUT" }, () => setIsAuthenticated(false)),
            Match.exhaustive
          )
        ),
        Match.when({ _tag: "UNKNOWN" }, () => setIsAuthenticated(undefined)),
        Match.exhaustive
      );
    });
  }, []);

  const fetchAccessToken: (args: {
    forceRefreshToken: boolean;
  }) => Promise<string | null> = useCallback((args) => {
    return Effect.runPromise(
      p.firebaseMgr.getFirebaseTokenEff(args).pipe(
        Effect.tap((r) => Console.log("GET FIREBASE TOKEN CALLED! ", r)),
        Effect.map(Option.getOrNull),
        Effect.catchAll((_) => Effect.die("Could not get token"))
      )
    );
  }, []);

  const ret = useMemo(
    () => ({
      isLoading: isAuthenticated == undefined ? true : false,
      isAuthenticated: isAuthenticated !== undefined ? isAuthenticated : false,
      fetchAccessToken,
    }),
    [isAuthenticated, fetchAccessToken]
  );

  useEffect(() => {
    console.log("RET! ", ret);
  }, [ret]);

  return ret;
}
