import { useWebToast } from "@/src/components/toast/global-toast";
import { Menu, Switch } from "@headlessui/react";
import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { FullCalendar } from "@webapp/componentscalendar/full-calendar.fc";
import { CalendarMenuInput } from "@webapp/componentsform/calendar.input";
import { DurationPicker } from "@webapp/componentsform/durationpicker.input";
import {
  TextAreaInput$,
  TextInput$,
} from "@webapp/componentsform/form-inputs.fc";
import { PrimaryButton } from "@webapp/componentsprimitives/button";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { Rx } from "@webapp/prelude";
import { useMutation, useQuery } from "convex/react";
import { type DefaultError } from "frontend-shared/src/api.mgr";
import { useFirebaseJs } from "frontend-shared/src/firebase";
import { useCommunitySam } from "frontend-shared/src/mgrs/state-mgrs/community.statemgr";
import { useOnce } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import React, { useCallback, useEffect, useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useTypedSearchParams } from "react-router-typesafe-routes/dom";
import { O, RD } from "shared/base-prelude";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import type { UpcomingCommunityCalEventInstanceCard } from "shared/convex/Screens/Community/CommunityEventsScreen.Types";
import { UROUTES } from "shared/routes/u.routes";
import {
  type RecurrenceFrequencyChoice,
  type SimpleRecurrenceRule,
} from "shared/types/calendar.types";
import { RRuleUtils } from "shared/utils/calendar.utils";
import { CommunityEventCardContainer } from "@/src/domains/communities/community/events/community-event-card";
import { CommunityEventDetailsPanel } from "@/src/domains/communities/community/events/view-community-event-details";
import { ScheduleCommunityEventForm } from "@/src/domains/communities/community/events/schedule-community-event.form";

export const CommunityEventsTabLayout: React.FC = () => {
  return (
    <div className="flex flex-col gap-8">
      <Outlet />
    </div>
  );
};

export const CommunityEventsTabAll: React.FC = () => {
  const hpState = useHpState();
  const sam = useCommunitySam();
  const { toast } = useWebToast();
  const [{ showEventRightNav, showEventInstanceRightNav }] =
    useTypedSearchParams(
      UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.EVENTS.ALL
    );

  useEffect(() => {
    if (showEventRightNav !== undefined) {
      hpState.dashboardState.openRightNav({
        _tag: "VIEW_COMMUNITY_EVENT",
        eventTemplateId: showEventRightNav,
        communitySlug: sam.communitySlug,
      });
    } else if (showEventInstanceRightNav !== undefined) {
      hpState.dashboardState.openRightNav({
        _tag: "VIEW_COMMUNITY_EVENT_INSTANCE",
        instanceId: showEventInstanceRightNav,
        communitySlug: sam.communitySlug,
      });
    }
  }, [showEventRightNav, showEventInstanceRightNav]);

  const now = useOnce(() => Date.now());

  const allInstances = useQuery(
    api.Screens.Community.CommunityEventsScreenFns
      .getAllCommunityEventsScreenData,
    {
      communitySlug: sam.communitySlug,
      now,
    }
  );

  useEffect(() => {
    console.log("ALL INSTANCES", allInstances);
  }, [allInstances]);

  if (allInstances === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex flex-col gap-8 rounded-lg min-h-[500px]">
      <LiveAndUpcomingEventsSection
        cards={[...allInstances.upcomingCommunityCalEventInstances.cards]}
      />
      <FullCalendar
        stateMgr={sam.calendarMgr}
        fetchOnMount={true}
        onApptClick={(appt) => {
          hpState.dashboardState.openRightNav({
            _tag: "CUSTOM",
            topView: <></>,
            content: (
              <CommunityEventDetailsPanel
                instanceId={appt.instanceId as Id<"calEventInstances">}
                communitySlug={sam.communitySlug}
                closePanel={() => {
                  hpState.dashboardState.showBottomToast({
                    msg: "Deleted event",
                    reload: true,
                    closeRightNav: true,
                  });
                }}
              />
            ),
          });
        }}
        onDayClick={(_) => {
          hpState.dashboardState.openRightNav({
            _tag: "CUSTOM",
            topView: <div>{`Create event`}</div>,
            content: (
              <ScheduleCommunityEventForm communitySlug={sam.communitySlug} />
            ),
          });
        }}
        newApptButton={{
          title: "Create event",
          onClick: () => {
            hpState.dashboardState.openRightNav({
              _tag: "CUSTOM",
              topView: <div>Create event</div>,
              content: (
                <ScheduleCommunityEventForm communitySlug={sam.communitySlug} />
              ),
            });
          },
        }}
      />
      {/* <GoLiveNowButton /> */}
    </div>
  );
};

export const LiveAndUpcomingEventsSection: React.FC<{
  cards: (typeof UpcomingCommunityCalEventInstanceCard.Encoded)[];
}> = ({ cards }) => {
  return (
    <div className="flex flex-col gap-4 relative">
      <h3 className="text-[24px]">Live and upcoming</h3>
      {cards.length === 0 ? (
        <div className="flex justify-center items-center min-h-[100px] text-gray-500">
          No live or upcoming events
        </div>
      ) : (
        <div className="relative h-[400px]">
          <div className="absolute inset-x-0 flex gap-4 overflow-x-auto">
            {cards.map((evt) => (
              <CommunityEventCardContainer
                key={evt.instance._id}
                initialCardData={evt}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const GoLiveNowButton: React.FC<{}> = () => {
  const nav = useNavigate();
  const sam = useCommunitySam();
  const firebaseJs = useFirebaseJs();
  return (
    <div className="flex p-0 lg:p-8 lg:border-4 rounded-lg lg:shadow-md">
      <button
        className="w-full lg:w-[200px] lg:ml-4 bg-vid-purple text-center text-white p-buttons lg:p-3 lg:mb-4 rounded-xl"
        onClick={() => {
          // nav(
          //   UROUTES.GROUP_SESSIONS.GROUP_SESSION_ID.ENTRY.buildPath({
          //     groupSessionId: er.right.groupSessionId,
          //   })
          // );
        }}
      >
        Go live now
      </button>
    </div>
  );
};

type CreateEventFormData = {
  communitySlug: string;
  title: string;
  description: string;
  startDate: Date;
  durationSeconds: number;
  recurrenceRule: SimpleRecurrenceRule | null;
};

class CreateEventFormMgr {
  startTime$ = new Rx.BehaviorSubject<O.Option<Date>>(O.none);
  durationInMinutes$ = new Rx.BehaviorSubject<O.Option<number>>(O.none);
  name$ = new Rx.BehaviorSubject<string>("");
  description$ = new Rx.BehaviorSubject<string>("");
  recurringStatus$ = new Rx.BehaviorSubject<RecurrenceFrequencyChoice>("none");
  rdSubmitRes$ = new Rx.BehaviorSubject<RD.RemoteData<DefaultError, any>>(
    RD.initial
  );
  sendInviteToAll$ = new Rx.BehaviorSubject<boolean>(false);

  constructor(
    readonly communitySlug: string,
    readonly submitToConvex: (p: CreateEventFormData) => Promise<void>,
    readonly onSuccessSubmit: () => void
  ) {}

  getFullForm = () => {
    const startTime = O.toNullable(this.startTime$.value);
    const durationInMinutes = O.toNullable(this.durationInMinutes$.value);
    const name = this.name$.value;
    const description = this.description$.value;
    const recurringStatus = this.recurringStatus$.value;

    return {
      startTime,
      durationInMinutes,
      name,
      description,
      recurringStatus,
      sendInviteToAll: this.sendInviteToAll$.value,
    };
  };

  submit = async () => {
    console.log(
      this.startTime$.value,
      this.durationInMinutes$.value,
      this.name$.value
    );
    const {
      startTime,
      durationInMinutes,
      name,
      description,
      recurringStatus,
      sendInviteToAll,
    } = this.getFullForm();

    if (startTime === null) {
      alert("Start time is required");
      return;
    }

    if (durationInMinutes === null) {
      alert("Duration is required");
      return;
    }

    this.rdSubmitRes$.next(RD.pending);

    const recurrenceRule =
      recurringStatus === "none"
        ? null
        : ({
            frequency: recurringStatus,
            interval: 1,
          } as SimpleRecurrenceRule);

    console.log("SUBMITTTING! ", {
      communitySlug: this.communitySlug,
      title: name,
      description,
      startDate: startTime,
      durationSeconds: durationInMinutes * 60,
      recurrenceRule,
    });

    const submitRes = await this.submitToConvex({
      communitySlug: this.communitySlug,
      title: name,
      description,
      startDate: startTime,
      durationSeconds: durationInMinutes * 60,
      recurrenceRule,
    });

    this.onSuccessSubmit();

    // const submitRes = await BE.fetchEndpointTE((Api) =>
    //   Api.hp.community.scheduleEvent.mutate({
    //     communitySlug: this.communitySlug,
    //     title: name,
    //     description,
    //     recurrenceFrequencyChoice: recurringStatus,
    //     startDate: startTime,
    //     durationSeconds: durationInMinutes * 60,
    //     sendInviteToAll,
    //   })
    // )();

    // this.rdSubmitRes$.next(RD.fromEither(submitRes));

    // if (E.isRight(submitRes)) {
    //   this.onSuccessSubmit();
    // }
  };
}

type OpenDropdownId = "user" | "payment" | "duration" | "reminder";

const CreateEventForm: React.FC<{
  communitySlug: string;
  onSuccessSubmit: () => void;
}> = ({ communitySlug, onSuccessSubmit }) => {
  const createCommunityCalEvent = useMutation(
    api.Calendar.CommunityCalEventFns.createCommunityCalEvent
  );

  const submitToConvex = useCallback(
    async (p: CreateEventFormData) => {
      console.log("SUBMITTING TO CONVEX! ", p);
      const rrule = p.recurrenceRule
        ? RRuleUtils.asRRuleString(p.recurrenceRule)
        : null;

      console.log("RRULE STR! ", rrule);
      const startsAt = p.startDate.getTime();

      console.log("STARTS AT! ", startsAt);

      await createCommunityCalEvent({
        communitySlug: p.communitySlug,
        title: p.title,
        description: p.description,
        startsAt,
        durationSeconds: p.durationSeconds,
        rrule,
      });
    },
    [createCommunityCalEvent]
  );

  const mgr = useMemo(
    () =>
      new CreateEventFormMgr(communitySlug, submitToConvex, onSuccessSubmit),
    []
  );
  const rdSubmitRes = useObservableEagerState(mgr.rdSubmitRes$);

  const [openDropdownId, setOpenDropdownId] =
    React.useState<OpenDropdownId | null>(null);

  return (
    <div className="flex-1 flex flex-col justify-between p-4">
      <div className="flex flex-col gap-8">
        <TextInput$ label="Name" value$={mgr.name$} />
        <TextAreaInput$ label="Description" value$={mgr.description$} />
        <CalendarMenuInput
          isOpen={openDropdownId === "duration"}
          onToggle={(isOpen) => setOpenDropdownId(isOpen ? "duration" : null)}
          title="Start time"
          onSelection={(dt) => {
            console.log("onSelection", dt);
            mgr.startTime$.next(O.some(dt));
          }}
        />
        <DurationPicker durationInMinutes$={mgr.durationInMinutes$} />
        <RecurringInput$
          recurringStatus$={mgr.recurringStatus$}
          onSelect={(rs) => {
            mgr.recurringStatus$.next(rs);
          }}
        />
        <SendInviteToAllButton
          enabled$={mgr.sendInviteToAll$}
          onChange={(enabled) => {
            mgr.sendInviteToAll$.next(enabled);
          }}
        />
      </div>
      <PrimaryButton
        title="Submit"
        onClick={() => mgr.submit()}
        isLoading={RD.isPending(rdSubmitRes)}
      />
      {RD.isFailure(rdSubmitRes) && (
        <div className="text-red-500 flex justify-center">
          {rdSubmitRes.error.error.message}
        </div>
      )}
    </div>
  );
};

const SendInviteToAllButton: React.FC<{
  enabled$: Rx.BehaviorSubject<boolean>;
  onChange: (enabled: boolean) => void;
}> = ({ enabled$, onChange }) => {
  const enabled = useObservableEagerState(enabled$);
  return (
    <div className="flex flex-col gap-4">
      <label className="font-semibold text-vid-purple">
        Send invite to all community members?
      </label>
      <Switch
        checked={enabled}
        onChange={onChange}
        className={`${
          enabled ? "bg-blue-600" : "bg-gray-200"
        } relative inline-flex h-6 w-11 items-center rounded-full`}
      >
        <span className="sr-only">Send invite to all community members?</span>
        <span
          className={`${
            enabled ? "translate-x-6" : "translate-x-1"
          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
      </Switch>
    </div>
  );
};

const RecurringInput$: React.FC<{
  recurringStatus$: Rx.BehaviorSubject<RecurrenceFrequencyChoice>;
  onSelect: (rs: RecurrenceFrequencyChoice) => void;
}> = ({ recurringStatus$, onSelect }) => {
  const recurringStatus = useObservableEagerState(recurringStatus$);
  return (
    <Menu as="div" className="text-input">
      <Menu.Button className="flex gap-4">
        <label className="defaultLabel">Recurring (optional)</label>
        <div>{recurringStatus}</div>
      </Menu.Button>
      <Menu.Items>
        {ALL_RECURRING_STATUSES.map((rs) => (
          <Menu.Item>
            <div
              className="cursor-pointer"
              onClick={() => {
                onSelect(rs);
              }}
            >
              {rs}
            </div>
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};

const ALL_RECURRING_STATUSES: RecurrenceFrequencyChoice[] = [
  "none",
  "daily",
  "weekly",
];
