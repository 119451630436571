export const InsightLiveLogoWithText: React.FC<{
  width?: number;
}> = ({ width }) => {
  return (
    <div className="flex items-center gap-2 ">
      <img
        src="/insightLiveLogo.png"
        alt="InsightLive"
        style={{ width: width ?? 180 }}
      />
      {/* <h4 className="text-[36px] font-medium">InsightLive</h4> */}
    </div>
  );
};

export const InsightLiveSvgIcon: React.FC<{}> = () => {
  return <img src="/il-logo.svg" alt="InsightLive" />;
};
