import { AvatarCircle } from "@/src/components/avatar.tc";
import { SearchInput } from "@/src/components/form/form-inputs.fc";
import { PrimaryButton } from "@/src/components/primitives/button";
import { Checkbox } from "@/src/components/primitives/form.primitives";
import { RightDrawerContentContainer } from "@/src/components/right-drawer";
import { useWebToast } from "@/src/components/toast/global-toast";
import { FullContainerLoadingSpinner } from "@/src/loading";
import { useUMgr } from "@/src/pages/u/u.webstatemgr";
import { useAction, usePaginatedQuery } from "convex/react";
import { Match } from "effect";
import { createEffect, createEvent, createStore, sample } from "effector";
import { useUnit } from "effector-react";
import { useOnce } from "frontend-shared/src/util";
import { throttle } from "patronum/throttle";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import { ImageSrc } from "shared/types/miscellaneous.types";
import type { SimpleUserWithProfilePhoto } from "shared/types/user.types";

export class FormVM {
  readonly setEmail = createEvent<string>();
  readonly setInputSearchFilter = createEvent<string>();
  readonly throttledSearchFilter = throttle(this.setInputSearchFilter, 500);
  readonly setUserCheckedEvt = createEvent<{
    userId: Id<"users">;
    checked: boolean;
  }>();

  readonly $email = createStore("");
  readonly $inputSearchFilter = createStore<string>("");
  readonly $searchFilter = createStore<string | null>(null);
  readonly $selectedUserIds = createStore<Id<"users">[]>([]);

  readonly sendFx = createEffect<
    {
      communitySlug: string;
      selectedUserIds: Id<"users">[];
      email: string;
    },
    any
  >();

  constructor(p: {
    submit: (args: {
      communitySlug: string;
      selectedUserIds: Id<"users">[];
      email: string;
    }) => Promise<any>;
  }) {
    this.sendFx.use(p.submit);

    sample({
      clock: this.setEmail,
      target: this.$email,
    });

    sample({
      clock: this.setInputSearchFilter,
      target: this.$inputSearchFilter,
    });

    sample({
      clock: this.throttledSearchFilter,
      target: this.$searchFilter,
    });

    sample({
      clock: this.setUserCheckedEvt,
      source: this.$selectedUserIds,
      fn: (ids, { userId, checked }) =>
        checked ? [...ids, userId] : ids.filter((id) => id !== userId),
      target: this.$selectedUserIds,
    });
  }
}

export const InviteToCommunityRightDrawer: React.FC<{
  communitySlug: string;
}> = ({ communitySlug }) => {
  return (
    <RightDrawerContentContainer
      header={{
        _tag: "ICON_TITLE",
        icon: { _tag: "PLUS_PERSON" },
        title: "Invite members to this community",
      }}
    >
      <InviteToCommunityForm communitySlug={communitySlug} />
    </RightDrawerContentContainer>
  );
};

const InvitableUserButton: React.FC<{
  user: SimpleUserWithProfilePhoto;
  isFirst: boolean;
  isSelected: boolean;
  onToggle: (checked: boolean) => void;
}> = ({ user, isFirst, isSelected, onToggle }) => {
  return (
    <button
      type="button"
      onClick={() => onToggle(!isSelected)}
      className={`flex items-center gap-3 py-3 px-4 gap-4 border-x border-b w-full text-left hover:bg-gray-50 ${
        isFirst ? "border-t rounded-t-[12px]" : ""
      }`}
    >
      <Checkbox
        isChecked={isSelected}
        onChange={onToggle}
        onClick={(e) => e.stopPropagation()}
      />
      <div className="flex items-center gap-4">
        <AvatarCircle
          mbProfilePhoto={ImageSrc.fromMbUrl(user.profilePhoto)}
          size={48}
        />
        <div>{user.name}</div>
      </div>
    </button>
  );
};

const InvitableUsersList: React.FC<{
  users: SimpleUserWithProfilePhoto[];
  selectedUserIds: Id<"users">[];
  onToggleUser: (args: { userId: Id<"users">; checked: boolean }) => void;
}> = ({ users, selectedUserIds, onToggleUser }) => {
  return (
    <div className="flex flex-col">
      {users.map((user, index) => (
        <InvitableUserButton
          key={user.id}
          user={user}
          isFirst={index === 0}
          isSelected={selectedUserIds.includes(user.id)}
          onToggle={(checked) => onToggleUser({ userId: user.id, checked })}
        />
      ))}
    </div>
  );
};

export const InviteToCommunityForm: React.FC<{
  communitySlug: string;
}> = ({ communitySlug }) => {
  const sendInviteToCommunity = useAction(
    api.Community.CommunityScreenFns.onSendInviteToCommunitySubmit
  );
  const vm = useOnce(() => new FormVM({ submit: sendInviteToCommunity }));
  const toast = useWebToast();
  const [email, setEmail] = useUnit([vm.$email, vm.setEmail]);
  const [searchFilter, inputSearchFilter, setSearchFilter, isSending] = useUnit(
    [
      vm.$searchFilter,
      vm.$inputSearchFilter,
      vm.setInputSearchFilter,
      vm.sendFx.pending,
    ]
  );

  const [selectedUserIds, toggleUser] = useUnit([
    vm.$selectedUserIds,
    vm.setUserCheckedEvt,
  ]);
  const uMgr = useUMgr();

  const { results, status, loadMore } = usePaginatedQuery(
    api.Community.CommunityScreenFns.getAllInvitableUsers,
    {
      communitySlug,
      searchFilter: searchFilter ?? undefined,
    },
    { initialNumItems: 10 }
  );

  return (
    <form
      className="flex-1 flex flex-col gap-8 px-4"
      onSubmit={(e) => {
        e.preventDefault();

        vm.sendFx({
          communitySlug,
          selectedUserIds,
          email,
        }).then((_) => {
          uMgr.rightDrawerVM.closeEvt();

          toast.toast({
            id: "invite-sent",
            title: "Invite sent",
            duration: 3000,
          });
        });
      }}
    >
      <SearchInput
        value={inputSearchFilter}
        onChange={(value) => setSearchFilter(value)}
      />
      {Match.value(status).pipe(
        Match.when("LoadingFirstPage", () => <FullContainerLoadingSpinner />),
        Match.when("LoadingMore", () => <FullContainerLoadingSpinner />),
        Match.when("CanLoadMore", () => (
          <InvitableUsersList
            users={results}
            selectedUserIds={selectedUserIds}
            onToggleUser={toggleUser}
          />
        )),
        Match.when("Exhausted", () => (
          <InvitableUsersList
            users={results}
            selectedUserIds={selectedUserIds}
            onToggleUser={toggleUser}
          />
        )),
        Match.exhaustive
      )}
      <div className="flex-1 flex flex-col my-8">
        <h5 className="text-vid-purple">Add an email invite (optional)</h5>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="text-input"
          placeholder="Email"
        />
      </div>
      <PrimaryButton
        title="Send invite"
        type="submit"
        isLoading={isSending}
        disabled={isSending}
      />
    </form>
  );
};
