import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { CalendarDropdown } from "@webapp/componentsform/calendar-picker.input";
import { SearchableDropdownV2 } from "@webapp/componentsform/searchabel-dropdown.fc";
import { PrimaryButton } from "@webapp/componentsprimitives/button";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { useMutation, useQuery } from "convex/react";
import { combine, createEffect, createEvent, createStore } from "effector";
import { useUnit } from "effector-react";
import { CalendarPickerVM } from "frontend-shared/src/shared-vms/calendar-picker.vm";
import { BaseSearchableDropdownVM } from "frontend-shared/src/shared-vms/searchable-dropdown.vm";
import { useMemo } from "react";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import { ClientSearchableDropdown } from "src/domains/user/hp/clients/select-client-search.input";

class ClientSelectVM extends BaseSearchableDropdownVM<{
  id: string;
  label: string;
}> {
  constructor(p: { allMyClients: { id: string; name: string }[] }) {
    super(p.allMyClients.map((c) => ({ id: c.id, label: c.name })));
  }
}

function useSetupForm(p: { onSuccessSubmit?: () => void }) {
  const initialData = useQuery(
    api.Screens.Hp.Dashboard.CalendarScreenFns
      .getInitialDataForScheduleNewHcSessionForm
  );
  const scheduleNewHcSession = useMutation(
    api.Screens.Hp.Dashboard.CalendarScreenFns.scheduleNewHcSession
  );

  const vm = useMemo(() => {
    if (initialData === undefined) return undefined;

    console.log("initialData!!!", initialData);

    return new FormVM({
      allMyClients: initialData.allMyClients.map((c) => ({
        id: c.clientId,
        name: c.name,
      })),
      submit: async (payload) => {
        await scheduleNewHcSession({
          clientUserId: payload.clientId,
          startsAt: payload.date.getTime(),
        });
      },
      onSuccessSubmit: p.onSuccessSubmit,
    });
  }, [initialData]);

  return vm;
}

class FormVM {
  clientSelectVM: ClientSelectVM;
  calendarPickerVM = new CalendarPickerVM();

  submitted = createEvent();

  $validationError = createStore<string | null>(null);

  submitFx = createEffect<{ clientId: Id<"users">; date: Date }, void, Error>();

  constructor(p: {
    allMyClients: { id: string; name: string }[];
    submit: (p: { clientId: Id<"users">; date: Date }) => Promise<void>;
    onSuccessSubmit?: () => void;
  }) {
    this.clientSelectVM = new ClientSelectVM({ allMyClients: p.allMyClients });

    this.submitFx.use(p.submit);

    this.$validationError.on(
      combine(this.clientSelectVM.$selectedItem, this.calendarPickerVM.$picker),
      () => null
    );

    this.submitted.watch(async () => {
      const selectedClient = this.clientSelectVM.$selectedItem.getState();
      const selectedDate = this.calendarPickerVM.$selectedDate.getState();

      if (selectedClient && selectedDate) {
        try {
          await this.submitFx({
            clientId: selectedClient.id as Id<"users">,
            date: selectedDate,
          });
          p.onSuccessSubmit?.();
        } catch {}
      }
    });

    this.$validationError.on(this.submitted, () => {
      const selectedClient = this.clientSelectVM.$selectedItem.getState();
      const pickerState = this.calendarPickerVM.$picker.getState();

      const errors: string[] = [];

      if (!selectedClient) {
        errors.push("Please select a client");
      }

      if (!pickerState.selectedDay) {
        errors.push("Please select a date");
      } else if (!pickerState.selectedTime) {
        errors.push("Please select a time");
      }

      return errors.length > 0 ? errors.join(", ") : null;
    });
  }
}

export const NewHCSessionForm = () => {
  const hpState = useHpState();
  const vm = useSetupForm({
    onSuccessSubmit: () =>
      hpState.dashboardState.showBottomToast({
        msg: "Session scheduled successfully",
        reload: true,
        closeRightNav: true,
      }),
  });

  if (vm === undefined) return <FullContainerLoadingSpinner />;

  return <NewHCSessionFormLoaded vm={vm} />;
};

const NewHCSessionFormLoaded: React.FC<{
  vm: FormVM;
}> = ({ vm }) => {
  const isSubmitting = useUnit(vm.submitFx.pending);
  const validationError = useUnit(vm.$validationError);

  return (
    <div className="flex-1 flex flex-col gap-6 p-6">
      <div className="self-stretch">
        <ClientSearchableDropdown
          onSelect={(client) => {
            vm.clientSelectVM.itemSelected({
              id: client.id,
              label: client.name,
            });
          }}
        />
      </div>
      <CalendarDropdown vm={vm.calendarPickerVM} />
      <div className="flex-1 flex flex-col justify-end">
        <div className="flex flex-col gap-4">
          {validationError && (
            <div className="text-red-500">{validationError}</div>
          )}
          <PrimaryButton
            title="Schedule session"
            onClick={() => vm.submitted()}
            isLoading={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};
