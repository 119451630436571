import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { useQuery } from "convex/react";
import { pipe } from "fp-ts/lib/function";
import type { ChatStateMgr } from "frontend-shared/src/mgrs/state-mgrs/chat.statemgr";
import { useObservableEagerState } from "observable-hooks";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { epipe, O } from "shared/base-prelude";
import { api } from "shared/convex/_generated/api";
import { ImageSrc } from "shared/types/miscellaneous.types";
import type { StreamChat } from "stream-chat";
import {
  InsightLiveLogoWithText,
  InsightLiveSvgIcon,
} from "../../assets/il-logo-with-text.fc";
import { FullContainerLoadingSpinner } from "../../loading";
import { AvatarCircle } from "../avatar.tc";
import { DashboardStateMgr, ShowBottomToastConfig } from "./dashboard.state";
import {
  LeftMenuLink,
  LogoutLeftMenuLink,
  type LeftMenuLinkProps,
} from "./main-nav-links";

export const DashboardLayoutContainer: React.FC<{
  leftMenu: ReactNode;
  mainContent: ReactNode;
  navbar: ReactNode;
  rightNav: ReactNode;
  showReloading: boolean;
  showBottomToast: ShowBottomToastConfig | null;
  closeToast: () => void;
}> = ({
  leftMenu,
  mainContent,
  navbar,
  rightNav,
  showReloading,
  showBottomToast,
  closeToast,
}) => {
  return (
    <div className="w-screen h-screen flex flex-col md:flex-row relative">
      <div className="hidden md:flex md:flex-col absolute top-0 left-0 z-20 max-h-screen w-[230px]">
        {leftMenu}
      </div>
      <div className="flex-1 flex flex-col lg:pl-[230px]">
        <div className="self-end"></div>
        <div
          id="dashboard-content"
          className={`flex-1 flex flex-col bg-white relative
          overscroll-x-none
          `}
        >
          {rightNav}
          {navbar}
          {showReloading ? (
            <FullContainerLoadingSpinner />
          ) : (
            <div className="flex-1 flex flex-col p-[46px]">{mainContent}</div>
          )}
          {showBottomToast && (
            <div className="fixed h-[100px] bottom-0 left-0 right-0 py-8 flex justify-center items-center animate-slideup">
              <BottomToast toast={showBottomToast} closeToast={closeToast} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

type DashboardLayoutProps<RightNav extends {}> = {
  stateMgr: DashboardStateMgr<RightNav>;
  matchViewForRightNav: (rightNav: RightNav) => {
    view: React.ReactNode;
    width?: string;
  };
  navbar: {
    currentDashboardPage: string;
    middleSection: ReactNode;
    hamburger: {
      links: {
        to: string;
        name: string;
        icon?: (isSelected: boolean) => React.ReactNode;
      }[];
      onSignout: () => void;
    };
    profileButton: {
      to: string;
      profilePhoto: ImageSrc | null;
    };
    chatStateMgr: ChatStateMgr<StreamChat>;
  };
  mainContent: ReactNode;
  leftMenu: {
    extraTop?: ReactNode;
    topLinks: LeftMenuLinkProps[];
    onSignout: () => void;
    onLogoClick: () => void;
  };
};

export const DashboardLayout = <RightNav extends {}>({
  stateMgr,
  leftMenu,
  navbar,
  matchViewForRightNav,
  mainContent,
}: DashboardLayoutProps<RightNav>) => {
  const showBottomToast = useObservableEagerState(stateMgr.showBottomToast$);
  const showReloading = useObservableEagerState(stateMgr.showReloading$);

  useEffect(() => {
    if (O.isSome(showBottomToast)) {
      const toast = showBottomToast.value;
      if (toast.reload) {
        stateMgr.setShowReloading(true);
        setTimeout(() => {
          stateMgr.setShowReloading(false);
        }, 300);
      }

      if (toast.duration && toast.duration._tag === "INFINITE") {
        return;
      } else {
        setTimeout(
          () => {
            stateMgr.hideBottomToast();
          },
          toast.duration?.seconds ? toast.duration.seconds * 1000 : 3000
        );
      }

      if (toast.closeRightNav) {
        stateMgr.closeRightNav();
      }
    } else {
      stateMgr.hideBottomToast();
    }
  }, [showBottomToast]);

  return (
    <DashboardLayoutContainer
      leftMenu={
        <LeftMenu
          onLogoClick={() => {
            leftMenu.onLogoClick();
          }}
          onSignout={() => {
            leftMenu.onSignout();
          }}
          extraTop={leftMenu.extraTop}
          links={leftMenu.topLinks.map((l) => {
            return <LeftMenuLink {...l} />;
          })}
        />
      }
      mainContent={mainContent}
      navbar={<Navbar {...navbar} />}
      rightNav={
        <RightNav
          dashboardState={stateMgr}
          matchViewForRightNav={matchViewForRightNav}
        />
      }
      closeToast={() => {
        stateMgr.hideBottomToast();
      }}
      showReloading={showReloading}
      showBottomToast={O.toNullable(showBottomToast)}
    />
  );
};

const RightNav = <RightNav extends {}>({
  dashboardState,
  matchViewForRightNav,
}: {
  dashboardState: DashboardStateMgr<RightNav>;
  matchViewForRightNav: (rightNav: RightNav) => {
    view: React.ReactNode;
    width?: string;
  };
}) => {
  const mbRightNavOpen = useObservableEagerState(dashboardState.rightNav$);
  const mbMatchingView = useMemo(() => {
    return epipe(mbRightNavOpen, O.map(matchViewForRightNav));
  }, [mbRightNavOpen]);

  return (
    <div
      className={`absolute top-0 right-0 bottom-0 flex flex-col overflow-y-auto z-50 bg-white transition-all duration-300 ease-in-out`}
      style={{
        boxShadow: "0 0 10px 0 rgba(0,0,0,0.5)",
        width: epipe(
          mbMatchingView,
          O.fold(
            () => "0px",
            (v) => v.width ?? "400px"
          )
        ),
      }}
    >
      {pipe(
        mbMatchingView,
        O.map((v) => v.view),
        O.toNullable
      )}
    </div>
  );
};

export const HamburgerButton: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  return (
    <button
      className="w-8 h-full flex flex-col justify-center items-center gap-1"
      onClick={onClick}
    >
      <div className="w-8 h-1 bg-black rounded-full"></div>
      <div className="w-8 h-1 bg-black rounded-full"></div>
      <div className="w-8 h-1 bg-black rounded-full"></div>
    </button>
  );
};

export const BottomToast: React.FC<{
  toast: ShowBottomToastConfig;
  closeToast: () => void;
}> = ({ toast, closeToast }) => {
  const { onUndo, confirmButton } = toast;
  return (
    <div className="bg-black  rounded-full px-8 py-4 flex items-center justify-between gap-8">
      <div className="text-white">{toast.msg}</div>
      {onUndo && (
        <div className="flex">
          <div
            className="text-white"
            onClick={() => {
              onUndo();
            }}
          >
            Undo
          </div>
        </div>
      )}
      {confirmButton && (
        <div
          className="bg-white p-4 rounded-lg cursor-pointer"
          onClick={() => {
            confirmButton.onClick();
            if (confirmButton.autoCloseOnClick) {
              closeToast();
            }
          }}
        >
          {confirmButton.label}
        </div>
      )}
    </div>
  );
};

type LeftMenuProps = {
  extraTop?: ReactNode;
  links: ReactNode[];
  onSignout?: () => void;
  onLogoClick: () => void;
};
export const LeftMenu: React.FC<LeftMenuProps> = ({
  extraTop,
  links,
  onLogoClick,
}) => {
  const hpState = useHpState();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="hidden min-h-screen lg-w-[265px] lg:flex flex-col items-center justify-between py-[30px] px-4 bg-white">
      <div className="self-stretch flex-1 flex flex-col items-center gap-8">
        <div
          className="cursor-pointer self-stretch flex justify-center items-center"
          onClick={() => {
            onLogoClick();
          }}
        >
          <InsightLiveLogoWithText width={160} />
        </div>
        <div className="flex-1 self-stretch flex flex-col gap-8">
          {extraTop}
          <div className="flex-1 w-full flex flex-col gap-[11px]">
            {links.map((link, key) => (
              <div
                key={key}
                onClick={() => {
                  hpState.dashboardState.closeRightNav();
                }}
                className="slef-stretch"
              >
                {link}
              </div>
            ))}
          </div>
        </div>
        {/* {onSignout && !isScrolled && (
          <div className="basis-[200px] self-stretch flex flex-col justify-end transition-opacity duration-200">
            <LogoutLeftMenuLink
              onClick={() => {
                onSignout();
              }}
            />
          </div>
        )} */}
      </div>
    </div>
  );
};

export const NavbarContainer: React.FC<{
  title: string;
  middleSection?: ReactNode;
  rightSection: ReactNode;
}> = ({ title, middleSection, rightSection }) => {
  return (
    <div className="flex justify-between py-4 pl-[46px] pr-8 border border-vid-black-200 border-b-[1px] border-x-0 border-t-0">
      <div className="font-medium font-outfit text-[36px] text-vid-black-900 leading-relaxed">
        {title}
      </div>
      {middleSection}
      {rightSection}
    </div>
  );
};

const Navbar: React.FC<{
  chatStateMgr: ChatStateMgr<StreamChat>;
  currentDashboardPage: string;
  middleSection: ReactNode;
  hamburger: {
    links: {
      to: string;
      name: string;
      icon?: (isSelected: boolean) => React.ReactNode;
    }[];
    onSignout: () => void;
  };
  profileButton: {
    to: string;
  };
}> = ({
  chatStateMgr,
  currentDashboardPage,
  middleSection,
  hamburger,
  profileButton,
}) => {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const unreadCount = useObservableEagerState(chatStateMgr.unreadCount$);

  useEffect(() => {
    console.log("unreadCount", unreadCount);
  }, [unreadCount]);

  const rightSection = (
    <>
      <div className="lg:hidden">
        <HamburgerButton
          onClick={() => {
            console.log("clicked");
            setIsHamburgerOpen((o) => !o);
          }}
        />
      </div>
      {isHamburgerOpen && (
        <HamburgerMenu
          isOpen={isHamburgerOpen}
          close={() => {
            setIsHamburgerOpen(false);
          }}
          links={[
            ...hamburger.links.map((l) => {
              return (
                <LeftMenuLink
                  to={l.to}
                  name={l.name}
                  icon={l.icon}
                  onClick={() => {
                    setIsHamburgerOpen(false);
                  }}
                />
              );
            }),
            <LogoutLeftMenuLink onClick={hamburger.onSignout} />,
          ]}
          onSignout={hamburger.onSignout}
        />
      )}
      <div className="flex items-center gap-4">
        <ProfileButton {...profileButton} />
      </div>
    </>
  );

  return (
    <NavbarContainer
      title={currentDashboardPage}
      middleSection={middleSection}
      rightSection={rightSection}
    />
  );
};

const ProfileButton: React.FC<{
  to: string;
}> = ({ to }) => {
  const mySimpleProfile = useQuery(api.User.UserFns.getAssumedSimpleMe);

  if (mySimpleProfile === undefined) {
    return null;
  }

  return (
    <Link
      to={to}
      className="hidden lg:flex border rounded-lg w-fit p-4 h-[60px] items-center justify-between"
    >
      <img src="/dashboard/menu.svg" width={20} height={20} className="mr-4" />
      <AvatarCircle
        mbProfilePhoto={ImageSrc.fromMbUrl(mySimpleProfile.profilePhoto)}
        size={30}
      />
    </Link>
  );
};

const HamburgerMenu: React.FC<{
  links: ReactNode[];
  onSignout: () => void;
  isOpen: boolean;
  close: () => void;
}> = ({ links, isOpen, close, onSignout }) => {
  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen bg-white z-50 overflow-scroll ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="flex flex-col gap-4 grow-0 shrink-0 py-8">
        <div className="flex justify-end w-full px-8">
          <button onClick={close} className="text-3xl">
            <img src="/close.svg" width={18} height={18} />
          </button>
        </div>
        <div className="w-full flex justify-center">
          <InsightLiveSvgIcon />
        </div>
        <div className="flex flex-col justify-between min-h-screen">
          <div className="flex flex-col gap-8 mb-8">{links}</div>
          <LogoutLeftMenuLink
            onClick={() => {
              onSignout();
            }}
          />
        </div>
        <div></div>
      </div>
    </div>
  );
};

export const TopLevelTabLink: React.FC<{
  to: string;
  isActive: boolean;
  children: string;
}> = ({ to, isActive, children }) => {
  return (
    <Link
      to={to}
      className={`text-[24px] ${
        isActive ? "text-vid-purple underline" : "text-vid-black-900"
      }`}
    >
      {children}
    </Link>
  );
};
