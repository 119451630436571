import { AvatarCircle, AvatarCircles } from "@/src/components/avatar.tc";
import { WaitingRoomContainer } from "@/src/components/live-session/live-session.components";
import { InversePrimaryButton } from "@/src/components/primitives/button";
import { SimpleTable } from "@/src/components/table.components";
import { FullScreenLoadingSpinner } from "@/src/loading";
import { PastSessionReviewSection } from "@/src/pages/u/sessions/[sessionId]/rtc/waiting-room-page.components";
import { VideoSection } from "@/src/pages/u/sessions/[sessionId]/rtc/waiting-room.page";
import { useCall, useCallStateHooks } from "@stream-io/video-react-sdk";
import { useAction, useMutation, useQuery } from "convex/react";
import { Match } from "effect";
import { useSessionRSM } from "frontend-shared/src/mgrs/remote-state-mgrs/main-room.rsm";
import { useEffect } from "react";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import { ImageSrc } from "shared/types/miscellaneous.types";
import type { SimpleUserWithProfilePhoto } from "shared/types/user.types";

export const WaitingRoom: React.FC = () => {
  const { useLocalParticipant } = useCallStateHooks();
  const meAsParticipant = useLocalParticipant();
  const call = useCall();
  const sessionRSM = useSessionRSM();

  const setUserEnteredWaitingRoom = useMutation(
    api.Sessions.Participants.ParticipantAttendanceFns.setUserEnteredWaitingRoom
  );

  const setUserClickedJoinSessionFromWaitingRoom = useMutation(
    api.Sessions.Participants.Views.WaitingRoomFns.onJoinSessionClick
  );

  useEffect(() => {
    setUserEnteredWaitingRoom({
      baseSessionId: sessionRSM.p.baseSessionId as Id<"sessionConfig">,
      now: Date.now(),
    }).catch();
  }, []);

  const belowVideoDisplayInfoRes = useQuery(
    api.Screens.U.Sessions.Rtc.WaitingRoomScreenFns.getBelowVideoDisplayInfo,
    {
      baseSessionId: sessionRSM.p.baseSessionId,
    }
  );

  const extraDisplayInfoRes = useQuery(
    api.Screens.U.Sessions.Rtc.WaitingRoomScreenFns.getExtraDisplayInfo,
    {
      baseSessionId: sessionRSM.p.baseSessionId,
    }
  );

  if (extraDisplayInfoRes === undefined || meAsParticipant === undefined) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <div className="flex-1 flex flex-col">
      <WaitingRoomContainer
        onBackClick={() => {
          call?.leave().then(() => {
            window.location.href = "/";
          });
        }}
        leftSide={
          <div className="flex flex-col gap-4 items-center justify-end mr-4">
            <VideoSection belowVideoDisplayInfo={belowVideoDisplayInfoRes} />
            {/* <h4 className="text-2xl">Invite others...</h4> */}
          </div>
        }
        rightSide={Match.value(extraDisplayInfoRes).pipe(
          Match.when({ sessionType: "hc" }, ({ pastSessionNotes }) => {
            return (
              <PastSessionReviewSection pastSessionReviews={pastSessionNotes} />
            );
          }),
          Match.when(
            { sessionType: "community-group" },
            ({ membersToInvite }) => {
              return <InviteOthersSection membersToInvite={membersToInvite} />;
            }
          ),
          Match.exhaustive
        )}
        gotoSession={{
          title: "Join session",
          onClick: () => {
            setUserClickedJoinSessionFromWaitingRoom({
              baseSessionId: sessionRSM.p.baseSessionId,
              now: Date.now(),
            }).then((_) => {
              window.location.reload();
            });
          },
        }}
      />
    </div>
  );
};

const InviteOthersSection: React.FC<{
  membersToInvite: SimpleUserWithProfilePhoto[];
}> = ({ membersToInvite }) => {
  return (
    <div className="flex flex-col gap-4">
      <h4 className="text-2xl">Invite others</h4>
      <SimpleTable
        columns={[
          {
            key: "people",
            header: "People",
            render: (m) => {
              return (
                <div className="flex gap-2 items-center">
                  <AvatarCircle
                    mbProfilePhoto={ImageSrc.fromMbUrl(m.profilePhoto)}
                  />
                  <div>{m.name}</div>
                </div>
              );
            },
          },
          {
            key: "invite",
            header: "Invite",
            render: (m) => {
              return <MemberInviteButton memberId={m.id} />;
            },
          },
        ]}
        data={membersToInvite}
      />
    </div>
  );
};

const MemberInviteButton: React.FC<{
  memberId: Id<"users">;
}> = ({ memberId }) => {
  const sessionRSM = useSessionRSM();
  const memberInviteState = useQuery(
    api.Screens.U.Sessions.Rtc.WaitingRoomScreenFns.getMemberInviteState,
    {
      baseSessionId: sessionRSM.p.baseSessionId,
      memberId,
    }
  );

  const onSendInvite = useAction(
    api.Screens.U.Sessions.Rtc.WaitingRoomScreenFns.onSendInviteToMember
  );

  return Match.value(memberInviteState).pipe(
    Match.when("PENDING", () => {
      return <h6 className="font-medium text-vid-purple">Invite sent</h6>;
    }),
    Match.when("NOT_INVITED", () => {
      return (
        <InversePrimaryButton
          title={`Send invite`}
          onClick={() => {
            onSendInvite({
              baseSessionId: sessionRSM.p.baseSessionId,
              memberId,
            })
              .then((r) => {
                console.log("INVITE SENT", r);
              })
              .catch((e) => {
                console.error("ERROR SENDING INVITE", e);
              });
          }}
        />
      );
    }),
    Match.when("ACCEPTED", () => {
      return (
        <InversePrimaryButton
          title={`Invite accepted`}
          onClick={() => {}}
          disabled
        />
      );
    }),
    Match.when(undefined, () => {
      return <InversePrimaryButton title={""} onClick={() => {}} isLoading />;
    }),
    Match.exhaustive
  );
};
