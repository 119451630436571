import type { ConvexClient } from "convex/browser";
import { Effect, type Either } from "effect";
import * as O from "fp-ts/Option";
import * as TE from "fp-ts/TaskEither";
import * as Rx from "rxjs";
import { E, RD } from "shared/base-prelude";
import { RemoteData } from "shared/remote-data";
import { ApiMgr } from "../../api.mgr";
import {
  FetchableStateAtom,
  useKeyOfBehaviorSubjectAsState,
  useKeyOfObservable,
} from "../../util";

export class BaseConvexStateMgr {
  convex: ConvexClient;

  constructor(p: { convex: ConvexClient }) {
    this.convex = p.convex;
  }
}

export class BaseStateMgr {
  BE: ApiMgr;
  convex: ConvexClient;

  constructor(p: { apiMgr: ApiMgr; convex: ConvexClient }) {
    this.BE = p.apiMgr;
    this.convex = p.convex;
  }

  mkFetchableState<V, E = any>(fetchTE: TE.TaskEither<E, V>, initialValue?: V) {
    return new FetchableStateAtom(fetchTE, initialValue);
  }
}

export class BaseFormStateMgr<
  FormData,
  SubmitResult,
  SubmitError extends unknown = unknown,
> extends BaseStateMgr {
  formData$: Rx.BehaviorSubject<FormData>;
  openDropdownId$ = new Rx.BehaviorSubject<keyof FormData | null>(null);
  submitResult$ = new Rx.BehaviorSubject<
    RD.RemoteData<SubmitError, SubmitResult>
  >(RD.initial);

  onSubmit: (formData: FormData) => TE.TaskEither<SubmitError, SubmitResult>;

  constructor(p: {
    apiMgr: ApiMgr;
    convex: ConvexClient;
    mbInitialData: O.Option<FormData>;
    defaultData: FormData;
    onSubmit: (formData: FormData) => TE.TaskEither<SubmitError, SubmitResult>;
  }) {
    super({ apiMgr: p.apiMgr, convex: p.convex });
    this.formData$ = new Rx.BehaviorSubject(
      O.isSome(p.mbInitialData) ? p.mbInitialData.value : p.defaultData
    );
    this.onSubmit = p.onSubmit;
  }

  submit = (onSuccess?: () => void) => {
    this.submitResult$.next(RD.pending);
    console.log("SUBMITTING PAYLOAD ", this.formData$.value);
    this.onSubmit(this.formData$.value)().then((er) => {
      console.log("SUBMIT RESULT ", er);
      this.submitResult$.next(RD.fromEither(er));
      if (E.isRight(er) && onSuccess) {
        onSuccess();
      }
    });
  };

  setFormValue<K extends keyof FormData>(key: K, value: FormData[K]) {
    this.formData$.next({
      ...this.formData$.value,
      [key]: value,
    });
  }

  setOpenDropdownId<K extends keyof FormData>(isOpen: boolean, id: K) {
    console.log("SETTING OPEN DROPDOWN ID ", id, isOpen);
    this.openDropdownId$.next(isOpen ? id : null);
  }
}

export abstract class StandardFormStateMgr<
  FormData,
  SubmitResult = void,
  SubmitError = string,
> extends BaseStateMgr {
  formData$: Rx.BehaviorSubject<FormData>;
  openDropdownId$ = new Rx.BehaviorSubject<keyof FormData | null>(null);
  submitResult$ = new Rx.BehaviorSubject<RemoteData<SubmitResult, SubmitError>>(
    RemoteData.initial()
  );
  defaultData: FormData;

  abstract onSubmit: (
    formData: FormData
  ) => Effect.Effect<SubmitResult, SubmitError>;

  constructor(p: {
    apiMgr: ApiMgr;
    convex: ConvexClient;
    mbInitialData: O.Option<FormData>;
    defaultData: FormData;
  }) {
    super({ apiMgr: p.apiMgr, convex: p.convex });
    console.log("INITIAL DATA ", p.mbInitialData);
    const initialFormVals = O.isSome(p.mbInitialData)
      ? p.mbInitialData.value
      : p.defaultData;
    console.log("INITIAL FORM VALS ", initialFormVals);
    this.formData$ = new Rx.BehaviorSubject(initialFormVals);
    this.defaultData = p.defaultData;
  }

  useFormValue$<K extends keyof FormData>(key: K) {
    return useKeyOfObservable(this.formData$, key);
  }

  submitEff = (): Effect.Effect<Either.Either<SubmitResult, SubmitError>> => {
    const self = this;
    return Effect.gen(function* () {
      self.submitResult$.next(RemoteData.loading());
      console.log("SUBMITTING PAYLOAD ", self.formData$.value);

      const formData = { ...self.formData$.value };
      const er = yield* self.onSubmit(formData).pipe(Effect.either);
      self.submitResult$.next(RemoteData.fromEither(er));
      return er;
    });
  };

  submit(onSuccess?: () => void) {
    Effect.runPromise(this.submitEff()).then(onSuccess);
  }

  submitP() {
    console.log("SUBMITTING PROMISE", this.formData$.value);
    return Effect.runPromise(this.submitEff());
  }

  setFormValue<K extends keyof FormData>(key: K, value: FormData[K]) {
    this.formData$.next({
      ...this.formData$.value,
      [key]: value,
    });
  }

  useFormValue<K extends keyof FormData>(key: K) {
    return useKeyOfBehaviorSubjectAsState(this.formData$, key);
  }

  setOpenDropdownId<K extends keyof FormData>(isOpen: boolean, id: K) {
    console.log("SETTING OPEN DROPDOWN ID ", id, isOpen);
    this.openDropdownId$.next(isOpen ? id : null);
  }
}
