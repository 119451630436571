import type { ConvexClient } from "convex/browser";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import { SessionNotesSchema } from "shared/schemas/session/annotation.schemas";
import { ConvexSessionStateSyncMgr } from "../remote-state-sync.mgr";

class SessionNotesMgr extends ConvexSessionStateSyncMgr<
  typeof SessionNotesSchema.Encoded,
  typeof SessionNotesSchema.Type
> {
  snapshotQueryFunction = api.Sessions.Base.BookmarkFns.getMyNotes;
  snapshotQueryArgs = () => ({
    baseSessionId: this.baseSessionId,
  });
  schema = SessionNotesSchema;
}

export class AnnotationsPanelMgr {
  notesMgr: SessionNotesMgr;

  constructor(
    readonly p: {
      convexCli: ConvexClient;
      baseSessionId: Id<"sessionConfig">;
      userId: Id<"users">;
    }
  ) {
    this.notesMgr = new SessionNotesMgr({
      ...p,
      initialState: SessionNotesSchema.default().encoded,
      schema: SessionNotesSchema,
    });
  }

  subscribeSync = () => {
    this.notesMgr.subscribeSync();
  };

  disposeAll = () => {
    this.notesMgr.dispose();
  };
}
