import { CpOnboardCreateAccountPage } from "@pages/cp-onboard/create-account.page";
import { HpRegisterV2Page } from "@pages/onboard/hp/signup.page";
import { OnboardCompletePage } from "@pages/onboard/onboard-complete.page";
import { OnboardLayout } from "@pages/onboard/onboard.layout";
import type { RouteObject } from "react-router-dom";
import { route, string } from "react-router-typesafe-routes/dom";
import { OnboardHpDurationPreferencePage } from "../pages/onboard/hp/duration-pref.page";
import { OnboardHpTherapyTypesPage } from "../pages/onboard/hp/therapy-types.page";
import { PortalSelectionPage } from "../pages/onboard/portal-selection.page";

export const ONBOARD_ROUTES = route(
  "onboard",
  {},
  {
    PORTAL_SELECTION: route("portal-selection", {}),
    COMPLETE: route("complete", {}),
    ANON: route(
      "anon",
      {},
      {
        CREATE_ACCOUNT: route("create-account", {
          searchParams: {
            email: string(),
          },
        }),
      }
    ),
    HP: route(
      "hp",
      {},
      {
        REGISTER: route("register", {
          searchParams: {
            email: string(),
          },
        }),
        MY: route(
          "my",
          {},
          {
            THERAPY_TYPES: route("therapy-types", {}),
            DURATION_PREFERENCE: route("duration-preference", {}),
          }
        ),
      }
    ),
  }
);

export const onboardRoutes: RouteObject = {
  path: ONBOARD_ROUTES.path,
  element: <OnboardLayout />,
  children: [
    {
      path: ONBOARD_ROUTES.ANON.path,
      children: [
        {
          path: ONBOARD_ROUTES.ANON.CREATE_ACCOUNT.path,
          element: <CpOnboardCreateAccountPage />,
        },
      ],
    },
    {
      path: ONBOARD_ROUTES.PORTAL_SELECTION.path,
      element: <PortalSelectionPage />,
    },
    {
      path: ONBOARD_ROUTES.COMPLETE.path,
      element: <OnboardCompletePage />,
    },
    {
      path: ONBOARD_ROUTES.HP.path,
      children: [
        {
          path: ONBOARD_ROUTES.HP.REGISTER.path,
          element: <HpRegisterV2Page />,
        },
        {
          path: ONBOARD_ROUTES.HP.MY.path,
          children: [
            {
              path: ONBOARD_ROUTES.HP.MY.THERAPY_TYPES.path,
              element: <OnboardHpTherapyTypesPage />,
            },
            {
              path: ONBOARD_ROUTES.HP.MY.DURATION_PREFERENCE.path,
              element: <OnboardHpDurationPreferencePage />,
            },
          ],
        },
      ],
    },
  ],
};
