import { RadioGroup } from "@headlessui/react";
import { FormCardContainer } from "./form.primitives";
import { EllipseMenuIcon } from "../../assets/icons/ellipse.svg";

interface RadioOption {
  name: string;
  value: string | number;
  icon?: React.ReactNode;
}

interface RadioButtonsProps {
  options: RadioOption[];
  value: string | number | null;
  onChange: (value: string | number | null) => void;
}

export const RadioButtons: React.FC<RadioButtonsProps> = ({
  options,
  value,
  onChange,
}) => {
  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      className="flex flex-col gap-5"
    >
      {options.map((option) => (
        <RadioGroup.Option key={option.name} value={option.value}>
          {({ checked }) => (
            <RadioGroupCircle
              checked={checked}
              text={option.name}
              icon={option.icon}
            />
          )}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
};

export const RadioGroupCircle: React.FC<{
  checked: boolean;
  text: string;
  icon?: React.ReactNode;
  size?: number;
}> = ({ checked, text, icon, size = 24 }) => {
  return (
    <div className={`flex justify-between items-center gap-3 cursor-pointer`}>
      <div className="flex items-center">
        {checked ? (
          <CheckedCircleIcon size={size} />
        ) : (
          <UncheckedCircleIcon size={size} />
        )}
        <div
          className={`
            ml-2 text-vid-black-900 ${size <= 24 ? "text-sm" : `text-[${size}px]`}
            font-roboto-flex line-clamp-1
            `}
        >
          {text}
        </div>
      </div>
      {icon && <div className="flex items-center">{icon}</div>}
    </div>
  );
};

export const FormRadioCard: React.FC<{
  radioProps: RadioButtonsProps;
  title: string;
  optionsMenu?: React.ReactNode;
}> = ({ radioProps, title, optionsMenu }) => {
  return (
    <FormCardContainer>
      <div className="flex items-center justify-between">
        <span className="text-vid-purple font-sans font-light text-sm">
          {title}
        </span>
        {optionsMenu && (
          <div>
            <EllipseMenuIcon size={3} fill={"gray"} />
          </div>
        )}
      </div>
      <div className="mt-2 flex-1 flex flex-col font-sans text-sm font-light">
        <RadioButtons {...radioProps} />
      </div>
    </FormCardContainer>
  );
};

function UncheckedCircleIcon({ size = 18 }: { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size + 1}
      fill="none"
      viewBox="0 0 18 19"
    >
      <circle cx="9" cy="9.438" r="8.5" fill="#fff" stroke="#1D1626"></circle>
    </svg>
  );
}

function CheckedCircleIcon({ size = 18 }: { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size + 1}
      fill="none"
      viewBox="0 0 18 19"
    >
      <circle cx="9" cy="9.438" r="8.5" fill="#fff" stroke="#1D1626"></circle>
      <circle
        cx="9"
        cy="9.438"
        r="5.5"
        fill="#690DFF"
        stroke="#690DFF"
      ></circle>
    </svg>
  );
}
