import { MentionsInput, Mention } from "react-mentions";

interface MentionableTextareaProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  data: {
    id: string;
    display: string;
  }[];
  onAdd?: (id: string | number) => void;
  className?: string; // Added className as an optional prop
  style?: React.CSSProperties;
  readOnly?: boolean; // Add this new prop
}

export const MentionableTextarea: React.FC<MentionableTextareaProps> = ({
  value,
  onChange,
  data,
  onAdd,
  className,
  style,
  readOnly = false, // Add this prop with a default value of false
  placeholder,
}) => {
  return (
    <MentionsInput
      value={value}
      onChange={(e) => {
        if (!readOnly) {
          onChange(e.target.value);
        }
      }}
      placeholder={placeholder}
      className={`${className} focus:outline-none focus:ring-1 focus:ring-gray-200`}
      readOnly={readOnly} // Add this prop to MentionsInput
      style={{
        width: "100%",
        height: "100%",
        // backgroundColor: "#F8F8FF",
        // borderRadius: "8px",
        control: {
          height: "56px", // Equivalent to h-14
          width: "594px", // Set a max height if desired
          overflow: "auto",
        },
        input: {
          padding: style?.padding || "0px",
          border: style?.border || "none",
          borderRadius: style?.borderRadius || "8px",
          backgroundColor: style?.backgroundColor || "#F8F8FF",
          overflow: "auto",
          resize: readOnly ? "none" : "vertical", // Disable resize when readOnly
          cursor: readOnly ? "default" : "text", // Change cursor style when readOnly
          ...style,
        },

        suggestions: {
          list: {
            backgroundColor: "white",
            padding: "8px",
            borderRadius: "8px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          },
          item: {
            padding: "5px 15px",
            borderBottom: "1px solid rgba(0,0,0,0.15)",
            "&focused": {
              backgroundColor: "#53CEDF",
            },
          },
        },
      }}
    >
      <Mention
        trigger="@"
        data={data}
        renderSuggestion={(sdi) => {
          return <div>{sdi.display}</div>;
        }}
        onAdd={onAdd}
        style={{ backgroundColor: "rgba(105, 13, 255, 0.1)" }}
        appendSpaceOnAdd={!readOnly} // Prevent adding space when readOnly
      />
    </MentionsInput>
  );
};
