import { createEvent, createStore, sample, createEffect } from "effector";
import type { LucideIcon } from "lucide-react";
import { delay } from "patronum";

export type OpenState = "IDLE" | "CAPTURING" | "SAVING" | "SUCCESS" | "CLOSING";

export type QuickActionState =
  | { tag: "CLOSED" }
  | { tag: "OPEN"; state: OpenState };

export interface QuickActionConfig {
  id: string;
  icon: LucideIcon;
  label: string;
}

export class QuickActionButtonVM {
  // Events
  readonly toggle = createEvent();
  readonly save = createEvent();
  readonly reset = createEvent();
  readonly startCapturingEvt = createEvent();
  readonly setCaptureStateOnly = createEvent();

  // Derived events
  protected readonly startSaving = createEvent();

  // Delayed events using Patronum's delay
  private readonly showSuccessDelayed = delay({
    source: this.startSaving,
    timeout: 500,
  });

  private readonly hideSuccessDelayed = delay({
    source: this.showSuccessDelayed,
    timeout: 700,
  });

  private readonly startClosing = delay({
    source: this.hideSuccessDelayed,
    timeout: 300,
  });

  // Create the base event first
  private readonly initiateClose = createEvent();

  // Then use it in delay
  private readonly closeAfterDelay = delay({
    source: this.initiateClose,
    timeout: 500,
  });

  // Store
  readonly $state = createStore<QuickActionState>({ tag: "CLOSED" });

  // Add the save effect
  readonly saveFx = createEffect();

  constructor(config?: {
    onSave?: () => Promise<void>;
    onCapture?: () => Promise<void>;
  }) {
    this.saveFx.use(config?.onSave ?? (() => Promise.resolve()));

    // Handle toggle
    sample({
      clock: this.toggle,
      source: this.$state,
      fn: (state): QuickActionState =>
        state.tag === "CLOSED"
          ? { tag: "OPEN", state: "IDLE" as const }
          : { tag: "OPEN", state: "CLOSING" as const },
      target: this.$state,
    });

    // Handle actual closing after animation
    sample({
      clock: this.toggle,
      source: this.$state,
      filter: (
        state: QuickActionState
      ): state is { tag: "OPEN"; state: "CLOSING" } =>
        state.tag === "OPEN" && state.state === "CLOSING",
      fn: () => undefined,
      target: this.initiateClose,
    });

    // Separate sample for updating state
    sample({
      clock: this.closeAfterDelay,
      fn: (): QuickActionState => ({ tag: "CLOSED" }),
      target: this.$state,
    });

    // Handle capture state
    sample({
      clock: this.startCapturingEvt,
      fn: (): QuickActionState => ({
        tag: "OPEN",
        state: "CAPTURING" as const,
      }),
      target: this.$state,
    });

    // New sample for setCaptureStateOnly
    sample({
      clock: this.setCaptureStateOnly,
      fn: (): QuickActionState => ({
        tag: "OPEN",
        state: "CAPTURING" as const,
      }),
      target: this.$state,
    });

    if (config?.onCapture) {
      sample({
        clock: this.startCapturingEvt,
        target: createEffect(config.onCapture),
      });
    }

    // Simplify the save flow to just pass through the string
    sample({
      clock: this.save,
      target: this.saveFx,
    });

    // Chain the save action after capturing is set to false
    sample({
      clock: this.save,
      target: this.startSaving,
    });

    sample({
      clock: this.startSaving,
      fn: (): QuickActionState => ({
        tag: "OPEN",
        state: "SAVING" as const,
      }),
      target: this.$state,
    });

    // Handle success state after delay
    sample({
      clock: this.showSuccessDelayed,
      fn: (): QuickActionState => ({
        tag: "OPEN",
        state: "SUCCESS" as const,
      }),
      target: this.$state,
    });

    // Modify the hideSuccessDelayed handler to trigger closing state first
    sample({
      clock: this.hideSuccessDelayed,
      fn: (): QuickActionState => ({
        tag: "OPEN",
        state: "CLOSING" as const,
      }),
      target: this.$state,
    });

    // Actually close after animation
    sample({
      clock: this.startClosing,
      fn: (): QuickActionState => ({
        tag: "CLOSED",
      }),
      target: this.$state,
    });

    // Update reset handling
    sample({
      clock: this.reset,
      fn: (): QuickActionState => ({
        tag: "CLOSED",
      }),
      target: this.$state,
    });
  }

  destroy() {
    this.reset();
  }
}
