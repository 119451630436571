import { AnonDashboardContainer } from "@/src/domains/anon/anon.dashboard";
import { GlobalPractitionerDashUnAuthedView } from "@/src/domains/communities/global-practitioner-dash";

export const MyGlobalProfilePage: React.FC = () => {
  return (
    <AnonDashboardContainer>
      <GlobalPractitionerDashUnAuthedView />
    </AnonDashboardContainer>
  );
};
