import { InputContainer } from "@/src/components/form/form-inputs.fc";
import { InversePrimaryButton } from "@/src/components/primitives/button";
import { RadioButtons } from "@/src/components/primitives/radio-buttons";
import { useUnit } from "effector-react";
import { createState, useOnce } from "frontend-shared/src/util";
import React from "react";

class SetupBreakoutRoomsVM {
  numGroupsVM = createState<number | null>(null);
  isCustomGroupsVM = createState(false);
  customGroupsInputVM = createState<string>("");
}

export const SetupBreakoutRoomsRightDrawer: React.FC = () => {
  const vm = useOnce(() => new SetupBreakoutRoomsVM());

  return (
    <div className="flex flex-col p-6 gap-6">
      <SetupBreakoutRoomsForm vm={vm} />
    </div>
  );
};

export const SetupBreakoutRoomsForm: React.FC<{
  vm: SetupBreakoutRoomsVM;
}> = ({ vm }) => {
  const [
    numGroups,
    setNumGroups,
    isCustomGroups,
    setIsCustomGroups,
    customGroupsInput,
    setCustomGroupsInput,
  ] = useUnit([
    vm.numGroupsVM.store,
    vm.numGroupsVM.event,
    vm.isCustomGroupsVM.store,
    vm.isCustomGroupsVM.event,
    vm.customGroupsInputVM.store,
    vm.customGroupsInputVM.event,
  ]);

  const handleCustomInputChange = (value: string) => {
    setCustomGroupsInput(value);
    const numValue = parseInt(value);
    if (!isNaN(numValue) && numValue > 0) {
      setNumGroups(numValue);
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <InputContainer label={{ text: "Number of groups", color: "black" }}>
        <div className="flex flex-col gap-6">
          <RadioButtons
            options={[
              {
                name: "2 groups",
                value: 2,
                icon: <CloudBeepIcon />,
              },
              { name: "3 groups", value: 3, icon: <CloudBeepIcon /> },
              { name: "4 groups", value: 4, icon: <CloudBeepIcon /> },
              { name: "Custom", value: "custom", icon: <CloudBeepIcon /> },
            ]}
            value={isCustomGroups ? "custom" : numGroups}
            onChange={(value) => {
              if (value === "custom") {
                setIsCustomGroups(true);
                setNumGroups(null);
              } else {
                setIsCustomGroups(false);
                setNumGroups(value as number);
              }
            }}
          />
          {isCustomGroups && (
            <div className="flex flex-col gap-2">
              <input
                type="number"
                min="1"
                value={customGroupsInput}
                onChange={(e) => handleCustomInputChange(e.target.value)}
                className="px-3 py-2 border rounded-md"
                placeholder="Enter number of groups"
              />
            </div>
          )}
          <InversePrimaryButton
            onClick={() => {}}
            title="Randomly assign"
            color="black"
            width="100%"
          />
        </div>
      </InputContainer>
    </div>
  );
};

const CloudBeepIcon: React.FC = () => {
  return <img src={"/tools/cloud-beep-icon.svg"} />;
};
