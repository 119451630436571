import { Menu, Transition } from "@headlessui/react";
import { format } from "date-fns";
import type { CalendarStateMgr } from "frontend-shared/src/mgrs/state-mgrs/calendar.statemgr";
import { createContextAndHook } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import { Fragment, useEffect } from "react";
import type { KnownCalendarAppt } from "shared/convex/Calendar/Calendar.Types";
import { CalendarUtils } from "shared/utils/calendar.utils";
import { InversePrimaryButton } from "../primitives/button";
import { DayViewCalendar } from "./calendar-day-view.fc";
import { CalendarMonthView } from "./calendar-month-view.fc";

interface FullCalendarProps {
  stateMgr: CalendarStateMgr;
  onDayClick: (day: Date) => void;
  newApptButton?: {
    onClick: () => void;
    title: string;
  };
  onApptClick: (appt: KnownCalendarAppt) => void;
  fetchOnMount?: boolean;
}

const [StateMgrContext, useStateMgr] = createContextAndHook<
  CalendarStateMgr | undefined
>(undefined);

export const FullCalendar = ({
  stateMgr,
  onDayClick,
  onApptClick,
  newApptButton,
  fetchOnMount,
}: FullCalendarProps) => {
  const rdMonthAppts = useObservableEagerState(
    stateMgr.monthViewStateMgr.appointmentsForNearbyMonths$
  );
  const dayInFocus = useObservableEagerState(
    stateMgr.monthViewStateMgr.currentDayInFocus$
  );
  const viewState = useObservableEagerState(stateMgr.viewState$);

  useEffect(() => {
    if (fetchOnMount) {
      stateMgr.fetchAndSetAppts(dayInFocus);
    }
  }, [fetchOnMount]);

  return (
    <StateMgrContext.Provider value={stateMgr}>
      <div className="flex-1 flex flex-col">
        <CalendarNavbar newApptButton={newApptButton} />
        <div className="flex-1 min-h-0">
          {viewState._tag === "DAY" ? (
            <DayViewCalendar
              appointmentsForCurrentDay$={
                stateMgr.dayViewStateMgr.appointmentsForCurrentDay$
              }
            />
          ) : (
            <CalendarMonthView
              rdDaysWithEventsForMonth={rdMonthAppts}
              dayInFocus={CalendarUtils.asDOY(dayInFocus)}
              onDayClick={onDayClick}
              onApptClick={onApptClick}
            />
          )}
        </div>
      </div>
    </StateMgrContext.Provider>
  );
};

const CalendarNavbar: React.FC<{
  newApptButton?: { onClick: () => void; title: string };
}> = ({ newApptButton }) => {
  const stateMgr = useStateMgr()!;
  const viewState = useObservableEagerState(stateMgr.viewState$);

  return (
    <div className="flex justify-between items-center mx-4">
      {viewState._tag === "DAY" ? (
        <DayViewDayToggles />
      ) : (
        <CalendarMonthViewDateToggles />
      )}
      <div className="flex gap-4">
        <DayMonthViewToggleButton />
        {newApptButton && (
          <InversePrimaryButton
            title={newApptButton.title}
            onClick={newApptButton.onClick}
            width={"200px"}
          />
        )}
      </div>
    </div>
  );
};

const CalendarMonthViewDateToggles: React.FC = () => {
  const stateMgr = useStateMgr()!;
  const model = stateMgr.monthViewStateMgr;
  const currentMonth = useObservableEagerState(
    model.monthInFocusNameToDisplay$
  );

  return (
    <div className="flex gap-8 items-center">
      <h1 className="font-sans font-light text-xl w-[100px]">{currentMonth}</h1>
      <div className="flex gap-4">
        <button
          onClick={() => {
            model.goToPreviousMonth();
          }}
        >
          <BackwardArrowSvgIcon />
        </button>
        <button
          onClick={() => {
            model.goToNextMonth();
          }}
        >
          <ForwardArrowSvgIcon />
        </button>
      </div>
    </div>
  );
};

const DayMonthViewToggleButton: React.FC = () => {
  const stateMgr = useStateMgr()!;
  const viewState = useObservableEagerState(stateMgr.viewState$);
  return (
    <Menu as="div" className="relative flex">
      <div>
        <Menu.Button className="w-full h-full justify-center rounded-xl px-4 py-2 text-sm border text-black hover:bg-vid-gray/10 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/7 flex items-center">
          <p className="mr-2">{viewState._tag === "DAY" ? "Day" : "Month"}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M16.6 7.45837L11.1666 12.8917C10.525 13.5334 9.47496 13.5334 8.8333 12.8917L3.39996 7.45837"
              stroke="#161616"
              strokeWidth="1.2"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-40 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
          <div className="px-1 py-1 ">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-violet-500 text-white" : "text-gray-900"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={() => {
                    stateMgr.setViewState({ _tag: "MONTH" });
                  }}
                >
                  Month
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-violet-500 text-white" : "text-gray-900"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={() => {
                    stateMgr.setViewState({ _tag: "DAY" });
                  }}
                >
                  Day
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const DayViewDayToggles: React.FC = () => {
  const stateMgr = useStateMgr()!;
  const model = stateMgr.dayViewStateMgr;
  const currentDay = useObservableEagerState(model.selectedDate$);

  return (
    <div className="flex gap-8 items-center">
      <h1 className="font-bold">{format(currentDay, "EEEE, MMMM d, yyyy")}</h1>
      <div className="flex gap-4">
        <button
          onClick={() => {
            model.prevDay();
          }}
        >
          <BackwardArrowSvgIcon />
        </button>
        <button
          onClick={() => {
            model.nextDay();
          }}
        >
          <ForwardArrowSvgIcon />
        </button>
      </div>
    </div>
  );
};

const ForwardArrowSvgIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.91 19.9201L15.43 13.4001C16.2 12.6301 16.2 11.3701 15.43 10.6001L8.91 4.08008"
        stroke="#1D1626"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const BackwardArrowSvgIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.09 19.9201L8.57 13.4001C7.8 12.6301 7.8 11.3701 8.57 10.6001L15.09 4.08008"
        stroke="#1D1626"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
