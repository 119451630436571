import { HpStateContext } from "@/src/pages/u/hp/hp.webstatemgr";
import { AvatarCircles } from "@webapp/components/avatar.tc";
import {
  InversePrimaryButton,
  PrimaryButton,
} from "@webapp/components/primitives/button";
import { ThreeDotsMenu } from "@webapp/componentsmenus/three-dots.menu";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { useMutation, useQuery } from "convex/react";
import { Match } from "effect";
import { useOnce } from "frontend-shared/src/util";
import { useNavigate } from "react-router-dom";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import type { CommunityEventDetailsPanelST } from "shared/convex/Calendar/Calendar.Types";
import { UROUTES } from "shared/routes/u.routes";

export const CommunityEventDetailsPanel: React.FC<{
  instanceId: Id<"calEventInstances">;
  communitySlug: string;
  closePanel: (p: { message: string } | null) => void;
}> = ({ instanceId, communitySlug, closePanel }) => {
  const nav = useNavigate();
  const fullyRemoveCommunityCalEvent = useMutation(
    api.Calendar.CommunityCalEventFns.fullyRemoveCommunityCalEvent
  );
  const removeCommunityCalEventSingleInstance = useMutation(
    api.Calendar.CommunityCalEventFns.removeCommunityCalEventSingleInstance
  );
  const onSubmitCta = useMutation(
    api.Screens.Community.CommunityEventsScreenFns.handleCtaSubmit
  );

  const inviteAllInCommunityToEvent = useMutation(
    api.Community.CommunityScreenFns.inviteAllInCommunityToEvent
  );

  const now = useOnce(() => Date.now());

  const panelData = useQuery(
    api.Screens.Community.CommunityEventsScreenFns
      .getCommunityEventDetailsPanelData,
    {
      now,
      instanceId,
    }
  );

  const onCardClick = (
    panelData: typeof CommunityEventDetailsPanelST.Encoded
  ) => {
    onSubmitCta({
      templateId: panelData.templateId as Id<"calendarEventTemplates">,
      instanceId,
      cta: panelData.button.cta,
    }).then((r) => {
      console.log("RESULT OF SUBMIT CTA!!! ", r);

      closePanel(null);

      const nextAction: () => void = Match.value(r).pipe(
        Match.when({ nextAction: "GO_TO_SESSION" }, ({ baseSessionId }) => {
          return () =>
            nav(
              UROUTES.SESSIONS.SESSION_ID.RTC.buildPath({
                sessionId: baseSessionId,
              })
            );
        }),
        Match.when({ nextAction: "CLOSE_AND_REFRESH" }, () => {
          return () => closePanel(null);
        }),
        Match.when({ nextAction: "DO_NOTHING" }, () => {
          return () => {};
        }),
        Match.exhaustive
      );

      nextAction();
    });
  };

  if (panelData === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex flex-col gap-4 p-4">
      <div className="flex justify-end px-4">
        <ThreeDotsMenu
          size={30}
          menuItems={[
            {
              label: "Remove this event from cal",
              onClick: () => {
                closePanel({ message: "Removedevent" });
                removeCommunityCalEventSingleInstance({
                  instanceId,
                }).then(() => {});
              },
            },
            {
              label: "Remove all recurring events",
              onClick: () => {
                closePanel({ message: "Removed events" });
                fullyRemoveCommunityCalEvent({
                  templateId:
                    panelData.templateId as Id<"calendarEventTemplates">,
                }).then(() => {});
              },
            },
            {
              label: "Invite all in community",
              onClick: () => {
                closePanel({ message: "Invited all in community" });
                inviteAllInCommunityToEvent({
                  communitySlug,
                  instanceId,
                }).then(() => {});
              },
            },
          ]}
        />
      </div>
      <div className="text-2xl font-bold">{panelData.title}</div>
      <div className="text-md text-vid-black-500">{panelData.description}</div>
      {panelData.attendees.length > 0 && (
        <AvatarCircles
          sources={panelData.attendees.map((a) => a.user.profilePhoto)}
          size={44}
        />
      )}
      <div className="text-md text-vid-black-500">
        {panelData.occurenceMessage}
      </div>
      <div className="text-md text-vid-black-500">{panelData.occursAtStr}</div>
      <div>
        {Match.value(panelData.button).pipe(
          Match.when({ style: "REGULAR" }, () => (
            <PrimaryButton
              title={panelData.button.title}
              onClick={() => {
                onCardClick(panelData);
              }}
            />
          )),
          Match.when({ style: "INVERSE" }, () => (
            <InversePrimaryButton
              title={panelData.button.title}
              onClick={() => {
                onCardClick(panelData);
              }}
            />
          )),
          Match.exhaustive
        )}
      </div>
    </div>
  );
};
