import * as S from "@effect/schema/Schema";
import { Array, Order } from "effect";
import { epipe } from "../../../base-prelude";
import { isNotNullOrUndefined } from "../../../util";
import {
  CommunityCalEventInstanceCtaAction,
  type CommunityEventInstanceST,
} from "../../Calendar/Calendar.Types";
import type { Doc, Id } from "../../_generated/dataModel";

export class UpcomingCommunityCalEventInstanceCard extends S.Class<UpcomingCommunityCalEventInstanceCard>(
  "UpcomingCommunityCalEventInstanceCard"
)({
  templateId: S.String,
  instance: S.Struct({
    _id: S.String,
    startAt: S.Number,
    endsAt: S.Number,
  }),
  title: S.String,
  occurenceMessage: S.NullOr(S.String),
  occursAtStr: S.String,
  participants: S.Array(
    S.Struct({
      profilePhoto: S.NullOr(S.String),
      name: S.String,
    })
  ),
  communitySlug: S.String,
  button: S.NullOr(
    S.Struct({
      title: S.String,
      isDisabled: S.Boolean,
      cta: CommunityCalEventInstanceCtaAction,
    })
  ),
  isLive: S.Boolean,
  isLoading: S.Boolean,
  mbSessionId: S.NullOr(S.String),
}) {
  static fromCommunityCalEventInstance(
    ceist: CommunityEventInstanceST,
    args: {
      mbSessionLifecycle: Doc<"sessionLifecycle"> | null;
      myUserId: Id<"users">;
      now: number;
    }
  ): UpcomingCommunityCalEventInstanceCard {
    const button = ceist.instanceST.getMyCtaButtonInfo({
      myUserId: args.myUserId,
      now: args.now,
      mbSessionLifecycle: args.mbSessionLifecycle,
    });
    const isLive = isNotNullOrUndefined(args.mbSessionLifecycle?.startedAt);
    return UpcomingCommunityCalEventInstanceCard.make({
      templateId: ceist.templateId,
      instance: {
        _id: ceist.instanceST.instance._id,
        startAt: ceist.instanceST.instance.startTime,
        endsAt: ceist.instanceST.instance.endTime,
      },
      title: ceist.instanceST.template.title,
      occurenceMessage: ceist.occurenceMessage,
      occursAtStr: ceist.occursAtStr,
      participants: ceist.instanceST.participants.map((p) => ({
        profilePhoto: p.user.profilePhoto,
        name: p.user.name,
      })),
      communitySlug: ceist.community.slug,
      button,
      isLive,
      isLoading: false,
      mbSessionId: args.mbSessionLifecycle?.baseSessionId ?? null,
    });
  }

  get encoded(): typeof UpcomingCommunityCalEventInstanceCard.Encoded {
    return S.encodeUnknownSync(UpcomingCommunityCalEventInstanceCard)(this);
  }

  static asDefaultLoading(p: {
    template: Doc<"calendarEventTemplates">;
    communitySlug: string;
    instance: Doc<"calEventInstances">;
  }): UpcomingCommunityCalEventInstanceCard {
    return UpcomingCommunityCalEventInstanceCard.make(
      {
        templateId: p.template._id,
        instance: {
          _id: p.instance._id,
          startAt: p.instance.startTime,
          endsAt: p.instance.endTime,
        },
        title: p.template.title,
        occursAtStr: "",
        occurenceMessage: null,
        participants: [],
        communitySlug: p.communitySlug,
        button: null,
        isLive: false,
        isLoading: true,
        mbSessionId: null,
      },
      { disableValidation: true }
    );
  }
}

export class UpcomingCommunityCalEventInstanceCards extends S.Class<UpcomingCommunityCalEventInstanceCards>(
  "UpcomingCommunityCalEventInstanceCards"
)({
  cards: S.Array(UpcomingCommunityCalEventInstanceCard),
}) {
  static default: UpcomingCommunityCalEventInstanceCards =
    UpcomingCommunityCalEventInstanceCards.make({
      cards: [],
    });

  get withSortedCards(): UpcomingCommunityCalEventInstanceCards {
    const byStartsAt = Order.mapInput(
      Order.number,
      (card: UpcomingCommunityCalEventInstanceCard) => card.instance.startAt
    );
    const sortedCards = epipe(this.cards, Array.sortBy(byStartsAt));
    return UpcomingCommunityCalEventInstanceCards.make(
      {
        cards: sortedCards,
      },
      { disableValidation: true }
    );
  }

  get encoded(): typeof UpcomingCommunityCalEventInstanceCards.Encoded {
    return S.encodeUnknownSync(UpcomingCommunityCalEventInstanceCards)(this);
  }
}

export interface AllCommunityEventsScreenData {
  upcomingCommunityCalEventInstances: typeof UpcomingCommunityCalEventInstanceCards.Encoded;
}
