import { TopLevelTabLink } from "@webapp/componentsdashboard/dashboard-layout.fc";
import { useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { UROUTES } from "shared/routes/u.routes";

const TOP_TABS = [
  {
    title: "Account",
    path: UROUTES.HP.MY.DASHBOARD.SETTINGS.ACCOUNT.path + "#practice-info",
    param: "account",
  },
  {
    title: "Clients And Scheduling",
    path: UROUTES.HP.MY.DASHBOARD.SETTINGS.CLIENT_AND_SCHEDULING.path,
    param: "clients-and-scheduling",
  },
  {
    title: "Billing",
    path: UROUTES.HP.MY.DASHBOARD.SETTINGS.BILLING.path,
    param: "billing",
  },
];

export const HpSettingsLayout: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isActiveTab = useMemo(() => {
    return (
      TOP_TABS.find((tab) => pathname.includes(tab.param))?.param || "account"
    );
  }, [pathname]);

  useEffect(() => {
    if (pathname === UROUTES.HP.MY.DASHBOARD.SETTINGS.path) {
      navigate(
        UROUTES.HP.MY.DASHBOARD.SETTINGS.ACCOUNT.buildPath(
          {},
          undefined,
          "practice-info"
        )
      );
    }
  }, [pathname, navigate]);

  return (
    <div className="flex flex-col">
      <div className="flex gap-32 pl-4">
        {TOP_TABS.map((tab) => (
          <TopLevelTabLink
            key={tab.param}
            to={tab.path}
            isActive={isActiveTab === tab.param}
          >
            {tab.title}
          </TopLevelTabLink>
        ))}
      </div>
      <div className="flex-1 flex flex-col pt-8">
        <Outlet />
      </div>
    </div>
  );
};
