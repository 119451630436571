import { Combobox } from "@headlessui/react";
import { useUnit } from "effector-react";
import type {
  BaseSearchableDropdownVM,
  SearchableEntity,
} from "frontend-shared/src/shared-vms/searchable-dropdown.vm";
import React from "react";
import { SearchInput } from "../../form/form-inputs.fc";

interface DashboardSearchProps<T extends SearchableEntity> {
  vm: BaseSearchableDropdownVM<T>;
  renderItem?: (item: T) => React.ReactNode;
  placeholder?: string;
}

export const DashboardSearch = <T extends SearchableEntity>({
  vm,
  renderItem,
  placeholder = "Search",
}: DashboardSearchProps<T>) => {
  const searchText = useUnit(vm.$searchText);
  const filteredItems = useUnit(vm.$filteredItems);
  const isLoading = "loadItemsFx" in vm ? useUnit(vm.$isLoading) : false;

  // Default item renderer if none provided
  const defaultRenderItem = (item: T) => (
    <div className="flex items-center justify-between">
      <div>
        <h4 className="text-sm font-medium text-gray-900">{item.label}</h4>
        {item.details && (
          <p className="text-xs text-gray-500">{item.details}</p>
        )}
      </div>
      {item.type && (
        <span className="text-xs font-medium text-gray-400 capitalize">
          {item.type}
        </span>
      )}
    </div>
  );

  const actualRenderItem = renderItem || defaultRenderItem;

  const handleSelect = (item: T | null) => {
    vm.itemSelected(item);
    vm.searchTextChanged("");
  };

  return (
    <Combobox
      as="div"
      value={null}
      onChange={handleSelect}
      className="relative w-full max-w-2xl"
    >
      <SearchInput
        value={searchText}
        onChange={(value) => vm.searchTextChanged(value)}
        placeholder={placeholder}
        as={Combobox.Input}
        inputClassName="w-full"
      />

      <Combobox.Options className="absolute w-full mt-2 bg-white rounded-lg shadow-lg border border-gray-200 max-h-96 overflow-y-auto z-50">
        {isLoading ? (
          <div className="px-4 py-3 text-sm text-gray-500">Loading...</div>
        ) : filteredItems.length > 0 ? (
          filteredItems.map((item) => (
            <Combobox.Option
              key={item.id}
              value={item}
              className={({ active }) =>
                `px-4 py-3 cursor-pointer border-b last:border-b-0 ${
                  active ? "bg-gray-50" : ""
                }`
              }
            >
              {actualRenderItem(item)}
            </Combobox.Option>
          ))
        ) : (
          <div className="px-4 py-3 text-sm text-gray-500">
            No results found
          </div>
        )}
      </Combobox.Options>
    </Combobox>
  );
};
